import {useMutation, useQuery} from "react-query";
import {cacheKeys} from "../../constants/allConsts";
import {addStyle, getStyles, updateStyle, deleteStyle} from "../../services/rowModels/styleServices";
import {IStyle} from "../../types/rowModelTypes";

// TODO extend useGetStyles to be able to use getStyleById once it's implemented (see usePurchaseOrders.ts for example)
export const useGetStyles = (): any => {
  const result = useQuery({
    queryKey: [cacheKeys.styleData],
    queryFn: () => getStyles(),
  });
  return result;
};

// TODO implement useGetStyleBasic (see usePurchaseorders.ts for example)

export const useAddStyle = () => {
  const result = useMutation(async (update: IStyle) => {
    return await addStyle(update);
  });
  return result;
};

export const useUpdateStyle = () => {
  const result = useMutation(async (update: IStyle) => {
    return await updateStyle(update);
  });
  return result;
};

export const useDeleteStyle = () => {
  const result = useMutation(async (update: IStyle) => {
    return await deleteStyle(update);
  });
  return result;
};
