import {useMutation, useQuery} from "react-query";
import {cacheKeys} from "../../constants/allConsts";
import {addColor, deleteColor, getColors, updateColor} from "../../services/rowModels/colorServices";
import {IColor} from "../../types/rowModelTypes";

// TODO extend useGetColors to be able to use getColorById once it's implemented (see usePurchaseOrders.ts for example)
export const useGetColors = (): any => {
  const result = useQuery({
    queryKey: [cacheKeys.colorData],
    queryFn: () => getColors(),
  });
  return result;
};

// TODO implement useGetAdjustmentBasic (see usePurchaseorders.ts for example)

export const useAddColor = () => {
  const result = useMutation(async (update: IColor) => {
    return await addColor(update);
  });
  return result;
};

export const useUpdateColor = () => {
  const result = useMutation(async (update: IColor) => {
    return await updateColor(update);
  });
  return result;
};

export const useDeleteColor = () => {
  const result = useMutation(async (update: IColor) => {
    return await deleteColor(update);
  });
  return result;
};
