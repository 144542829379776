import React, {forwardRef, useState, useImperativeHandle, useRef, useEffect} from 'react';
import styled from "styled-components";
import {MenuItem, Popper} from '@material-ui/core';
import {GridApi} from "ag-grid-community";

const BasicSelectionEditor = forwardRef((props: {value: string, options: string[], api?: GridApi}, ref: any) => {
  // output
  const [value, setValue] = useState<string>(props?.value || '');
  // stores anchor element for popper
  const [anchorEl, setAnchorEl] = React.useState(null);

  const inputRef = useRef(null);
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value || '';
      },
      afterGuiAttached: () => {
        setValue(props?.value || '');
      }
    };
  });

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => setAnchorEl(inputRef.current), 10);
    }
  }, [inputRef]);

  const open = Boolean(anchorEl);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  const handleChange = (option: string) => {
    setValue(option);
    setTimeout(() => props?.api?.stopEditing(), 50);
  };

  return (
    <StyledContainer>
      <input type={"text"} value={value} ref={inputRef} onChange={handleInputChange} readOnly />
      <Popper id={"popper"} open={open} anchorEl={anchorEl} disablePortal={true} placement="bottom-start">
        <StyledOptionsContainer className={"options-container"}>

          {props?.options?.map((op: string) => {
            return <MenuItem value={op} key={op} onClick={() => handleChange(op)}>{op}</MenuItem>;
          })}

        </StyledOptionsContainer>
      </Popper>
    </StyledContainer>
  );
});

export {BasicSelectionEditor};

const StyledContainer = styled.span`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  background: white;
  display: flex;
  position: absolute;
  input {
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
    outline: inherit;
    border: inherit;
    border-radius: inherit;
  }
  #popper {
    width: 100%;
    margin-top: 2px;
  }
`;


const StyledOptionsContainer = styled.div`
  width: auto;
  background: white;
  box-shadow: 2px 3px 9px rgba(0,0,0,0.1);
  min-width: 150px;
`;
