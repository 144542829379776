import React, {useCallback, useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {Button, DialogActions, DialogContent, DialogProps, DialogTitle, Slide} from "@material-ui/core";
import {TransitionProps} from "@material-ui/core/transitions";
import styled from "styled-components";
import CloseIcon from '@material-ui/icons/Close';
import {colors} from "../../constants/allConsts";
import {useGetRolls, useUpdateRoll} from "../../hooks/rowModels/useRolls";
import {IRoll} from "../../types/rowModelTypes";
import {utils} from "../../utils/utils";
import {MuiButtonPrimary} from "../../styledComponentPresets/presets";
import {useQueryClient} from "react-query";
import {GridApi} from "ag-grid-community";
import {useAddAdjustment} from "../../hooks/rowModels/useAdjustments";
import {rollUtils} from "../../utils/rollUtils";

interface IProps {
  open: boolean;
  close: () => any;
  rollAdjustmentsId: string;
  size?: string;
  gridApi: GridApi | null;
}

const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RollAdjustmentModal = ({open, close, rollAdjustmentsId, size, gridApi}: IProps) => {
  const [currentRollData, setCurrentRollData] = useState<IRoll>();
  const [fullWidth, setFullWidth] = React.useState(true);
  //@ts-ignore
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>(size || 'lg');
  const [yards, setYards] = useState<string>('0');
  const [poundsOut, setPoundsOut] = useState<string>('0');
  const [cutSewNumber, setCutSewNumber] = useState<string>('');
  const [notes, setNotes] = useState<string>('');

  const {data, isLoading} = useGetRolls();

  const {mutate: updateRoll} = useUpdateRoll();
  const {mutate: addAdjustment} = useAddAdjustment();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isLoading) {
      const currentRoll = data.filter((roll: IRoll) => roll?.id === rollAdjustmentsId)[0];
      if (currentRoll) {
        setCurrentRollData(currentRoll);
      }
    }
  }, [isLoading]);

  const handleCutSewNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCutSewNumber(event.target.value);
  };

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);
  };

  const handleYardsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (Number(value) <= (Number(currentRollData?.yd)) * -1) {
      const newYard = (Number(currentRollData?.yd) * -1);
      setYards(newYard.toString());
      adjustPoundsOutBasedOnYard(newYard);
    } else if (/^(-|)([0-9])*\.*([0-9])*$/.test(event.target.value)) {
      setYards(value);
      adjustPoundsOutBasedOnYard(Number(value));
    }
  };

  const handlePoundsOutChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (Number(value) <= (Number(currentRollData?.lb_out)) * -1) {
      const newPoundsOut = (Number(currentRollData?.lb_out) * -1);
      setPoundsOut(newPoundsOut.toString());
      adjustYardsBasedOnPoundsOut(newPoundsOut);
    } else if (/^(-|)([0-9])*\.*([0-9])*$/.test(event.target.value)) {
      setPoundsOut(value);
      adjustYardsBasedOnPoundsOut(Number(value));
    }
  };

  const onYardsBlur = () => {
    if (yards === '' || yards === '-') setYards('0');
  };

  const onPoundsOutBlur = () => {
    if (poundsOut === '' || poundsOut === '-') setPoundsOut('0');
  };

  const incrementYards = () => {
    const newYard = (Number(yards) + 1);
    setYards(utils.roundNumber(newYard, 3).toString());
    adjustPoundsOutBasedOnYard(newYard);
  };

  const decrementYards = () => {
    if (Number(yards) > Number(currentRollData?.yd) * -1) {
      let newYard = (Number(yards) - 1);
      if (newYard < Number(currentRollData?.yd) * -1) newYard = (Number(currentRollData?.yd) * -1);
      setYards(utils.roundNumber(newYard, 3).toString());
      adjustPoundsOutBasedOnYard(newYard);
    }
  };

  const incrementPounds = () => {
    const newPoundsOut = (Number(poundsOut) + 1);
    setPoundsOut(utils.roundNumber(newPoundsOut, 3).toString());
    adjustYardsBasedOnPoundsOut(newPoundsOut);
  };

  const decrementPounds = () => {
    if (Number(poundsOut) > Number(currentRollData?.lb_out) * -1) {
      let newPoundsOut = (Number(poundsOut) - 1);
      if (newPoundsOut < Number(currentRollData?.lb_out) * -1) newPoundsOut = (Number(currentRollData?.lb_out) * -1);
      setPoundsOut(utils.roundNumber(newPoundsOut, 3).toString());
      adjustYardsBasedOnPoundsOut(newPoundsOut);
    }
  };

  const adjustPoundsOutBasedOnYard = (newYard: number) => {
    if (currentRollData?.lb_per_yd !== '' && !isNaN(Number(currentRollData?.lb_per_yd || 'false'))) {
      if (newYard <= (Number(currentRollData?.yd) * -1)) {
        setPoundsOut((Number(currentRollData?.lb_out) * -1).toString());
        return;
      }
      const poundsPerYard = Number(currentRollData?.lb_per_yd);
      const adjustedPoundsOut = (((poundsPerYard * 100) * Number(newYard)) / 100);
      const roundedPoundsOut = utils.roundNumber(adjustedPoundsOut, 3);
      setPoundsOut(roundedPoundsOut.toString() !== 'NaN' ? roundedPoundsOut.toString() : '0');
    }
  };

  const adjustYardsBasedOnPoundsOut = (newPoundsOut: number) => {
    if (currentRollData?.lb_per_yd !== '' && !isNaN(Number(currentRollData?.lb_per_yd || 'false'))) {
      if (newPoundsOut <= (Number(currentRollData?.lb_out) * -1)) {
        setYards((Number(currentRollData?.yd) * -1).toString());
        return;
      }
      const poundsPerYard = Number(currentRollData?.lb_per_yd);
      const adjustedYards = (newPoundsOut * 100) / (poundsPerYard * 100);
      const roundedYards = utils.roundNumber(adjustedYards, 3);
      setYards(roundedYards.toString() !== 'NaN' ? roundedYards.toString() : '0');
    }
  };

  const makeAdjustment = () => {
    rollUtils.makeRollAdjustment({
      gridApi,
      currentRollData: currentRollData as IRoll,
      adjustedPoundsOut: getAdjustedPoundsOut().toString(),
      adjustedYards: getAdjustedYards().toString(),
      cutSewNumber,
      notes,
      addAdjustmentCall: addAdjustment,
      updateRollCall: updateRoll,
      queryClient,
      callback: () => {
        setYards('0');
        setPoundsOut('0');
        close();
      }
    });
  };

  const getAdjustedYards = useCallback(() => {
    return utils.roundNumber(Number(currentRollData?.yd) + Number(yards), 3);
  }, [yards, currentRollData?.yd]);

  const getAdjustedPoundsOut = useCallback(() => {
    return utils.roundNumber(Number(currentRollData?.lb_out) + Number(poundsOut), 3);
  }, [poundsOut, currentRollData?.lb_out]);

  return (
    <StyledDialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={close}
      aria-labelledby="max-width-dialog-title"
      TransitionComponent={SlideTransition}
    >
      <StyledDialogActions>
        <StyledCloseButton onClick={close} color="primary">
          <CloseIcon/>
        </StyledCloseButton>
      </StyledDialogActions>
      <StyledDialogContentContainer>
        <StyledDialogTitle id="max-width-dialog-title">Make New Adjustment</StyledDialogTitle>
        {currentRollData?.roll_number &&
        <StyledSubtitle id="max-width-dialog-title">Adjust: {currentRollData?.roll_number}</StyledSubtitle>}
        <StyledDialogContent id={"max-width-dialog-contents"}>
          <StyledFormGroupWrapper>
            <StyledFormGroup>
              <StyledFieldSetWrapper>
                <label htmlFor={"ram-cut-sew-number"}>Cut/Sew # </label>
                <input id={"ram-cut-sew-number"} type="text" value={cutSewNumber} onChange={handleCutSewNumberChange}/>
              </StyledFieldSetWrapper>
              <StyledFieldSetWrapper>
                <label htmlFor={"ram-notes"}>Notes </label>
                <input id={"ram-notes"} type="text" value={notes} onChange={handleNotesChange}/>
              </StyledFieldSetWrapper>
              <br/>
              <StyledFieldSetWrapper>
                <label htmlFor={"ram-yards"}>Yards </label>
                <StyledPlusMinusButtonWrapper>
                  <input id="ram-yards" type="text" value={yards} onChange={handleYardsChange} onBlur={onYardsBlur}/>
                  <button onClick={decrementYards}>-</button>
                  <button onClick={incrementYards}>+</button>
                </StyledPlusMinusButtonWrapper>
              </StyledFieldSetWrapper>
              <StyledFieldSetWrapper>
                <label htmlFor={"ram-pounds-out"}>Pounds Out </label>
                <StyledPlusMinusButtonWrapper>
                  <input type="text" value={poundsOut} onChange={handlePoundsOutChange} onBlur={onPoundsOutBlur}/>
                  <button onClick={decrementPounds}>-</button>
                  <button onClick={incrementPounds}>+</button>
                </StyledPlusMinusButtonWrapper>
              </StyledFieldSetWrapper>
            </StyledFormGroup>
            <StyledFormGroup>
              <br/>
              <StyledInfoWrapper>
                <StyledInfo>
                  <p>Current Yards:</p>
                  {utils.roundNumber(Number(currentRollData?.yd), 3)}
                </StyledInfo>
                <StyledInfo>
                  <p>Adjusted Yards:</p>
                  {getAdjustedYards()}
                </StyledInfo>
              </StyledInfoWrapper>
              <StyledInfoWrapper>
                <StyledInfo>
                  <p>Current Pounds Out:</p>
                  {utils.roundNumber(Number(currentRollData?.lb_out), 3)}
                </StyledInfo>
                <StyledInfo>
                  <p>Adjusted Pounds Out:</p>
                  {getAdjustedPoundsOut()}
                </StyledInfo>
              </StyledInfoWrapper>
            </StyledFormGroup>
          </StyledFormGroupWrapper>
          <StyledAdjustmentButtonWrapper>
            <StyledAdjustmentButton
              variant={"contained"}
              color={"primary"}
              size={"medium"}
              onClick={makeAdjustment}
            >Make Adjustment
            </StyledAdjustmentButton>
          </StyledAdjustmentButtonWrapper>
        </StyledDialogContent>
      </StyledDialogContentContainer>
    </StyledDialog>
  );
};

export {RollAdjustmentModal};

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    height: 100vh;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    //position: sticky;
    top: 0;
    width: auto;
    margin-left: auto;
    z-index: 1;
  }
`;

const StyledDialogContentContainer = styled.div`
  position: relative;
  height: auto;
`;

const StyledCloseButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  &.MuiDialogActions-root {
    position: sticky;
    top: 0;
  }
  .MuiSvgIcon-root {
  position: sticky;
  top: 0;
    padding: 2px;
  }
  &:hover {
    .MuiSvgIcon-root {
      background: ${colors.lightBackgroundGray};
      border-radius: 50%;
    }
  }
`;

const StyledSubtitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    font-weight: bolder;
    padding: 0 50px;
    .MuiTypography-h6 {
      margin-bottom: 20px;
      font-size: 1rem;
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    display: inline-block; // allows it to have auto width
    font-weight: bolder;
    padding: 0 50px;
    .MuiTypography-h6 {
      display: inline-block; // allows it to have auto width
      width: auto;
      font-size: 1.7rem;
      margin-bottom: 15px;
    }
  }
`;

const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding-top: 0;
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const StyledAdjustmentButton = styled(Button)`
  ${MuiButtonPrimary};
`;

const StyledFieldSetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  label {
    font-weight: 500;
  }
  input {
    width: 75%;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    border-color: rgba(0,0,0,0.35);
  }
  button {
    width: 25px;
    height: 25px;
    font-weight: bolder;
    border: 1px solid rgba(0,0,0,0.1);
    margin-left: 5px;
    margin-bottom: 5px;
  }
`;

const StyledPlusMinusButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledFormGroup = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const StyledAdjustmentButtonWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding-top: 50px;
`;

const StyledFormGroupWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
`;

const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const StyledInfo = styled.div`
  width: 40%;
  p {
    padding: 0;
    margin: 0;
    font-weight: 600;
  }
`;
