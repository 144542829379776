import React, {useEffect, useState} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {gridApiUtils} from "../../../../utils/gridApiUtils";
import {AlpineContainer} from "../../../containers/defaultAlpineContainer";
import {AutoCompleteReferenceEditor} from "../../../agFrameworkComponents/editors/autoCompleteRefEditor";
import {AutoCompleteEditor} from "../../../agFrameworkComponents/editors/autoCompleteEditor";
import {AutoCompleteRenderer} from "../../../agFrameworkComponents/renderers/autoCompleteRenderer";
import {CustomRefFilter} from "../../../agFrameworkComponents/filters/customRefFilter";
import {CustomRefFloatingFilter} from "../../../agFrameworkComponents/filters/customRefFloatingFilter";
import {emptyObjectGenerator} from "../../../emptyObjects";
import {AutoCompleteReferenceRenderer} from "../../../agFrameworkComponents/renderers/autoCompleteRefRenderer";
import {FirstColRenderer} from "../../../agFrameworkComponents/renderers/firstColRenderer";
import {DateEditor} from "../../../agFrameworkComponents/editors/dateEditor";
import {useUpdatePurchaseOrder} from "../../../../hooks/rowModels/usePurchaseOrders";
import {GridApi} from "ag-grid-community";

export const PurchaseOrderFragmentLoader = (props: {data: any, getGridFragmentHook: any, processCellForClipboard?: (params: any) => void, hideDefaultGrid?: boolean, filterId?: string | null, gridApi?: GridApi | null }): JSX.Element => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [parentGridApi] = useState(props?.gridApi || null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [serverSideDataSource, setServerSideDataSource] = useState(undefined);

  const {colArray} = props.getGridFragmentHook({...props});
  const {mutate: updatePurchaseOrder} = useUpdatePurchaseOrder();

  useEffect(() => {
    // TODO get rid of emptyObjectGenerator. Get empty row from server instead (the grid needs at least 1 row for user to right click to add new rows)
    //@ts-ignore
    setServerSideDataSource(gridApiUtils.setServerSideDatasource(props.data, (Array.isArray(props.data) && props.data?.length > 0) ? props.data : [emptyObjectGenerator.purchaseOrder()]));
  }, [props.data]);

  const onGridReady = (params: any) => {
    if (!gridApi) setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    // if (props?.filterId) {
    //   var filterModel = {
    //     id: {
    //       filter: props.filterId,
    //       filterType: "text",
    //       type: "contains"
    //     }
    //   };
    //   params.api.setFilterModel(filterModel);
    // }
  };

  const onCellValueChanged = (params: any) => {
    // update server
    updatePurchaseOrder(params?.data, {
      onSuccess: () => {
        // I don't want the popup to update after every change
        // queryClient.invalidateQueries([cacheKeys.poData]);
      },
      onError: (e) => {
      },
      onSettled: (e) => {
      }
    });
    gridApiUtils.updatePopupTransactionUpdates(parentGridApi, gridApi, props?.filterId);
  };

  // @ts-ignore
  return (
    <AlpineContainer hideDefaultGrid={props?.hideDefaultGrid}>
      <AgGridReact
        defaultColDef={{
          resizable: true,
          editable: true,
          floatingFilter: true,
          filter: true,
          sortable: true,
          filterParams: {
            debounceMs: 100
          },
        }}
        getRowNodeId={(data) => data.id}
        // turn off column virtualization if grid is displayed in a rowEditorModal (otherwise, not all the columns will show)
        suppressColumnVirtualisation={!!props?.filterId}
        processCellForClipboard={props.processCellForClipboard}
        // allow single-edit if grid is displayed in a rowEditorModal
        singleClickEdit={!!props?.filterId}
        suppressRowClickSelection={true}
        rowSelection={'multiple'}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={50}
        animateRows={true}
        rowModelType={'serverSide'}
        serverSideDatasource={serverSideDataSource}
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        suppressMenuHide={!!props?.hideDefaultGrid}
        //@ts-ignore
        getContextMenuItems={[]}
        stopEditingWhenCellsLoseFocus={true}
        frameworkComponents={{
          customRefFilter: CustomRefFilter,
          customRefFloatingFilter: CustomRefFloatingFilter,
          autoCompleteEditor: AutoCompleteEditor,
          autoCompleteRenderer: AutoCompleteRenderer,
          autoCompleteReferenceEditor: AutoCompleteReferenceEditor,
          autoCompleteReferenceRenderer: AutoCompleteReferenceRenderer,
          firstColRenderer: FirstColRenderer,
          dateEditor: DateEditor,
        }}
      >
        {colArray}
      </AgGridReact>
    </AlpineContainer>
  );
};

