import axios from "axios";
import {routes} from "../../constants/allConsts";
import {IAdjustment} from "../../types/rowModelTypes";
import {userUtils} from "../../utils/userUtils";

// TODO implement get rolls paged (see purchaseOrderServices.ts for example)

export const getAdjustments = async (): Promise<IAdjustment[] | {}> => {
  try {
    const accessToken = await userUtils.getAccessToken();
    const fetchUrl = `${routes.API_ROOT}/adjustments`;
    // TODO get demo fetchUrl from server once ready
    const data = await axios.get(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then(res => res?.data?.data)
      .catch(err => {
        console.error("Error fetching adjustments: ", err?.response?.data);
        throw Error(err.response);
      });
    return data;
  } catch (error: any) {
    console.log("error fetching:: ", error);
    return {};
  }
};

// TODO write function for getting adjustments basic list (see purchaseOrderServices.ts for example)

// TODO write function for getting adjustment by id (see purchaseOrderServices.ts for example)

export const updateAdjustment = async (adjustment: IAdjustment) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    const apiURL = `${routes.API_ROOT}/adjustments`;
    // TODO get demo fetchUrl from server once ready
    const requestBody = adjustment;
    const data = axios.put(apiURL, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing adjustment: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};

export const addAdjustment = async (adjustment: IAdjustment) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    const apiURL = `${routes.API_ROOT}/adjustments`;
    // TODO get demo fetchUrl from server once ready
    const requestBody = adjustment;
    const data = axios.post(apiURL, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing adjustment: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};

export const deleteAdjustment = async (adjustment: IAdjustment) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    const apiURL = `${routes.API_ROOT}/adjustments`;
    // TODO get demo fetchUrl from server once ready
    const requestBody = adjustment;
    const data = axios.delete(apiURL, {
      data: requestBody,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing adjustment: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};
