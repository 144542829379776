import {IFinishedGood, IDataPaged} from "../../types/rowModelTypes";
import axios from "axios";
import {routes} from "../../constants/allConsts";
import {userUtils} from "../../utils/userUtils";

export const getFinishedGoodsPaged = async (page: number = 0, offset: string | null): Promise<IDataPaged<IFinishedGood>[] | {}> => {

  const accessToken = await userUtils.getAccessToken();
  const fetchUrl = `${routes.DEMO_LOTS}`;
  try {
    const data = await axios.get(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        page: page,
        offset: offset,
      }
    })
    .then(res => res?.data)
    .then(res => {
      return res.data;
    });
    return data;
  } catch (error: any) {
    console.log("error fetching: ", error);
    return {};
  }

};

// export const getPurchaseOrdersPaged = async (page: number = 0, offset: string | null): Promise<IPurchaseOrdersPaged[] | {}> => {
//   const accessToken = await userUtils.getAccessToken();
//   const fetchUrl = `${routes.DEMO_YARN_POS}`;
//   try {
//     const data = await axios.get(fetchUrl, {
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': accessToken,
//       },
//       params: {
//         page: page,
//         offset: offset,
//       }
//     })
//       .then(res => res?.data)
//       .then(res => {
//         return res.data;
//       });
//     return data;
//   } catch (error: any) {
//     console.log("error fetching:: ", error);
//     return {};
//   }
// };
// TODO implement get finished goods paged (see purchaseOrderServices.ts for example)

export const getFinishedGoods = async (): Promise<IFinishedGood[] | {}> => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const fetchUrl = `${routes.API_ROOT}/finished-goods`;
    const fetchUrl = `${routes.DEMO_LOTS}`;
    const data = await axios.get(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        pageSize: 10000,
      }
    })
    // .then(res => res?.data?.data) // mock server
    //   .then(res => res?.data)
      .then(res => res?.data)
      .then(res => {
        if (res.status === 'success') {
          return res?.data?.data;
        }
        return res?.data;
      })
      .catch(err => {
        console.error("Error fetching lots: ", err?.response?.data);
        throw Error(err.response);
      });
    return data;
  } catch (error: any) {
    console.log("error fetching:: ", error);
    return {};
  }
};

export const getFinishedGoodsBasic = async (nameKeys: string[] = []) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    const fetchUrl = `${routes.DEMO_LOTS}/basic-list`;
    const nameParams = nameKeys.reduce((prev, current) => ({ ...prev, [current]: true}), {});
    const data = await axios.get(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        ...nameParams,
      }
    })
      .then(res => res?.data?.data)
      .catch(err => {
        console.error("Error fetching finished goods: ", err?.response?.data);
        throw Error(err.response);
      });
    return data;
  } catch (error: any) {
    console.log("error fetching:: ", error);
  }
};

export const getFinishedGoodById = async (id: string) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    const fetchUrl = `${routes.DEMO_LOTS}/by-id`;
    const data = await axios.get(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        id: id,
      }
    })
      .then(res => res?.data?.data)
      .catch(err => {
        console.error("Error fetching yarn type: ", err?.response?.data);
        throw Error(err.response);
      });
    return data;
  } catch (error: any) {
    console.log("error fetching:: ", error);
  }
};

export const updateFinishedGood = async (finishedGood: IFinishedGood) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const apiURL = `${routes.API_ROOT}/finished-goods`;
    const apiURL = `${routes.DEMO_LOTS}`;
    const requestBody = finishedGood;
    const data = axios.put(apiURL, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing lot: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};

export const addFinishedGood = async (finishedGood: IFinishedGood) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const apiURL = `${routes.API_ROOT}/finished-goods`;
    const apiURL = `${routes.DEMO_LOTS}`;
    const requestBody = finishedGood;
    const data = await axios.post(apiURL, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing lot: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};

export const deleteFinishedGood = async (finishedGood: IFinishedGood) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const apiURL = `${routes.API_ROOT}/finished-goods`;
    const apiURL = `${routes.DEMO_LOTS}`;
    const requestBody = finishedGood;
    const data = axios.delete(apiURL, {
      data: requestBody,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing lot: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};
