import React, {forwardRef, useState, useImperativeHandle, useRef, useEffect, useMemo} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import styled from "styled-components";
import {GridApi, RowNode} from "ag-grid-community";
import {useQueryClient} from "react-query";
import {utils} from "../../../utils/utils";
import _ from "lodash";

const filter = createFilterOptions<any>();

const AutoCompleteReferenceEditor = forwardRef((
  props: {
    api: GridApi,
    value: any,
    nameKey: string, // the key to the autocomplete object
    cacheKey: string,
    addDataService: any,
    node: RowNode,
    updateRowDataOnAutocomplete?: (p: { currentNode: RowNode; chosenRefObject: any }) => any
    emptyObject: any,
    getBasicListHook: any,
  }, ref: any) => {
  const [value, setValue] = useState<any>('');
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
  const textFieldRef = useRef(null);
  const [output, setOutput] = useState('');
  const queryClient = useQueryClient();
  const cacheKey = props.cacheKey;

  // const {data, isLoading} = props.getBasicListHook(props.nameKey);
  const {data, isLoading} = props.getBasicListHook;
  const dataArray = React.useMemo(() => _.flatten(data), [data]);

  const nameMap = useMemo(() => new Map<string, any>(!isLoading ? dataArray?.map((refObj: any) => [refObj?.[props?.nameKey], refObj]) : []), [data]);

  const dataOptions = dataArray.map((refObj: any) => {
    return {[props.nameKey]: refObj[props.nameKey]}
  });

  useEffect(() => {
    // as soon as autocomplete option is clicked on (and saved), stop editing
    if (isDataChanged && value !== '') {
      props.api.stopEditing();
    }

  }, [value]);
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return output || '';
      },
      afterGuiAttached: () => {
        setValue(props?.value || '');
        if (props?.value) {
          // @ts-ignore
          setOutput(nameMap?.get(props?.value[props?.nameKey])?.id || '');
        }
        // allow field to be edited as soon as element UI is ready
        if (textFieldRef.current) {
          // @ts-ignore
          textFieldRef.current?.focus();
        }
      }
    };
  });

  const updateRowOnAutoCompleteSelection = (chosenRefObject: any) => {
    if (props.updateRowDataOnAutocomplete) {
      props.updateRowDataOnAutocomplete({currentNode: props.node, chosenRefObject: chosenRefObject});
    }
  };

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({[props.nameKey]: newValue});
          // @ts-ignore
          setOutput(nameMap.get(newValue?.[prosp?.nameKey])?.id);
          updateRowOnAutoCompleteSelection(nameMap.get(newValue));


        } else if (newValue && newValue.inputValue) {
          // // Create a new value from the user input
          const empty = props.emptyObject();
          //@ts-ignore
          empty[props.nameKey] = newValue.inputValue;
          setValue(empty?.id);
          setOutput(empty?.id);

          props?.addDataService(empty, {
            onSuccess: () => {
              queryClient.invalidateQueries([cacheKey]).then((res) => {
                /* data is only loaded once when the app is mounted
                   to prevent entire ui from rerendering when queries are invalidated)
                   so we redraw the affected rows here
                */
                props.api.redrawRows({rowNodes: [props?.node]});
                updateRowOnAutoCompleteSelection(empty);
              });
            }
          })?.then((res: any) => {
            // queryClient.invalidateQueries([cacheKey]).then((res) => {
            //   /* data is only loaded once when the app is mounted
            //      to prevent entire ui from rerendering when queries are invalidated)
            //      so we redraw the affected rows here
            //   */
            //   props.api.redrawRows({rowNodes: [props?.node]});
            //   updateRowOnAutoCompleteSelection(empty);
            // });
          });
        } else {
          setValue(newValue || '');
          if (!newValue) {
            setOutput('');
          } else {
            // @ts-ignore
            setOutput(nameMap.get(newValue[props?.nameKey] || '')?.id || '');
            updateRowOnAutoCompleteSelection(nameMap.get(newValue[props?.nameKey]) || '');
          }
        }
        setIsDataChanged(true);
      }}
      onOpen={() => {
        setIsDataChanged(false);
      }}
      filterOptions={(options, params) => {
        options = options || [];
        // @ts-ignore
        // const filtered = filter(options.filter(f => f[props.nameKey]), params);
        const filtered = filter(options || [], params).sort((a, b) => utils.baseComparator(a[props.nameKey], b[props.nameKey]));
        // Suggest the creation of a new value
        if (params.inputValue !== '' && (filtered.filter((f: any) => f[props.nameKey] === params.inputValue).length < 1)) {
          filtered.push({
            inputValue: params.inputValue,
            [props.nameKey]: `Add "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      openOnFocus
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={`${cacheKey}-autocomplete-ref-selection`}
      options={dataOptions}
      getOptionLabel={(option: any) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option || '';
        }
        // Add "xxx" option created dynamically
        if (option?.inputValue) {
          return option?.inputValue || '';
        }
        // Regular option
        return option[props.nameKey] || '';
      }}
      // @ts-ignore
      renderOption={(option) => option[props.nameKey]}
      freeSolo
      renderInput={(params) => (
        <StyledAutoCompleteTextField
          {...params}
          variant="outlined"
          className="autocomplete-textfield"
          inputRef={input => {
            textFieldRef.current = input;
          }}
        />
      )}
    />
  );
});



export {AutoCompleteReferenceEditor};

const StyledAutoCompleteTextField = styled(TextField)`
input {
  padding: 0;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0;
}
.MuiOutlinedInput-notchedOutline {
  border: none;
}

`;
