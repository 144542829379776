import React from 'react';
import _ from "lodash";
import {v4 as uuidv4} from 'uuid';
import {generatePurchaseOrderPdf} from "./pdf/purchaseOrderPdf";
import {RowNode} from "ag-grid-community";
import openInFull from '../assets/img/open_in_full.svg';

const contextMenuItems = (props: {
  cacheKey: string | string[],
  params: any,
  expandRow?: any,
  makeEmptyObject?: any,
  addRowServerCall?: any,
  deleteRowServerCall?: any,
  queryClient: any,
  rowDescription?: string
  showDownloadPurchasePdf?: boolean,
  pdfData?: any,
  disableDuplication?: boolean,
  disableAddRows?: boolean,
  disableMultipleRowAdds?: boolean,
  customAddRowFunction?: any,
  customDeleteRowFunction?: any,
  preventRowEdits?: boolean,
}) => {
  // check for selected nodes on load
  const selectedRowLength = props.params.api.getSelectedNodes().length;
  const addNewRow = (cacheKey: string | string[], currentNode: RowNode, isBelow?: boolean) => {
    if (props?.customAddRowFunction) {
      props?.customAddRowFunction(cacheKey, currentNode, isBelow);
      return;
    }
    if (currentNode?.rowIndex !== undefined && (currentNode?.rowIndex || 0) < 0) return;
    let index = currentNode?.rowIndex || 0;
    if (props.params.api) {
      const newItems = [];
      if (selectedRowLength > 0) {
        props.params.api.getSelectedNodes().forEach((f: any) => {
          index = isBelow ? (index > f.rowIndex ? f.rowIndex : index) : (index < f.rowIndex ? f.rowIndex : index);
          const newRow = props.makeEmptyObject();
          newItems.push(newRow);
        });
        if (!isBelow) index = index + 1;
      } else {
        if (!isBelow) index = index + 1;
        const newRow = props.makeEmptyObject();
        newItems.push(newRow);
      }

      // const newItems = [newRow];
      const res = props.params.api.applyServerSideTransaction({
        add: newItems,
        addIndex: index,
      });

      // deselect all
      props.params.api.clearFocusedCell();
      props.params.api.deselectAll();


      res.add.forEach((node: RowNode, index: number) => {
        // if duplication was made from selected range or rows, highlight the neew items
        if (selectedRowLength > 0) node.setSelected(true);
        // TODO delete timeout once we have real server
        setTimeout(() => {
          // add to server
          props?.addRowServerCall(node.data, {onSuccess: () => {
              if (index === res.add.length - 1) {
                // allows grid to be instantly editable in popups
                props?.queryClient?.invalidateQueries(props?.cacheKey);
              }
            }});
        }, index * 200);
      });
    }
  };

  const duplicateRow = (cacheKey: string | string[], currentNode: RowNode) => {
    if (props.params.api && currentNode) {
      let index = currentNode?.rowIndex || 0;
      let newItems = [];
      if (selectedRowLength > 0) {
        props.params.api.getSelectedNodes().forEach((f: any) => {
          // make index the largest rowindex from selected nodes
          index = index < f.rowIndex ? f.rowIndex : index;
          // copy the selected node data
          const selectedRowData = _.clone(f.data);
          // generate unique id if needed
          if (selectedRowData?.id) selectedRowData.id = uuidv4();
          // add cloned items to addlist
          newItems.push(selectedRowData);
        });
        index = index + 1;
      } else {
        // duplicate curerent node
        const newRow: RowNode = _.clone(currentNode.data);
        // generate unique id if needed
        if (newRow?.id) newRow.id = uuidv4();
        // add cloned item to addlist
        newItems.push(newRow);
        index = index + 1;
      }

      // add new items to ag grid using transaction
      const res = props.params.api.applyServerSideTransaction({
        add: newItems,
        addIndex: index,
      });

      // deselect all
      props.params.api.clearFocusedCell();
      props.params.api.deselectAll();

      res.add.forEach((node: RowNode, index: number) => {
        // if duplication was made from selected range or rows, highlight the new items
        if (selectedRowLength > 0) node.setSelected(true);
        // TODO delete timeout once we have real server
        setTimeout(() => {
          // add to server
          props?.addRowServerCall(node.data, {
            onSuccess: () => {
              if (props.queryClient && index === res.add.length - 1) {
                // allows grid to be instantly editable in popups
                props?.queryClient?.invalidateQueries(props?.cacheKey);
              }
            }
          });
        }, index * 200);
      });


      res.remove.forEach((node: RowNode, index: number) => {
        // TODO delete timeout once we have real server
        setTimeout(() => {
          // delete from server
          props.deleteRowServerCall(node.data, {
            onSuccess: () => {
              if (props.queryClient) {
                props.queryClient.invalidateQueries(props.cacheKey);
              }
            }
          });
        }, index * 200);
      });

    }
  };

  const deleteRow = (cacheKey: string | string[], currentNode: RowNode) => {
    if (props?.customDeleteRowFunction) {
      props?.customDeleteRowFunction(cacheKey, currentNode);
      return;
    }
    if (window.confirm(`Are you sure you want to delete this record?`)) {
      if (props.params.api && currentNode) {
        let dataToRemove = [currentNode.data || null];
        if (selectedRowLength > 0) {
          dataToRemove = [];
          props.params.api.getSelectedNodes().forEach((f: any) => {
            dataToRemove.push(f.data);
          });
        }
        const res = props.params.api.applyServerSideTransaction({
          remove: dataToRemove,
        });


        if (res.remove.length > 0) {
          // if we deleted all of the rows, leave make a new row
          if (props.params.api.getRenderedNodes().length === 0) {
            const newRow = props.makeEmptyObject();
            const addRes = props.params.api.applyServerSideTransaction({
              add: [newRow],
              addIndex: 0,
            });
            if (addRes?.add?.length > 0) {
              // update server
              props.addRowServerCall(addRes.add[0].data);
            }
          }
          res.remove.forEach((node: RowNode, index: number) => {
            // TODO delete timeout once we have real server
            setTimeout(() => {
              // delete from server
              props.deleteRowServerCall(node.data, {
                onSuccess: () => {
                  if (props.queryClient) {
                    props.queryClient.invalidateQueries(props.cacheKey);
                  }
                }
              });
            }, index * 200);
          });
        }
      }
    }
  };

  let rowName = 'Row';
  if (props.rowDescription) rowName = props.rowDescription.trim();
  const result = [
    {
      name: 'Log current cell',
      action: function () {
        // @ts-ignore
        window.params = props.params;
      },
      cssClasses: ['redFont', 'bold'],
    },
    'separator',
    'copy',
    {
      name: `Expand ${rowName}`,
      icon: `<img src=${openInFull} />`,
      action: function () {
        //@ts-ignore
        if (props?.expandRow && props?.params?.node?.data?.id) {
          props?.expandRow(props?.params?.node?.data?.id);
        }
      },
      cssClasses: ['semi-bodld', 'context-icon'],
    },
    (!props?.preventRowEdits || selectedRowLength > 0) && 'separator',
    selectedRowLength > 0 && {
      name: 'Deselect All',
      action: function () {
        props?.params?.api?.deselectAll();
      },
      cssClasses: ['capitalize'],
    },
    !props?.preventRowEdits && {
      name: selectedRowLength > 1 ? `add (${selectedRowLength}) ${rowName}s above` : `add ${rowName} above`,
      disabled: (props?.disableAddRows) || (props?.disableMultipleRowAdds && selectedRowLength > 1),
      action: function () {
        addNewRow(props?.cacheKey, props.params.node, true);
      },
      cssClasses: ['capitalize'],
    },
    !props?.preventRowEdits && {
      name: selectedRowLength > 1 ? `add (${selectedRowLength}) ${rowName}s below` : `add ${rowName} below`,
      disabled: (props?.disableAddRows) || (props?.disableMultipleRowAdds && selectedRowLength > 1),
      action: function () {
        addNewRow(props?.cacheKey, props.params.node);
      },
      cssClasses: ['capitalize'],
    },
    !props?.preventRowEdits && {
      name: selectedRowLength > 1 ? `duplicate (${selectedRowLength}) ${rowName}s` : `duplicate ${rowName}`,
      disabled: props?.disableDuplication || (props?.disableMultipleRowAdds && selectedRowLength > 1),
      action: function () {
        duplicateRow(props?.cacheKey, props.params.node);
      },
      cssClasses: ['capitalize'],
    },
    !props?.preventRowEdits && {
      name: selectedRowLength > 1 ? `delete (${selectedRowLength}) ${rowName}s` : `delete ${rowName}`,
      action: function () {
        deleteRow(props?.cacheKey, props.params.node);
      },
      cssClasses: ['redFont', 'bold', 'capitalize'],
    },
  ];
  if (props.showDownloadPurchasePdf) {
    // @ts-ignore
    result.push(
      'separator',
      {
        name: `Download Yarn Order (pdf)`,
        action: function () {
          generatePurchaseOrderPdf({rowNode: props.params.node, pdfData: props?.pdfData});
        },
        cssClasses: ['capitalize'],
      },
    )
  }
  return result;
};

export {contextMenuItems};
