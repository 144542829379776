import {IImage, IServerError, ITokens, IUser, SERVER_ERROR_CODES, USER_TYPES} from "../../constants/users";
import {IaccessCodeAuthorizationResponse, ISetUserTypeResponse} from "../../constants/serverResponseInterface";
import {routes} from "../../constants/allConsts";
import {IImageValidator, ITokensValidator, IUserValidator} from "../../validateFunctions";
import axios from "axios";
import {userUtils} from "../../utils/userUtils";

const userApiCalls = {
  getCurrentUserData: async (): Promise<IUser> => {
    try {
      const accessToken = await userUtils.getAccessToken();
      return await axios
        .get(`${routes.DEMO_USERS}/current-user-data`, {
          headers: {
            'Authorization': accessToken,
          },
        })
        .then((response: any) => {
          const userData = response.data.data.userData;
          if (!userUtils.isValid(userData, IUserValidator)) {
            console.error('currentUserData was ', userData);
            throw userUtils.createErrorObject('Data mismatch for current user data response', SERVER_ERROR_CODES.CLIENT_DATA_MISMATCH_ERROR);
          }
          return userData;
        })
        .catch( (error: any) => {
          throw error.response.data as IServerError;
        });
    } catch (error) {
      console.error('Error when getting current user data', error);
      throw error;
    }
  },

  login: async (email: string, password: string): Promise<ITokens> => {
    return await axios
      .post(`${routes.DEMO_USERS}/login`, {
        email,
        password,
      })
      .then((response: any) => {
        const tokenPair = response.data.data.tokenPair;
        if (!userUtils.isValid(tokenPair, ITokensValidator)) {
          console.error('tokenPair was ', tokenPair);
          throw userUtils.createErrorObject('Data mismatch for login response', SERVER_ERROR_CODES.CLIENT_DATA_MISMATCH_ERROR);
        }

        return tokenPair;
      })
      .catch((error: any) => {
        console.error('Error while logging in', error.response.data);
        throw error.response.data as IServerError;
      });
  },

  // setUserType: async (userType: USER_TYPES): Promise<ISetUserTypeResponse> => {
  //   try {
  //     const accessToken = await userUtils.getAccessToken();
  //     return await axios
  //       .post(`${routes.DEMO_USERS}/set-user-type`,
  //         {
  //           newUserType: userType,
  //         },
  //         {
  //           headers: {
  //             'Authorization': accessToken,
  //           },
  //         }
  //       )
  //       .then((response: any) => {
  //         const data = response.data.data;
  //         if (!data.userType) {
  //           console.error('user type data was ', data);
  //           throw userUtils.createErrorObject('Data mismatch for set user type response', SERVER_ERROR_CODES.CLIENT_DATA_MISMATCH_ERROR);
  //         }
  //         return data;
  //       })
  //       .catch((error: any) => {
  //         console.error('Error when setting the user type', error.response.data);
  //         throw error.response.data as IServerError;
  //       });
  //   } catch (error) {
  //     console.error('Error when setting the user type', error);
  //     throw error;
  //   }
  // },

  // signUp: async (email: string, firstName: string, lastName: string, password: string, userType: USER_TYPES, signUpCode: string): Promise<ITokens> => {
  //   return await axios
  //     .post(`${routes.DEMO_USERS}/sign-up`, {
  //       email,
  //       firstName,
  //       lastName,
  //       password,
  //       userType,
  //       signUpCode,
  //     })
  //     .then((response: any) => {
  //       const tokenPair = response.data.data.tokenPair;
  //       if (!utils.isValid(tokenPair, ITokensValidator)) {
  //         console.error('tokenPair was ', tokenPair);
  //         throw utils.createErrorObject('Data mismatch for login response', SERVER_ERROR_CODES.CLIENT_DATA_MISMATCH_ERROR);
  //       }
  //
  //       return tokenPair;
  //     })
  //     .catch((error: any) => {
  //       console.error('Error while signing up', error.response.data);
  //       throw error.response.data as IServerError;
  //     });
  // },

  // requestSignUpCode: async (email: string): Promise<void> => {
  //   return await axios
  //     .post(`${routes.DEMO_USERS}/request-sign-up-code`, {
  //       email,
  //     })
  //     .then(() => {
  //       return;
  //     })
  //     .catch((error: any) => {
  //       console.error('Error while requesting signing up code', error.response.data);
  //       throw error.response.data as IServerError;
  //     });
  // },

  // resetPasswordRequest: async (email: string): Promise<void> => {
  //   return await axios
  //     .get(`${routes.DEMO_USERS}/request-password-recover-email`, { params: { email } })
  //     .then(() => {
  //       return;
  //     })
  //     .catch((error: any) => {
  //       console.error('Error when making reset password request', error.response);
  //       throw error.response.data as IServerError;
  //     });
  // },

  /**
   * Requests a new access token from a server
   * @param accessToken Access token for server validation
   * @param refreshToken Refresh token that is used to get a new access token
   * @param skipThrows If set to true, will skip all the throws, used in axiosConfig. Since we need to call this
   * when we get invalid access token or refresh token(Server needs this to potentially log some malicious activities)
   * setting this to true will prevent an infinite loop situation.
   * @returns promise containing the new access token
   */
  requestNewAccessToken: async (accessToken: string, refreshToken: string, skipThrows?: boolean): Promise<ITokens> => {
    return await axios
      .post(`${routes.DEMO_USERS}/token`, {
          accessToken,
        },
        {
          headers: {
            'Authorization': refreshToken,
          },
        }
      )
      .then((response: any) => {
        if (skipThrows) {
          return;
        }

        const tokenPair = response.data.data.tokenPair;
        if (!userUtils.isValid(tokenPair, ITokensValidator)) {
          console.error('tokenPair was ', tokenPair);
          throw userUtils.createErrorObject('Data mismatch for request new access token', SERVER_ERROR_CODES.CLIENT_DATA_MISMATCH_ERROR);
        }
        return tokenPair;
      })
      .catch((error: any) => {
        console.error('Error while requesting access code', error.response);
        if (!skipThrows) {
          throw error.response.data as IServerError;
        }
      });
  },

  signOut: async (): Promise<void> => {
    try {
      const accessToken = await userUtils.getAccessToken();
      return await axios
        .post(`${routes.DEMO_USERS}/sign-out`, {},
          {
            headers: {
              'Authorization': accessToken,
            },
          }
        )
        .then(() => {
          return;
        })
        .catch((error: any) => {
          console.error('Error when making sign out request', error.response);
          throw error.response.data as IServerError;
        });
    } catch (error) {
      console.error('Error when signing out', error);
    }
  },

  updateCurrentUserData: async (firstName?: string, lastName?: string, description?: string, occupation?: string): Promise<IUser> => {
    try {
      const accessToken = await userUtils.getAccessToken();
      return await axios
        .post(`${routes.DEMO_USERS}/update-current-user-data`, {
            firstName,
            lastName,
            description,
            occupation,
          },
          {
            headers: {
              'Authorization': accessToken,
            },
          }
        )
        .then((response: any) => {
          const incomingUserData = response.data.data.userData;
          if (!userUtils.isValid(incomingUserData, IUserValidator)) {
            console.error('currentUserData was ', incomingUserData);
            throw userUtils.createErrorObject('Data mismatch for current user data response', SERVER_ERROR_CODES.CLIENT_DATA_MISMATCH_ERROR);
          }
          return incomingUserData;
        })
        .catch((error: any) => {
          console.error('Error when making update current user data request', error.response);
          throw error.response.data as IServerError;
        });
    } catch (error) {
      console.error('Error when update current user data', error);
      throw error;
    }
  },

  changePassword: async (currentPassword: string, newPassword: string): Promise<void> => {
    try {
      const accessToken = await userUtils.getAccessToken();
      return await axios
        .post(`${routes.DEMO_USERS}/change-password`, {
            currentPassword,
            newPassword,
          },
          {
            headers: {
              'Authorization': accessToken,
            },
          }
        )
        .then(() => {
          return;
        })
        .catch((error: any) => {
          console.error('Error when making change password request', error.response);
          throw error.response.data as IServerError;
        });
    } catch (error) {
      console.error('Error when making change password request', error);
      throw error;
    }
  },

  changePasswordRecovery: async (newPassword: string, recoveryToken: string): Promise<void> => {
    return await axios
      .post(`${routes.DEMO_USERS}/change-password-recovery`, {
        newPassword,
        recoveryToken,
      })
      .then(() => {
        return;
      })
      .catch((error: any) => {
        console.error('Error when making change password request', error.response);
        throw error.response.data as IServerError;
      });
  },

  uploadProfilePhoto: async ({ formData, uploadProgress }: { formData: FormData, uploadProgress?: (percentCompleted: number) => void }): Promise<IImage> => {
    try {
      const accessToken = await userUtils.getAccessToken();
      return await axios
        .post(`${routes.DEMO_USERS}/changeProfilePhoto`, formData,
          {
            onUploadProgress: (progressEvent: any) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              uploadProgress?.(percentCompleted);
            },
            headers: {
              'Authorization': accessToken,
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((response: any) => {
          const image = response.data.data;

          if (!userUtils.isValid(image, IImageValidator)) {
            console.error('image was ', image);
            throw userUtils.createErrorObject('Data mismatch for upload profile photo response', SERVER_ERROR_CODES.CLIENT_DATA_MISMATCH_ERROR);
          }
          return image;
        })
        .catch((error: any) => {
          console.error('Error when making upload profile photo request', error.response);
          throw error.response.data as IServerError;
        });
    } catch (error) {
      console.error('Error when making upload profile photo request', error);
      throw error;
    }
  },
};

export { userApiCalls };
