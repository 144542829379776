import React, {useEffect, useState} from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {BodyScrollEvent, GridApi} from "ag-grid-community";
import {AlpineContainer} from "../containers/defaultAlpineContainer";
import {contextMenuItems} from "../agContextMenu";
import {gridApiUtils} from "../../utils/gridApiUtils";
import {routes, cacheKeys} from "../../constants/allConsts";
import {emptyObjectGenerator} from "../emptyObjects";
import {ButtonBar} from "../buttonBar";
import {useQueryClient} from "react-query";
import {RowEditorModal} from "../modals/rowEditorModal";
import {FirstColRenderer} from "../agFrameworkComponents/renderers/firstColRenderer";
import {VendorGrid} from "./vendorGrid";
import {CustomRefFilter} from "../agFrameworkComponents/filters/customRefFilter";
import {CustomRefFloatingFilter} from "../agFrameworkComponents/filters/customRefFloatingFilter";
import {AutoCompleteReferenceEditor} from "../agFrameworkComponents/editors/autoCompleteRefEditor";
import {AutoCompleteReferenceRenderer} from "../agFrameworkComponents/renderers/autoCompleteRefRenderer";
import { IColor, IVendor } from '../../types/rowModelTypes';
import { useGetRowModel, useGetRowModelInfinite, 
        useGetRowModelBasic, useAddRowModel, 
        useDeleteRowModel, useUpdateRowModel } from '../../hooks/rowModels/RowModelHooks';
import _, { filter } from "lodash";

const GetSingleColorForPopup = (id?: string) => {
  return useGetRowModel<IColor>(routes.DEMO_COLORS , {id});
};

const GetInfiniteColors = () => {
  return useGetRowModelInfinite<IColor>(routes.DEMO_COLORS);
};

export const ColorGrid = (props: { hideDefaultGrid?: boolean, filterId?: string | null, gridApi?: GridApi | null }): JSX.Element => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [parentGridApi] = useState(props?.gridApi || null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [serverSideDataSource, setServerSideDataSource] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedRowFilterId, setExpandedRowFilterId] = useState<string>('');
  const [isDataDisplayed, setIsDataDisplayed] = useState<boolean>(false);

  // TODO implement pagination
  const colors = props?.filterId ? GetSingleColorForPopup(props.filterId) : GetInfiniteColors();

  const {mutate: addColor} = useAddRowModel<IColor>(routes.DEMO_COLORS);
  const {mutate: updateColor} = useUpdateRowModel<IColor>(routes.DEMO_COLORS);
  const {mutate: deleteColor} = useDeleteRowModel<IColor>(routes.DEMO_COLORS);
  const {mutate: addVendor} = useAddRowModel<IVendor>(routes.DEMO_VENDORS);

  const queryClient = useQueryClient();

  useEffect(() => {
    // Load only once on mount to populate grid. Allow FE to take care of updates optimistically
    if (!isDataDisplayed && !colors?.isLoading) {
      let data = emptyObjectGenerator.color();
      data = props.filterId ? colors?.data : colors?.data?.pages
      // TODO get rid of emptyObjectGenerator. Get empty row from server instead (the grid needs at least 1 row for user to right click to add new rows)
      //@ts-ignore
      const setDataSourceFunc = props?.filterId ? gridApiUtils.setServerSideDatasource : gridApiUtils.setServerSidePaginatedDatasource;
      const dataSource: any = setDataSourceFunc(colors?.isSuccess, data)
      setServerSideDataSource(dataSource);
      setIsDataDisplayed(true);
    }
  }, [colors?.isLoading]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const closeModal = () => {
    if(gridApi) {
      const expandedNode = gridApi?.getRowNode(expandedRowFilterId);
      // refresh the previously expanded node in case changes were made to it while in expanded mode
      if (expandedNode) {
        gridApi?.redrawRows({rowNodes: [expandedNode]});
      }
    }
    setIsModalOpen(false);
    setExpandedRowFilterId('');
  };

  const expandRow = (rowId: string) => {
    setExpandedRowFilterId(rowId);
    setIsModalOpen(true);
  };

  const onCellValueChanged = (params: any) => {
    // update server
    updateColor(params?.data);
    gridApiUtils.updatePopupTransactionUpdates(parentGridApi, gridApi, props?.filterId);
  };

  /**
   * @description processes cell for clipboard so the right contents get copied.
   * Used primarily for reference buttons where you want the button names to
   * be copied rather than the reference id the cell actually holds
   * */
  const processCellForClipboard = (params: any) => {
    // TODO rewrite cell processer for clipboard (example implementation in purchaseOrderGrid)
    // switch (params?.column?.colId) {
    //   case "vendor_ref":
    //     return vendorIdMap.get(params?.value)?.vendor_name;
    // }
    return params.value;
  };

  return (
    <AlpineContainer hideDefaultGrid={props?.hideDefaultGrid}>
      <ButtonBar
        addRowTitle="Add Color"
        cacheKey={cacheKeys.colorData}
        isAllDataLoaded={!colors?.isLoading}
        addRowEmptyObject={(onAddRowSuccess: any) => {
          let newRowData = {}
          // invalidate queries to refetch data from server
          addColor(newRowData, {
            onSuccess: (data) => {
              console.log(`NEW DATA: ${JSON.stringify(data,null,2)}`)
              queryClient.invalidateQueries([cacheKeys.colorData]);
              setExpandedRowFilterId(data.id);
              onAddRowSuccess(data);
            }
          });
        }}
        // pass in gridApi for buttonBar to manipulate the grid
        gridApi={gridApi}
        gridComponent={<ColorGrid gridApi={gridApi}/>}
      >
      </ButtonBar>
      <AgGridReact
        defaultColDef={{
          resizable: true,
          editable: true,
          floatingFilter: true,
          filter: true,
          sortable: true,
          filterParams: {
            debounceMs: 100
          }
        }}
        enableRangeSelection={!props?.filterId}
        enableFillHandle={!props?.filterId}
        fillHandleDirection={'y'}
        fillOperation={gridApiUtils.setFillOperation}
        onFillEnd={gridApiUtils.fillEnd}
        processCellForClipboard={processCellForClipboard}
        getRowNodeId={(data) => {
          return data.id;
        }}
        onBodyScroll={_.debounce((event: BodyScrollEvent) => gridApiUtils.customInfinitePaginationOnBodyScroll(event, colors, gridApi), 100)}
        // turn off column virtualization if grid is displayed in a rowEditorModal (otherwise, not all the columns will show)
        suppressColumnVirtualisation={!!props?.filterId}
        // allow single-edit if grid is displayed in a rowEditorModal
        singleClickEdit={!!props?.filterId}
        suppressRowClickSelection={true}
        rowSelection={'multiple'}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={50}
        animateRows={true}
        rowModelType={'serverSide'}
        serverSideDatasource={serverSideDataSource}
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        suppressMenuHide={!!props?.hideDefaultGrid}
        //@ts-ignore
        getContextMenuItems={(params) => props?.hideDefaultGrid ? [] : contextMenuItems({
          cacheKey: cacheKeys.colorData,
          queryClient: queryClient,
          expandRow: expandRow,
          params,
          // TODO make empty object serverside
          makeEmptyObject: emptyObjectGenerator.color,
          addRowServerCall: addColor,
          deleteRowServerCall: deleteColor,
          rowDescription: 'color'
        })}
        stopEditingWhenCellsLoseFocus={true}
        frameworkComponents={{
          firstColRenderer: FirstColRenderer,
          customRefFilter: CustomRefFilter,
          customRefFloatingFilter: CustomRefFloatingFilter,
          autoCompleteReferenceEditor: AutoCompleteReferenceEditor,
          autoCompleteReferenceRenderer: AutoCompleteReferenceRenderer,
        }}
      >
        <AgGridColumn
          field={"id"}
          lockPosition
          editable={false}
          cellClass={'suppress-fill-handle'}
          suppressColumnsToolPanel={true}
          hide
        />
        <AgGridColumn
          headerName={"Color Code"}
          field={"code"}
          lockPosition
          cellRenderer={"firstColRenderer"}
          cellRendererParams={{expandRow: expandRow}}
          checkboxSelection={!props?.hideDefaultGrid}
          headerClass={"span-third"}
          cellClass={"span-third"}
        />
        <AgGridColumn
          headerName={"Vendor"}
          field={"vendor_id"}
          // cellEditor={"autoCompleteReferenceEditor"}
          // cellRenderer={"autoCompleteReferenceRenderer"}
          // TODO rewrite comparator by using contents retrieved from paginated data
          // comparator={(valueA, valueB) => {
          //   const refValueA = vendorIdMap.get(valueA)?.vendor_name || '';
          //   const refValueB = vendorIdMap.get(valueB)?.vendor_name || '';
          //   return utils.baseComparator(refValueA, refValueB);
          // }}
          editable={gridApiUtils.setRefEditable}
          onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
          cellRendererParams={(rendererParams: any) => {
            return {
              deleteCellValue: (params: any) => {
                // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
              },
              gridComponent: (
                <VendorGrid
                  hideDefaultGrid={true}
                  filterId={rendererParams.data.vendor_id}
                />
              )
            }
          }
          }
          cellEditorParams={{
            addDataService: addVendor,
            nameKey: 'vendor_name',
            cacheKey: cacheKeys.vendorData,
            // TODO make empty object serverside
            emptyObject: emptyObjectGenerator.vendor,
            // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
          }
          }
          // TODO implement server-side filter
          // filter={"customRefFilter"}
          // floatingFilterComponent={"customRefFloatingFilter"}
          // floatingFilterComponentParams={{suppressFilterButton: true}}
          // filterParams={{
          //   cacheKey: cacheKeys.vendorData,
          //   filterData: vendorData,
          //   nameKey: 'vendor_name',
          // }}
        />
        <AgGridColumn
          headerName={"Vendor Color"}
          field={"vendor_color"}
          width={350}
          headerClass={"span-third"}
          cellClass={"span-third uppercase"}
          valueSetter={(params) => {
            const data = params.data;
            const newValue = params.newValue.toUpperCase();
            const isValueChanged = data.vendor_color !== newValue;
            if (isValueChanged) data.vendor_color = newValue;
            return isValueChanged;
          }}
        />
        <AgGridColumn
          headerName={"LAA Color"}
          field={"laa_color"}
          width={350}
          headerClass={"span-third"}
          cellClass={"span-third uppercase"}
          valueSetter={(params) => {
            const data = params.data;
            const newValue = params.newValue.toUpperCase();
            const isValueChanged = data.laa_color !== newValue;
            if (isValueChanged) data.laa_color = newValue;
            return isValueChanged;
          }}
        />
        <AgGridColumn
          field={"desc"}
          minWidth={300}
          headerClass={"span-third"}
          cellClass={"span-third"}
          flex={1}
        />
      </AgGridReact>
      <RowEditorModal
        open={isModalOpen}
        close={closeModal}
        title={"Edit Color"}
        gridComponent={<ColorGrid hideDefaultGrid={true} filterId={expandedRowFilterId} gridApi={gridApi}/>}
      />
    </AlpineContainer>
  );
};
