import _ from "lodash";
import {BodyScrollEvent, GridApi, RowNode} from "ag-grid-community";
import {utils} from "./utils";

class GridApiUtils {
  confirmationOpen = false;
  doCancelFill = false;

  customInfinitePaginationOnBodyScroll = (event: BodyScrollEvent, pagedQuery: any, gridApi: GridApi | null) => {
    if (event.direction === 'vertical') {
      if ((event.api.getDisplayedRowAtIndex(event.api.getDisplayedRowCount() - 1)?.rowTop || 0) - 500 < (event.top + 200)) {
        if (pagedQuery.hasNextPage) {
          pagedQuery.fetchNextPage().then((fetchres: any) => {
            const nextRows = fetchres?.data?.pages?.[fetchres?.data?.pages?.length - 1];
            const pages = fetchres?.data?.pages;
            const lastPage = pages[pages?.length - 1];
            // Make sure row doesn't already exist
            if (gridApi && !gridApi?.getRowNode(lastPage?.data.slice(-1)[0].id)) {
              gridApi.applyServerSideTransaction({
                add: nextRows?.data || [],
              });
            }
          });
        }
      }
    }
  };
  /**
   * @description:
   * This is the fallback function for setting up infinite pagination using ag-grid's out-of-the-box
   * infinite row model (https://www.ag-grid.com/react-data-grid/infinite-scrolling/). TO use this function, call it in
   * the onGridReady callback function and pass in the fetch service. This allows ag-grid to infinitely the data in
   * paginated format when the user scrolls to the end of a page. ie. Transactions are not available when using
   * infinite row model
   *
   * This function is currently not used because ag-grid's infinite model comes with some problems that will cause
   * a negative user experience. One of the biggest problems is the data will always need to be synced to the server.
   * Due to this constraint, the data will always need to be cleared out and reloaded again every time a row is
   * created or deleted. Newly created rows will always be rendered at the very bottom and the user will have to
   * filter for it (will have to be implemented serverside).
   *
   * @param fetchService the fetch function to call
   * @example:
   * onGridReady = (params: any) => {
   *   params.api.setDatasource(gridApiUtils.setInfiniteDatasource(getPurchaseOrdersPaged));
   * }
   */
  setInfiniteDatasource = (fetchService: any) => {
    const datasource = {
      getRows(params: any) {
        // get current page by diving rendered rows by default ag-grid cacheBlock size and pageSize
        let page = params?.endRow / 100;
        // fetch the page (using fetch or axios. react-query will just get in the way due to the difference in implementation)
        const res = fetchService(page - 1, null).then((rowData: any) => {
          if (rowData?.data?.length) {
            let lastRow = () => {
              if (rowData?.hasNextPage) {
                // if there are more pages left to fetch, return -1 to continue fetching on each cacheBlock
                return -1;
              }
              // stops fetching data once total size is reached
              return rowData?.totalSize;
            };
            // load more rows to the grid based on lastRow
            params.successCallback(rowData?.data, lastRow());
          }
        });
      }
    };
    return datasource;
  };

  /**
   * @description populate ag-grid using pages from react-query's useInfiniteQuery hook
   */
  setServerSidePaginatedDatasource = (isSuccess: boolean, pages: any) => {
    const datasource = {
      getRows: function (params: any) {
        if (isSuccess) {
          
          // flattens the data from different pages to render out
          const data = Array.isArray(pages)? _.flatten(pages.map((page: any) => page.data)) : [pages];
          params.success({rowData: data});
        } else {
          params.fail();
        }
      },
    };
    return datasource;
  };

  /**
   * 
   * @description temporary method to handle setting serverDataSource for YARN POs
   */
   setServerSidePaginatedDatasourcePOs = (isSuccess: boolean, pages: any) => {
    const datasource = {
      getRows: function (params: any) {
        if (isSuccess) {
          // flattens the data from different pages to render out
          const data = _.flatten(pages.map((page: any) => page.data));
          params.success({rowData: data});
        } else {
          params.fail();
        }
      },
    };
    return datasource;
  };


  /**
   * @description populate ag-grid using data array returned from server (for when all data is already available)
   */
  setServerSideDatasource = (isSuccess: boolean, data: any) => {
    const datasource = {
      getRows: function (params: any) {
        // console.log('[Datasource] - rows requested by grid: ', params.request);
        if (isSuccess) {
          console.log(`ROW DATA: ${JSON.stringify(data, null,2)}`)
          params.success({rowData: [data]});
        } else {
          params.fail();
        }
      },
    };
    return datasource;
  };

  /**
   * @description retrieve all nodes in the grid
   */
  getAllRows = (gridApi: GridApi) => {
    const rowData: RowNode[] = [];
    gridApi.forEachNode((node: RowNode) => rowData.push(node.data));
    return rowData;
  };

  /**
   * @description helper for deleting value from reference button cells
   */
  deleteReferenceButton = (params: any, propToDelete: string, updateServerCall: any, makeClickableAfterDeletion?: boolean) => {
    if (!params.node) return false;
    const updatedRow = _.clone(params.node);
    // delete the prop from server (and in node) by setting its value to empty string
    _.set(updatedRow.data, propToDelete, '');
    if (makeClickableAfterDeletion) {
      this.editEmptyRefCellOnClick(params);
    }
    updateServerCall(updatedRow.data);
    return true;
  };

  /**
   * @description make ref button cells editable
   */
  editEmptyRefCellOnClick = (params: any) => {
    // create paths that don't exist yet
    if (params?.colDef?.field?.indexOf('.') >= 0) {
      // create path to object from field path if it doesn't exist
      const pathToObj = params.colDef.field.split(".").slice(0, -1);
      if (!_.get(params.data, pathToObj)) {
        // create path to obj to be edited if it doesn't exist
        _.set(params.data, pathToObj, {});
      }
    }
    if (!params.data[params.column.colId]) {
      params.api.startEditingCell({
        // @ts-ignore
        rowIndex: params.node.rowIndex,
        // @ts-ignore // gets the first columnKey
        colKey: params.column.colId,
      });
    }
  };

  /**
   * @description helper to duplicate rows
   */
  duplicateContent = (params: any) => {
    const selectedRows = [];
    // if multiple cells are selected, duplicate the entire range
    const cellRange = params?.api.getCellRanges()[0];
    const startRowIdx = cellRange.startRow.rowIndex;
    const endRowIdx = cellRange.endRow.rowIndex;
    var model = params.api.getModel();
    // add newly duplicated row(s) to array for batch redraws
    for (let i = startRowIdx; i <= endRowIdx; i++) {
      selectedRows.push(model.getRow(i));
    }
    params.api.redrawRows({rowNodes: selectedRows});
    params.api.stopEditing();
    // return all the rows that were duplicated
    return selectedRows;
  };

  /**
   * @description callback for when fill (mass edits on drag) handle ends
   */
  fillEnd = () => {
    // reset confirmation open on fill end
    this.confirmationOpen = false;
    // reset cancel operation on fill end
    this.doCancelFill = false;
  };

  /**
   * @description perform fill operation (mass edits on drag)
   */
  setFillOperation = (params: any, doSkipConfirmation?: boolean) => {
    // prevent confirmation window from opening multiple times when copying multiple rows
    if (!this.doCancelFill && (this.confirmationOpen || doSkipConfirmation || window.confirm("Perform mass edit?"))) {
      this.confirmationOpen = true;
      // target only cells that are using the reference renderer since their editable values are conditional
      if (params?.column && (params.column.colDef.cellRenderer === "autoCompleteReferenceRenderer")) {
        // temporarily make ref fields editable
        params.column.colDef.editable = true;
        // wait until grid is done applying the new value and then make it go back to the original editable value
        setTimeout(() => {
          //@ts-ignore
          params.column.colDef.editable = gridApiUtils.setRefEditable;
          this.duplicateContent(params);
        }, 10);
      } else {
        // duplicate row by default
        this.duplicateContent(params);
      }
    } else {
      // set cancel flag so alert won't be shown multiple times
      this.doCancelFill = true;
      // clear selection
      params.api.clearRangeSelection();
      // return current value with no changes
      return params?.currentCellValue || '';
    }
    return false;
  };

  /**
   * @description returns whether a ref field is editable
   * @returns boolean
   */
  setRefEditable = (params: any) => {
    return !_.get(params.data, params?.colDef?.field || '');
  };

  /** IMPORTANT! There is no need to update server, because updates will happen once page is refreshed
   * When new rows are added via transactions in a popup, it won't necessarily update the parent grid
   * with references to the newly created rows in the original grid,
   * so we have to update via transactions so that the UI of the original grid be properly updated
   */
  updatePopupTransactionUpdates = (parentGridApi: GridApi | null, currentGridApi: GridApi | null, rowId: string | null | undefined) => {
    if (parentGridApi && currentGridApi && rowId) {
      const rowNode = currentGridApi?.getRowNode(rowId);
      if (rowNode?.data) {
        const res = parentGridApi?.applyServerSideTransaction({
          update: [rowNode?.data]
        });
        if ((res?.update?.length || -1) > 0) {
          parentGridApi?.redrawRows({rowNodes: res?.update});
        }
      }
    }
  };

  /**
   * @description format value as number using valueFormatter
   */
  numberSetter = (params: any, key: string) => {
    const data = params.data;
    const newValue = Number(params.newValue) || 0;
    const isValueChanged = data[key] !== newValue;
    if (isValueChanged) data[key] = newValue;
    return isValueChanged;
  };

  /**
   * @description formats date using valueFormatter
   */
  dateFormatter = (params: any) => {
    return utils.formatDate(params.value);
  };
}

export const
  gridApiUtils = new GridApiUtils();
