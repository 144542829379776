import {AgGridColumn} from "ag-grid-react";
import React from "react";
import {GridApi} from "ag-grid-community";

export const usePurchaseOrderGroup3 = (props: {
  doSkipId?: boolean,
  expandRow?: any,
  gridApi?: GridApi | null,
  hideDefaultGrid?: boolean,
}): { colArray: JSX.Element[] } => {

  const colArray = [
    ...(!props?.doSkipId ? [<AgGridColumn
      field={"id"}
      lockPosition
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={'suppress-fill-handle'}
      suppressColumnsToolPanel={true}
      hide
      key={"id"}

    />] : []),
    <AgGridColumn
      key={"delivery_note"}
      headerName={"Delivery Note"}
      field={"delivery_note"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
    />
    ,
    <AgGridColumn
      key={"memo"}
      headerName={"Memo"}
      field={"memo"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
    />,
    <AgGridColumn
      key={"entered_by"}
      headerName={"Entered By"}
      field={"entered_by"}
      width={150}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
    />,
    <AgGridColumn
      key={"notes"}
      headerName={"Notes"}
      field={"notes"}
      width={350}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      headerClass={"span-max"}
      cellClass={"span-max"}
    />
  ];

  return {colArray};
};
