import React, {useEffect, useState} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {gridApiUtils} from "../../../../utils/gridApiUtils";
import {AlpineContainer} from "../../../containers/defaultAlpineContainer";
import {AutoCompleteReferenceEditor} from "../../../agFrameworkComponents/editors/autoCompleteRefEditor";
import {AutoCompleteEditor} from "../../../agFrameworkComponents/editors/autoCompleteEditor";
import {AutoCompleteRenderer} from "../../../agFrameworkComponents/renderers/autoCompleteRenderer";
import {CustomRefFilter} from "../../../agFrameworkComponents/filters/customRefFilter";
import {CustomRefFloatingFilter} from "../../../agFrameworkComponents/filters/customRefFloatingFilter";
import {emptyObjectGenerator} from "../../../emptyObjects";
import {AutoCompleteReferenceRenderer} from "../../../agFrameworkComponents/renderers/autoCompleteRefRenderer";
import {FirstColRenderer} from "../../../agFrameworkComponents/renderers/firstColRenderer";
import {DateEditor} from "../../../agFrameworkComponents/editors/dateEditor";
import {GridApi} from "ag-grid-community";
import {AdjustmentsRenderer} from "../../../agFrameworkComponents/renderers/adjustments";
import {QcEditor} from "../../../agFrameworkComponents/editors/qcEditor";
import {BasicSelectionEditor} from "../../../agFrameworkComponents/editors/basicSelectionEditor";
import {CheckmarkRenderer} from "../../../agFrameworkComponents/renderers/checkmarkRenderer";
import {CheckmarkEditor} from "../../../agFrameworkComponents/editors/checkmarkEditor";
import {useUpdateFinishedGood} from "../../../../hooks/rowModels/useFinishedGoods";

export const FinishedGoodsFragmentLoader = (props: {
  getGridFragmentHook: any,
  processCellForClipboard?: (params: any) => void,
  updateColorsByCrossReferencingVendorandCode?: any,
  hideDefaultGrid?: boolean,
  filterId?: string | null,
  gridApi?: GridApi | null
}): JSX.Element => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [parentGridApi] = useState(props?.gridApi || null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [serverSideDataSource, setServerSideDataSource] = useState(undefined);

  const {data, colArray, isAllDataLoaded} = props.getGridFragmentHook({...props});
  const {mutate: updateFinishedGood} = useUpdateFinishedGood();

  useEffect(() => {
    // TODO get rid of emptyObjectGenerator. Get empty row from server instead (the grid needs at least 1 row for user to right click to add new rows)
    //@ts-ignore
    setServerSideDataSource(gridApiUtils.setServerSideDatasource(isAllDataLoaded, (Array.isArray(data) && data?.length > 0) ? data : [emptyObjectGenerator.finishedGoods()]));
  }, [isAllDataLoaded]);

  const onGridReady = (params: any) => {
    if (!gridApi) setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    if (props?.filterId) {
      var filterModel = {
        id: {
          filter: props.filterId,
          filterType: "text",
          type: "contains"
        }
      };
      params.api.setFilterModel(filterModel);
    }
  };

  const onCellValueChanged = (params: any) => {
    // update server
    updateFinishedGood(params?.data, {
      onSuccess: () => {
        // I don't want the popup to update after every change
        // queryClient.invalidateQueries([cacheKeys.finishedGoodsData]);
      }
    });
    gridApiUtils.updatePopupTransactionUpdates(parentGridApi, gridApi, props?.filterId);
  };

  // @ts-ignore
  return (
    <AlpineContainer hideDefaultGrid={props?.hideDefaultGrid}>
      <AgGridReact
        defaultColDef={{
          resizable: true,
          editable: true,
          floatingFilter: true,
          filter: true,
          sortable: true,
          filterParams: {
            debounceMs: 100
          },
        }}
        getRowNodeId={(data) => data.id}
        // turn off column virtualization if grid is displayed in a rowEditorModal (otherwise, not all the columns will show)
        suppressColumnVirtualisation={!!props?.filterId}
        processCellForClipboard={props.processCellForClipboard}
        // allow single-edit if grid is displayed in a rowEditorModal
        singleClickEdit={!!props?.filterId}
        suppressRowClickSelection={true}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={50}
        rowModelType={'serverSide'}
        serverSideDatasource={serverSideDataSource}
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        suppressMenuHide={!!props.hideDefaultGrid}
        //@ts-ignore
        getContextMenuItems={([])}
        stopEditingWhenCellsLoseFocus={true}
        frameworkComponents={{
          autoCompleteEditor: AutoCompleteEditor,
          autoCompleteRenderer: AutoCompleteRenderer,
          customRefFilter: CustomRefFilter,
          customRefFloatingFilter: CustomRefFloatingFilter,
          adjustmentsRenderer: AdjustmentsRenderer,
          autoCompleteReferenceEditor: AutoCompleteReferenceEditor,
          autoCompleteReferenceRenderer: AutoCompleteReferenceRenderer,
          firstColRenderer: FirstColRenderer,
          qcEditor: QcEditor,
          dateEditor: DateEditor,
          basicSelectionEditor: BasicSelectionEditor,
          checkmarkRenderer: CheckmarkRenderer,
          checkmarkEditor: CheckmarkEditor,
        }}
      >
        {colArray}
      </AgGridReact>
    </AlpineContainer>
  );
};

