import React from 'react';
import {useHistory} from 'react-router-dom';
import BannerLogo from '../assets/img/banner-logo.png';
import styled from "styled-components";
import {Button} from "@material-ui/core";
import {colors} from "../constants/allConsts";
import {userUtils} from "../utils/userUtils";

export const MenuBar = () => {
  const history = useHistory();
  const signOut = () => {
    userUtils.signOut().then(() => {
      history.go(0);
    });

  };
  return (
    <StyledContainer>
      <img src={BannerLogo} alt={"laa-logo"}/>
      <MenuContainer>
        <Button onClick={signOut}>Sign Out</Button>
      </MenuContainer>
    </StyledContainer>
  )
};

const StyledContainer = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  //background: ${colors.lightBackgroundGray};
  border-bottom: 1px solid rgba(0,0,0,0.1);
  img {
    height: 20px;
    padding: 0 10px;
  }
`;

const MenuContainer = styled.div`
  margin-left: auto;
  margin-right: 10px;
`;
