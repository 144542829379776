import React from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import styled from "styled-components";
import {GridApi} from "ag-grid-community";
import {useRollGroup1} from "../../../../hooks/gridFragments/rollGrid/useRollGridGroup1";
import {useRollGroup3} from "../../../../hooks/gridFragments/rollGrid/useRollGridGroup3";
import {useRollGroup2} from "../../../../hooks/gridFragments/rollGrid/useRollGridGroup2";
import {AdjustmentFragmentLoader} from "./adjustmentFragmentLoader";
import {useAdjustmentGroup1} from "../../../../hooks/gridFragments/adjustments/useAdjustmentGroup1";
import {useAdjustmentGroup2} from "../../../../hooks/gridFragments/adjustments/useAdjustmentGroup2";

export const AdjustmentSectionedGrid = React.forwardRef((props: {
  expandRow?: any,
  hideDefaultGrid?: boolean,
  filterId?: string,
  gridApi?: GridApi | null,
}, ref): JSX.Element => {
  // TODO implement and pass to adjustment fragment loader (see purchaseORderSectionedGrid for example)
  // const {data} = useGetAdjustments({id: props?.filterId});
  return (
    <>
      <StyledSectionedGroup className={"group1"} groupName={"Group 1"}>
        <AdjustmentFragmentLoader
          {...props}
          getGridFragmentHook={useAdjustmentGroup1}
        />
      </StyledSectionedGroup>
      <StyledSectionedGroup className={"group2"} groupName={"Group 2"}>
        <AdjustmentFragmentLoader
          {...props}
          getGridFragmentHook={useAdjustmentGroup2}
        />
      </StyledSectionedGroup>
    </>
  );
});

interface ISectionedGroup {
  groupName: string;
};

const StyledSectionedGroup = styled.div<ISectionedGroup>`
  padding: 15px;
  position: relative;
  margin-top: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  &:nth-child(1) {
    margin-top: 1rem;
  }
  &:before {
    content: ${(props) => `'${props.groupName}'`};
    position: absolute;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    top: -0.8rem;
    background: white;
    padding: 0 12px;
    left: 12px;
    color: #2D2D2D;
    border-radius: 4px;
   }
`;
