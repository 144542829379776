import {
  IAdjustment,
  IColor,
  IFinishedGood,
  IKnitter,
  IMaker,
  IDyer,
  IVendor,
  IPurchaseOrder,
  IRoll,
  IStyle,
  ISupplier,
  IYarnType, ILocation,
} from "../types/rowModelTypes";
import {v4 as uuidv4} from "uuid";
import moment from "moment";
import {utils} from "../utils/utils";

//TODO IMPORTANT:: delete this file and rewrite create new objects from server

// empty objects are written as a class so that uuids are different each time they're called
class EmptyObjects {
  purchaseOrder() {
    return {
      // "id": uuidv4(),
      "date": utils.formatDate(moment().toString()),
      "price_per_lb": '',
      "yarn_type_ref": '',
      "size": '',
      "desc": '',
      "content": '',
      "color": '',
      "qty": '',
      "supplier_ref": '',
      "ship_by": utils.formatDate(moment().toString()),
      "delivered": utils.formatDate(moment().toString()),
      "terms": '',
      "knitter_ref": '',
      "maker_ref": '',
      "lot_number": '',
      "freights": '',
      "trucks": '',
      "received": '',
      "price_per_kg": '',
      "delivery_note": '',
      "memo": '',
      "entered_by": '',
      // "notes": '',
    } as IPurchaseOrder;
  }

  finishedGoods() {
    return {
      // "id": uuidv4(),
      "type": '',
      "lot_number": '',
      "style": '',
      "laa_color": { 
        // "color_id": uuidv4(),
        "laa_color": '',
      },
      "vendor_color": {
        // "color_id": uuidv4(),
        "vendor_color": '',
      },
      "color_code": {
        // "color_id": uuidv4(),
        "color_code": '',
      },
      "matching_rib": false,
      "shrinkage_length": '',
      "shrinkage_width": '',
      "torque": '',
      "notes": '',
      "trims_notes": '',
      "qc_report": [],
      "dye_house": {
        // 'id': uuidv4(),
        'name': '',
      },
      "vendor": {
        // 'id': uuidv4(),
        'name': '',
      },
      "yarn_maker": {
        // 'id': uuidv4(),
        'name': '',
      },
      "location": {
        // 'id': uuidv4(),
        'name': '',
      },
      "sub_location": '',
      "cost_per_yard": '',
      "cost_per_pound": '',
      "before_wash_width": 0,
      "after_wash_width": 0
    } as IFinishedGood;
  }

  roll(skipDate?: boolean) {
    return {
      // "id": uuidv4(),
      "lots_ref": '',
      "date": skipDate ? '' : utils.formatDate(moment().toString()),
      "lot_number": '',
      "style_ref": '',
      "color_code_ref": '',
      "roll_number": '',
      "yd": '',
      "yd_og": '',
      "lb_in": '',
      "lb_in_og": '',
      "lb_out": '',
      "lb_out_og": '',
      "working_loss": '',
      "laa_color_ref": '',
      "vendor_color_ref": '',
      "lb_per_yd": '',
      "linear_yield": '',
      "sq_yield": '',
      "gsm": '',
      "rib_roll": false,
      "vendor_ref": '',
    } as IRoll;
  }

  adjustment() {
    return {
      // "id": uuidv4(),
      "reference_number": new Date().getTime().toString(),
      "lot_number": '',
      "roll_number": '',
      "cut_sew_number": '',
      "date_of_change": utils.formatDate(moment().toString()),
      "lb_before": '',
      "lb_after": '',
      "yd_before": '',
      "yd_after": '',
      "notes": '',
      "entered_by": '',
    } as IAdjustment;
  }

  supplier() {
    return {
      // "id": uuidv4(),
      "supplier_name": '',
      "supplier_code": '',
      "phone": '',
      "contact": '',
      "email": '',
      "address": "",
    } as ISupplier;
  }


  yarnType() {
    return {
      // "id": uuidv4(),
      "master_code": '',
      "size": '',
      "desc": '',
      "content": '',
      "color": '',
      "price_per_lb": '',
      "price_per_kg": '',
      "cost": '',
      "notes": '',
    } as IYarnType;
  }

  style() {
    return {
      // "id": uuidv4(),
      "style_number": '',
      "pattern": '',
      "desc": '',
      "description": '',
      "content": '',
      "notes": '',
      "finished_width": '',
      "dye_house_id": '',
      "dye_house_name": '',
      "actual_linear_yield": 0,
      "actual_square_yield": 0,
      "actual_gsm": 0,
    } as IStyle;
  }

  color() {
    return {
      // "id": uuidv4(),
      "color_code": '',
      "vendor_ref": '',
      "vendor_color": '',
      "laa_color": '',
      "color_desc": '',
    } as IColor;
  }

  knitter() {
    return {
      // "id": uuidv4(),
      "knitter_name": "",
      "contact": "",
      "phone": "",
      "email": "",
      "address": "",
    } as IKnitter;
  }

  maker() {
    return {
      "maker_name": "",
      "phone": "",
      "contact": "",
      "email": "",
      "address": "",
    } as IMaker
  }

  dyer() {
    return {
      // "id": uuidv4(),
      "dyer_name": "",
      "phone": "",
      "contact": "",
      "email": "",
      "address": "",
    } as IDyer
  }

  vendor() {
    return {
      // "id": uuidv4(),
      "vendor_name": "",
      "phone": "",
      "contact": "",
      "email": "",
      "address": "",
    } as IVendor
  }

  location() {
    return {
      // "id": uuidv4(),
      "location_name": "",
      "address": "",
    } as ILocation
  }
}

export const emptyObjectGenerator = new EmptyObjects();
