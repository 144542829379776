import {AgGridColumn} from "ag-grid-react";
import {utils} from "../../../utils/utils";
import {gridApiUtils} from "../../../utils/gridApiUtils";
import {cacheKeys} from "../../../constants/cacheKeys";
import {emptyObjectGenerator} from "../../../components/emptyObjects";
import React from "react";
import {GridApi} from "ag-grid-community";
import {useUpdateRoll} from "../../rowModels/useRolls";
import {useAddVendor} from "../../rowModels/useVendors";
import {lotUtils} from "../../../utils/lotUtils";
import {VendorGrid} from "../../../components/grids/vendorGrid";

export const useRollGroup3 = (props: {
  doSkipId?: boolean,
  gridApi?: GridApi | null,
  updateColorsByCrossReferencingVendorandCode?: any,
  hideDefaultGrid?: boolean,
}): {  colArray: JSX.Element[] } => {

  const {mutate: updateRoll} = useUpdateRoll();
  const {mutate: addVendor} = useAddVendor();

  const colArray = [
    ...(!props?.doSkipId ? [<AgGridColumn
      field={"id"}
      lockPosition
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={'suppress-fill-handle'}
      suppressColumnsToolPanel={true}
      hide
      key={"id"}
    />] : []),
    <AgGridColumn
      key={"lb_per_yd"}
      headerName={"Pound/Yard"}
      field={"lb_per_yd"}
      // editable={false}
      filter={"agNumberColumnFilter"}
      // cellClass={"noneditable"}
      // We want
      // valueGetter={(params) => {
      //   // IMPORTANT: inconsistent returns when lb_out or yd is NaN can result in infinite updates and crash the app
      //   if (params.data.lb_out === '' || isNaN(params.data.lb_out) || params.data.yd === '' || isNaN(params.data.yd)) return '';
      //   const lbPerYd = utils.roundNumber(((Number(params.data.lb_out) || 0) / (Number(params.data.yd) || 0)), 6);
      //   if (params.data.lb_per_yd !== lbPerYd) {
      //     params.data.lb_per_yd = lbPerYd;
      //     updateRoll(params?.data);
      //   }
      //   return lbPerYd;
      // }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"linear_yield"}
      headerName={"Linear Yield"}
      field={"linear_yield"}
      editable={false}
      filter={"agNumberColumnFilter"}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // IMPORTANT: inconsistent returns when lb_per_yd is NaN can result in infinite updates and crash the app
        if (params.getValue('lb_per_yd') === '' || isNaN(params.getValue('lb_per_yd'))) return '';
        const linearYield = lotUtils.calculateLinearYield(params.getValue('lb_per_yd'));
        if (params.data.linear_yield !== linearYield) {
          params.data.linear_yield = linearYield;
          updateRoll(params?.data);
        }
        return linearYield;
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"sq_yield"}
      headerName={"Square Yield"}
      field={"sq_yield"}
      editable={false}
      filter={"agNumberColumnFilter"}
      cellClass={"noneditable"}
      // rewrite valueGetter
      // valueGetter={(params) => {
      //   // TODO make sure we're getting the width from the right place. Is it supposed to get width from style?
      //   // IMPORTANT: inconsistent returns when linear_yield is NaN can result in infinite updates and crash the app
      //   if (params.getValue('linear_yield') === '' || isNaN(params.getValue('linear_yield'))) return '';
      //   const widthFromStyle = (params?.data?.style_ref) ? (Number(styleIdMap.get(params.data.style_ref)?.finished_width)) : '';
      //   const squareYield = lotUtils.calculateSquareYield(widthFromStyle, params.getValue('linear_yield'));
      //   if (params.data.sq_yield !== squareYield) {
      //     params.data.sq_yield = squareYield;
      //     updateRoll(params?.data);
      //   }
      //   return squareYield;
      // }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"gsm"}
      headerName={"GSM"}
      field={"gsm"}
      editable={false}
      filter={"agNumberColumnFilter"}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // IMPORTANT: inconsistent returns when sq_yield is NaN can result in infinite updates and crash the app
        if (params.getValue('sq_yield') === '' || isNaN(params.getValue('sq_yield'))) return '';
        const GSM = lotUtils.calculateGSM(params.getValue('sq_yield'));
        if (params.data.gsm !== GSM) {
          params.data.gsm = GSM;
          updateRoll(params?.data);
        }
        return GSM;
      }}
      comparator={utils.numberComparator}
      minWidth={200}
      flex={1}
    />,
    <AgGridColumn
      key={"rib_roll"}
      headerName={"Rib Roll?"}
      field={"rib_roll"}
      cellRenderer={"checkmarkRenderer"}
      cellEditor={"checkmarkEditor"}
      comparator={utils.emptyComparator}
      // singleClickEdit
    />,
    <AgGridColumn
      key={"vendor_ref"}
      headerName={"Vendor"}
      field={"vendor_ref"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = vendorIdMap.get(valueA)?.vendor_name || '';
      //   const refValueB = vendorIdMap.get(valueB)?.vendor_name || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      cellRendererParams={(rendererParams: any) => {
        return {
          deleteCellValue: (params: any) => {
            // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
          },
          gridComponent: (
            <VendorGrid
              hideDefaultGrid={true}
              filterId={rendererParams.data.vendor_ref}
            />
          )
        }
      }}
      cellEditorParams={{
        addDataService: addVendor,
        nameKey: 'vendor_name',
        cacheKey: cacheKeys.vendorData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.vendor,
        // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
      }}
      onCellValueChanged={props?.updateColorsByCrossReferencingVendorandCode}
      // TODO implement server-side filter
      // filter={"customRefFilter"}
      // floatingFilterComponent={"customRefFloatingFilter"}
      // floatingFilterComponentParams={{suppressFilterButton: true}}
      // filterParams={{
      //   cacheKey: cacheKeys.vendorData,
      //   filterData: vendorData,
      //   nameKey: 'vendor_name',
      // }}
    />
  ];

  return {colArray};
};
