import React, {forwardRef, useState, useImperativeHandle, useRef} from 'react';
import styled from "styled-components";
import {utils} from "../../../utils/utils";
import {colors} from "../../../constants/allConsts";

const AddressEditor = forwardRef((props: {value: string}, ref: any) => {
  const [value, setValue] = useState<string>('');
  const textFieldRef = useRef(null);
  const [output, setOutput] = useState<string>('');
  const [addressLine1, setAddressLine1] = useState<string>('');
  const [addressLine2, setAddressLine2] = useState<string>('');
  const [addressCity, setAddressCity] = useState<string>('');
  const [addressState, setAddressState] = useState<string>('');
  const [addressZip, setAddressZip] = useState<string>('');

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return output || '';
      },
      afterGuiAttached: () => {
        setValue(props?.value || '');
        setOutput(props?.value || '');
        // allow field to be edited as soon as element UI is ready
        if(textFieldRef.current) {
          // @ts-ignore
          textFieldRef.current.focus();
        }

        if (props.value) {
          const addressParts = utils.getAddressPartsFromString(props?.value || '');
          setAddressLine1(addressParts.addressLine1);
          setAddressLine2(addressParts.addressLine2);
          setAddressCity(addressParts.addressCity);
          setAddressState(addressParts.addressState);
          setAddressZip(addressParts.addressZip);
          const addressOutput = `${addressParts.addressLine1}, ${(addressParts.addressLine2) ? `${addressParts.addressLine2}, ` : ''}${addressParts.addressCity}, ${addressParts.addressState}, ${addressParts.addressZip}`;
          setOutput(addressOutput);
        }
      }
    };
  });

  const handleLine1Change = (e: React.FormEvent<HTMLInputElement>) => {
    setAddressLine1(e?.currentTarget?.value.replace(/,/g, '') || '');
    const addressOutput = `${e.currentTarget.value.replace(/,/g, '').trim()}, ${addressLine2 ? `${addressLine2}, ` : ''}${addressCity}, ${addressState}, ${addressZip}`;
    setOutput(addressOutput);
  };
  const handleLine2Change = (e: React.FormEvent<HTMLInputElement>) => {
    setAddressLine2(e?.currentTarget?.value.replace(/,/g,'') || '');
    const addressOutput = `${addressLine1}, ${e?.currentTarget?.value ? `${e?.currentTarget?.value.replace(/,/g,'').trim()}, ` : ''}${addressCity}, ${addressState}, ${addressZip}`;
    setOutput(addressOutput);
  };
  const handleCityChange = (e: React.FormEvent<HTMLInputElement>) => {
    setAddressCity(e?.currentTarget?.value || '');
    const addressOutput = `${addressLine1}, ${addressLine2 ? `${addressLine2}, ` : ''}${e.currentTarget.value.trim()}, ${addressState}, ${addressZip}`;
    setOutput(addressOutput);
  };
  const handleStateChange = (e: React.FormEvent<HTMLInputElement>) => {
    setAddressState(e?.currentTarget?.value.toUpperCase().trim() || '');
    const addressOutput = `${addressLine1}, ${addressLine2 ? `${addressLine2}, ` : ''}${addressCity}, ${e.currentTarget.value.trim()}, ${addressZip}`;
    setOutput(addressOutput);
  };
  const handleZipChange = (e: React.FormEvent<HTMLInputElement>) => {
    setAddressZip(e?.currentTarget?.value?.trim() || '');
    const addressOutput = `${addressLine1}, ${addressLine2 ? `${addressLine2}, ` : ''}${addressCity}, ${addressState}, ${e.currentTarget.value.trim()}`;
    setOutput(addressOutput);
  };
  return (
    <StyledInputContainer className={"ag-address-editor"}>
      <input
        className={"ag-text-field-input"}
        type="text"
        placeholder="Address Line 1"
        onChange={handleLine1Change}
        value={addressLine1}
        ref={textFieldRef}
      />
      <input
        className={"ag-text-field-input"}
        type="text"
        placeholder="Address Line 2"
        size={12}
        onChange={handleLine2Change}
        value={addressLine2}
        tabIndex={0}
      />
      <input
        className={"ag-text-field-input"}
        type="text"
        placeholder="City"
        size={12}
        onChange={handleCityChange}
        value={addressCity}
      />
      <input
        className={"ag-text-field-input"}
        type="text"
        placeholder="State"
        maxLength={2}
        size={2}
        onChange={handleStateChange}
        value={addressState}
      />
      <input
        className={"ag-text-field-input"}
        type="text"
        placeholder="zipcode"
        maxLength={6}
        size={6}
        onChange={handleZipChange}
        value={addressZip}
      />
    </StyledInputContainer>
  );
});

export {AddressEditor};

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: ${colors.white};
  box-shadow: 3px 3px 6px rgba(0,0,0,0.05);
  box-sizing: border-box;
  border-radius: 3px;
  input {
    box-sizing: border-box;
    margin: 2px !important;
    padding: 0;
    height: auto;
    font-size: 0.85rem !important;
  }
`;