import React, {useState} from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {cacheKeys} from "../../../constants/allConsts";
import {gridApiUtils} from "../../../utils/gridApiUtils";
import {AlpineContainer} from "../../containers/defaultAlpineContainer";
import {AutoCompleteReferenceEditor} from "../../agFrameworkComponents/editors/autoCompleteRefEditor";
import {AutoCompleteEditor} from "../../agFrameworkComponents/editors/autoCompleteEditor";
import {AutoCompleteRenderer} from "../../agFrameworkComponents/renderers/autoCompleteRenderer";
import {emptyObjectGenerator} from "../../emptyObjects";
import {AutoCompleteReferenceRenderer} from "../../agFrameworkComponents/renderers/autoCompleteRefRenderer";
import {StyleGrid} from "../styleGrid";
import {NumberEditor} from "../../agFrameworkComponents/editors/numberEditor";
import styled from "styled-components";
import {DialogTitle} from "@material-ui/core";
import {ColorGrid} from "../colorGrid";
import {VendorGrid} from "../vendorGrid";
import {useAddStyle} from "../../../hooks/rowModels/useStyles";
import {useAddColor} from "../../../hooks/rowModels/useColors";
import {useAddVendor} from "../../../hooks/rowModels/useVendors";


export const NewLotGrid = React.forwardRef((props: { setDoGenerateRolls: (doGenerateRolls: boolean) => void }, ref): JSX.Element => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rollNamesPreview, setRollNamesPreview] = useState<string[]>([]);

  const {mutate: addColor} = useAddColor();
  const {mutate: addVendor} = useAddVendor();
  const {mutate: addStyle} = useAddStyle();

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  // @ts-ignore
  return (
    <AlpineContainer hideDefaultGrid={true}>
      <AgGridReact
        //@ts-ignore // forward ref to parent
        ref={ref}
        defaultColDef={{
          resizable: true,
          editable: true,
          floatingFilter: true,
          filter: true,
          sortable: true,
          filterParams: {
            debounceMs: 100
          }
        }}
        suppressRowClickSelection={true}
        rowSelection={'multiple'}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={50}
        animateRows={true}
        rowData={[{
          lot_number: '',
          roll_count: '1',
          style_ref: '',
          color_code: '', // laa_color and color_ref are autopopulated with color_code, which is cross-referenced from vendor
        }]}
        singleClickEdit
        onGridReady={onGridReady}
        onCellKeyDown={(params) => {
          const tempRollNamesPreview: string[] = [];
          //@ts-ignore
          const colId = params?.api?.getEditingCells()[0]?.column?.colId;
          const data = params?.node?.data;
          let rollCount = data?.roll_count;
          let lotNumber = data?.lot_number?.toUpperCase();

          if (colId === 'lot_number') {
            // get lot number from editor instance instead of renderer
            //@ts-ignore
            lotNumber = params?.api?.getCellEditorInstances()[0]?.eInput?.value?.toUpperCase();
          } else if (colId === 'roll_count') {
            // get roll count from editor instance instead of renderer
            //@ts-ignore
            rollCount = params?.api?.getCellEditorInstances()[0]?.eParentElement?.querySelectorAll('input')[0]?.value;
          }
          if (lotNumber && rollCount) {
            props?.setDoGenerateRolls(true);
            for (let i = 0; i < Number(rollCount); i++) {
              tempRollNamesPreview.push(`${lotNumber}${i + 1}`);
            }
            setRollNamesPreview(tempRollNamesPreview);
          } else {
            props?.setDoGenerateRolls(false);
            setRollNamesPreview([]);
          }
        }}
        getContextMenuItems={(params) => []}
        stopEditingWhenGridLosesFocus={true}
        frameworkComponents={{
          autoCompleteEditor: AutoCompleteEditor,
          autoCompleteRenderer: AutoCompleteRenderer,
          autoCompleteReferenceEditor: AutoCompleteReferenceEditor,
          autoCompleteReferenceRenderer: AutoCompleteReferenceRenderer,
          numberEditor: NumberEditor,
        }}
      >
        <AgGridColumn
          headerName={"New Lot Number"}
          field={"lot_number"}
          cellClass={"uppercase"}
        />
        <AgGridColumn
          headerName={"Number of Rolls"}
          field={"roll_count"}
          filter={"agTextColumnFilter"}
          cellEditor={"numberEditor"}
        />
        <AgGridColumn
          headerName={"New Lot Style"}
          field={"style_ref"}
          cellEditor={"autoCompleteReferenceEditor"}
          cellRenderer={"autoCompleteReferenceRenderer"}
          // TODO rewrite comparator by using contents retrieved from paginated data
          // comparator={(valueA, valueB) => {
          //   const refValueA = styleIdMap.get(valueA)?.style_name || '';
          //   const refValueB = styleIdMap.get(valueB)?.style_name || '';
          //   return utils.baseComparator(refValueA, refValueB);
          // }}
          editable={gridApiUtils.setRefEditable}
          onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
          cellRendererParams={(rendererParams: any) => {
            return {
              deleteCellValue: (params: any) => {
                // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
              },
              gridComponent: (
                <StyleGrid
                  hideDefaultGrid={true}
                  filterId={rendererParams.data.style_ref}
                />
              )
            }
          }}
          cellEditorParams={{
            addDataService: addStyle,
            nameKey: 'style_name',
            cacheKey: cacheKeys.styleData,
            // TODO make empty object serverside
            emptyObject: emptyObjectGenerator.style,
            // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
          }}
          // TODO implement server-side filter
          // filter={"customRefFilter"}
          // floatingFilterComponent={"customRefFloatingFilter"}
          // floatingFilterComponentParams={{suppressFilterButton: true}}
          // filterParams={{
          //   cacheKey: cacheKeys.styleData,
          //   filterData: styleData,
          //   nameKey: 'style_name',
          // }}
        />
        <AgGridColumn
          headerName={"Color Code"}
          field={"color_code"}
          cellEditor={"autoCompleteReferenceEditor"}
          cellRenderer={"autoCompleteReferenceRenderer"}
          // TODO rewrite comparator by using contents retrieved from paginated data
          // comparator={(valueA, valueB) => {
          //   const refValueA = colorIdMap.get(valueA)?.color_code || '';
          //   const refValueB = colorIdMap.get(valueB)?.color_code || '';
          //   return utils.baseComparator(refValueA, refValueB);
          // }}
          editable={gridApiUtils.setRefEditable}
          onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
          cellRendererParams={(rendererParams: any) => {
            return {
              deleteCellValue: (params: any) => {
                // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
              },
              gridComponent: (
                <ColorGrid
                  hideDefaultGrid={true}
                  filterId={rendererParams.data.color_code}
                />
              )
            }
          }}
          cellEditorParams={{
            addDataService: addColor,
            nameKey: 'color_code',
            cacheKey: cacheKeys.colorData,
            // TODO make empty object serverside
            emptyObject: emptyObjectGenerator.color,
            // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
            updateRowDataOnAutocomplete: ({currentNode, chosenRefObject: chosenColor}: any) => {
              const dat = Object.assign({}, currentNode.data);
              // link color code to color ref
              dat.color_code = chosenColor?.id || '';
              currentNode.setData(dat);
            },
          }}
          // TODO implement server-side filter
          // filter={"customRefFilter"}
          // floatingFilterComponent={"customRefFloatingFilter"}
          // floatingFilterComponentParams={{suppressFilterButton: true}}
          // filterParams={{
          //   cacheKey: cacheKeys.colorData,
          //   filterData: colorData,
          //   nameKey: 'color_code',
          // }}
        />
        <AgGridColumn
          headerName={"Vendor"}
          field={"vendor_ref"}
          cellEditor={"autoCompleteReferenceEditor"}
          cellRenderer={"autoCompleteReferenceRenderer"}
          // TODO rewrite comparator by using contents retrieved from paginated data
          // comparator={(valueA, valueB) => {
          //   const refValueA = vendorIdMap.get(valueA)?.vendor_name || '';
          //   const refValueB = vendorIdMap.get(valueB)?.vendor_name || '';
          //   return utils.baseComparator(refValueA, refValueB);
          // }}
          editable={gridApiUtils.setRefEditable}
          onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
          cellRendererParams={(rendererParams: any) => {
            return {
              deleteCellValue: (params: any) => {
                // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
              },
              gridComponent: (
                <VendorGrid
                  hideDefaultGrid={true}
                  filterId={rendererParams.data.vendor_ref}
                />
              )
            }
          }
          }
          cellEditorParams={{
            addDataService: addVendor,
            nameKey: 'vendor_name',
            cacheKey: cacheKeys.vendorData,
            // TODO make empty object serverside
            emptyObject: emptyObjectGenerator.vendor,
            // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
          }
          }
          // TODO implement server-side filter
          // filter={"customRefFilter"}
          // floatingFilterComponent={"customRefFloatingFilter"}
          // floatingFilterComponentParams={{suppressFilterButton: true}}
          // filterParams={{
          //   cacheKey: cacheKeys.vendorData,
          //   filterData: vendorData,
          //   nameKey: 'vendor_name',
          // }}
        />
      </AgGridReact>
      {(rollNamesPreview.length > 0) &&
      <StyledPreviewContainer>
        <StyledPreviewTitle>Rolls To Generate:</StyledPreviewTitle>
        <StyledPreviewUl>
          {rollNamesPreview.map((rollName) => (
            <li key={rollName.toString()}>{rollName}</li>
          ))}
        </StyledPreviewUl>
      </StyledPreviewContainer>
      }
    </AlpineContainer>
  );
});

const StyledPreviewContainer = styled.div`
    display: inline-grid;
    margin-top: 30px;
`;

const StyledPreviewTitle = styled(DialogTitle)`
   padding-bottom: 0;
   &.MuiDialogTitle-root {
    padding-top: 5px;
    padding-bottom: 0;
    padding-left: 0;
    .MuiTypography-h6 {
      font-size: 0.9rem;
      margin-bottom: 0;
      padding-top: 5px;
    }
   }
`;

const StyledPreviewUl = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 5px;
  max-height: 200px;
  overflow: auto;
  li {
    font-size: 0.8rem;
    margin-bottom: 5px;
    padding: 0;
    border-radius: 2px;
    background: #ffffff;
    font-weight: 500;
  }
`;
