import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {utils} from "../../utils/utils";
import {RowNode} from "ag-grid-community";
import moment from "moment";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const generatePurchaseOrderPdf = (props: { rowNode: RowNode | undefined, pdfData: any }) => {
  const rowData = props?.rowNode?.data;
  const knitterData = props?.pdfData?.knitterData || {};
  const customerData = props?.pdfData?.customerData || {};
  const purchaseOrderData = props?.pdfData?.purchaseOrderData || {};
  const colorData = props?.pdfData?.colorData || {};
  const makerData = props?.pdfData.makerData || {};
  const supplierData = props?.pdfData.supplierData || {};
  const vendorData = props?.pdfData?.vendorData || {};

  const knitterAddressParts = utils.getAddressPartsFromString(knitterData.address);
  const customerAddressParts = utils.getAddressPartsFromString(customerData.address);

  const hLine = (valprops: { top: number, bottom: number }) => {
    return {
      margin: [0, valprops.top, 0, valprops.bottom],
      table: {
        headerRows: 1,
        widths: ['100%'],
        body: [['']],
      },
      layout: {
        fillColor: '#aaaaaa',
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },
      }
    }
  };

  const dd = {
    pageSize: 'A4',
    content: [
      {
        fontSize: 11,
        columns: [
          {
            stack: [
              {
                text: 'Los Angeles Apparel Inc.',
                bold: true,
                style: 'subheader'
              },
              {
                margin: [0, 10, 0, 0],
                text: '1020 E 59th St.'
              },
              {
                text: 'Los Angeles, CA 90011',
              },
              {
                text: 'losangelesapparel.net',
                italics: true,
              }
            ],
          },
          {
            stack: [
              {
                alignment: 'right',
                text: `Yarn PO #:         ${rowData.order_number}`,
                style: 'header'
              },
              {
                margin: [0, 10, 0, 0],
                columns: [
                  {text: 'Date:', alignment: 'right', bold: true},
                  {alignment: 'right', text: `${moment(rowData.date).format("MMM DD YY").toString()}`, width: 60}],

              },
              {
                margin: [0, 0, 0, 10],
                columns: [
                  {text: 'WO#', alignment: 'right', bold: true},
                  {alignment: 'right', text: ' 0', width: 60}],
              }

            ],
          },
        ],
      },
      hLine({top: 10, bottom: 5}),
      {
        fontSize: 11,
        margin: [0, 0, 0, 5],
        stack: [
          {text: 'Division', style: 'header'},
          // @ts-ignore
          {text: 'Company', alignment: 'left', style: 'subheader', bold: true},
          {
            columns: [
              {text: knitterData.knitter_name, alignment: 'left'},
              {text: `${knitterData.phone}` || '', alignment: 'right', margin: [0, 0, -30, 0]},
              {text: `Lot # ${purchaseOrderData?.lot_number}`, alignment: 'right'},
            ],
          },
          {
            columns: [
              {
                text: `${knitterAddressParts.addressLine1}${knitterAddressParts.addressLine2 ? `, ${knitterAddressParts.addressLine2}` : ''}`,
                alignment: 'left'
              },
              {text: `FAX:  ${vendorData}`, alignment: 'left', background: 'yellow'},
            ],
          },
          {
            columns: [
              {
                columns: [
                  {text: knitterAddressParts.addressCity, alignment: 'left'},
                  {text: knitterAddressParts.addressState, alignment: 'center'},
                  {text: knitterAddressParts.addressZip, alignment: 'center'},
                ]
              },
              {text: 'ATTN:', alignment: 'left', background: 'yellow'},
            ],
          },
        ],
      },
      hLine({top: 5, bottom: 5}),
      {
        fontSize: 11,
        stack: [
          {
            columns: [
              {text: 'Bill To', alignment: 'left', style: 'subheader', bold: true},
              {text: 'Ship To ... same as knitter address?', alignment: 'left', style: 'subheader', bold: true, background: 'yellow'},
            ],
          },
          {
            columns: [
              {text: `${customerData.customer_name}`, alignment: 'left'},
            ],
          },
          {
            columns: [
              {
                text: `${customerAddressParts.addressLine1}${customerAddressParts.addressLine2 ? `, ${customerAddressParts.addressLine2}` : ''}`,
                alignment: 'left'
              },
            ],
          },
          {
            columns: [
              {
                columns: [
                  {text: customerAddressParts.addressCity, alignment: 'left'},
                  {text: customerAddressParts.addressState, alignment: 'center'},
                  {text: customerAddressParts.addressZip, alignment: 'center'},
                ]
              },
              {text: customerData?.phone, alignment: 'left'},
            ],
          },
        ],
      },
      hLine({top: 5, bottom: 5}),
      {
        fontSize: 11,
        stack: [
          {
            columns: [
              {text: 'Deliver By', alignment: 'center', bold: true},
              {text: 'Terms', alignment: 'center', bold: true},
              {text: 'Freight', alignment: 'center', bold: true},
              {text: 'Ship Via', alignment: 'center', bold: true},
              {text: 'Ordered By', alignment: 'center', bold: true, background: 'yellow'},
            ]
          },
          {
            columns: [
              {text: `Ship by? ${purchaseOrderData.ship_by}`, alignment: 'center', background: 'yellow'},
              {text: purchaseOrderData.terms, alignment: 'center'},
              {text: purchaseOrderData.freights > 0 ? purchaseOrderData.freights : '', alignment: 'center'},
              {text: '', alignment: 'center'},
              {text: 'current user?', alignment: 'center', background: 'yellow'},
            ],
          }
        ]
      },
      hLine({top: 5, bottom: 10}),
      {
        fontSize: 11,
        columns: [
          {
            stack: [
              {
                columns: [
                  {text: 'Size', bold: true, width: 65},
                  {text: purchaseOrderData?.size},
                ],
              },
              {
                columns: [
                  {text: 'Desc', bold: true, width: 65},
                  {text: purchaseOrderData?.desc},
                ],
              },
              {
                columns: [
                  {text: 'Content', bold: true, width: 65},
                  {text: purchaseOrderData?.content},
                ],
              },
              {
                columns: [
                  {text: 'Color', bold: true, width: 65},
                  {text: /* colorData?.color_name */ "(vendor or laa color?)" , background: 'yellow'},
                ],
              },
              {
                columns: [
                  {text: 'Color #', bold: true, width: 65},
                  {text: colorData?.code},
                ],
              },
              {
                columns: [
                  {text: 'Additional.', bold: true, width: 65},
                ],
              },
              {
                columns: [
                  {text: 'Desc.', bold: true, width: 65},
                  {text: colorData?.desc},
                ],
              }
            ]
          },
          {
            stack: [
              {
                columns: [
                  {text: 'Maker', bold: true, width: 65},
                  {text: makerData?.maker_name},
                ]
              },
              {
                columns: [
                  {text: 'Supplier', bold: true, width: 65},
                  {text: supplierData?.supplier_name},
                ]
              },
              {
                columns: [
                  {},
                  {
                    width: '60%',
                    stack: [
                      {
                        columns: [
                          {text: 'Quantity', bold: true, width: 50},
                          {text: purchaseOrderData?.qty, alignment: 'right'},
                          {text: `lbs`, alignment: 'right', width: 25},
                        ]
                      },
                      {
                        columns: [
                          {text: 'Price', bold: true, width: 50},
                          {text: `$${purchaseOrderData?.price_per_lb}`, alignment: 'right'},
                          {text: '', width: 25},
                        ]
                      },
                      {
                        columns: [
                          {text: 'Ext Price', bold: true, width: 50},
                          {
                            text: `$${Number(purchaseOrderData?.qty) * Number(purchaseOrderData?.price_per_lb)}`,
                            alignment: 'right',
                            decoration: 'overline'
                          },
                          {text: 'bal?', width: 25, background: 'yellow', alignment: 'right'},
                        ]
                      }
                    ]
                  }
                ]
              },
            ]
          },
        ]
      },
      hLine({top: 10, bottom: 10}),
      {fontSize: 11, text: 'Remarks', bold: true},
      hLine({top: 5, bottom: 5}),
      {fontSize: 11, text: 'Important', bold: true, margin: [0, 0, 0, 30]},
      {
        fontSize: 11,
        columns: [
          {text: '', width: '35%'},
          {
            columns: [
              {text: 'Purchasing Agent: ________________________'},
              {text: 'Date: ______________', width: '35%', alignment: 'right'},
            ]
          }

        ]
      },
      {fontSize: 6, bold: true, background: 'yellow', text: `CURRENTUSER    `},
      {fontSize: 6, bold: true, text: `${moment().format("HH:mm").toString()}    ${moment().format("MMM DD YY").toString()}`}
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'right',
        margin: [0, 0, 0, 0]
      },
      bold: {
        bold: true,
      },
      subheader: {
        fontSize: 12
      },
      superMargin: {
        margin: [40, 0, 40, 0],
        fontSize: 15
      }
    }
  };
  // @ts-ignore
  pdfMake.createPdf(dd).download(`purchase_order_${purchaseOrderData?.order_number}.pdf`);
};

export {generatePurchaseOrderPdf};
