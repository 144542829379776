import React, {useState} from 'react';
import styled from "styled-components";
import {InfoModal} from "../../modals/infoModal";
import {colors} from "../../../constants/allConsts";

const AdjustmentsRenderer = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const closeModal = () => {
        setShowModal(false);
    };
    return (
        <>
        <StyledButton onClick={() => setShowModal(true)}>
            Adjustments
         </StyledButton>
        <InfoModal title={'Make adjustments here'} open={showModal} close={closeModal}>
            <StyledEmptySpace>Adjustments...</StyledEmptySpace>
        </InfoModal>
        </>
    );
};

export {AdjustmentsRenderer};

const StyledButton = styled.button`
    border-radius: 2px;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 5px 8px;
    color: ${colors.white};
    background: #689fe5;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
    cursor: pointer;
    &:active {
      filter: brightness(0.8);
      box-shadow: none;
    }
`;

const StyledEmptySpace = styled.div`
  min-height: 300px;
  width: 100%;
  
`;