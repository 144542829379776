import {AgGridColumn} from "ag-grid-react";
import {utils} from "../../../utils/utils";
import React from "react";
import {GridApi} from "ag-grid-community";

export const usePurchaseOrderGroup2 = (props: {
  doSkipId?: boolean,
  expandRow?: any,
  gridApi?: GridApi | null,
  hideDefaultGrid?: boolean,
}): { colArray: JSX.Element[]} => {

  const colArray = [
    ...(!props?.doSkipId ? [<AgGridColumn
      field={"id"}
      lockPosition
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={'suppress-fill-handle'}
      suppressColumnsToolPanel={true}
      hide
      key={"id"}
    />] : []),
    <AgGridColumn
      key={"freights"}
      field={"freights"}
      width={150}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"trucks"}
      field={"trucks"}
      width={150}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"received"}
      headerName={"Rcvd"}
      field={"received"}
      width={150}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"price_per_kg"}
      headerName={"Price/KG"}
      field={"yarn_master_code.price_per_kg"}
      width={150}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      valueFormatter={(params: any) => {
        return utils.USDFormatter(params.value) || '';
      }}
      comparator={utils.numberComparator}
    />
  ];

  return {colArray};
};
