import {utils} from "./utils";

class LotUtils {
  /**
  * @description calculates working loss percentage
  * @param lbOut recorded pounds out
  * @param lbIn recorded pounds in
  * */
  calculateWorkingLossPercentage(lbOut: string | number | undefined, lbIn: string | number | undefined): number | '' {
    if (lbOut === "" || lbIn === "" || isNaN(Number(lbOut)) || isNaN(Number(lbIn))) return '';
    return utils.roundNumber((100 - (((Number(lbOut) || 0) / Number(lbIn) || 0)) * 100), 3) as number;
  };

  /**
   * @description calculates linear yield (pounds per yard * 16)
   * @param lbPerYd recorded pounds per yard
   * */
  calculateLinearYield(lbPerYd: string | number): number | '' {
    if (lbPerYd === "" || isNaN(Number(lbPerYd))) return '';
    return utils.roundNumber((Number(lbPerYd || 0) * 16), 6) as number;
  }

  /**
   * @description calculates linear yield (linear yield * 36 / fabric width)
   * @param styleWidth fabric width from associated style
   * @param linearYield recorded linear yield
   * */
  calculateSquareYield(styleWidth: string | number, linearYield: string | number): number | '' {
    if (styleWidth === "" || linearYield === "" || isNaN(Number(styleWidth)) || isNaN(Number(linearYield))) return "";
    return utils.roundNumber(((Number(linearYield) * 36) / Number(styleWidth)), 6) as number;
  }

  /**
   * @description calculates GSM (square yield * 33.9)
   * @param sqYield recorded square yield
   * */
  calculateGSM(sqYield: string | number): number | ''{
    if (sqYield === "" || isNaN(Number(sqYield))) return "";
    return utils.roundNumber(((Number(sqYield) || 0) * 33.9), 6) as number;
  }
}

export const lotUtils = new LotUtils();