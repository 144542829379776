import {gridApiUtils} from './gridApiUtils';
import {IRoll} from "../types/rowModelTypes";
import {emptyObjectGenerator} from "../components/emptyObjects";
import {cacheKeys} from "../constants/cacheKeys";
import {GridApi} from "ag-grid-community";
import {QueryClient} from "react-query";

class RollUtils {
  setFillRollOperation = (params: any) => {
    // if (params?.column === "")
    if (params?.column?.colId === "yd" || params?.column?.colId === "lb_out") {
      const cellRange = params?.api.getCellRanges()[0];
      const startRowIdx = cellRange?.startRow?.rowIndex;
      const endRowIdx = cellRange?.endRow?.rowIndex;
      var model = params.api.getModel();
      if (startRowIdx && endRowIdx) {
      }
    } else {
    }
    return gridApiUtils.setFillOperation(params);
  };

  makeRollAdjustment = (props: {
                          gridApi: GridApi | null,
                          currentRollData: IRoll,
                          adjustedPoundsOut: string | number,
                          adjustedYards: string | number,
                          cutSewNumber?: string,
                          notes?: string,
                          addAdjustmentCall: any,
                          updateRollCall: any,
                          queryClient: QueryClient,
                          callback?: () => void,
                        }
  ) => {
    const adjustmentRoll = {...props.currentRollData} as IRoll;
    adjustmentRoll.lb_out = props.adjustedPoundsOut.toString();
    adjustmentRoll.yd = props.adjustedYards.toString();

    // TODO get new object from server, not from emptyObjectGenerator
    const newHistoryObject = emptyObjectGenerator.adjustment();
    newHistoryObject.lot_number = props.currentRollData?.lot_number;
    newHistoryObject.roll_number = props.currentRollData?.roll_number;
    newHistoryObject.cut_sew_number = props?.cutSewNumber || '';
    newHistoryObject.notes = props?.notes || '';
    newHistoryObject.lb_before = props.currentRollData?.lb_out;
    newHistoryObject.lb_after = adjustmentRoll.lb_out;
    newHistoryObject.yd_before = props.currentRollData?.yd;
    newHistoryObject.yd_after = adjustmentRoll.yd;
    // TODO insert current user
    props.addAdjustmentCall(newHistoryObject, {
      onSuccess: () => {
        props.queryClient.invalidateQueries(cacheKeys.adjustmentData);
      }
    });

    props.updateRollCall(adjustmentRoll, {
      onSuccess: () => {
        props.queryClient.invalidateQueries(cacheKeys.rollData).then(() => {
          if (props.gridApi) {
            props.gridApi?.applyServerSideTransaction({
              update: [adjustmentRoll]
            });
            if (props?.callback) props.callback();
            // setYards('0');
            // setPoundsOut('0');
          }
          // close();
        });
      }
    });
  }

}

export const rollUtils = new RollUtils();
