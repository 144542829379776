import {AgGridColumn} from "ag-grid-react";
import {utils} from "../../../utils/utils";
import React from "react";
import {
  IRoll,
} from "../../../types/rowModelTypes";
import {GridApi} from "ag-grid-community";
import {
  useUpdateFinishedGood
} from "../../rowModels/useFinishedGoods";

export const useFinishedGoodsGroup6 = (props: {
  doSkipId?: boolean,
  expandRow?: any,
  gridApi?: GridApi | null,
  hideDefautGrid?: boolean,
}): {colArray: JSX.Element[]} => {

  const {mutate: updateFinishedGoods} = useUpdateFinishedGood();


    // TODO rewrite
  // const rollsByLotsMap = useMemo(() => {
    // const rolls = new Map();
    /* we don't want to loop and filter every single roll in lots when data changes,
      so we'll do it once at the top here and use this data directly */
    // rollData?.forEach((roll: IRoll) => {
    //   if (rolls.get(roll.lots_ref)) rolls.set(roll.lots_ref, [roll, ...rolls.get(roll.lots_ref)]);
    //   else rolls.set(roll.lots_ref, [roll]);
    // });
  //   return rolls;
  // }, [rollData]);

  const colArray = [
    ...(!props?.doSkipId ? [<AgGridColumn
      field={"id"}
      lockPosition
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={'suppress-fill-handle'}
      suppressColumnsToolPanel={true}
      hide
      key={"id"}
    />] : []),
    <AgGridColumn
      key={"self_rolls_originally_received"}
      headerName={"# Self Rolls Originally Received"}
      field={"self_rolls_originally_received"}
      filter={"agNumberColumnFilter"}
      width={250}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const selfRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + (current?.rib_roll ? 0 : 1), 0);
        // if (params.data.self_rolls_originally_received !== selfRollsSum) {
        //   params.data.self_rolls_originally_received = selfRollsSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${selfRollsSum}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"rib_rolls_originally_received"}
      headerName={"# Rib Rolls Originally Received"}
      field={"rib_rolls_originally_received"}
      filter={"agNumberColumnFilter"}
      width={250}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const ribRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + (current?.rib_roll ? 1 : 0), 0);
        // if (params.data.rib_rolls_originally_received !== ribRollsSum) {
        //   params.data.rib_rolls_originally_received = ribRollsSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${ribRollsSum}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"self_rolls_in_stock"}
      headerName={"# Self Rolls In Stock"}
      field={"self_rolls_in_stock"}
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const selfRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((!current?.rib_roll && (current?.yd !== '0' && current?.lb_out !== '0')) ? 1 : 0), 0);
        // if (params.data.self_rolls_in_stock !== selfRollsSum) {
        //   params.data.self_rolls_in_stock = selfRollsSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${selfRollsSum}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"rib_rolls_in_stock"}
      headerName={"# Rib Rolls In Stock"}
      field={"rib_rolls_in_stock"}
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const ribRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((current?.rib_roll && (current?.yd !== '0' && current?.lb_out !== '0')) ? 1 : 0), 0);
        // if (params.data.rib_rolls_in_stock !== ribRollsSum) {
        //   params.data.rib_rolls_in_stock = ribRollsSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${ribRollsSum}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"self_rolls_used"}
      headerName={"# Self Rolls Used"}
      field={"self_rolls_used"}
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const selfRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((!current?.rib_roll && (current?.yd === '0' || current?.lb_out === '0')) ? 1 : 0), 0);
        // if (params.data.self_rolls_used !== selfRollsSum) {
        //   params.data.self_rolls_used = selfRollsSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${selfRollsSum}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"rib_rolls_used"}
      headerName={"# Rib Rolls Used"}
      field={"rib_rolls_used"}
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const ribRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((current?.rib_roll && (current?.yd === '0' || current?.lb_out === '0')) ? 1 : 0), 0);
        // if (params.data.rib_rolls_used !== ribRollsSum) {
        //   params.data.rib_rolls_used = ribRollsSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${ribRollsSum}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"self_rolls_originally_received_in_lb_out"}
      headerName={"Self Rolls Originally Received (pounds out)"}
      field={"self_rolls_originally_received_in_lb_out"}
      filter={"agNumberColumnFilter"}
      width={325}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const selfRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((!current?.rib_roll) ? (Number(current?.lb_out_og) || 0) : 0), 0);
        // if (params.data.self_rolls_originally_received_in_lb_out !== selfRollsSum) {
        //   params.data.self_rolls_originally_received_in_lb_out = selfRollsSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${selfRollsSum}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"rib_rolls_originally_received_in_lb_out"}
      headerName={"Rib Rolls Originally Received (pounds out)"}
      field={"rib_rolls_originally_received_in_lb_out"}
      filter={"agNumberColumnFilter"}
      width={325}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const ribRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((current?.rib_roll) ? (Number(current?.lb_out_og) || 0) : 0), 0);
        // if (params.data.rib_rolls_originally_received_in_lb_out !== ribRollsSum) {
        //   params.data.rib_rolls_originally_received_in_lb_out = ribRollsSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${ribRollsSum}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"self_rolls_used_in_lb_out"}
      headerName={"Self Rolls Used (pounds out)"}
      field={"self_rolls_used_in_lb_out"}
      filter={"agNumberColumnFilter"}
      width={225}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const originalSelfRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((!current?.rib_roll) ? (Number(current?.lb_out_og) || 0) : 0), 0);
        // const currentSelfRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((!current?.rib_roll) ? (Number(current?.lb_out) || 0) : 0), 0);
        // const selfRollsUsed = originalSelfRollsSum - currentSelfRollsSum;
        // if (params.data.self_rolls_used_in_lb_out !== selfRollsUsed) {
        //   params.data.self_rolls_used_in_lb_out = selfRollsUsed;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${selfRollsUsed}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"rib_rolls_used_in_lb_out"}
      headerName={"Rib Rolls Used (pounds out)"}
      field={"rib_rolls_used_in_lb_out"}
      filter={"agNumberColumnFilter"}
      width={225}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const originalRibRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((current?.rib_roll) ? (Number(current?.lb_out_og) || 0) : 0), 0);
        // const currentRibRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((current?.rib_roll) ? (Number(current?.lb_out) || 0) : 0), 0);
        // const ribRollsUsed = originalRibRollsSum - currentRibRollsSum;
        // if (params.data.rib_rolls_used_in_lb_out !== ribRollsUsed) {
        //   params.data.rib_rolls_used_in_lb_out = ribRollsUsed;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${ribRollsUsed}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"self_rolls_in_stock_in_lb_out"}
      headerName={"Self Rolls in Stock (pounds out)"}
      field={"self_rolls_in_stock_in_lb_out"}
      filter={"agNumberColumnFilter"}
      width={250}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const selfRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((!current?.rib_roll) ? (Number(current?.lb_out) || 0) : 0), 0);
        // if (params.data.self_rolls_in_stock_in_lb_out !== selfRollsSum) {
        //   params.data.self_rolls_in_stock_in_lb_out = selfRollsSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${selfRollsSum}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"rib_rolls_in_stock_in_lb_out"}
      headerName={"Rib Rolls in Stock (pounds out)"}
      field={"rib_rolls_in_stock_in_lb_out"}
      filter={"agNumberColumnFilter"}
      width={250}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const ribRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((current?.rib_roll) ? (Number(current?.lb_out) || 0) : 0), 0);
        // if (params.data.rib_rolls_in_stock_in_lb_out !== ribRollsSum) {
        //   params.data.rib_rolls_in_stock_in_lb_out = ribRollsSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${ribRollsSum}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"self_rolls_originally_received_in_yd"}
      headerName={"Self Rolls Originally Received (yards)"}
      field={"self_rolls_originally_received_in_yd"}
      filter={"agNumberColumnFilter"}
      width={275}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const selfRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((!current?.rib_roll) ? (Number(current?.yd_og) || 0) : 0), 0);
        // if (params.data.self_rolls_originally_received_in_yd !== selfRollsSum) {
        //   params.data.self_rolls_originally_received_in_yd = selfRollsSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${selfRollsSum}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"rib_rolls_originally_received_in_yd"}
      headerName={"Rib Rolls Originally Received (yards)"}
      field={"rib_rolls_originally_received_in_yd"}
      filter={"agNumberColumnFilter"}
      width={275}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const ribRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((current?.rib_roll) ? (Number(current?.yd_og) || 0) : 0), 0);
        // if (params.data.rib_rolls_originally_received_in_yd !== ribRollsSum) {
        //   params.data.rib_rolls_originally_received_in_yd = ribRollsSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${ribRollsSum}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"self_rolls_used_in_yd"}
      headerName={"Self Rolls Used (yards)"}
      field={"self_rolls_used_in_yd"}
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const originalSelfRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((!current?.rib_roll) ? (Number(current?.yd_og) || 0) : 0), 0);
        // const currentSelfRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((!current?.rib_roll) ? (Number(current?.yd) || 0) : 0), 0);
        // const selfRollsUsed = originalSelfRollsSum - currentSelfRollsSum;
        // if (params.data.self_rolls_used_in_yd !== selfRollsUsed) {
        //   params.data.self_rolls_used_in_yd = selfRollsUsed;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${selfRollsUsed}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"rib_rolls_used_in_yd"}
      headerName={"Rib Rolls Used (yards)"}
      field={"rib_rolls_used_in_yd"}
      filter={"agNumberColumnFilter"}
      width={200}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const originalRibRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((current?.rib_roll) ? (Number(current?.yd_og) || 0) : 0), 0);
        // const currentRibRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((current?.rib_roll) ? (Number(current?.yd) || 0) : 0), 0);
        // const ribRollsUsed = originalRibRollsSum - currentRibRollsSum;
        // if (params.data.rib_rolls_used_in_yd !== ribRollsUsed) {
        //   params.data.rib_rolls_used_in_yd = ribRollsUsed;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${ribRollsUsed}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"self_rolls_in_stock_in_yd"}
      headerName={"Self Rolls In Stock (yards)"}
      field={"self_rolls_in_stock_in_yd"}
      filter={"agNumberColumnFilter"}
      width={225}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const selfRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((!current?.rib_roll) ? (Number(current?.yd) || 0) : 0), 0);
        // if (params.data.self_rolls_in_stock_in_yd !== selfRollsSum) {
        //   params.data.self_rolls_in_stock_in_yd = selfRollsSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${selfRollsSum}` || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"rib_rolls_in_stock_in_yd"}
      headerName={"Rib Rolls In Stock (yards)"}
      field={"rib_rolls_in_stock_in_yd"}
      filter={"agNumberColumnFilter"}
      width={225}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const ribRollsSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + ((current?.rib_roll) ? (Number(current?.yd) || 0) : 0), 0);
        // if (params.data.rib_rolls_in_stock_in_yd !== ribRollsSum) {
        //   params.data.rib_rolls_in_stock_in_yd = ribRollsSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${ribRollsSum}` || '';
      }}
      comparator={utils.numberComparator}
    />
  ];

  return {colArray};
};
