import {AUTHORIZED_STATUS, USER_TYPES} from "./constants/users";
import Ajv from 'ajv';
import { JTDDataType } from 'ajv/dist/core';

const ajv = new Ajv();

const IImageSchema = {
  type: 'object',
  nullable: true,
  properties: {
    _id: { type: 'string' },
    small: { type: 'string' },
    medium: { type: 'string' },
    large: { type: 'string' },
  },
  required: ['_id'],
  additionalProperties: false,
};

const IAccessTokenSchema = {
  type: 'object',
  properties: {
    expiresAt: { type: 'string' },
    expiresAtMs: { type: 'number' },
    expiresIn: { type: 'string' },
    expiresInMs: { type: 'integer' },
    token: { type: 'string' },
  },
  required: ['expiresAt', 'expiresAtMs', 'expiresIn', 'expiresInMs', 'token'],
  additionalProperties: false,
};

const ITokensSchema = {
  type: 'object',
  properties: {
    refreshToken: { type: 'string' },
    accessToken: IAccessTokenSchema,
  },
  required: ['refreshToken', 'accessToken'],
  additionalProperties: false,
};

const IUserSchema = {
  type: 'object',
  properties: {
    // id: { type: 'string' },
    // memberId: { type: 'string' },
    email: { type: 'string' },
    userType: { enum: Object.keys(USER_TYPES).map(key => USER_TYPES[key as keyof typeof USER_TYPES]) },
    firstName: { type: 'string' },
    lastName: { type: 'string' },
    // description: { type: 'string' },
    // occupation: { type: 'string' },
    // memberAuthorizedStatus: { enum: Object.keys(AUTHORIZED_STATUS).map(key => AUTHORIZED_STATUS[key as keyof typeof AUTHORIZED_STATUS]) },
    // twitter: ISocialAccountSchema,
    // instagram: ISocialAccountSchema,
    // tiktok: ISocialAccountSchema,
    // profileImage: IImageSchema,
    // registration: IRegistrationSchema,
    // addresses: {
    //   type: 'array',
    //   items: IAddressSchema,
    // },
    // blockStatus: IBlockStatusSchema,
  },
  // required: ['id', 'email', 'userType', 'firstName', 'lastName', 'registration'],
  additionalProperties: false,
};

export const IImageValidator = ajv.compile<JTDDataType<typeof IImageSchema>>(IImageSchema);
export const ITokensValidator = ajv.compile<JTDDataType<typeof ITokensSchema>>(ITokensSchema);
export const IUserValidator = ajv.compile<JTDDataType<typeof IUserSchema>>(IUserSchema);
