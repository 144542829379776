import React from "react";
import {AgGridColumn} from "ag-grid-react";
import {utils} from "../../../utils/utils";
import {GridApi} from "ag-grid-community";

export const useAdjustmentGroup1 = (props: {
  doSkipId?: boolean,
  expandRow?: any,
  gridApi?: GridApi | null,
  hideDefaultGrid?: boolean,
}): {colArray: JSX.Element[]} => {

  const colArray = [
    ...(!props?.doSkipId ? [<AgGridColumn
      field={"id"}
      lockPosition
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={'suppress-fill-handle'}
      suppressColumnsToolPanel={true}
      hide
      key={"id"}
    />] : []),
    <AgGridColumn
      key={"reference_number"}
      headerName={"Reference #"}
      field={"reference_number"}
      cellRendererParams={{expandRow: props?.expandRow}}
      lockPosition
      checkboxSelection={!props?.hideDefaultGrid}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
    />,
    <AgGridColumn
      key={"lot_number"}
      headerName={"Lot #"}
      field={"lot_number"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
    />,
    <AgGridColumn
      key={"roll_number"}
      headerName={"Roll #"}
      field={"roll_number"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
    />,
    <AgGridColumn
      key={"cut_sew_number"}
      headerName={"Cut/Sew #"}
      field={"cut_sew_number"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
    />,
    <AgGridColumn
      key={"date_of_change"}
      headerName={"Date of Change"}
      field={"date_of_change"}
      width={200}
      // TODO implement server-side filter
      filter={"agDateColumnFilter"}
      editable={false}
      cellClass={"ag-cell-overflow-visible-on-edit noneditable"}
      filterParams={{
        comparator: utils.filterDateComparatorfunction, // comparator for filters
        inRangeInclusive: true
      }}
      comparator={utils.dateComparator} // comparator for sorting
    />
  ];

  return {colArray};
};
