import React, {forwardRef, useState, useImperativeHandle, useRef, useEffect} from 'react';
import styled from "styled-components";
import {Checkbox, ListItemText, MenuItem, Popper} from "@material-ui/core";

const QcEditor = forwardRef((props: { value: any }, ref: any) => {
  const [value, setValue] = useState<string>(props?.value);
  const [checkOptions, setCheckOptions] = useState([
    {name: 'pass', checked: (props?.value && props?.value?.indexOf('pass') >= 0)},
    {name: 'fail', checked: (props?.value && props?.value?.indexOf('fail') >= 0)},
    {name: 'hold', checked: (props?.value && props?.value?.indexOf('hold') >= 0)},
    {name: 'inspected', checked: props?.value && props?.value?.indexOf('inspected') >= 0},
  ]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const inputRef = useRef(null);

  const open = Boolean(anchorEl);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value || '';
      },
      afterGuiAttached: () => {
        setValue(props?.value || '');
      }
    };
  });


  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => setAnchorEl(inputRef.current), 10);
    }
  }, [inputRef]);

  const handleChange = (event: any, name: string) => {
    let updatedItems = checkOptions.map((item) => {
      // uncheck pass if fail is checked
      if (value.indexOf('pass') >= 0 && item.name === "pass" && name === "fail") item.checked = false;
      // uncheck fail is pass is checked
      else if (value.indexOf('fail') >= 0 && item.name === "fail" && name === "pass") item.checked = false;
      if (item.name === name) item.checked = !item.checked;
      return item;
    });

    const filteredList = updatedItems.map(item => {
      if (item.checked) return item.name;
    }).filter(e => e != undefined);
    setCheckOptions(updatedItems);
    setValue(filteredList.join(", "));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <StyledContainer>
      <input type={"text"} value={value} ref={inputRef} onChange={handleInputChange} readOnly/>
      <Popper id={"popper"} open={true} anchorEl={anchorEl} disablePortal={true} placement="bottom-start">
        <StyledOptionsContainer className={"options-container"}>
          {/* IMPORTANT Mapping over checkOptions causes entire ui to rerender before update can be completed */}
          <MenuItem value={checkOptions[0].name} onClick={(event) => handleChange(event, checkOptions[0].name)}>
            <Checkbox checked={checkOptions[0].checked}/>
            <ListItemText primary={checkOptions[0].name}/>
          </MenuItem>
          <MenuItem value={checkOptions[1].name} onClick={(event) => handleChange(event, checkOptions[1].name)}>
            <Checkbox checked={checkOptions[1].checked}/>
            <ListItemText primary={checkOptions[1].name}/>
          </MenuItem>
          <StyledSeparator/>
          <MenuItem value={checkOptions[2].name} onClick={(event) => handleChange(event, checkOptions[2].name)}>
            <Checkbox checked={checkOptions[2].checked}/>
            <ListItemText primary={checkOptions[2].name}/>
          </MenuItem>
          <MenuItem value={checkOptions[3].name} onClick={(event) => handleChange(event, checkOptions[3].name)}>
            <Checkbox checked={checkOptions[3].checked}/>
            <ListItemText primary={checkOptions[3].name}/>
          </MenuItem>

        </StyledOptionsContainer>
      </Popper>
    </StyledContainer>

  );
});

export {QcEditor};

const StyledContainer = styled.span`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  background: white;
  display: flex;
  position: absolute;
  input {
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
    outline: inherit;
    border: inherit;
    border-radius: inherit;
  }
  #popper {
    width: 100%;
    margin-top: 2px;
  }
`;


const StyledOptionsContainer = styled.div`
  width: auto;
  background: white;
  box-shadow: 2px 3px 9px rgba(0,0,0,0.1);
  min-width: 150px;
  width: 100%;
`;


// const StyledContainer = styled.div`
//     height: 100%;
//     width: 100%;
//     display: inline-block;
//     vertical-align: middle;
//     text-align: center;
//     cursor: pointer;
//     box-sizing: border-box;
//     background: white !important;
//     box-shadow: 2px 3px 9px rgba(0,0,0,0.1);
// `;
//
const StyledSeparator = styled.div`
  border-bottom: 1px solid rgba(0,0,0,0.2);
  width: 100%;
  height: 0;
`;