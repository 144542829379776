import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useQueryClient} from "react-query";
import {useSignIn} from "../hooks/useUsers";
import {userUtils} from "../utils/userUtils";
import {cacheKeys, colors} from "../constants/allConsts";
import {STRING_CONSTANTS} from "../constants/users";
import styled from "styled-components";
import {MuiButtonPrimary} from "../styledComponentPresets/presets";
import {Button, CircularProgress} from "@material-ui/core";
import BannerLogo from '../assets/img/banner-logo.png';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const queryClient = useQueryClient();
  const history = useHistory();

  const signIn = useSignIn();

  const signInAttempt = () => {
    signIn.mutate(
      {email, password},
      {
        onSuccess: data => {
          // navigatorSignedInStatus.setSignedInStatus?.(NAVIGATOR_SIGNED_IN_STATUS.signedIn);
          queryClient.invalidateQueries(cacheKeys.getCurrentUserData);
          userUtils.setAccessToken(data);
          history.go(0);
        },
        onError: () => {
          setErrorMessage(STRING_CONSTANTS.FAILED_TO_LOG_IN);
        },
      },
    );
  };

  const handleEmailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <StyledLoginContainer>

      <StyledLoginContents>
        <StyledLogo src={BannerLogo} alt={"laa-logo"}/>
        <label htmlFor={"email"}>Email</label>
        <input id="email" type={"text"} value={email} onChange={handleEmailOnChange}/>
        <label htmlFor={"password"}>Password</label>
        <input id="password" type={"password"} value={password} onChange={handlePasswordOnChange}/>
        <StyledSignInButton
          onClick={signInAttempt}
          variant={"contained"}
          color={"primary"}
          endIcon={signIn.isLoading ? <CircularProgress size="20px" color="inherit" /> : null}
        >
          Sign In
        </StyledSignInButton>
        {errorMessage !== '' && <div className={"error"}>{errorMessage}</div>}
      </StyledLoginContents>
    </StyledLoginContainer>
  );
};

const StyledLoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
  padding-top: 150px;
`;

const StyledLogo = styled.img`
  width: 100%;
  margin-bottom: 20px;
`;

const StyledLoginContents = styled.div`
  width: 500px;
  height: auto;
  padding: 40px 40px;
  border: 1px solid rgba(0,0,0,0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: auto;
  align-self: center;
  justify-self: center;
  border-radius: 6px;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.1);
  input {
    width: 100%;
    padding: 6px;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.2);
    margin-bottom: 10px;
  }
  label {
    margin-right: auto;
    font-weight: 600;
  }
  .error {
    margin-top: 10px;
    color: ${colors.dangerRed};
  }
`;

const StyledSignInButton = styled(Button)`
  ${MuiButtonPrimary};
  &.MuiButton-root {
    margin-top: 10px;
  }
`;
