import styled from "styled-components";
import React, {useState} from "react";
import {Button} from "@material-ui/core";
import {MuiButtonPrimary} from "../styledComponentPresets/presets";
import {RowEditorModal} from "./modals/rowEditorModal";
import {useQueryClient} from "react-query";
import {GridApi} from "ag-grid-community";

export const ButtonBar = (props: {
  gridApi: GridApi | null,
  addRowTitle: string,
  addRowEmptyObject: (onAddRowSuccess: any) => void,
  children?: any,
  gridComponent: any,
  cacheKey: string,
  customAddRowFunction?: any,
  hideAddButton?: boolean,
  isAllDataLoaded?: boolean,
}): JSX.Element => {
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newRowId, setNewRowId] = useState(null);
  const closeModal = () => {
    if (props.gridApi) {
      /* allows new grid to be instantly editable in popup editors */
      const node  = props.gridComponent.gridApi?.getRowNode(newRowId || '');
      if (node) {
        props.gridApi.applyServerSideTransaction({
          update: [node.data]
        });
        props.gridApi.redrawRows({rowNodes: [node]});
      }

    }
    setIsModalOpen(false);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const addNewRowInModal = () => {
    if (!props?.gridApi) return;
    if (props.customAddRowFunction) {
      props.customAddRowFunction();
      return;
    }
    const newEmptyRowObject: any = props.addRowEmptyObject(async (data: any) => {
      console.log(`NEW ROW id: ${data?.id}`)
      setNewRowId(data?.id);

      // @ts-ignore
      const res = props?.gridApi.applyServerSideTransaction({
        add: [data],
        addIndex: 0,
      });
      if (res?.add) {
        queryClient.invalidateQueries([props?.cacheKey]).then((queryRes: any) => {
          if (props.isAllDataLoaded) {
            props?.gridApi?.redrawRows({rowNodes: res?.add});
            openModal();
          }
        });
      }
    });
  };
  return (
    <>
      <StyledButtonBar id={"button-bar"}>
        {!props?.hideAddButton &&
          (<StyledAddRowButton
          onClick={addNewRowInModal}
          variant={"contained"}
          color={"primary"}
          size={"medium"}
        >{props?.addRowTitle}</StyledAddRowButton>)
        }
        {props.children}
      </StyledButtonBar>
      <RowEditorModal
        open={isModalOpen}
        close={closeModal}
        gridComponent={React.cloneElement(props.gridComponent,{hideDefaultGrid: true, filterId: newRowId})}
      />
    </>
  )
};

const StyledAddRowButton = styled(Button)`
  ${MuiButtonPrimary};
`;

const StyledButtonBar = styled.div`
  height: 50px;
  width: 100%;
  background: inherit;
  border-top: 1px solid rgba(0,0,0,0.15);
  display: flex;
  padding: 6px 10px;
  box-sizing: border-box;
`;
