import {useInfiniteQuery, useMutation, useQuery} from "react-query";
import {cacheKeys} from "../../constants/allConsts";
import {
  getPurchaseOrders,
  addPurchaseOrder,
  updatePurchaseOrder,
  deletePurchaseOrder, getPurchaseOrdersPaged, getPurchaseOrderById, getPurchaseOrdersBasic,
} from "../../services/rowModels/purchaseOrderServices";
import {IPurchaseOrder, IPurchaseOrdersPaged} from "../../types/rowModelTypes";

export const useGetPurchaseOrders = (purchaseOrderOptions?: {id?: string | null | undefined}): any => {
  const result = useQuery({
    queryKey: purchaseOrderOptions?.id ? [cacheKeys.poData, purchaseOrderOptions?.id] : [cacheKeys.poData],
    queryFn: () => purchaseOrderOptions?.id ? getPurchaseOrderById(purchaseOrderOptions?.id) : getPurchaseOrders(),
  });
  return result;
};

export const useGetPurchaseOrdersBasic = (nameKey: string) => {
  const result = useQuery({
    queryKey: [cacheKeys.poData, 'basic'],
    queryFn: () => getPurchaseOrdersBasic([nameKey]),
  });
  return result;
};

/**
 * @description use infinite queries to retrieve paged data */
export const useGetPurchaseOrdersInfinite = (): any => {
  const getPurchaseOrdersInfinite = async ({pageParam = {page: 0, offset: null}}) => {
    return await getPurchaseOrdersPaged(pageParam.page, pageParam.offset);
  };
  // options to get more data based on server response (each page has 100 data elements by default, can be changed in backend if needed)
  const infiniteQueryOptions = {
    getNextPageParam: (lastPage: IPurchaseOrdersPaged, allPages: IPurchaseOrdersPaged[]) => {
      console.log("get fetch more:: ", {lastPage, allPages});
      return lastPage?.hasNextPage ? {page: Number(lastPage.nextPage), offset: lastPage.offset} : null;
    }
  };
  //@ts-ignore
  const data = useInfiniteQuery(cacheKeys.poData, getPurchaseOrdersInfinite, infiniteQueryOptions);
  console.log("retrieved data:: ", data?.data?.pages);
  return data;
};

export const useAddPurchaseOrder = () => {
  const result = useMutation(async (update: IPurchaseOrder) => {
    return await addPurchaseOrder(update);
  });
  return result;
};

export const useUpdatePurchaseOrder = () => {
  const result = useMutation(async (update: IPurchaseOrder) => {
    return await updatePurchaseOrder(update);
  });
  return result;
};

export const useDeletePurchaseOrder = () => {
  const result = useMutation(async (update: IPurchaseOrder) => {
    return await deletePurchaseOrder(update);
  });
  return result;
};
