import {IRoll} from "../../types/rowModelTypes";
import axios from "axios";
import {routes} from "../../constants/allConsts";
import {userUtils} from "../../utils/userUtils";

// TODO implement get rolls paged (see purchaseOrderServices.ts for example)

export const getRolls = async (): Promise<IRoll[] | {}> => {
  try {
    const accessToken = await userUtils.getAccessToken();
    const fetchUrl = `${routes.API_ROOT}/rolls`;
    // TODO get demo fetchUrl from server once ready
    const data = await axios.get(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then(res => res?.data?.data)
      .catch(err => {
        console.error("Error fetching rolls: ", err?.response?.data);
        throw Error(err.response);
      });
    return data;
  } catch (error: any) {
    console.log("error fetching:: ", error);
    return {};
  }
};

// TODO write function for getting rolls basic list (see purchaseOrderServices.ts for example)

// TODO write function for getting roll by id (see purchaseOrderServices.ts for example)

export const updateRoll = async (roll: IRoll) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    const apiURL = `${routes.API_ROOT}/rolls`;
    // TODO get demo fetchUrl from server once ready
    const requestBody = roll;
    const data = axios.put(apiURL, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing roll: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};

export const addRoll = async (roll: IRoll) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    const apiURL = `${routes.API_ROOT}/rolls`;
    // TODO get demo fetchUrl from server once ready
    const requestBody = roll;
    const data = axios.post(apiURL, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing roll: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};

export const deleteRoll = async (roll: IRoll) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    const apiURL = `${routes.API_ROOT}/rolls`;
    // TODO get demo fetchUrl from server once ready
    const requestBody = roll;
    const data = axios.delete(apiURL, {
      data: requestBody,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing roll: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};
