import React from "react";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {DialogActions, DialogContent, DialogProps, DialogTitle, Slide} from "@material-ui/core";
import {TransitionProps} from "@material-ui/core/transitions";
import styled from "styled-components";
import {colors} from "../../constants/colors";
import CloseIcon from '@material-ui/icons/Close';

interface IProps {
    title: string;
    subtitle ?: string;
    open: boolean;
    close: () => any;
    size?: string;
    children: JSX.Element;
};

const SlideTransition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const InfoModal = ({title, subtitle, open, close, size, children}: IProps) => {
    const [fullWidth, setFullWidth] = React.useState(true);
    // @ts-ignore
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>(size || 'md');

    return (
    <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={close}
        aria-labelledby="max-width-dialog-title"
        TransitionComponent={SlideTransition}
    >
        <DialogActions>
            <StyledCloseButton onClick={close} color="primary">
                <CloseIcon/>
            </StyledCloseButton>
        </DialogActions>
        <StyledDialogTitle id="max-width-dialog-title">{title}</StyledDialogTitle>
        {subtitle && <StyledSubtitle id="max-width-dialog-subtitle">{subtitle}</StyledSubtitle>}
        <StyledDialogContent id={"max-width-dialog-contents"}>
            {children}
        </StyledDialogContent>
    </Dialog>
    );
};

export {InfoModal};

const StyledDialogTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    font-weight: bolder;
    padding: 0 50px;
    .MuiTypography-h6 {
      font-size: 1.7rem;
      margin-bottom: 15px;
    }
  }
`;

const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding-top: 0;
    padding-left: 50px;
    padding-right: 50px;
  }
  ul {
    margin-left: 0;
    padding-left: 0;
    li {
      list-style: none;
      padding-bottom: 5px;
      label{
        padding-right: 10px;
        font-weight: 500;
      }
      input {
      display: flex;
      border-radius: 2px;
      border: 1px solid rgba(0,0,0,0.25);
      padding: 5px;
      margin-top: 2px;
      }
    }
  }
`;

const StyledCloseButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  &.MuiDialogActions-root {
    position: sticky;
    top: 0;
  }
  .MuiSvgIcon-root {
  position: sticky;
  top: 0;
    padding: 2px;
  }
  &:hover {
    .MuiSvgIcon-root {
      background: ${colors.lightBackgroundGray};
      border-radius: 50%;
    }
  }
`;

const StyledSubtitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    font-weight: bolder;
    padding: 0 50px;
    .MuiTypography-h6 {
      margin-bottom: 20px;
      font-size: 1rem;
      color: rgba(255,0,0,0.8);
    }
  }
`;