import {useMutation, useQuery, useQueryClient, useInfiniteQuery} from "react-query";
import {cacheKeys} from "../../constants/allConsts";
import {
  getFinishedGoods,
  addFinishedGood,
  updateFinishedGood,
  deleteFinishedGood, getFinishedGoodsBasic, getFinishedGoodById,
  getFinishedGoodsPaged
} from "../../services/rowModels/finishedGoodsServices";
import {IFinishedGood, IDataPaged} from "../../types/rowModelTypes";

export const useGetFinishedGoods = (finishedGoodsOptions?: {id?: string | null | undefined}): any => {
  const result = useQuery({
    queryKey: finishedGoodsOptions?.id ? [cacheKeys.finishedGoodsData, finishedGoodsOptions?.id] : [cacheKeys.finishedGoodsData],
    queryFn: () => finishedGoodsOptions?.id ? getFinishedGoodById(finishedGoodsOptions?.id) : getFinishedGoods(),
  });
  return result;
};

export const useGetFinishedGoodsBasic = (nameKey: string) => {
  const result = useQuery({
    queryKey: [cacheKeys.yarnTypeData, 'basic'],
    queryFn: () => getFinishedGoodsBasic([nameKey]),
  });
  return result;
};

export const useGetFinishedGoodsInfinite = (): any => {
  const getFinishedGoodsInfinite = async ({pageParam = {page: 0, offset: null}}) => {
    return await getFinishedGoodsPaged(pageParam.page, pageParam.offset);
  };
  // options to get more data based on server response (each page has 100 data elements by default, can be changed in backend if needed)
  const infiniteQueryOptions = {
    getNextPageParam: (lastPage: IDataPaged<IFinishedGood>, allPages: IDataPaged<IFinishedGood>[]) => {
      console.log("get fetch more:: ", {lastPage, allPages});
      return lastPage?.hasNextPage ? {page: Number(lastPage.nextPage), offset: lastPage.offset} : null;
    }
  };
  //@ts-ignore
  const data = useInfiniteQuery(cacheKeys.finishedGoodsData, getFinishedGoodsInfinite, infiniteQueryOptions);
  console.log("retrieved data:: ", data?.data?.pages);
  return data;
};

export function useAddFinishedGood() {
  const result = useMutation(async (update: IFinishedGood) => {
    return await addFinishedGood(update);
  });
  return result;
};

export function useUpdateFinishedGood() {
  const result = useMutation(async (update: IFinishedGood) => {
    return await updateFinishedGood(update);
  });
  return result;
};

export function useDeleteFinishedGood() {
  const result = useMutation(async (update: IFinishedGood) => {
    return await deleteFinishedGood(update);
  });
  return result;
};
