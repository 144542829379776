import React, {forwardRef, useState, useImperativeHandle, useRef, useEffect} from 'react';
import styled from "styled-components";
import Calendar from 'react-calendar';
import "react-calendar/dist/Calendar.css";
import {Popper} from '@material-ui/core';
import {utils} from "../../../utils/utils";
import {GridApi} from "ag-grid-community";
import moment from "moment";

const DateEditor = forwardRef((props: {value: string, api: GridApi}, ref: any) => {
  // output
  const [value, setValue] = useState<string>(props?.value || '');
  // temporary text input text
  const [inputValue, setInputValue] = useState<string>(props?.value || '');
  // calendar date using new Date() format
  const [date, setDate] = useState((props?.value && props?.value?.toString() !== "Invalid Date") ? new Date(props?.value) : new Date());
  // stores anchor element for popper
  const [anchorEl, setAnchorEl] = React.useState(null);

  const inputRef = useRef(null);
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return moment(value).toISOString() || '';
      },
      afterGuiAttached: () => {
        setValue(utils.formatDate(props?.value) || '');
      }
    };
  });

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => setAnchorEl(inputRef.current), 10);
    }
  }, [inputRef]);

  const open = Boolean(anchorEl);

  const handleCalendarChange = (selectedDate: any) => {
    setDate(selectedDate);
    // save output
    setValue(selectedDate ? utils.formatDate(selectedDate) : '');
    // update text input on calendar change
    setInputValue(selectedDate ? utils.formatDate(selectedDate) : '');
    setTimeout(() => {props.api.stopEditing();}, 50);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // update input field, allow it even if input is wrong or invalid
    setInputValue(event.target.value);
    if (event.target.value) {
      if (new Date(event.target.value).toString() !== "Invalid Date") {
        // save output
        setValue(utils.formatDate(event.target.value));
        // update calendar on input change
        setDate(new Date(event.target.value));
      }
    } else {
      // if text input is empty, save output as empty field
      setValue('');
    }
  };
  return (
    <StyledContainer>
      <input type={"text"} value={utils.formatDate(inputValue)} ref={inputRef} onChange={handleInputChange}/>
      <Popper id={"popper"} open={open} anchorEl={anchorEl} disablePortal={true}>
        <StyledCalendarContainer className={"calendar-container"}>
          <Calendar
            onChange={handleCalendarChange}
            value={date}
            inputRef={inputRef}
          />
        </StyledCalendarContainer>
      </Popper>
    </StyledContainer>
  );
});

export {DateEditor};

const StyledContainer = styled.span`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  background: white;
  box-shadow: 2px 3px 9px rgba(0,0,0,0.1);
  display: flex;
  position: absolute;
  input {
    width: 100%;
    border: inherit;
    outline: inherit;
    border-radius: inherit;
  }
  .react-datepicker-wrapper {
    display: flex;
    box-sizing: border-box;
  }
`;


const StyledCalendarContainer = styled.div`
  .react-calendar {
    width: 275px;
    margin: 10px;
  }
  
  .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background: none;
    //font-weight: bold;
    text-shadow: 1px 0 0 black;
  }
  
  .react-calendar__navigation {
    height: 30px;
    margin-bottom: 0;
    button {
      font-size: 0.8rem;
    }
  }
  
  .react-calendar__tile {
    padding: 0.35rem;
  }
`;
