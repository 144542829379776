import React, {forwardRef, useState, useImperativeHandle, useRef, useEffect, ReactHTMLElement} from 'react';
import styled from "styled-components";
import {GridApi} from "ag-grid-community";

const NumberEditor = forwardRef((props: { value: string, api?: GridApi }, ref: any) => {
  // output
  const [value, setValue] = useState<string>(props?.value?.toString() === '' ? '1' : props?.value);

  const inputRef = useRef(null);
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value || '1';
      },
      afterGuiAttached: () => {
        setValue(props?.value || '1');
      }
    };
  });

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const value = event.target.value;
    if (
      (value.length === 1 && /[1-9]/.test(value)) ||
      (value.length > 1 && /^[1-9][0-9]*$/.test(value)) ||
      (value === '')
    ) {
      setValue(value.toString());
    }
  };

  return (
    <StyledContainer>
      <input
        type={"text"}
        value={value}
        ref={inputRef}
        onChange={handleInputChange}
        onFocus={handleFocus}
      />
    </StyledContainer>
  );
});

export {NumberEditor};

const StyledContainer = styled.span`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  background: white;
  
  display: flex;
  position: absolute;
  input {
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
    outline: inherit;
    border: inherit;
    border-radius: inherit;
  }
  .react-datepicker-wrapper {
    display: flex;
    box-sizing: border-box;
  }
`;

