export const cacheKeys = {
  // row models
  poData: 'purchaseOrderData',
  yarnInventoryData: 'yarnInventoryData',
  finishedGoodsData: 'finishedGoodsData',
  adjustmentData: 'adjustmentData',
  yarnTypeData: 'yarnTypeData',
  knitterData: 'knitterData',
  supplierData: 'supplierData',
  rollData: 'rollData',
  styleData: 'styleData',
  makerData: 'makerData',
  dyerData: 'dyerData',
  vendorData: 'vendorData',
  colorData: 'colorData',
  locationData: 'locationData',
  // options
  terms: 'terms',
  descriptions: 'descriptions',
  contents: 'contents',
  lot_numbers: 'lot_numbers',
  sub_locs: 'sub_locs',
  yarn_type_colors: 'yarn_type_colors',
  // user login
  getCurrentUserData: 'getCurrentUserData',
  memberPublicData: 'memberPublicData',
};
