import {Button} from "@material-ui/core";
import React, {useEffect} from "react";
import {useHistory} from 'react-router-dom';
import styled from "styled-components";
import {PurchaseOrderGrid} from "../components/grids/purchaseOrderGrid";
import {YarnTypeGrid} from "../components/grids/yarnTypeGrid";
import {SupplierGrid} from "../components/grids/supplierGrid";
import {KnitterGrid} from "../components/grids/knitterGrid";
import {FinishedGoodsGrid} from "../components/grids/finishedGoodsGrid";
import {StyleGrid} from "../components/grids/styleGrid";
import {colors, tabNames} from "../constants/allConsts";
import {RollGrid} from "../components/grids/rollGrid";
import {MakerGrid} from "../components/grids/makerGrid";
import {DyerGrid} from "../components/grids/dyerGrid";
import {VendorGrid} from "../components/grids/vendorGrid";
import {ColorGrid} from "../components/grids/colorGrid";
import {AdjustmentGrid} from "../components/grids/adjustmentGrid";
import {MenuBar} from "../components/MenuBar";
import PackageJson from '../../package.json';
import {mainTabs} from "../constants/tabNames";
import {useGetQueryParams} from "../hooks/useQueryParams";
import {LocationGrid} from "../components/grids/locationGrid";

//@ts-ignore
window.getVersion = () => {
  return PackageJson.version;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

const TabbedApp = () => {
  const history = useHistory();
  const {tab: queryParamsTab}: { tab: string } = useGetQueryParams();
  const [value, setValue] = React.useState<string>(tabNames.PURCHASE_ORDERS);
  const [mostRecentActiveMainTab, setMostRecentActiveMainTab] = React.useState<string>(tabNames.PURCHASE_ORDERS);

  useEffect(() => {
    if (queryParamsTab) {
      setValue(queryParamsTab);
      if (mainTabs.indexOf(queryParamsTab) >= 0) {
        setMostRecentActiveMainTab(queryParamsTab);
      }
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (mainTabs.indexOf(newValue) >= 0) {
      setMostRecentActiveMainTab(newValue);
    }

    if (newValue === value && (mainTabs.indexOf(newValue) < 0)) {
      setValue(mostRecentActiveMainTab);
    } else {
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (!queryParamsTab || (queryParamsTab !== value)) {
      history.push({
        pathname: '/',
        search: `?tab=${value}`
      });
    }
  }, [value, queryParamsTab]);

  const Tab = (props: { tabIndex: string, label: string, id: string, maintabisactive?: string, className?: string }) => {
    return (
      <StyledTab
        id={props.id}
        onClick={(e) => handleChange(e, props.tabIndex)}
        active={(props.tabIndex === value).toString()}
        maintabisactive={props.maintabisactive|| 'false'}
        className={props?.className || ''}
      >
        {props.label}
      </StyledTab>
    );
  };

  const MainTabs = () => (
    <StyledTabContainer aria-label="main tabs">
      <Tab
        id={'yarn-pos-tab'} label={"Yarn POs"} tabIndex={tabNames.PURCHASE_ORDERS}
        maintabisactive={(mostRecentActiveMainTab === tabNames.PURCHASE_ORDERS).toString()}
        className={"main-tabs"}
      />
      <Tab
        id={'lots-tab'} label={"Lots"} tabIndex={tabNames.FINISHED_GOODS}
        maintabisactive={(mostRecentActiveMainTab === tabNames.FINISHED_GOODS).toString()}
        className={"main-tabs"}
      />
      <Tab
        id={'rolls-tab'} label={"Rolls"} tabIndex={tabNames.ROLLS}
        maintabisactive={(mostRecentActiveMainTab === tabNames.ROLLS).toString()}
        className={"main-tabs"}
      />
      <Tab
        id={'adjustments-tab'} label={"History"} tabIndex={tabNames.ADJUSTMENTS}
        maintabisactive={(mostRecentActiveMainTab === tabNames.ADJUSTMENTS).toString()}
        className={"main-tabs"}
      />
    </StyledTabContainer>
  );

  const SubTabs = () => (
    <StyledTabContainer aria-label="sub tabs">
      <Tab id={'knitters-tab'} label={"Knitters"} tabIndex={tabNames.KNITTERS}/>
      <Tab id={'suppliers-tab'} label={"Yarn Suppliers"} tabIndex={tabNames.SUPPLIERS}/>
      <Tab id={'dye-houses-tab'} label={"Dye Houses"} tabIndex={tabNames.DYER}/>
      <Tab id={'locations-tab'} label={"Locations"} tabIndex={tabNames.LOCATIONS}/>
      <Tab id={'makers-tab'} label={"Yarn Makers"} tabIndex={tabNames.MAKERS}/>
      <Tab id={'vendors-tab'} label={"Vendors"} tabIndex={tabNames.VENDOR}/>
      <Tab id={'yarn-types'} label={"Yarn Master Code"} tabIndex={tabNames.YARN_TYPES}/>
      <Tab id={'styles-tab'} label={"Styles"} tabIndex={tabNames.STYLES}/>
      <Tab id={'colors-tab'} label={"Color Code"} tabIndex={tabNames.COLORS}/>
    </StyledTabContainer>
  );

  return (
    <>
      <MenuBar/>
      <MainTabs />
      <SubTabs />
      <TabPanel value={value} index={tabNames.PURCHASE_ORDERS}>
        <PurchaseOrderGrid/>
      </TabPanel>
      <TabPanel value={value} index={tabNames.FINISHED_GOODS}>
        <FinishedGoodsGrid/>
      </TabPanel>
      <TabPanel value={value} index={tabNames.ROLLS}>
        <RollGrid/>
      </TabPanel>
      <TabPanel value={value} index={tabNames.ADJUSTMENTS}>
        <AdjustmentGrid/>
      </TabPanel>
      <TabPanel value={value} index={tabNames.KNITTERS}>
        <KnitterGrid/>
      </TabPanel>
      <TabPanel value={value} index={tabNames.SUPPLIERS}>
        <SupplierGrid/>
      </TabPanel>
      <TabPanel value={value} index={tabNames.MAKERS}>
        <MakerGrid/>
      </TabPanel>
      <TabPanel value={value} index={tabNames.DYER}>
        <DyerGrid/>
      </TabPanel>
      <TabPanel value={value} index={tabNames.LOCATIONS}>
        <LocationGrid/>
      </TabPanel>
      <TabPanel value={value} index={tabNames.VENDOR}>
        <VendorGrid/>
      </TabPanel>
      <TabPanel value={value} index={tabNames.YARN_TYPES}>
        <YarnTypeGrid/>
      </TabPanel>
      <TabPanel value={value} index={tabNames.STYLES}>
        <StyleGrid/>
      </TabPanel>
      <TabPanel value={value} index={tabNames.COLORS}>
        <ColorGrid/>
      </TabPanel>
    </>
  );
};

export {TabbedApp};

const StyledTabContainer = styled.div`
  height: 48px;
  overflow: auto;
  white-space: nowrap;
`;

const StyledTab = styled(Button)<{ active: string; maintabisactive?: string; }>`
  &.main-tabs {
    min-width: 160px;
  }
  &.MuiButton-root {
    text-transform: none;
    color: ${colors.teal};
    border-radius: 0;
    box-shadow: 0px -2px 0px inset ${(props) => props.active === "true" ? colors.primaryBlue : (props.maintabisactive === 'true' ? colors.primaryBlueInactive : 'none')};
    color: ${(props) => props.active === "true" ? colors.black : colors.lightInactiveGray};
    &:hover {
      background: ${(props) => props.active === "true" ? 'none' : ''};
    }
  }
  &.MuiButton-text {
    padding: 12px 25px;
    color: ${(props) => props.active === "true" ? 'inherit' : colors.lightInactiveGray};
    font-size: 0.8rem;
  }
`;

