import {css, keyframes} from "styled-components";
import {colors} from "../constants/colors";

export const MuiButtonPrimary = css`
  &.MuiButton-containedPrimary {
    background: ${colors.primaryBlue};
    &:hover {
      background: ${colors.primaryBlueActive};
    }
  }
`;

export const MuiButtonRed = css`
  &.MuiButton-containedPrimary {
    background: ${colors.dangerRed};
    &:hover {
      background: ${colors.dangerRedActive};
    }
  }
`;

export const CellIconButton = css`
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0 8px 0 3px;
  display: flex;
  opacity: 0.7;
  &:hover {
    opacity: 1;
    filter: drop-shadow(2px 1px 2px rgba(0,0,0,0.125));
  }
  img {
    height: 1rem;
    width: 1rem;
  }
`;

export const CellContainer = css`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  background: inherit;
  box-sizing: border-box;
`;

export const FadeIn = keyframes`
  0% {opacity: 0}
  100% {opacity: 1}
`;