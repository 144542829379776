import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import {IImage, IServerError, ITokens, IUser, USER_TYPES} from '../constants/users';
import { IaccessCodeAuthorizationResponse } from '../constants/serverResponseInterface';

import {userApiCalls} from "../services/users/userServices";
import {cacheKeys} from "../constants/allConsts";

export const useGetCurrentUserData = (queryOptions?: UseQueryOptions<IUser, IServerError>) => {
  return useQuery<IUser, IServerError>(cacheKeys.getCurrentUserData, userApiCalls.getCurrentUserData, { staleTime: Infinity, ...queryOptions });
};

export const useSignIn = () => {
  return useMutation<ITokens, IServerError, { email: string; password: string }, unknown>(
    async (data) => userApiCalls.login(data.email, data.password));
};

// export const useChooseUserType = () => {
//   const queryClient = useQueryClient();
//   return useMutation<{ userType: USER_TYPES }, IServerError, { userType: USER_TYPES }, unknown>(
//     async (data) => userApiCalls.setUserType(data.userType),
//     {
//       onSuccess: (data) => {
//         queryClient.setQueryData<IUser | undefined>(cacheKeys.getCurrentUserData, (old: any) => {
//           if (!old) {
//             return undefined;
//           }
//
//           return {
//             ...old,
//             userType: data.userType,
//           };
//         });
//         queryClient.invalidateQueries(cacheKeys.getCurrentUserData);
//       },
//     });
// };

// export const useSignUp = () => {
//   return useMutation<ITokens, IServerError, { email: string, firstName: string, lastName: string, password: string, userType: USER_TYPES, signUpCode: string }, unknown>(
//     async (data) => userApiCalls.signUp(data.email, data.firstName, data.lastName, data.password, data.userType, data.signUpCode)
//   );
// };

// export const useRequestSignUpCode = () => {
//   return useMutation<void, IServerError, { email: string }, unknown>(
//     async (data) => userApiCalls.requestSignUpCode(data.email)
//   );
// };

// export const useResetPasswordRequest = () => {
//   return useMutation<void, IServerError, string, unknown>(
//     async (data) => userApiCalls.resetPasswordRequest(data)
//   );
// };

// export const useUpdateCurrentUserData = () => {
//   const queryClient = useQueryClient();
//
//   return useMutation<IUser, IServerError, { firstName?: string, lastName?: string, description?: string, occupation?: string }, unknown>(
//     async (data) => userApiCalls.updateCurrentUserData(data.firstName, data.lastName, data.description, data.occupation),
//     {
//       onSuccess: (data) => {
//         queryClient.setQueryData<IUser | undefined>(cacheKeys.getCurrentUserData, () => data);
//         queryClient.invalidateQueries(cacheKeys.getCurrentUserData);
//       },
//     }
//   );
// };

// export const useChangePassword = () => {
//   return useMutation<void, IServerError, { currentPassword: string, newPassword: string }, unknown>(
//     async (data) => userApiCalls.changePassword(data.currentPassword, data.newPassword));
// };

// export const useChangePasswordRecovery = () => {
//   return useMutation<void, IServerError, { newPassword: string, recoveryToken: string }, unknown>(
//     async (data) => userApiCalls.changePasswordRecovery(data.newPassword, data.recoveryToken));
// };

// export const useUpdateProfilePhoto = () => {
//   const queryClient = useQueryClient();
//
//   // eslint-disable-next-line no-spaced-func
//   return useMutation<IImage, IServerError, { formData: FormData, uploadProgress?: (percentCompleted: number) => void }, unknown>(
//     async ({ formData, uploadProgress }) => userApiCalls.uploadProfilePhoto({ formData, uploadProgress }),
//     {
//       onSuccess: (data) => {
//         queryClient.setQueryData<IUser | undefined>(cacheKeys.getCurrentUserData, (old: any) => {
//           if (!old) {
//             return undefined;
//           }
//
//           return {
//             ...old,
//             profileImage: data,
//           };
//         });
//         queryClient.invalidateQueries(cacheKeys.getCurrentUserData);
//
//         const currentUserData = queryClient.getQueryData<IUser>(cacheKeys.getCurrentUserData);
//       },
//     }
//   );
// };

