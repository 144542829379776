import React, {
    forwardRef,
    Fragment,
    useImperativeHandle,
    useRef,
} from 'react';

const CustomRefFloatingFilter = forwardRef((props, ref) => {
    const inputRef = useRef(null);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {
            onParentModelChanged(parentModel: any) {
                // When the filter is empty we will receive a null value here
                if (!parentModel) {
                    // @ts-ignore
                    // inputRef.current.value || '';
                } else {
                    // @ts-ignore
                    inputRef.current.value = parentModel;
                    // @ts-ignore
                }
            },
        };
    });

    const onInputBoxChanged = (input: React.ChangeEvent<HTMLInputElement>) => {
        const value = input.target.value;
        if (value === '') {
            // @ts-ignore // Remove the filter
            props.parentFilterInstance((instance: any) => {
                instance
                    .getFrameworkComponentInstance()
                    .myMethodForTakingValueFromFloatingFilter(null);
            });
            return;
        }

        // @ts-ignore
        props.parentFilterInstance((instance: any) => {
            instance
                .getFrameworkComponentInstance()
                .myMethodForTakingValueFromFloatingFilter(value);
        });
    };

    return (
        <Fragment>
            <input
                ref={inputRef}
                type="text"
                onInput={onInputBoxChanged}
                className={"ag-input-field-input"}
            />
        </Fragment>
    );
});

export {CustomRefFloatingFilter};