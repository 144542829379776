import React, {useEffect, useState} from 'react';
import {Route, Switch} from "react-router";
import {Login} from "../pages/Login";
import {BrowserRouter as Router} from "react-router-dom";
import {QueryClient, useQueryClient} from "react-query";
import {userUtils} from "../utils/userUtils";
import {TabbedApp} from "../pages/TabbedApp";
import {LoadingScreen} from "../pages/LoadingScreen";

export enum NAVIGATOR_SIGNED_IN_STATUS {
  loading = 'loading',
  signedIn = 'signedIn',
  signedOut = 'signedOut',
}

export const navigatorSignedInStatus: { setSignedInStatus?: (status: NAVIGATOR_SIGNED_IN_STATUS) => void, signedInStatus?: NAVIGATOR_SIGNED_IN_STATUS, timeOuter?: any } = {};

export const rootNavigationQueryClient: { queryClient: QueryClient | undefined } = { queryClient: undefined };

export const AppNavigator = () => {
  const [signedInStatus, setSignedInStatus] = useState<NAVIGATOR_SIGNED_IN_STATUS>(NAVIGATOR_SIGNED_IN_STATUS.loading);
  navigatorSignedInStatus.setSignedInStatus = (status: NAVIGATOR_SIGNED_IN_STATUS) => {
    navigatorSignedInStatus.signedInStatus = signedInStatus;
    setSignedInStatus(status);
  };

  rootNavigationQueryClient.queryClient = useQueryClient();

  useEffect(() => {
    if (signedInStatus === NAVIGATOR_SIGNED_IN_STATUS.loading) {
      userUtils.getAccessToken()
        .then((accessToken) => {
          if (accessToken) {
            navigatorSignedInStatus.signedInStatus = NAVIGATOR_SIGNED_IN_STATUS.signedIn;
            setSignedInStatus(NAVIGATOR_SIGNED_IN_STATUS.signedIn);
          } else {
            navigatorSignedInStatus.signedInStatus = NAVIGATOR_SIGNED_IN_STATUS.signedOut;
            setSignedInStatus(NAVIGATOR_SIGNED_IN_STATUS.signedOut);
          }
        });
    }
  }, [signedInStatus]);

  if (signedInStatus === NAVIGATOR_SIGNED_IN_STATUS.loading) return <LoadingScreen />;

  return (
    <Router>
      <Switch>
        <Route path={"/"} exact >
          {signedInStatus === NAVIGATOR_SIGNED_IN_STATUS.signedIn ? <TabbedApp/> : <Login/>}
        </Route>

      </Switch>
    </Router>
  );
};
