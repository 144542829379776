import {IPurchaseOrder, IPurchaseOrdersPaged} from "../../types/rowModelTypes";
import axios from "axios";
import {routes} from "../../constants/allConsts";
import {userUtils} from "../../utils/userUtils";

export const getPurchaseOrdersPaged = async (page: number = 0, offset: string | null): Promise<IPurchaseOrdersPaged[] | {}> => {
  const accessToken = await userUtils.getAccessToken();
  const fetchUrl = `${routes.DEMO_YARN_POS}`;
  try {
    const data = await axios.get(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        page: page,
        offset: offset,
      }
    })
      .then(res => res?.data)
      .then(res => {
        return res.data;
      });
    return data;
  } catch (error: any) {
    console.log("error fetching:: ", error);
    return {};
  }
};

export const getPurchaseOrders = async (): Promise<IPurchaseOrder[] | {}> => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const fetchUrl = `${routes.API_ROOT}/purchase-orders`; // mock server
    const fetchUrl = `${routes.DEMO_YARN_POS}`;
    const data = await axios.get(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        pageSize: 10000,
      }
    })
    // .then(res => res?.data?.data) // mock server
      .then(res => res?.data)
      .then(res => {
        if (res.status === 'success') {
          return res?.data?.data;
        }
        return res?.data;
      })
      .catch(err => {
        console.error("Error fetching purchase orders: ", err?.response?.data);
        throw Error(err.response);
      });
    return data;
  } catch (error: any) {
    console.log("error fetching:: ", error);
    return {};
  }
};

export const getPurchaseOrdersBasic = async (nameKeys: string[] = []) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    const fetchUrl = `${routes.DEMO_YARN_POS}/basic-list`;
    const nameParams = nameKeys.reduce((prev, current) => ({ ...prev, [current]: true}), {});
    const data = await axios.get(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        ...nameParams,
      }
    })
      .then(res => res?.data?.data)
      .catch(err => {
        console.error("Error fetching purchase order: ", err?.response?.data);
        throw Error(err.response);
      });
    return data;
  } catch (error: any) {
    console.log("error fetching:: ", error);
  }
};

export const getPurchaseOrderById = async (id: string) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    const fetchUrl = `${routes.DEMO_YARN_POS}/by-id`;
    const data = await axios.get(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        id: id,
      }
    })
      .then(res => res?.data?.data)
      .catch(err => {
        console.error("Error fetching purchase order: ", err?.response?.data);
        throw Error(err.response);
      });
    return data;
  } catch (error: any) {
    console.log("error fetching:: ", error);
  }
};

export const updatePurchaseOrder = async (purchaseOrder: IPurchaseOrder) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const apiURL = `${routes.API_ROOT}/purchase-orders`; // mock server
    const apiURL = `${routes.DEMO_YARN_POS}`;
    const requestBody = purchaseOrder;
    // using post because the endpoint for updating and adding are now the same
    const data = axios.post(apiURL, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing purchase order: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};

export const addPurchaseOrder = async (purchaseOrder: IPurchaseOrder) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const apiURL = `${routes.API_ROOT}/purchase-orders`; // mock server
    const apiURL = `${routes.DEMO_YARN_POS}`;
    const requestBody = purchaseOrder;
    const data = await axios.post(apiURL, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing purchase order: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};

export const deletePurchaseOrder = async (purchaseOrder: IPurchaseOrder) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const apiURL = `${routes.API_ROOT}/purchase-orders`; // mock server
    const apiURL = `${routes.DEMO_YARN_POS}`;
    const requestBody = purchaseOrder;
    const data = axios.delete(apiURL, {
      data: requestBody,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing purchase order: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};
