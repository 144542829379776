import { readlink } from 'fs';
import moment from 'moment';
import { routes, cacheKeys } from '../constants/allConsts';


class Utils {
  /**
  * @description formats the date in the form of MM/DD/YYYY
  * @param dateString some date representation
  * */
  formatDate(dateString: string): string {
    return moment(dateString).format('MM/DD/YYYY')
  };

  /**
   * @description rounds a number to a specified number of digits
   * @param input the string of number representation of a number to round
   * @param decimalsToRoundTo decimals in which to round to. Default is 0
   * */
  roundNumber(input: string | number, decimalsToRoundTo?: number) {
    const numInput = Number(input);
    if (isNaN(numInput)) {
      return input;
    } else {
      return Number(numInput.toFixed(decimalsToRoundTo || 0));
    }
  }

  /**
   * @description formats a number in usd format
   * @param input some number to convert to usd format
   * */
  USDFormatter(input: string | number): string {
    const number = Number(input);
    if ((typeof input === 'string' && input === '') || isNaN(number)) return '';
    var formatted = number.toFixed(2);
    if (formatted.indexOf('-') === 0) {
      return '-$' + formatted.slice(1);
    }
    return '$' + formatted;
  };

  /**
   * @description extracts the size of the yarn based on its name
   * @param yarnString the name of the yarn
   * */
  extractYarnSize(yarnString: string): string {
    var rx = /^(\d+\/\d+)/g;
    var arr = rx.exec(yarnString);
    if (arr && arr.length > 0){
      return arr[0];
    }
    return '';
  };

  /**
   * @description extracts the address by parts according to specific format
   * @param address the address delimited by commas
   * */
  getAddressPartsFromString(address: string) {
    const addressParts = address ? address?.split(',') : [];
    const addressLine1 = (addressParts.length > 0) ? addressParts[0].trim() || '' : '';
    const addressLine2 = (addressParts.length > 4 && addressParts[1]) ? addressParts[1].trim() : '';
    const addressCity = (addressParts.length > 0) ? (addressParts[addressParts.length - 3].trim() || '') : '';
    const addressState = (addressParts.length > 0) ? (addressParts[addressParts.length - 2].trim() || '') : '';
    const addressZip = (addressParts.length > 0) ? (addressParts[addressParts.length - 1].trim() || '') : '';
    return {
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressZip,
    };
  };

  /**
   * @description make a copy of an object and then set all its values to empty strings (first level only)
   * @object the json object
   * */
  makeEmptyObject(object: any): any {
    return Object.keys(object).map(k=>({[k]:('')})).reduce((kd1,kd2)=>({...kd1,...kd2}));
  }

  /**
   * @description basic comparator that compares strings
   * @param valueA the first value to compare
   * @param valueB the second value to compare
   * */
  baseComparator(valueA: string, valueB: string): number {
    if (valueA === valueB) return 0;
    return (valueA > valueB) ? 1 : -1;
  }

  /**
   * @description basic comparator that compares numbers
   * @param valueA the first value to compare
   * @param valueB the second value to compare
   * */
  numberComparator(valueA: string, valueB: string): number {
    if (Number(valueA) === Number(valueB)) return 0;
    return (Number(valueA) > Number(valueB)) ? 1 : -1;
  }

  /**
   * @description comparator that compares date strings
   * @param valueA the first date string to compare
   * @param valueB the second date string to compare
   * */
  dateComparator(valueA: string, valueB: string): number {
    if (moment(valueA).isSame(valueB,'day')) return 0;
    return moment(valueA).isAfter(valueB, 'day') ? 1 : -1;
  }

  /**
   * @description comparator that compares date strings (made specifically for filters, not sort)
   * @param filterLocalDateAtMidnight the first date string to compare
   * @param cellValue the second date string to compare
   * */
  filterDateComparatorfunction (filterLocalDateAtMidnight: any, cellValue: any): number { // comparator for filters
    if (!cellValue) return -1;
    return utils.dateComparator(cellValue, filterLocalDateAtMidnight.toString());
  }

  /**
   * @description compares by whether something is empty
   * @param valueA the first value to compare
   * @param valueB the second value to compare
   * */
  emptyComparator(valueA: string, valueB: string): number {
    if (valueA && valueB) return 0;
    if (valueA === '' && valueB !== '') return 1;
    if (valueA !== '' && valueB === '') return -1;
    return 0;
  }
  /**
   * @description maps apiRoutes to cacheKeys
   * @param route the api route for data
   */
  getCacheKeyForRoute(route: string): string {
    let cacheKey: string = "";
    console.log(routes)

    switch(route) {
      case routes.DEMO_COLORS:
        cacheKey = cacheKeys.colorData;
        break;
      case routes.DEMO_LOCATIONS:
        cacheKey = cacheKeys.locationData;
        break;
      case routes.DEMO_VENDORS:
        cacheKey = cacheKeys.vendorData;
        break;
      case routes.DEMO_DYERS:
        cacheKey = cacheKeys.dyerData;
        break;
      case routes.DEMO_MAKERS:
        cacheKey = cacheKeys.makerData;
        break;
      case routes.DEMO_STYLES:
        cacheKey = cacheKeys.styleData;
        break;
      case routes.DEMO_SUPPLIERS:
        cacheKey = cacheKeys.supplierData;
        break;
      case routes.DEMO_KNITTERS:
        cacheKey = cacheKeys.knitterData;
        break;
      case routes.DEMO_YARN_POS:
        cacheKey = cacheKeys.poData;
        break;
      case routes.DEMO_LOTS:
        cacheKey = cacheKeys.finishedGoodsData;
        break;
      case routes.DEMO_MASTER_CODES:
        cacheKey = cacheKeys.yarnTypeData;
        break;
    }
    return cacheKey;
  }
}

export const utils = new Utils();