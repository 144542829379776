import {useMutation, useQuery} from "react-query";
import {cacheKeys} from "../../constants/allConsts";
import {addMaker, deleteMaker, getMakers, updateMaker} from "../../services/rowModels/makerServices";
import {IMaker} from "../../types/rowModelTypes";

// TODO extend useGetMakers to be able to use getMakerById once it's implemented (see usePurchaseOrders.ts for example)
export const useGetMakers = (): any => {
  const result = useQuery({
    queryKey: [cacheKeys.makerData],
    queryFn: () => getMakers(),
  });
  return result;
};

// TODO implement useGetAdjustmentBasic (see usePurchaseorders.ts for example)

export const useAddMaker = () => {
  const result = useMutation(async (update: IMaker) => {
    return await addMaker(update);
  });
  return result;
};

export const useUpdateMaker = () => {
  const result = useMutation(async (update: IMaker) => {
    return await updateMaker(update);
  });
  return result;
};

export const useDeleteMaker = () => {
  const result = useMutation(async (update: IMaker) => {
    return await deleteMaker(update);
  });
  return result;
};
