export enum USER_TYPES {
  admin = 'admin',
  member = 'member',
  user = 'user',
  notSet = 'notSet',
}

export enum AUTHORIZED_STATUS {
  unauthorized = 'unauthorized',
  requested = 'requested',
  authorized = 'authorized',
}

export enum SOCIAL_MEDIA_TYPES {
  instagram = 'instagram',
  twitter = 'twitter',
  tiktok = 'tiktok',
}

export enum BLOCK_TYPES {
  temporaryBlocked = 'temporaryBlocked',
  permanentBlocked = 'permanentBlocked',
}

export enum SERVER_ERROR_CODES {
  EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE',
  SIGN_UP_USER_TYPE_REQUIRED = 'USER_TYPE_REQUIRED',
  INVALID_ACCESS_CODE = 'INVALID_ACCESS_CODE',
  CLIENT_DATA_MISMATCH_ERROR = 'CLIENT_DATA_MISMATCH_ERROR',
  INVALID_ACCESS_TOKEN = 'INVALID_ACCESS_TOKEN',
  INVALID_REFRESH_TOKEN = 'INVALID_REFRESH_TOKEN',
  EXPIRED_ACCESS_TOKEN = 'EXPIRED_ACCESS_TOKEN',
  EXPIRED_PASSWORD_RECOVERY_TOKEN = 'EXPIRED_PASSWORD_RECOVERY_TOKEN',
  INVALID_ACCESS_TOKEN_REDIRECT = 'INVALID_ACCESS_TOKEN_REDIRECT',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  GENERAL_ERROR = 'GENERAL_ERROR',
  GOOGLE_LOGIN_FAILED = 'GOOGLE_LOGIN_FAILED',
  APPLE_LOGIN_FAILED = 'APPLE_LOGIN_FAILED',
  INVALID_SIGN_UP_CODE = 'INVALID_SIGN_UP_CODE',
  SIGN_UP_CODE_EXPIRED = 'SIGN_UP_CODE_EXPIRED',
  NOT_FOUND = 'NOT_FOUND',
  INVALID_PARAMETER = 'INVALID_PARAMETER',
  LIMIT_FILE_SIZE = 'LIMIT_FILE_SIZE',
}

export enum REDIRECT_REASON {
  googleLogin = 'googleLogin',
  connectTwitter = 'connectTwitter',
  connectTiktok = 'connectTiktok',
  connectInstagram = 'connectInstagram',
  passwordRecovery = 'passwordRecovery',
}

export interface IBlockStatus {
  message: string,
  blockType: BLOCK_TYPES,
}

export interface IAddress {
  _id?: string,
  countryOrRegion: string,
  address: string,
  secondaryAddress?: string,
  city: string,
  state: string,
  zipCode: number,
  isPrimaryAddress: boolean,
}

export interface ISocialAccount {
  provider: SOCIAL_MEDIA_TYPES,
  profileId?: string,
  username: string,
  isVerified?: boolean,
  followersCount?: string,
  verificationCode?: string,
}

export interface ITokens {
  refreshToken: string,
  accessToken: {
    expiresAt: string,
    expiresAtMs: number,
    expiresIn: string,
    expiresInMs: number,
    token: string,
  };
}

export interface IImage {
  _id: string,
  small?: string,
  medium?: string,
  large?: string,
}

export interface IUser {
  id: string,
  email: string,
  userType: USER_TYPES,
  firstName: string | undefined,
  lastName: string | undefined,
  // profileImage?: IImage,
  // registration: {
  //   apple?: boolean,
  //   google?: boolean,
  //   email?: boolean,
  // },
  // addresses: IAddress[],
  // description?: string,
  // blockStatus?: IBlockStatus
}

export interface IServerError {
  message?: string,
  errorCode: SERVER_ERROR_CODES | string,
  status: 'error' | 'success',
  data?: any,
  redirectReason?: REDIRECT_REASON,
}

export const STRING_CONSTANTS = {
  ERROR: 'Error',
  EMAIL_ALREADY_IN_USE: 'This email address is already taken. Please try to log in or use a different email.',
  FAILED_TO_LOG_IN: 'Failed to log in, please try again',
  PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
  NEW_PASSWORD_IS_SAME_AS_OLD: 'The new password can`t be the same as the old password',
  CURRENT_PASSWORD_WAS_INVALID: 'Current password was invalid',
  SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN: 'Something went wrong. Please try again',
  PASSWORD_RECOVERY_LINK_SEND_SUCCESS: 'Password reset link sent to your given email address',
  PASSWRORD_RECOVERY_EXPIRED: 'Password reset link expired. Please request a new password recovery link',
  INVALID_SIGN_UP_CODE: 'The registration code you entered was invalid',
  EXPIRED_SIGN_UP_CODE: 'The registration code you entered was expired',
  REGISTRATION_CODE_WAS_SENT_TO_YOUR_EMAIL: 'We sent a registration code to your email.\nPlease enter that code.',
  IMAGE_UPLOADER_ERROR_TOO_MANY_FILES: 'You can only upload one file',
  IMAGE_UPLOADER_ERROR_INCORRECT_FORMAT: 'Incorrect file format',
  VIDEO_FILE_INCORRECT_FORMAT: 'This video file could not be read by your browser\nTo continue, please choose another video file.',
  VIDEO_FILE_TOO_LONG: 'Provided vide was too long\n.Maximum allowed video duration is 1 minute',
  FILL_IN_ALL_THE_REQUIRED_FIELDS: 'Please fill in all the required fields',
  FIELDS_CANT_BE_NEGATIVE: 'Some of the fields contain negative numbers\n.Please fix the issue and try again',
  FAILED_TO_UPLOAD_ITEM_VIDEO_OR_PHOTO: 'Your item was successfully created but\nvideo or image upload failed.\nPlease try to re-upload the images/video.',
  FAILED_TO_DELETE_ITEM_IMAGE: 'Item must have at least one image!',
};
