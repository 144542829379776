export const tabNames = {
  PURCHASE_ORDERS: 'purchase-orders',
  FINISHED_GOODS: 'lots',
  ROLLS: 'rolls',
  ADJUSTMENTS: 'history',
  KNITTERS: 'knitters',
  SUPPLIERS: 'suppliers',
  MAKERS: 'makers',
  DYER: 'dyers',
  VENDOR: 'vendors',
  YARN_TYPES: 'master-codes',
  STYLES: 'styles',
  COLORS: 'colors',
  LOCATIONS: 'locations',
};

export const mainTabs = [
  tabNames.PURCHASE_ORDERS,
  tabNames.FINISHED_GOODS,
  tabNames.ROLLS,
  tabNames.ADJUSTMENTS,
];