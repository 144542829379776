import React, {forwardRef, useImperativeHandle, useState} from "react";
import CheckIcon from '@material-ui/icons/Check';
import styled from "styled-components";
import {colors} from "../../../constants/allConsts";

const CheckmarkRenderer = forwardRef((props: {value: string}, ref: any) => {
  const [selected, setSelected] = useState(props?.value && props?.value.toString() === "true");

  useImperativeHandle(ref, () => {
    return {
      refresh(params: any) {
        setSelected(params?.value || false);
      }
    };
  });

  return (
    <StyledCheckboxContainer>{selected ? <CheckIcon /> : ' '}</StyledCheckboxContainer>
  );
});

export {CheckmarkRenderer};

const StyledCheckboxContainer = styled.span`
    height: 100%;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: ${colors.checkmarkGreen};
    cursor: pointer;
    background: inherit;
    box-sizing: border-box;
`;