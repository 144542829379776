import React, {useEffect, useRef, useState} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {cacheKeys} from "../../constants/allConsts";
import {gridApiUtils} from "../../utils/gridApiUtils";
import {AlpineContainer} from "../containers/defaultAlpineContainer";
import {contextMenuItems} from "../agContextMenu";
import {CustomRefFilter} from "../agFrameworkComponents/filters/customRefFilter";
import {CustomRefFloatingFilter} from "../agFrameworkComponents/filters/customRefFloatingFilter";
import {AdjustmentsRenderer} from "../agFrameworkComponents/renderers/adjustments";
import {emptyObjectGenerator} from "../emptyObjects";
import {ButtonBar} from "../buttonBar";
import {AutoCompleteRenderer} from "../agFrameworkComponents/renderers/autoCompleteRenderer";
import {AutoCompleteEditor} from "../agFrameworkComponents/editors/autoCompleteEditor";
import {useQueryClient} from "react-query";
import {AutoCompleteReferenceEditor} from "../agFrameworkComponents/editors/autoCompleteRefEditor";
import {IRoll, IStyle} from "../../types/rowModelTypes";
import {AutoCompleteReferenceRenderer} from "../agFrameworkComponents/renderers/autoCompleteRefRenderer";
import {RowEditorModal} from "../modals/rowEditorModal";
import {FirstColRenderer} from "../agFrameworkComponents/renderers/firstColRenderer";
import {QcEditor} from "../agFrameworkComponents/editors/qcEditor";
import {DateEditor} from "../agFrameworkComponents/editors/dateEditor";
import {BasicSelectionEditor} from "../agFrameworkComponents/editors/basicSelectionEditor";
import styled from "styled-components";
import {Button} from "@material-ui/core";
import {FadeIn, MuiButtonPrimary, MuiButtonRed} from "../../styledComponentPresets/presets";
import {NewLotGrid} from "./customPopupGrids/newLotGrid";
import {GridApi, RowNode} from "ag-grid-community";
import {
  useAddFinishedGood,
  useDeleteFinishedGood, useGetFinishedGoods,
  useUpdateFinishedGood, useGetFinishedGoodsInfinite
} from "../../hooks/rowModels/useFinishedGoods";
import {useGetStyles} from "../../hooks/rowModels/useStyles";
import {useAddRoll, useDeleteRoll, useGetRolls} from "../../hooks/rowModels/useRolls";
import {CheckmarkRenderer} from "../agFrameworkComponents/renderers/checkmarkRenderer";
import {CheckmarkEditor} from "../agFrameworkComponents/editors/checkmarkEditor";
import moment from "moment";
import {FinishedGoodsSectionedGrid} from "./customPopupGrids/finishedGoodsSectionedGrid/finishedGoodsSectionedGrid";
import {useFinishedGoodsGroup1} from "../../hooks/gridFragments/finishedGoodsGrid/useFinishedGoodsGridGroup1";
import {useFinishedGoodsGroup2} from "../../hooks/gridFragments/finishedGoodsGrid/useFinishedGoodsGridGroup2";
import {useFinishedGoodsGroup3} from "../../hooks/gridFragments/finishedGoodsGrid/useFinishedGoodsGridGroup3";
import {useFinishedGoodsGroup5} from "../../hooks/gridFragments/finishedGoodsGrid/useFinishedGoodsGridGroup5";
import {useFinishedGoodsGroup4} from "../../hooks/gridFragments/finishedGoodsGrid/useFinishedGoodsGridGroup4";
import {useFinishedGoodsGroup6} from "../../hooks/gridFragments/finishedGoodsGrid/useFinishedGoodsGridGroup6";
import {RollEditorModal} from "../modals/rollEditorModal";
import {RollGrid} from "./rollGrid";

const GetSingleFinishedGoodForPopup = (id?: string) => {
  return useGetFinishedGoods({id});
};

const GetInfiniteFinishedGoods = () => {
  return useGetFinishedGoodsInfinite();
}

export const FinishedGoodsGrid = (props: { hideDefaultGrid?: boolean, filterId?: string | null, updateModalTitle?: any, gridApi?: GridApi | null }): JSX.Element => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [parentGridApi] = useState(props?.gridApi || null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [showClearFilters, setShowClearFilters] = useState<boolean>(false);
  const [serverSideDataSource, setServerSideDataSource] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddLotModalOpen, setIsAddLotModalOpen] = useState(false);
  const [isEditRollModalOpen, setIsEditRollModalOpen] = useState(false);

  const [expandedRowFilterId, setExpandedRowFilterId] = useState<string>('');
  const [expandedLotNumber, setExpandedLotNumber] = useState<string>('');
  const [isDataDisplayed, setIsDataDisplayed] = useState<boolean>(false);
  const newLotsRef = useRef(null);
  const [doGenerateRolls, setDoGenerateRolls] = useState<boolean>(false);
  const [newLotRowIndex, setNewLotRowIndex] = useState<number>(0);

  // TODO implement pagination
  const {data, isLoading} = useGetFinishedGoods();
  // TODO delete
  const {data: styleData, isSuccess: isStyleDataLoaded} = useGetStyles();


  const {mutate: addFinishedGoods} = useAddFinishedGood();
  const {mutate: deleteFinishedGoods} = useDeleteFinishedGood();
  const {mutate: updateFinishedGoods} = useUpdateFinishedGood();
  const {mutate: addRoll} = useAddRoll();

  const {mutate: deleteRoll} = useDeleteRoll();
  const {mutate: deleteFinishedGood} = useDeleteFinishedGood();

  const queryClient = useQueryClient();

  const expandRow = (rowId: string) => {
    queryClient.invalidateQueries([cacheKeys.finishedGoodsData]);
    setExpandedRowFilterId(rowId);
    setIsModalOpen(true);
    //@ts-ignore
    setExpandedLotNumber(gridApi?.getRowNode(rowId)?.data?.lot_number);
  };

  const updateColorsByCrossReferencingVendorandCode = (params: any) => {
    // TODO rewrite implementation that doesn't use colorIdMap
    // const colorRow = colorIdMap.get(params.data.color_code_ref);
    // let laaColor = '';
    // let vendorColor = '';
    // if (params.data.color_code_ref && params.data.vendor_ref) {
    //   if (colorRow?.vendor_ref === params.data.vendor_ref) {
    //     laaColor = colorRow?.id || '';
    //     vendorColor = colorRow?.id || '';
    //   }
    // }
    // // if empty, we don't want to replace what's already there
    // if (laaColor) params.data.laa_color_ref = laaColor;
    // // if empty, we don't want to replace what's already there
    // if (vendorColor) params.data.vendor_color_ref = vendorColor;
    // params.api.redrawRows({rowNodes: [params.node]});
    // const rollsInLot = rollData.filter((roll: IRoll) => roll.lots_ref === params.data.id);
    // rollsInLot.forEach((roll: IRoll) => {
    //   if (params?.column?.colId === "color_code_ref") roll.color_code_ref = colorRow?.id || '';
    //   if (params?.column?.colId === "vendor_ref") roll.vendor_ref = params.data.vendor_ref || '';
    //   // if empty, we don't want to replace what's already there
    //   if (laaColor) roll.laa_color_ref = laaColor;
    //   // if empty, we don't want to replace what's already there
    //   if (vendorColor) roll.vendor_color_ref = vendorColor;
    //   // TODO bring out of foreach once batch updates are implemented
    //   updateRoll(roll, {
    //     onSuccess: () => {
    //       queryClient.invalidateQueries(cacheKeys.rollData);
    //     }
    //   });
    //
    // });
  };

  const {colArray: group1cols} = useFinishedGoodsGroup1({
    expandRow: expandRow,
    hideDefaultGrid: props?.hideDefaultGrid,
  });

  const {colArray: group2cols} = useFinishedGoodsGroup2({
    doSkipId: true,
    updateColorsByCrossReferencingVendorandCode,
  });

  const {colArray: group3cols} = useFinishedGoodsGroup3({
    doSkipId: true,
    updateColorsByCrossReferencingVendorandCode,
  });

  const {colArray: group4cols} = useFinishedGoodsGroup4({
    doSkipId: true,
  });

  const {colArray: group5cols} = useFinishedGoodsGroup5({
    doSkipId: true,
  });

  const {colArray: group6cols} = useFinishedGoodsGroup6({
    doSkipId: true,
  });


  useEffect(() => {
    // Load only once on mount to populate grid. Allow FE to take care of updates optimistically
    if (!isDataDisplayed && !isLoading) {
      // TODO get rid of emptyObjectGenerator. Get empty row from server instead (the grid needs at least 1 row for user to right click to add new rows)
      //@ts-ignore
      setServerSideDataSource(gridApiUtils.setServerSideDatasource(!isLoading, (Array.isArray(data) && data?.length > 0) ? data : [emptyObjectGenerator.finishedGoods()]));
      setIsDataDisplayed(true);
    }
  }, [isLoading]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    if (props?.filterId) {
      var filterModel = {
        id: {
          filter: props.filterId,
          filterType: "text",
          type: "contains"
        }
      };
      params.api.setFilterModel(filterModel);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setExpandedRowFilterId('');
    setExpandedLotNumber('');
    setNewLotRowIndex(0);
  };

  const closeEditRollModal = () => {
    setIsEditRollModalOpen(false);
  };

  const closeAddLotModal = (doSkipConfirmation?: boolean) => {
    if (doSkipConfirmation || window.confirm("Are you sure you want to exit? All changes will be lost")) {
      setIsAddLotModalOpen(false);
    }
  };

  // emulate custom new row function in context menu
  const generateNewLot = () => {
    // grab contents from forwarded ref::
    //@ts-ignore
    if (newLotsRef.current && newLotsRef?.current?.api) {
      // @ts-ignore
      const newLotDataModel = newLotsRef?.current?.api?.getRenderedNodes()[0]?.data;
      const newLotNumber = newLotDataModel?.lot_number?.toUpperCase();
      const newLotRollCount = newLotDataModel?.roll_count;
      const styleRef = newLotDataModel?.style_ref;
      const vendorRef = newLotDataModel?.vendor_ref;
      const colorCodeRef = newLotDataModel?.color_code;
      // TODO rewrite chosenStyle to not use styledata (because it will be paginated)
      const chosenStyle = styleData?.filter((style: IStyle) => style.id === newLotDataModel?.style_ref)[0];
      const description = chosenStyle?.desc || '';
      const newRolls: any = [];
      // TODO get emptyObject from server, not from emptyObjectGenerator
      const emptyObject = emptyObjectGenerator.finishedGoods();
      let laa_color = '';
      let vendor_color = '';
      emptyObject.lot_number = newLotNumber;
      emptyObject.style = styleRef;
      emptyObject.notes = description;
      emptyObject.color_code = colorCodeRef;
      emptyObject.vendor = vendorRef;
      // emptyObject.total_rolls_in_stock = newLotRollCount;
      if (colorCodeRef && vendorRef) {
        // TODO rewrite so colorIdMap is not used
        // const colorRow = colorIdMap.get(colorCodeRef);
        // if (colorRow?.vendor_ref === vendorRef) {
        //   laa_color = colorRow?.id || '';
        //   vendor_color = colorRow?.id || '';
        //   if (laa_color) emptyObject.laa_color_ref = laa_color;
        //   if (vendor_color) emptyObject.vendor_color_ref = vendor_color;
        // }
      }
      addFinishedGoods(emptyObject, {
        onSuccess: () => {
          queryClient.invalidateQueries([cacheKeys.finishedGoodsData]).then((queryRes: any) => {
            //@ts-ignore
            const res = gridApi?.applyServerSideTransaction({
              add: [emptyObject],
              addIndex: newLotRowIndex,
            });
          });

          // wait for lot to be created first before generating new rolls
          for (let i = 0; i < Number(newLotRollCount); i++) {
            // TODO get new roll object from server, not from emptyObjectGenerator
            const newRoll = emptyObjectGenerator.roll();
            newRoll.roll_number = `${newLotNumber}${i + 1}`;
            newRoll.lot_number = newLotNumber;
            newRoll.style_ref = styleRef;
            newRoll.lots_ref = emptyObject.id ? emptyObject.id : 'nothing';
            newRoll.color_code_ref = colorCodeRef;
            newRoll.vendor_ref = vendorRef;
            if (laa_color) newRoll.laa_color_ref = laa_color;
            if (vendor_color) newRoll.vendor_color_ref = vendor_color;
            newRolls.push(newRoll);
            // TODO get rid of timeout once backend is ready (especially for batch updates)
            setTimeout(() => {
              console.log("new roll to add:: ", newRoll);
              addRoll(newRoll, {
                onSuccess: () => {
                  if (i === Number(newLotRollCount) - 1) {
                    queryClient.invalidateQueries(cacheKeys.rollData).then(() => {
                      expandRow(emptyObject.id || 'nothing');
                      closeAddLotModal(true);
                    });
                  }
                }
              });
            }, 200 * i);
            // TODO set color code from vendor
          }
        }
      });

      //@ts-ignore
      // -- TODO perform input validation

    }

  };

  const onCellValueChanged = (params: any) => {
    if (props?.updateModalTitle) {
      props?.updateModalTitle();
    }
    // update server
    updateFinishedGoods(params?.data);
    gridApiUtils.updatePopupTransactionUpdates(parentGridApi, gridApi, props?.filterId);
  };

  const clearFilters = () => {
    if (!gridApi) return;
    // @ts-ignore
    gridApi.setFilterModel(null);
  };

  const setTodayFilter = () => {
    if (!gridApi) return;
    var filterModel = {
      date_added: {
        dateFrom: moment().format("YYYY-MM-DD").toString(), // start of last week
        dateTo: null,
        filterType: "date",
        type: "equals"
      }
    };
    // @ts-ignore
    gridApi.setFilterModel(filterModel);
  };

  const setThisWeekFilter = () => {
    if (!gridApi) return;
    var filterModel = {
      date_added: {
        dateFrom: moment().startOf('week').format("YYYY-MM-DD").toString(), // start of last week
        dateTo: moment().format("YYYY-MM-DD").toString(),
        filterType: "date",
        type: "inRange"
      }
    };
    // @ts-ignore
    gridApi.setFilterModel(filterModel);
  };

  const setLastWeekFilter = () => {
    if (!gridApi) return;
    var filterModel = {
      date_added: {
        dateFrom: moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD").toString(), // start of last week
        dateTo: moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD").toString(),
        filterType: "date",
        type: "inRange"
      }
    };
    // @ts-ignore
    gridApi.setFilterModel(filterModel);
  };

  const onFilterChanged = (filterParams: any) => {
    if (!gridApi) return;
    // @ts-ignore
    if (_.isEmpty(gridApi?.getFilterModel()) && showClearFilters) setShowClearFilters(false);
    else {
      // @ts-ignore
      if (!_.isEmpty(gridApi?.getFilterModel()) && !!gridApi?.getFilterModel()?.date_added && !showClearFilters) setShowClearFilters(true);
    }
  };


  /**
   * @description processes cell for clipboard so the right contents get copied.
   * Used primarily for reference buttons where you want the button names to
   * be copied rather than the reference id the cell actually holds
   * */
  const processCellForClipboard = (params: any) => {
    // TODO rewrite cell processer for clipboard (example implementation in purchaseOrderGrid)
    // switch (params?.column?.colId) {
    //   case "style_ref":
    //     return styleIdMap.get(params?.value)?.style_name;
    //   case "laa_color_ref":
    //     return colorIdMap.get(params?.value)?.laa_color;
    //   case "vendor_color_ref":
    //     return colorIdMap.get(params?.value)?.vendor_color;
    //   case "color_code_ref":
    //     return colorIdMap.get(params?.value)?.color_code;
    //   case "dyer_ref":
    //     return dyerIdMap.get(params?.value)?.dyer_name;
    //   case "vendor_ref":
    //     return vendorIdMap.get(params?.value)?.vendor_name;
    //   case "maker_ref":
    //     return makerIdMap.get(params?.value)?.maker_name;
    // }
    return params.value;
  };

  return (
    <AlpineContainer hideDefaultGrid={props?.hideDefaultGrid}>
      <ButtonBar
        addRowTitle="Add Lot"
        cacheKey={cacheKeys.finishedGoodsData}
        isAllDataLoaded={!isLoading}
        // we don't want to create new lots the regular way. We want to open the popup to add new rolls to the lot
        addRowEmptyObject={() => null}
        customAddRowFunction={() => {
          setIsAddLotModalOpen(true);
        }}
        // pass in gridApi for buttonBar to manipulate the grid
        gridApi={gridApi}
        gridComponent={<FinishedGoodsSectionedGrid
          gridApi={gridApi}
          processCellForClipboard={processCellForClipboard}
          updateColorsByCrossReferencingVendorandCode={updateColorsByCrossReferencingVendorandCode}
        />}
      >
        <StyledRightButtonsContainer>
          {showClearFilters && (<StyledRowButtonRed
            onClick={clearFilters}
            variant={"contained"}
            color={"primary"}
            size={"medium"}
          >
            Clear Date Filters
          </StyledRowButtonRed>)}
          <StyledRowButton
            onClick={setTodayFilter}
            variant={"contained"}
            color={"primary"}
            size={"medium"}
          >
            Today
          </StyledRowButton>
          <StyledRowButton
            onClick={setThisWeekFilter}
            variant={"contained"}
            color={"primary"}
            size={"medium"}
          >
            This Week
          </StyledRowButton>
          <StyledRowButton
            onClick={setLastWeekFilter}
            variant={"contained"}
            color={"primary"}
            size={"medium"}
          >
            Last Week
          </StyledRowButton>
        </StyledRightButtonsContainer>
      </ButtonBar>
      <AgGridReact
        defaultColDef={{
          resizable: true,
          editable: true,
          floatingFilter: true,
          filter: true,
          sortable: true,
          filterParams: {
            debounceMs: 100
          },
        }}
        enableRangeSelection={!props?.filterId}
        enableFillHandle={!props?.filterId}
        fillHandleDirection={'y'}
        fillOperation={gridApiUtils.setFillOperation}
        onFillEnd={gridApiUtils.fillEnd}
        processCellForClipboard={processCellForClipboard}
        getRowNodeId={(data) => {
          return data.id;
        }}
        // turn off column virtualization if grid is displayed in a rowEditorModal (otherwise, not all the columns will show)
        suppressColumnVirtualisation={!!props?.filterId}
        // allow single-edit if grid is displayed in a rowEditorModal
        singleClickEdit={!!props?.filterId}
        onFilterChanged={onFilterChanged}
        suppressRowClickSelection={true}
        rowSelection={'multiple'}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={50}
        animateRows={true}
        rowModelType={'serverSide'}
        serverSideDatasource={serverSideDataSource}
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        suppressMenuHide={!!props.hideDefaultGrid}
        //@ts-ignore
        getContextMenuItems={(params) => props?.hideDefaultGrid ? [] : contextMenuItems({
          cacheKey: cacheKeys.finishedGoodsData,
          queryClient: queryClient,
          expandRow: expandRow,
          params,
          // TODO make empty object serverside
          makeEmptyObject: emptyObjectGenerator.finishedGoods,
          addRowServerCall: addFinishedGoods,
          deleteRowServerCall: deleteFinishedGoods,
          rowDescription: 'lot',
          disableMultipleRowAdds: true,
          disableDuplication: true,
          customAddRowFunction: (cacheKey: string, currentNode: RowNode, isBelow: boolean) => {
            setIsAddLotModalOpen(true);
            if (currentNode && currentNode?.rowIndex) {
              setNewLotRowIndex(isBelow ? currentNode.rowIndex : currentNode.rowIndex + 1);
            }
          },
          customDeleteRowFunction: (cacheKey: string, currentNode: RowNode) => {
            // TODO rewrite
            // let totalRollsInLot = currentNode?.data?.total_rolls_in_stock;
            // const selectedRows = gridApi?.getSelectedNodes() || [];
            // if (selectedRows.length > 0) {
            //   totalRollsInLot = selectedRows.reduce((prev = 0, current: any) => prev + (Number(current?.data?.total_rolls_in_stock) || 0), 0);
            // }
            // if (window.confirm(`Deleting ${(selectedRows.length > 1 ? 'these lots' : 'this lot')} will also delete associated rolls${totalRollsInLot ? ` (${totalRollsInLot} rolls)` : ''}. Do you want to continue?`)) {
            //   // get all ids of rolls we need to delete
            //   const rollIdsToMatch = selectedRows.length > 0 ? selectedRows.map((roll: any) => roll?.data?.id) : [currentNode?.data?.id];
            //   // delete all associated records
            //   let rollsToDelete = rollData?.filter((roll: IRoll) => rollIdsToMatch.indexOf(roll?.lots_ref) >= 0);
            //   if (rollsToDelete && rollsToDelete.length > 0) {
            //     rollsToDelete.forEach((data: IRoll, index: number) => {
            //       // TODO delete timeout once we have real server
            //       setTimeout(() => {
            //         // delete from server
            //         deleteRoll(data, {
            //           onSuccess: () => {
            //             if (index === rollsToDelete.length - 1) {
            //               queryClient.invalidateQueries(cacheKeys.rollData);
            //             }
            //           }
            //         });
            //       }, index * 200);
            //     });
            //   }
            //
            //   const res = gridApi?.applyServerSideTransaction({
            //     remove: selectedRows.length > 0 ? selectedRows : [currentNode?.data],
            //   });
            //   if (res && (res?.remove?.length)) {
            //     if (selectedRows?.length === 0) {
            //       deleteFinishedGood(currentNode?.data, {
            //         onSuccess: () => {
            //           queryClient.invalidateQueries(cacheKeys.finishedGoodsData);
            //         }
            //       })
            //     } else {
            //       selectedRows.forEach((node: any, index: number) => {
            //         deleteFinishedGood(node?.data, {
            //           onSuccess: () => {
            //             // TODO get rid of loop once batch deletion is implemented on backend
            //             if (index === selectedRows.length - 1) {
            //               queryClient.invalidateQueries(cacheKeys.finishedGoodsData);
            //             }
            //           }
            //         })
            //       })
            //     }
            //   }
            // }
          }
        })}
        stopEditingWhenCellsLoseFocus={true}
        frameworkComponents={{
          autoCompleteEditor: AutoCompleteEditor,
          autoCompleteRenderer: AutoCompleteRenderer,
          customRefFilter: CustomRefFilter,
          customRefFloatingFilter: CustomRefFloatingFilter,
          adjustmentsRenderer: AdjustmentsRenderer,
          autoCompleteReferenceEditor: AutoCompleteReferenceEditor,
          autoCompleteReferenceRenderer: AutoCompleteReferenceRenderer,
          firstColRenderer: FirstColRenderer,
          qcEditor: QcEditor,
          dateEditor: DateEditor,
          basicSelectionEditor: BasicSelectionEditor,
          checkmarkRenderer: CheckmarkRenderer,
          checkmarkEditor: CheckmarkEditor,
        }}
      >
        {group1cols}
        {group2cols}
        {group3cols}
        {group4cols}
        {group5cols}
        {group6cols}
      </AgGridReact>
      <RowEditorModal
        open={isModalOpen}
        close={closeModal}
        title={`Edit Lot ${expandedLotNumber}`}
        topButtonComponent={
          <StyledRowButton
            onClick={() => {
              setIsEditRollModalOpen(true);
            }}
            variant={"contained"}
            color={"primary"}
            size={"medium"}
          >
            Edit Rolls
          </StyledRowButton>
        }
        gridComponent={
          <FinishedGoodsSectionedGrid
            hideDefaultGrid={true}
            gridApi={gridApi}
            filterId={expandedRowFilterId}
            processCellForClipboard={processCellForClipboard}
            updateColorsByCrossReferencingVendorandCode={updateColorsByCrossReferencingVendorandCode}
          />}
      />
      <RowEditorModal
        open={isAddLotModalOpen}
        close={closeAddLotModal}
        title={`Add New Lot`}
        gridComponent={
          <NewLotGrid
            setDoGenerateRolls={setDoGenerateRolls}
            ref={newLotsRef}
          />
        }
        bottomButtonComponent={
          <StyledAddRowButton
            variant={"contained"}
            color={"primary"}
            size={"medium"}
            onClick={generateNewLot}
            //@ts-ignore
            disabled={!doGenerateRolls}
          >
            Generate New Lot
          </StyledAddRowButton>
        }
      />
      <RollEditorModal
        open={isEditRollModalOpen}
        close={closeEditRollModal}
        gridComponent={
          <RollGrid
            lotToFilter={expandedLotNumber}
          />
        }
      />
    </AlpineContainer>
  );
};


const StyledAddRowButton = styled(Button)`
  ${MuiButtonPrimary};
`;

const StyledRowButton = styled(Button)`
  ${MuiButtonPrimary};
  &.MuiButtonBase-root {
    margin-left: 10px;
  }
`;

const StyledRowButtonRed = styled(Button)`
  ${MuiButtonRed};
  &.MuiButtonBase-root {
    margin-left: 10px;
    animation: ${FadeIn} 0.2s linear forwards;
  }
`;

const StyledRightButtonsContainer = styled.div`
  margin-left: auto;
`;
