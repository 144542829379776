import React, {forwardRef, MouseEventHandler, useCallback, useImperativeHandle, useState} from "react";
import {RowEditorModal} from "../../modals/rowEditorModal";
import CloseIcon from '@material-ui/icons/Close';
import styled from "styled-components";
import {colors} from "../../../constants/allConsts";
import {GridApi, RowNode} from "ag-grid-community";
import _ from "lodash";

const AutoCompleteReferenceRenderer = forwardRef((props: {
  api: GridApi, // default ag prop (current api object)
  node: RowNode, // default ag prop (current node)
  value: any, // default ag prop (current value)
  deleteCellValue: any, // custom function called to delete current value
  gridComponent: any, // grid component to open in modal
  closeModalCallback?: any, // callback to be called when closing modal
  openModalCallback?: any, // callback to be called when opening modal
  data: any, // default ag-grid rowdata
  displayPath?: string, // the name path to display based on id
}, ref: any) => {
  const [refOutput, setRefOutput] = useState<any>(_.get(props?.data, props?.displayPath?.split(".") || []));
  const [showModal, setShowModal] = useState<boolean>(false);

  const getNameFromDisplayPath = useCallback((data: any) => {
    return _.get(props?.data, props?.displayPath?.split(".") || []);
  }, [props?.data]);

  useImperativeHandle(ref, () => {
    return {
      refresh(params: any) {
        setRefOutput(getNameFromDisplayPath(props?.data));
      }
    };
  });

  const deleteCurrentRef = () => {
    if (props.deleteCellValue) props.deleteCellValue(props);
  };

  const closeModal = () => {
    if (props?.closeModalCallback) props?.closeModalCallback();
    // refresh current row
    props?.api?.redrawRows({rowNodes: [props?.node]});
    setShowModal(false);
  };

  const openModal = () => {
    if (props?.openModalCallback) props?.openModalCallback();
    setShowModal(true);
  };

  const wasRefDeleted = props?.value && !refOutput;
  return (
    (props?.value) ?
      (
        <>
          <StyledButtonContainer className={"ref-pill-button"}>
            <StyledCloseButton onClick={deleteCurrentRef}><CloseIcon/></StyledCloseButton>
            <StyledPillButton
              className={wasRefDeleted ? 'ref-deleted' : ''}
              onClick={openModal}>
              {wasRefDeleted ? 'undefined' : getNameFromDisplayPath(props?.data)}
            </StyledPillButton>
          </StyledButtonContainer>
          {(!wasRefDeleted) &&
          <RowEditorModal
            open={showModal}
            close={closeModal}
            title={getNameFromDisplayPath(props?.data)}
            subtitle={`Changes to these fields will be applied to its corresponding tab`}
            gridComponent={props.gridComponent}
          />
          }
        </>
      )
      : (
        <span></span>
      )
  );
});

export {AutoCompleteReferenceRenderer};

const StyledButtonContainer = styled.div`
  height: auto;
  width: auto;
  position: relative;
  display: inline-flex;
  flex-direction: row-reverse;
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 4px;
  &:hover {
    background: rgba(0,0,0,0.1);
    border-radius: 4px;
  }
`;

const StyledPillButton = styled.button`
    height: 1.6rem;
    background: none;
    border: none;
    font-weight: bold;
`;

const StyledCloseButton = styled.button`
  height: 1.6rem;
  width: 1.6rem;
  background: none;
  border: none;
  padding: 0;
  color: rgba(0,0,0,0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  svg.MuiSvgIcon-root {
    height: 65%;
    width: 65%;
  }
  &:hover {
    color: ${colors.black};
    filter: drop-shadow(0.5px 0px 0px black);
    ~ button {
      opacity: 0.55;
    }
  }
`;
