import React, {useEffect, useState} from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {AlpineContainer} from "../containers/defaultAlpineContainer";
import {contextMenuItems} from "../agContextMenu";
import {gridApiUtils} from "../../utils/gridApiUtils";
import {cacheKeys, routes} from "../../constants/allConsts";
import {emptyObjectGenerator} from "../emptyObjects";
import {ButtonBar} from "../buttonBar";
import {useQueryClient} from "react-query";
import {RowEditorModal} from "../modals/rowEditorModal";
import {FirstColRenderer} from "../agFrameworkComponents/renderers/firstColRenderer";
import {AddressEditor} from "../agFrameworkComponents/editors/addressEditor";
import {BodyScrollEvent, GridApi} from "ag-grid-community";
import { IMaker } from '../../types/rowModelTypes';
import { useGetRowModel, useGetRowModelInfinite, 
        useGetRowModelBasic, useAddRowModel, 
        useDeleteRowModel, useUpdateRowModel } from '../../hooks/rowModels/RowModelHooks';
import _, { filter } from "lodash";


const GetSingleMakerForPopup = (id?: string) => {
  return useGetRowModel<IMaker>(routes.DEMO_MAKERS , {id});
};

const GetInfiniteMakers = () => {
  return useGetRowModelInfinite<IMaker>(routes.DEMO_MAKERS);
};
        

export const MakerGrid = (props: { hideDefaultGrid?: boolean, filterId?: string | null, gridApi?: GridApi | null }): JSX.Element => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [parentGridApi] = useState(props?.gridApi || null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [serverSideDataSource, setServerSideDataSource] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedRowFilterId, setExpandedRowFilterId] = useState<string>('');
  const [isDataDisplayed, setIsDataDisplayed] = useState<boolean>(false);

  const makers = props?.filterId ? GetSingleMakerForPopup(props.filterId) : GetInfiniteMakers();

  const {mutate: addMaker} = useAddRowModel<IMaker>(routes.DEMO_MAKERS);
  const {mutate: updateMaker} = useUpdateRowModel<IMaker>(routes.DEMO_MAKERS);
  const {mutate: deleteMaker} = useDeleteRowModel<IMaker>(routes.DEMO_MAKERS);

  const queryClient = useQueryClient();

  useEffect(() => {
    // Load only once on mount to populate grid. Allow FE to take care of updates optimistically
    if (!isDataDisplayed && !makers?.isLoading) {
      let data = emptyObjectGenerator.maker();
      data = props.filterId ? makers?.data : makers?.data?.pages
      // TODO get rid of emptyObjectGenerator. Get empty row from server instead (the grid needs at least 1 row for user to right click to add new rows)
      //@ts-ignore
      const setDataSourceFunc = props?.filterId ? gridApiUtils.setServerSideDatasource : gridApiUtils.setServerSidePaginatedDatasource;
      const dataSource: any = setDataSourceFunc(makers?.isSuccess, data)
      setServerSideDataSource(dataSource);
      setIsDataDisplayed(true);
    }
  }, [makers?.isLoading]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const closeModal = () => {
    if(gridApi) {
      const expandedNode = gridApi?.getRowNode(expandedRowFilterId);
      // refresh the previously expanded node in case changes were made to it while in expanded mode
      if (expandedNode) {
        gridApi?.redrawRows({rowNodes: [expandedNode]});
      }
    }
    setIsModalOpen(false);
    setExpandedRowFilterId('');
  };

  const expandRow = (rowId: string) => {
    setExpandedRowFilterId(rowId);
    setIsModalOpen(true);
  };

  const onCellValueChanged = (params: any) => {
    // update server
    updateMaker(params?.data);
    gridApiUtils.updatePopupTransactionUpdates(parentGridApi, gridApi, props?.filterId);
  };

  const clearFilters = () => {
    if (!gridApi) return;
    gridApi.setFilterModel(null);
  };

  const processCellForClipboard = (params: any) => {
    // TODO rewrite cell processer for clipboard (example implementation in purchaseOrderGrid)
    return params.value;
  };

  return (
    <AlpineContainer hideDefaultGrid={props?.hideDefaultGrid}>
      <ButtonBar
        addRowTitle="Add Maker"
        cacheKey={cacheKeys.makerData}
        isAllDataLoaded={!makers?.isLoading}
        addRowEmptyObject={(onAddRowSuccess: any) => {
          let newRowData = {}
          // invalidate queries to refetch data from server
          addMaker(newRowData, {
            onSuccess: (data) => {
              console.log(`NEW DATA: ${JSON.stringify(data,null,2)}`)
              queryClient.invalidateQueries([cacheKeys.makerData]);
              setExpandedRowFilterId(data.id);
              onAddRowSuccess(data);
            }
          });
        }}
        // pass in gridApi for buttonBar to manipulate the grid
        gridApi={gridApi}
        gridComponent={<MakerGrid gridApi={gridApi}/>}
      >
      </ButtonBar>
      <AgGridReact
        defaultColDef={{
          resizable: true,
          editable: true,
          floatingFilter: true,
          filter: true,
          sortable: true,
          filterParams: {
            debounceMs: 100
          }
        }}
        enableRangeSelection={!props?.filterId}
        enableFillHandle={!props?.filterId}
        fillHandleDirection={'y'}
        fillOperation={gridApiUtils.setFillOperation}
        onFillEnd={gridApiUtils.fillEnd}
        getRowNodeId={(data) => {
          return data.id;
        }}
        onBodyScroll={_.debounce((event: BodyScrollEvent) => gridApiUtils.customInfinitePaginationOnBodyScroll(event, makers, gridApi), 100)}
        // turn off column virtualization if grid is displayed in a rowEditorModal (otherwise, not all the columns will show)
        suppressColumnVirtualisation={!!props?.filterId}
        // allow single-edit if grid is displayed in a rowEditorModal
        singleClickEdit={!!props?.filterId}
        suppressRowClickSelection={true}
        rowSelection={'multiple'}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={50}
        animateRows={true}
        rowModelType={'serverSide'}
        serverSideDatasource={serverSideDataSource}
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        suppressMenuHide={!!props?.hideDefaultGrid}
        //@ts-ignore
        getContextMenuItems={(params) => props?.hideDefaultGrid ? [] : contextMenuItems({
          cacheKey: cacheKeys.makerData,
          queryClient: queryClient,
          expandRow: expandRow,
          params,
          // TODO make empty object serverside
          makeEmptyObject: emptyObjectGenerator.maker,
          addRowServerCall: addMaker,
          deleteRowServerCall: deleteMaker,
          rowDescription: 'maker'
        })}
        stopEditingWhenCellsLoseFocus={true}
        frameworkComponents={{
          addressEditor: AddressEditor,
          firstColRenderer: FirstColRenderer,
        }}
      >
        <AgGridColumn
          field={"id"}
          lockPosition
          editable={false}
          cellClass={'suppress-fill-handle'}
          suppressColumnsToolPanel={true}
          hide
        />
        <AgGridColumn
          headerName={"Yarn Maker"}
          field={"maker_name"}
          cellRenderer={"firstColRenderer"}
          cellRendererParams={{expandRow: expandRow}}
          lockPosition
          checkboxSelection={!props?.hideDefaultGrid}
          width={250}
        />
        <AgGridColumn
          field={"phone"}
          width={200}
        />
        <AgGridColumn
          field={"contact"}
          width={200}
        />
        <AgGridColumn
          field={"email"}
          minWidth={250}
        />
        <AgGridColumn
          field={"address"}
          minWidth={575}
          cellEditor={"addressEditor"}
          headerClass={"span-half"}
          cellClass={"ag-cell-overflow-visible-on-edit span-half"}
          flex={1}
        />
      </AgGridReact>
      <RowEditorModal
        open={isModalOpen}
        close={closeModal}
        title={"Edit Maker"}
        gridComponent={
        <MakerGrid hideDefaultGrid={true} 
        filterId={expandedRowFilterId} 
        gridApi={gridApi}
        />}
      />
    </AlpineContainer>
  );
};
