import {useMutation, useQuery} from "react-query";
import {cacheKeys} from "../../constants/allConsts";
import {addRoll, deleteRoll, getRolls, updateRoll} from "../../services/rowModels/rollServices";
import {IRoll} from "../../types/rowModelTypes";

// TODO extend useGetRolls to be able to use getRollsById once endpoint is made (see usePurchaseOrders.ts for example)
export const useGetRolls = (): any => {
  const result = useQuery({
    queryKey: [cacheKeys.rollData],
    queryFn: () => getRolls(),
  });
  return result;
};

// TODO implement useGetRollsBasic (see usePurchaseorders.ts for example)

export const useAddRoll = () => {
  const result = useMutation(async (update: IRoll) => {
    return await addRoll(update);
  });
  return result;
};

export const useUpdateRoll = () => {
  const result = useMutation(async (update: IRoll) => {
    return await updateRoll(update);
  });
  return result;
};

export const useDeleteRoll = () => {
  const result = useMutation(async (update: IRoll) => {
    return await deleteRoll(update);
  });
  return result;
};
