export const colors = {
  primaryBlue: '#2F7DE0',
  primaryBlueActive: '#0F66D3',
  primaryBlueInactive: '#C9DAF0',
  lightInactiveGray: '#787878',
  lightBackgroundGray: '#F5F5F5',
  lighterBackgroundGray: '#F8F8F8',
  white: '#FFFFFF',
  black: '#000000',
  muiRed: '#f50057',
  dangerRed: '#EA4747',
  dangerRedActive: '#D43F3F',
  teal: '#3588AA',
  checkmarkGreen: `#1B7F31`,
};