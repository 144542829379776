import {AgGridColumn} from "ag-grid-react";
import {utils} from "../../../utils/utils";
import React from "react";
import {IPurchaseOrder} from "../../../types/rowModelTypes";
import {GridApi} from "ag-grid-community";
import {useGetFinishedGoods} from "../../rowModels/useFinishedGoods";

export const useFinishedGoodsGroup1 = (props: {
  doSkipId?: boolean,
  expandRow?: any,
  gridApi?: GridApi | null,
  hideDefaultGrid?: boolean,
}): {colArray: JSX.Element[]} => {
  const {
    doSkipId,
    expandRow,
    gridApi,
  } = props;

  const colArray = [
    ...(!doSkipId ? [<AgGridColumn
      field={"id"}
      lockPosition
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={'suppress-fill-handle'}
      suppressColumnsToolPanel={true}
      hide
      key={"id"}
    />] : []),
    <AgGridColumn
      key={"status"}
      field={"status"}
      width={175}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      lockPosition
      checkboxSelection={!props?.hideDefaultGrid}
      cellRenderer={"firstColRenderer"}
      cellEditor={"basicSelectionEditor"}
      cellEditorParams={{
        options: ['finished', 'print', 'greig'],
      }}
      cellClass={"ag-cell-overflow-visible-on-edit"}
      cellRendererParams={{expandRow: expandRow}}
      singleClickEdit
    />,
    <AgGridColumn
      key={"date_added"}
      headerName={"Date Added"}
      field={"date_added"}
      width={200}
      // TODO implement server-side filter
      filter={"agDateColumnFilter"}
      cellEditor={"dateEditor"}
      editable={false}
      cellClass={" noneditable ag-cell-overflow-visible-on-edit"}
      filterParams={{
        comparator: utils.filterDateComparatorfunction, // comparator for filters
        inRangeInclusive: true
      }}
      comparator={utils.dateComparator} // comparator for sorting
    />,
    <AgGridColumn
      key={"lot_number"}
      headerName={"Lot #"}
      field={"lot_number"}
      width={200}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
    />
  ];

  return {colArray};
};
