import { AgGridColumn } from "ag-grid-react";
import { utils } from "../../../utils/utils";
import { gridApiUtils } from "../../../utils/gridApiUtils";
import { routes, cacheKeys } from "../../../constants/allConsts";
import { SupplierGrid } from "../../../components/grids/supplierGrid";
import { emptyObjectGenerator } from "../../../components/emptyObjects";
import React from "react";
import { useUpdatePurchaseOrder } from "../../rowModels/usePurchaseOrders";
import { useAddSupplier } from "../../rowModels/useSuppliers";
import { useAddMaker } from "../../rowModels/useMakers";
import { MakerGrid } from "../../../components/grids/makerGrid";
import { useAddTerm, useGetTerms } from "../../selections/useTerms";
import { useAddLotNumber, useGetLotNumbers } from "../../selections/useLotNumbers";
import { YarnTypeGrid } from "../../../components/grids/yarnTypeGrid";
import { useAddDescription, useGetDescriptions } from "../../selections/useDesc";
import { useAddContent, useGetContents } from "../../selections/useContents";
import { useAddYarnTypeColor, useGetYarnTypeColors } from "../../selections/useYarnTypeColors";
import { KnitterGrid } from "../../../components/grids/knitterGrid";
import { GridApi } from "ag-grid-community";
import { useAddYarnType, useGetYarnTypes, useGetYarnTypesBasic } from "../../rowModels/useYarnTypes";
import { useAddRowModel, useDeleteRowModel, useGetRowModel, useGetRowModelBasic, useUpdateRowModel } from "../../rowModels/RowModelHooks";
import { useAddKnitter } from "../../rowModels/useKnitters";
import { RowModelServices } from "../../../services/rowModels/RowModelServices";
import { getSupplierById } from "../../../services/rowModels/supplierServices";
import { IKnitter, IMaker, IPurchaseOrder, ISupplier, IYarnType } from "../../../types/rowModelTypes";

const rowModelServices = {
  yarnTypes: new RowModelServices<IYarnType>(routes.DEMO_MASTER_CODES),
  suppliers: new RowModelServices<ISupplier>(routes.DEMO_SUPPLIERS),
  knitters: new RowModelServices<IKnitter>(routes.DEMO_KNITTERS),
  makers: new RowModelServices<IMaker>(routes.DEMO_MAKERS)
};

export const usePurchaseOrderGroup1 = (props: {
  doSkipId?: boolean,
  expandRow?: any,
  gridApi?: GridApi | null,
  hideDefaultGrid?: boolean,
}): { colArray: JSX.Element[] } => {
  // TODO rewrite
  // const {mutate: updatePurchaseOrder} = useUpdatePurchaseOrder();
  // const {mutate: addYarnType} = useAddYarnType();
  // const {mutate: addSupplier} = useAddSupplier();
  // const {mutate: addKnitter} = useAddKnitter();
  // const {mutate: addMaker} = useAddMaker();
  const { mutate: updatePurchaseOrder } = useUpdateRowModel<IPurchaseOrder>(routes.DEMO_YARN_POS);

  const colArray = [
    ...(!props?.doSkipId ? [<AgGridColumn
      field={"id"}
      lockPosition
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={'suppress-fill-handle'}
      suppressColumnsToolPanel={true}
      hide
      key={"id"}
    />] : []),
    <AgGridColumn
      key={"date"}
      headerName={"Date"}
      field={"date"}
      width={200}
      cellRenderer={"firstColRenderer"}
      cellRendererParams={{
        expandRow: props?.expandRow,
        valueFormatter: gridApiUtils.dateFormatter
      }}
      lockPosition
      checkboxSelection={!props?.hideDefaultGrid}
      // TODO implement server-side filter
      filter={"agDateColumnFilter"}
      cellEditor={"dateEditor"}
      cellClass={"ag-cell-overflow-visible-on-edit"}
      filterParams={{
        comparator: utils.filterDateComparatorfunction, // comparator for filters
        inRangeInclusive: true
      }}
      comparator={utils.dateComparator} // comparator for sorting
    />,
    <AgGridColumn
      key={"order_number"}
      headerName={"YO#"}
      field={"order_number"}
      editable={false}
      cellClass={"noneditable"}
      width={150}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
    />,
    <AgGridColumn
      key={"price_per_lb"}
      headerName={"Price/LB"}
      field={"yarn_master_code.price_per_lb"}
      width={150}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      valueFormatter={(params: any) => {
        return utils.USDFormatter(params.value) || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"yarn_type_ref"}
      headerName={"Master Code"}
      field={"yarn_master_code.id"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      // const refValueA = yarnTypeIdMap.get(valueA)?.name || '';
      // const refValueB = yarnTypeIdMap.get(valueB)?.name || '';
      // return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      width={250}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      onCellValueChanged={(params: any) => {
        if (!params?.newValue) return;
        // get chosen yarn type from server
        // getYarnTypeById(params?.newValue).then(chosenYarnType => {
        rowModelServices.yarnTypes.getDataById(params?.newValue).then(chosenYarnType => {
          /* manually populate "connected fields" in the row using retrieved yarn type */
          params.data.yarn_master_code.price_per_lb = chosenYarnType?.price_per_lb || '';
          params.data.yarn_master_code.size = chosenYarnType?.size || '';
          params.data.yarn_master_code.description = chosenYarnType?.description || '';
          params.data.yarn_master_code.content = chosenYarnType?.content || '';
          params.data.yarn_master_code.color = chosenYarnType?.color || '';
          params.data.yarn_master_code.name = chosenYarnType?.name || '';
          params.data.yarn_master_code.price_per_kg = chosenYarnType?.price_per_kg || '';
          // update server with new data
          updatePurchaseOrder(params.data);
        });
      }}
      cellRendererParams={(rendererParams: any) => {
        return {
          deleteCellValue: (params: any) => {
            // perform deletion of ref button
            gridApiUtils.deleteReferenceButton(params, 'yarn_master_code', updatePurchaseOrder, true);
          },
          displayPath: 'yarn_master_code.name',
          gridComponent: (
            <YarnTypeGrid
              hideDefaultGrid={true}
              filterId={rendererParams?.data?.yarn_master_code?.id}
            />
          )
        }
      }}
      cellEditorParams={{
        // addDataService: addYarnType,
        addDataService: rowModelServices.yarnTypes.addData,
        nameKey: 'name',
        cacheKey: cacheKeys.yarnTypeData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.yarnType,
        // getBasicListHook: useGetYarnTypesBasic,
        getBasicListHook: useGetRowModelBasic<IYarnType>(routes.DEMO_MASTER_CODES, 'name'),
      }}
    // TODO implement server-side filter
    // filter={"customRefFilter"}
    // floatingFilterComponent={"customRefFloatingFilter"}
    // floatingFilterComponentParams={{suppressFilterButton: true}}
    // filterParams={{
    //   cacheKey: cacheKeys.yarnTypeData,
    //   filterData: yarnTypeData,
    //   nameKey: 'master_code',
    // }}
    />,
    <AgGridColumn
      key={"size"}
      headerName={"Size"}
      field={"yarn_master_code.size"}
      width={150}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
    />,
    <AgGridColumn
      key={"desc"}
      headerName={"Description"}
      field={"yarn_master_code.description"}
      width={150}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      // cellEditor={"autoCompleteEditor"}
      cellRenderer={"autoCompleteRenderer"}
      // cellEditorParams={{
      //   getDataHook: useGetDescriptions,
      //   addDataServiceHook: useAddDescription,
      //   nameKey: 'desc',
      //   cacheKey: cacheKeys.descriptions,
      // }}
    // singleClickEdit
    />,
    <AgGridColumn
      key={"content"}
      headerName={"Content"}
      field={"yarn_master_code.content"}
      width={200}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      // cellEditor={"autoCompleteEditor"}
      cellRenderer={"autoCompleteRenderer"}
      // cellEditorParams={{
      //   getDataHook: useGetContents,
      //   addDataServiceHook: useAddContent,
      //   nameKey: 'content',
      //   cacheKey: cacheKeys.contents,
      // }}
    // singleClickEdit
    />,
    <AgGridColumn
      key={"color"}
      headerName={"Color"}
      field={"yarn_master_code.color"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      // cellEditor={"autoCompleteEditor"}
      cellRenderer={"autoCompleteRenderer"}
      // cellEditorParams={{
      //   getDataHook: useGetYarnTypeColors,
      //   addDataServiceHook: useAddYarnTypeColor,
      //   nameKey: 'color',
      //   cacheKey: cacheKeys.yarn_type_colors,
      // }}
    />,
    <AgGridColumn
      key={"qty"}
      field={"quantity"}
      width={150}
      valueSetter={(params) => {
        return gridApiUtils.numberSetter(params, 'quantity');
      }}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"supplier_ref"}
      headerName={"Yarn Supplier"}
      field={"supplier.id"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = supplierIdMap.get(valueA)?.supplier_name || '';
      //   const refValueB = supplierIdMap.get(valueB)?.supplier_name || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      onCellValueChanged={(params: any) => {
        if (!params?.newValue) return;
        // get chosen yarn type from server
        rowModelServices.suppliers.getDataById(params?.newValue).then(chosenSupplier => {
          /* manually populate "connected fields" in the row using retrieved yarn type */
          params.data.supplier.name = chosenSupplier?.supplier_name || '';
          // update server with new data
          updatePurchaseOrder(params.data);
        });
      }}
      cellRendererParams={(rendererParams: any) => {
        return {
          deleteCellValue: (params: any) => {
            gridApiUtils.deleteReferenceButton(params, 'supplier', updatePurchaseOrder, true);
          },
          displayPath: 'supplier.name',
          gridComponent: (
            <SupplierGrid
              hideDefaultGrid={true}
              filterId={rendererParams?.data?.supplier?.id}
            />
          )
        }
      }}
      cellEditorParams={{
        // addDataService: addYarnType,
        addDataService: rowModelServices.suppliers.addData,
        nameKey: 'supplier_name',
        cacheKey: cacheKeys.supplierData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.supplier,
        // getBasicListHook: useGetYarnTypesBasic,
        getBasicListHook: useGetRowModelBasic<ISupplier>(routes.DEMO_SUPPLIERS, 'supplier_name'),
      }}
    // TODO implement server-side filter
    // filter={"customRefFilter"}
    // floatingFilterComponent={"customRefFloatingFilter"}
    // floatingFilterComponentParams={{suppressFilterButton: true}}
    // filterParams={{
    //   cacheKey: cacheKeys.supplierData,
    //   filterData: supplierData,
    //   nameKey: 'supplier_name',
    // }}
    />,
    <AgGridColumn
      key={"ship_by"}
      headerName={"Ship By"}
      field={"ship_by"}
      width={200}
      filter={"agDateColumnFilter"}
      cellEditor={"dateEditor"}
      cellClass={"ag-cell-overflow-visible-on-edit"}
      valueFormatter={gridApiUtils.dateFormatter}
      filterParams={{
        comparator: utils.filterDateComparatorfunction, // comparator for filters
        inRangeInclusive: true
      }}
      comparator={utils.dateComparator} // comparator for sorting
    />,
    <AgGridColumn
      key={"delivered"}
      headerName={"Delivered"}
      field={"delivered"}
      width={200}
      filter={"agDateColumnFilter"}
      cellEditor={"dateEditor"}
      cellClass={"ag-cell-overflow-visible-on-edit"}
      valueFormatter={gridApiUtils.dateFormatter}
      filterParams={{
        comparator: utils.filterDateComparatorfunction, // comparator for filters
        inRangeInclusive: true
      }}
      comparator={utils.dateComparator} // comparator for sorting
    />,
    <AgGridColumn
      key={"terms"}
      field={"terms"}
      // cellEditor={"autoCompleteEditor"}
      cellRenderer={"autoCompleteRenderer"}
      // cellEditorParams={{
      //   getDataHook: useGetTerms,
      //   addDataServiceHook: useAddTerm,
      //   nameKey: 'terms',
      //   cacheKey: cacheKeys.terms,
      // }}
      width={150}
      filter={"agTextColumnFilter"}
    // singleClickEdit
    />,
    <AgGridColumn
      key={"knitter_ref"}
      headerName={"Ship To Knitter"}
      field={"knitter.id"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = knitterIdMap.get(valueA)?.knitter_name || '';
      //   const refValueB = knitterIdMap.get(valueB)?.knitter_name || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      width={200}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      onCellValueChanged={(params: any) => {
        if (!params?.newValue) return;
        // get chosen yarn type from server
        rowModelServices.knitters.getDataById(params?.newValue).then(chosenknitter => {
          /* manually populate "connected fields" in the row using retrieved yarn type */
          params.data.knitter.name = chosenknitter?.knitter_name || '';
          // update server with new data
          updatePurchaseOrder(params.data);
        });
      }}
      cellRendererParams={(rendererParams: any) => {
        return {
          deleteCellValue: (params: any) => {
            // perform deletion of ref button
            gridApiUtils.deleteReferenceButton(params, 'knitter', updatePurchaseOrder, true);
          },
          displayPath: 'knitter.name',
          gridComponent: (
            <KnitterGrid
              hideDefaultGrid={true}
              filterId={rendererParams?.data?.knitter?.id}
            />
          )
        }
      }}
      cellEditorParams={{
        // addDataService: addYarnType,
        addDataService: rowModelServices.knitters.addData,
        nameKey: 'knitter_name',
        cacheKey: cacheKeys.knitterData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.knitter,
        // getBasicListHook: useGetYarnTypesBasic,
        getBasicListHook: useGetRowModelBasic<IKnitter>(routes.DEMO_KNITTERS, 'knitter_name'),
      }}
    // TODO implement server-side filter
    // filter={"customRefFilter"}
    // floatingFilterComponent={"customRefFloatingFilter"}
    // floatingFilterComponentParams={{suppressFilterButton: true}}
    // filterParams={{
    //   cacheKey: cacheKeys.knitterData,
    //   filterData: knitterData,
    //   nameKey: 'knitter_name',
    // }}
    />,
    <AgGridColumn
      key={"maker_ref"}
      headerName={"Yarn Maker"}
      field={"maker.id"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = makerIdMap.get(valueA)?.maker_name || '';
      //   const refValueB = makerIdMap.get(valueB)?.maker_name || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      onCellValueChanged={(params: any) => {
        if (!params?.newValue) return;
        // get chosen yarn type from server
        rowModelServices.makers.getDataById(params?.newValue).then(chosenMaker => {
          /* manually populate "connected fields" in the row using retrieved yarn type */
          params.data.maker.name = chosenMaker?.maker_name || '';
          // update server with new data
          updatePurchaseOrder(params.data);
        });
      }}
      cellRendererParams={(rendererParams: any) => {
        return {
          deleteCellValue: (params: any) => {
            gridApiUtils.deleteReferenceButton(params, 'maker', updatePurchaseOrder, true);
          },
          displayPath: 'maker.name',
          gridComponent: (
            <MakerGrid
              hideDefaultGrid={true}
              filterId={rendererParams?.data?.maker?.id}
            />
          )
        }
      }}
      cellEditorParams={{
        // addDataService: addYarnType,
        addDataService: rowModelServices.makers.addData,
        nameKey: 'maker_name',
        cacheKey: cacheKeys.makerData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.maker,
        // getBasicListHook: useGetYarnTypesBasic,
        getBasicListHook: useGetRowModelBasic<IMaker>(routes.DEMO_MAKERS, 'maker_name'),
      }}
    // TODO implement server-side filter
    // filter={"customRefFilter"}
    // floatingFilterComponent={"customRefFloatingFilter"}
    // floatingFilterComponentParams={{suppressFilterButton: true}}
    // filterParams={{
    //   cacheKey: cacheKeys.makerData,
    //   filterData: makerData,
    //   nameKey: 'maker_name',
    // }}
    />,
    <AgGridColumn
      key={"lot_number"}
      headerName={"Lot #"}
      field={"lot_number"}
      filter={"agTextColumnFilter"}
      // cellEditor={"autoCompleteEditor"}
      cellRenderer={"autoCompleteRenderer"}
      // cellEditorParams={{
      //   getDataHook: useGetLotNumbers,
      //   addDataServiceHook: useAddLotNumber,
      //   nameKey: 'lot_number',
      //   cacheKey: cacheKeys.lot_numbers,
      // }}
    />
  ];

  return { colArray };
};
