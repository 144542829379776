import {IDataPaged} from "../../types/rowModelTypes";
import axios from "axios";
import {routes} from "../../constants/allConsts";
import {userUtils} from "../../utils/userUtils";

export class RowModelServices<T> {
    private _apiRoute: string = "";
    constructor(apiRoute: string) {
        this._apiRoute = apiRoute;
    };

    public set apiRoute(route: string) {
        this._apiRoute = route;
    }

    public  getDataPaged = async (page: number = 0, offset: string | null): Promise<IDataPaged<T>[]  | {}> => {

        const accessToken = await userUtils.getAccessToken();
        const fetchUrl = `${this._apiRoute}`;
        try {
          const data = await axios.get(fetchUrl, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': accessToken,
            },
            params: {
              page: page,
              offset: offset,
            }
          })
            .then(res => res?.data)
            .then(res => {
              return res.data;
            });
          return data;
        } catch (error: any) {
          console.log(`error fetching paged data in ${typeof(this)} `, error);
          return {};
        }

    };

    public getData = async (): Promise<T[] | {}> => {

        try {
            const accessToken = await userUtils.getAccessToken();
            // const fetchUrl = `${routes.API_ROOT}/purchase-orders`; // mock server
            const fetchUrl = `${this._apiRoute}`;
            const data = await axios.get(fetchUrl, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken,
              },
              params: {
                pageSize: 10000,
              }
            })
            // .then(res => res?.data?.data) // mock server
              .then(res => res?.data)
              .then(res => {
                if (res.status === 'success') {
                  return res?.data?.data;
                }
                return res?.data;
              })
              .catch(err => {
                console.error(`Error fetching data in ${typeof(this)}: `, err?.response?.data);
                throw Error(err.response);
              });
            return data;
          } catch (error: any) {
            console.log(`error fetching data: ${typeof(this)} `, error);
            return {};
          }

    };

    public getDataBasic = async (nameKeys: string[] = []) => {
        try {
          const accessToken = await userUtils.getAccessToken();
          const fetchUrl = `${this._apiRoute}/basic-list`;
          const nameParams = nameKeys.reduce((prev, current) => ({ ...prev, [current]: true}), {});
          const data = await axios.get(fetchUrl, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': accessToken,
            },
            params: {
              ...nameParams,
            }
          })
            .then(res => res?.data?.data)
            .catch(err => {
              console.error(`Error fetching basic data ${typeof(this)} `, err?.response?.data);
              throw Error(err.response);
            });
          return data;
        } catch (error: any) {
          console.log(`error fetching:: ${typeof(this)}`, error);
        }
    };

    public getDataById = async (id: string) => {
        try {
          const accessToken = await userUtils.getAccessToken();
          const fetchUrl = `${this._apiRoute}/by-id`;
          const data = await axios.get(fetchUrl, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': accessToken,
            },
            params: {
              id: id,
            }
          })
            .then(res => res?.data?.data)
            .catch(err => {
              console.error(`Error fetching data byb id: ${typeof(this)} `, err?.response?.data);
              throw Error(err.response);
            });
          return data;
        } catch (error: any) {
          console.log(`error fetching:: ${typeof(this)}`, error);
        }
    };
      
    public updateData = async (rowData: T) => {
        try {
          const accessToken = await userUtils.getAccessToken();
          // const apiURL = `${routes.API_ROOT}/purchase-orders`; // mock server
          const apiURL = `${this._apiRoute}`;
          const requestBody = rowData;
          // using post because the endpoint for updating and adding are now the same
          const data = axios.post(apiURL, requestBody, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': accessToken,
            },
          })
            .then((response) => response?.data?.data)
            .catch((error) => {
              console.error(`Error publishing Data: ${typeof(this)}`, error.response.data);
              throw error.response.data;
            });
          return data;
        } catch (e) {
          console.log(`Error ${typeof(this)}`, e);
        }
    };
      
    public addData = async (rowData: T) => {
        try {
          const accessToken = await userUtils.getAccessToken();
          // const apiURL = `${routes.API_ROOT}/purchase-orders`; // mock server
          const apiURL = `${this._apiRoute}`;
          const requestBody = rowData;
          const data = await axios.post(apiURL, requestBody, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': accessToken,
            },
          })
            .then((response) => response?.data?.data)
            .catch((error) => {
              console.error(`Error publishing Data: ${typeof(this)}`, error.response.data);
              throw error.response.data;
            });
          return data;
        } catch (e) {
          console.log(`Error: ${typeof(this)}`, e);
        }
    };
      
    public deleteData = async (rowData: T) => {
        try {
          const accessToken = await userUtils.getAccessToken();
          // const apiURL = `${routes.API_ROOT}/purchase-orders`; // mock server
          const apiURL = `${this._apiRoute}`;
          const requestBody = rowData;
          const data = axios.delete(apiURL, {
            data: requestBody,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': accessToken,
            },
          })
            .then((response) => response?.data?.data)
            .catch((error) => {
              console.error(`Error deleting Data: ${typeof(this)} `, error.response.data);
              throw error.response.data;
            });
          return data;
        } catch (e) {
          console.log(`Error: ${typeof(this)}`, e);
        }
    };
    
}