import React, {useEffect, useState} from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {AlpineContainer} from "../containers/defaultAlpineContainer";
import {contextMenuItems} from "../agContextMenu";
import {gridApiUtils} from "../../utils/gridApiUtils";
import {cacheKeys} from "../../constants/allConsts";
import {emptyObjectGenerator} from "../emptyObjects";
import {ButtonBar} from "../buttonBar";
import {useQueryClient} from "react-query";
import {RowEditorModal} from "../modals/rowEditorModal";
import {FirstColRenderer} from "../agFrameworkComponents/renderers/firstColRenderer";
import {AutoCompleteReferenceEditor} from "../agFrameworkComponents/editors/autoCompleteRefEditor";
import {AutoCompleteReferenceRenderer} from "../agFrameworkComponents/renderers/autoCompleteRefRenderer";
import {CustomRefFloatingFilter} from "../agFrameworkComponents/filters/customRefFloatingFilter";
import {CustomRefFilter} from "../agFrameworkComponents/filters/customRefFilter";
import {DateEditor} from "../agFrameworkComponents/editors/dateEditor";
import {
  useAddAdjustment,
  useDeleteAdjustment, useGetAdjustments,
  useUpdateAdjustment
} from "../../hooks/rowModels/useAdjustments";
import {GridApi} from "ag-grid-community";
import {useAdjustmentGroup1} from "../../hooks/gridFragments/adjustments/useAdjustmentGroup1";
import {useAdjustmentGroup2} from "../../hooks/gridFragments/adjustments/useAdjustmentGroup2";
import {AdjustmentSectionedGrid} from "./customPopupGrids/adjustmentSectionedGrid/adjustmentSectionedGrid";

export const AdjustmentGrid = (props: { hideDefaultGrid?: boolean, filterId?: string | null, gridApi?: GridApi | null }): JSX.Element => {
  const [gridApi, setGridApi] = useState(null);
  const [parentGridApi] = useState(props?.gridApi || null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [serverSideDataSource, setServerSideDataSource] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedRowFilterId, setExpandedRowFilterId] = useState<string>('');
  const [isDataDisplayed, setIsDataDisplayed] = useState<boolean>(false);

  const {data, isLoading} = useGetAdjustments();

  const {mutate: addAdjustment} = useAddAdjustment();
  const {mutate: updateAdjustment} = useUpdateAdjustment();
  const {mutate: deleteAdjustment} = useDeleteAdjustment();

  const queryClient = useQueryClient();

  const expandRow = (rowId: string) => {
    setExpandedRowFilterId(rowId);
    setIsModalOpen(true);
  };

  const {colArray: group1cols} = useAdjustmentGroup1({
    expandRow: expandRow,
    gridApi: gridApi
  });
  const {colArray: group2cols} = useAdjustmentGroup2({
    doSkipId: true,
    gridApi: gridApi
  });

  useEffect(() => {
    // Load only once on mount to populate grid. Allow FE to take care of updates optimistically
    if (!isDataDisplayed && !isLoading) {
      // TODO get rid of emptyObjectGenerator. Get empty row from server instead (the grid needs at least 1 row for user to right click to add new rows)
      //@ts-ignore
      setServerSideDataSource(gridApiUtils.setServerSideDatasource(!isLoading, (Array.isArray(data) && data?.length > 0) ? data : [emptyObjectGenerator.adjustment()]));
      setIsDataDisplayed(true);
    }
  }, [isLoading]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    if (props?.filterId) {
      var filterModel = {
        id: {
          filter: props.filterId,
          filterType: "text",
          type: "contains"
        }
      };
      params.api.setFilterModel(filterModel);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setExpandedRowFilterId('');
  };

  const onCellValueChanged = (params: any) => {
    // update server
    updateAdjustment(params?.data);
    gridApiUtils.updatePopupTransactionUpdates(parentGridApi, gridApi, props?.filterId);
  };

  return (
    <AlpineContainer hideDefaultGrid={props?.hideDefaultGrid}>
      <ButtonBar
        addRowTitle="Add Adjustment"
        cacheKey={cacheKeys.adjustmentData}
        isAllDataLoaded={!isLoading}
        hideAddButton
        addRowEmptyObject={() => {
          // TODO get emptyObject from server instead of making it client-side
          const emptyObject = emptyObjectGenerator.adjustment();
          // invalidate queries to refetch data from server
          addAdjustment(emptyObject, {
            onSuccess: () => {
              queryClient.invalidateQueries([cacheKeys.adjustmentData]);
            }
          });
          return emptyObject;
        }}
        // pass in gridApi for buttonBar to manipulate the grid
        gridApi={gridApi}
        gridComponent={
          <AdjustmentSectionedGrid
            gridApi={gridApi}
            hideDefaultGrid={true}
          />
        }
      >
      </ButtonBar>
      <AgGridReact
        defaultColDef={{
          resizable: true,
          editable: true,
          floatingFilter: true,
          filter: true,
          sortable: true,
          filterParams: {
            debounceMs: 100
          }
        }}
        enableRangeSelection={!props?.filterId}
        // enableFillHandle={!props?.filterId}
        // fillHandleDirection={'y'}
        // fillOperation={gridApiUtils.setFillOperation}
        // onFillEnd={gridApiUtils.fillEnd}
        getRowNodeId={(data) => {
          return data.id;
        }}
        // turn off column virtualization if grid is displayed in a rowEditorModal (otherwise, not all the columns will show)
        suppressColumnVirtualisation={!!props?.filterId}
        // allow single-edit if grid is displayed in a rowEditorModal
        singleClickEdit={!!props?.filterId}
        suppressRowClickSelection={true}
        rowSelection={'multiple'}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={50}
        animateRows={true}
        rowModelType={'serverSide'}
        serverSideDatasource={serverSideDataSource}
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        suppressMenuHide={!!props?.hideDefaultGrid}
        //@ts-ignore
        getContextMenuItems={(params) => props?.hideDefaultGrid ? [] : contextMenuItems({
          cacheKey: cacheKeys.adjustmentData,
          queryClient: queryClient,
          expandRow: expandRow,
          params,
          // TODO make empty object serverside
          makeEmptyObject: emptyObjectGenerator.adjustment,
          addRowServerCall: addAdjustment,
          deleteRowServerCall: deleteAdjustment,
          rowDescription: 'adjustment',
          disableDuplication: true,
          preventRowEdits: true,
        })}
        stopEditingWhenCellsLoseFocus={true}
        frameworkComponents={{
          firstColRenderer: FirstColRenderer,
          customRefFilter: CustomRefFilter,
          customRefFloatingFilter: CustomRefFloatingFilter,
          autoCompleteReferenceEditor: AutoCompleteReferenceEditor,
          autoCompleteReferenceRenderer: AutoCompleteReferenceRenderer,
          dateEditor: DateEditor,
        }}
      >
        {group1cols}
        {group2cols}
      </AgGridReact>
      <RowEditorModal
        open={isModalOpen}
        close={closeModal}
        title={"Edit Adjustment"}
        gridComponent={
          <AdjustmentSectionedGrid
            hideDefaultGrid={true}
            filterId={expandedRowFilterId}
            gridApi={gridApi}
          />
        }
      />
    </AlpineContainer>
  );
};
