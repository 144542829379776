import React, {forwardRef, useImperativeHandle, useState} from "react";

const AutoCompleteRenderer = forwardRef((props: {value: string}, ref: any) => {
  const [selected, setSelected] = useState(props?.value || '');

  useImperativeHandle(ref, () => {
    return {
      refresh(params: any) {
        setSelected(params?.value || '');
      }
    };
  });
  return (
    <span>{selected ? `${selected}` : ''}</span>
  );
});

export {AutoCompleteRenderer};