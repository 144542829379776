export const routes = {
  API_ROOT: 'http://localhost:3000/api',
  // API_ROOT: 'http://35.212.148.69:3000/api',
  DEMO_ROOT: 'https://api.laa.mightybyte.us',
  get DEMO_API_ROOT() { return `${routes.DEMO_ROOT}/api` },
  get DEMO_USERS() { return `${routes.DEMO_API_ROOT}/users` },
  get DEMO_MASTER_CODES() { return `${routes.DEMO_API_ROOT}/yarn-master-codes`},
  get DEMO_YARN_POS() { return `${routes.DEMO_API_ROOT}/yarn-pos`},
  get DEMO_LOTS() { return `${routes.DEMO_API_ROOT}/lots`},
  get DEMO_KNITTERS() { return `${routes.DEMO_API_ROOT}/knitters`},
  get DEMO_SUPPLIERS() { return `${routes.DEMO_API_ROOT}/suppliers`},
  get DEMO_DYERS() { return `${routes.DEMO_API_ROOT}/dye-houses`},
  get DEMO_LOCATIONS() { return `${routes.DEMO_API_ROOT}/locations`},
  get DEMO_MAKERS() { return `${routes.DEMO_API_ROOT}/makers`},
  get DEMO_VENDORS() { return `${routes.DEMO_API_ROOT}/vendors`},
  get DEMO_STYLES() { return `${routes.DEMO_API_ROOT}/styles`},
  get DEMO_COLORS() { return `${routes.DEMO_API_ROOT}/colors`},
};
