import React from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import styled from "styled-components";
import {GridApi} from "ag-grid-community";
import {RollFragmentLoader} from "./rollFragmentLoader";
import {useRollGroup1} from "../../../../hooks/gridFragments/rollGrid/useRollGridGroup1";
import {useRollGroup3} from "../../../../hooks/gridFragments/rollGrid/useRollGridGroup3";
import {useRollGroup2} from "../../../../hooks/gridFragments/rollGrid/useRollGridGroup2";

export const RollSectionedGrid = React.forwardRef((props: {
  expandRow?: any,
  lockInLbPerYd?: any,
  openRollAdjustments?: any,
  hideDefaultGrid?: boolean,
  updateColorsByCrossReferencingVendorandCode?: any,
  processCellForClipboard?: (params: any) => void,
  filterId?: string,
  gridApi?: GridApi | null,
}, ref): JSX.Element => {
  // TODO implement and pass to rolls fragment loader (see purchaseORderSectionedGrid for example)
  // const {data} = useGetRollss({id: props?.filterId});
  return (
    <>
      <StyledSectionedGroup className={"group1"} groupName={"Group 1"}>
        <RollFragmentLoader
          {...props}
          getGridFragmentHook={useRollGroup1}
        />
      </StyledSectionedGroup>
      <StyledSectionedGroup className={"group2"} groupName={"Group 2"}>
        <RollFragmentLoader
          {...props}
          getGridFragmentHook={useRollGroup2}
        />
      </StyledSectionedGroup>
      <StyledSectionedGroup className={"group3"} groupName={"Group 3"}>
        <RollFragmentLoader
          {...props}
          getGridFragmentHook={useRollGroup3}
        />
      </StyledSectionedGroup>
    </>
  );
});

interface ISectionedGroup {
  groupName: string;
};

const StyledSectionedGroup = styled.div<ISectionedGroup>`
  padding: 15px;
  position: relative;
  margin-top: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  &:nth-child(1) {
    margin-top: 1rem;
  }
  &:before {
    content: ${(props) => `'${props.groupName}'`};
    position: absolute;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    top: -0.8rem;
    background: white;
    padding: 0 12px;
    left: 12px;
    color: #2D2D2D;
    border-radius: 4px;
   }
`;
