import axios from "axios";
import {routes} from "../../constants/allConsts";
import {IColor} from "../../types/rowModelTypes";
import {userUtils} from "../../utils/userUtils";

// TODO implement get colors paged (see purchaseOrderServices.ts for example)

export const getColors = async (): Promise<IColor[] | {}> => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const fetchUrl = `${routes.API_ROOT}/colors`;
    const fetchUrl = `${routes.DEMO_COLORS}`;
    const data = await axios.get(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        pageSize: 10000,
      }
    })
    // .then(res => res?.data?.data) // mock server
      .then(res => res?.data)
      .then(res => {
        if (res.status === 'success') {
          return res?.data?.data;
        }
        return res?.data;
      })
      .catch(err => {
        console.error("Error fetching colors: ", err?.response?.data);
        throw Error(err.response);
      });
    return data;
  } catch (error: any) {
    console.log("error fetching:: ", error);
    return {};
  }
};

// TODO write function for getting adjustments basic list (see purchaseOrderServices.ts for example)

export const getColorId = async (id: string) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    const fetchUrl = `${routes.DEMO_COLORS}/by-id`;
    const data = await axios.get(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        id: id,
      }
    })
      .then(res => res?.data?.data)
      .catch(err => {
        console.error("Error fetching color: ", err?.response?.data);
        throw Error(err.response);
      });
    return data;
  } catch (error: any) {
    console.log("error fetching:: ", error);
  }
};

export const updateColor = async (color: IColor) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const apiURL = `${routes.API_ROOT}/colors`;
    const apiURL = `${routes.DEMO_COLORS}`;
    const requestBody = color;
    // using post because the endpoint for updating and adding are now the same
    const data = axios.post(apiURL, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing color: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};

export const addColor = async (color: IColor) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const apiURL = `${routes.API_ROOT}/colors`;
    const apiURL = `${routes.DEMO_COLORS}`;
    const requestBody = color;
    const data = axios.post(apiURL, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing color: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};

export const deleteColor = async (color: IColor) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const apiURL = `${routes.API_ROOT}/colors`;
    const apiURL = `${routes.DEMO_COLORS}`;
    const requestBody = color;
    const data = axios.delete(apiURL, {
      data: requestBody,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing color: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};
