import {useMutation, useQuery} from "react-query";
import {cacheKeys} from "../../constants/allConsts";
import {ILocation} from "../../types/rowModelTypes";
import {addLocation, deleteLocation, getLocations, updateLocation} from "../../services/rowModels/locationServices";

// TODO extend useGetLocationss to be able to use getLocationsById once it's implemented (see usePurchaseOrders.ts for example)
export const useGetLocations = (): any => {
  const result = useQuery({
    queryKey: [cacheKeys.locationData],
    queryFn: () => getLocations(),
  });
  return result;
};

// TODO implement useGetLocationsBasic (see usePurchaseorders.ts for example)

export const useAddLocation = () => {
  const result = useMutation(async (update: ILocation) => {
    return await addLocation(update);
  });
  return result;
};

export const useUpdateLocation = () => {
  const result = useMutation(async (update: ILocation) => {
    return await updateLocation(update);
  });
  return result;
};

export const useDeleteLocation = () => {
  const result = useMutation(async (update: ILocation) => {
    return await deleteLocation(update);
  });
  return result;
};
