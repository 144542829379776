import {AgGridColumn} from "ag-grid-react";
import {utils} from "../../../utils/utils";
import {gridApiUtils} from "../../../utils/gridApiUtils";
import {cacheKeys} from "../../../constants/cacheKeys";
import {emptyObjectGenerator} from "../../../components/emptyObjects";
import React from "react";
import {useAddLotNumber, useGetLotNumbers} from "../../selections/useLotNumbers";
import {GridApi} from "ag-grid-community";
import {useGetRolls} from "../../rowModels/useRolls";
import {useAddColor, useGetColors} from "../../rowModels/useColors";
import {useAddStyle, useGetStyles} from "../../rowModels/useStyles";
import {StyleGrid} from "../../../components/grids/styleGrid";
import {ColorGrid} from "../../../components/grids/colorGrid";

export const useRollGroup1 = (props: {
  doSkipId?: boolean,
  expandRow?: any,
  gridApi?: GridApi | null,
  hideDefaultGrid?: boolean,
  lockInLbPerYd?: any,
  openRollAdjustments?: any,
  updateColorsByCrossReferencingVendorandCode?: any,
}): {colArray: JSX.Element[]} => {
  const {mutate: addColor} = useAddColor();

  const {mutate: addStyle} = useAddStyle();

  const colArray = [
    ...(!props?.doSkipId ? [<AgGridColumn
      field={"id"}
      lockPosition
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={'suppress-fill-handle'}
      suppressColumnsToolPanel={true}
      hide
      key={"id"}
    />] : []),
    <AgGridColumn
      key={"lots_ref"}
      // reference to lots row id in case someone tries to duplicate, add, or delete roll
      field={"lots_ref"}
      lockPosition
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={'suppress-fill-handle'}
      suppressColumnsToolPanel={true}
      hide
    />,
    <AgGridColumn
      key={"date"}
      headerName={"Date"}
      field={"date"}
      width={200}
      cellRenderer={"firstColRenderer"}
      checkboxSelection={!props?.hideDefaultGrid}
      cellRendererParams={{expandRow: props?.expandRow}}
      lockPosition
      // TODO implement server-side filter
      filter={"agDateColumnFilter"}
      cellEditor={"dateEditor"}
      cellClass={"ag-cell-overflow-visible-on-edit noneditable"}
      editable={false}
      filterParams={{
        comparator: utils.filterDateComparatorfunction, // comparator for filters
        inRangeInclusive: true
      }}
      comparator={utils.dateComparator} // comparator for sorting
    />,
    <AgGridColumn
      key={"lot_number"}
      headerName={"Lot #"}
      field={"lot_number"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      // cellEditor={"autoCompleteEditor"}
      cellRenderer={"autoCompleteRenderer"}
      editable={false}
      cellClass={"noneditable"}
      // cellEditorParams={{
      //   getDataHook: useGetLotNumbers,
      //   addDataServiceHook: useAddLotNumber,
      //   nameKey: 'lot_number',
      //   cacheKey: cacheKeys.lot_numbers,
      // }}
      // singleClickEdit
    />,
    <AgGridColumn
      key={"style_ref"}
      headerName={"Style"}
      field={"style_ref"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = styleIdMap.get(valueA)?.style_name || '';
      //   const refValueB = styleIdMap.get(valueB)?.style_name || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      cellRendererParams={(rendererParams: any) => {
        return {
          deleteCellValue: (params: any) => {
            // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
          },
          gridComponent: (
            <StyleGrid
              hideDefaultGrid={true}
              filterId={rendererParams.data.style_ref}
            />
          )
        }
      }}
      cellEditorParams={{
        addDataService: addStyle,
        nameKey: 'style_name',
        cacheKey: cacheKeys.styleData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.style,
        // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
      }}
      // TODO implement server-side filter
      // filter={"customRefFilter"}
      // floatingFilterComponent={"customRefFloatingFilter"}
      // floatingFilterComponentParams={{suppressFilterButton: true}}
      // filterParams={{
      //   cacheKey: cacheKeys.styleData,
      //   filterData: styleData,
      //   nameKey: 'style_name',
      // }}
    />,
    <AgGridColumn
      key={"color_code_ref"}
      headerName={"Color Code"}
      field={"color_code_ref"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = colorIdMap.get(valueA)?.color_code || '';
      //   const refValueB = colorIdMap.get(valueB)?.color_code || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      cellRendererParams={(rendererParams: any) => {
        // TODO get getChosenColor from paginated data retrieved from server instead of colorIdMap
        // const chosenColor = colorIdMap.get(rendererParams.data.color_code_ref);
        return {
          deleteCellValue: (params: any) => {
            // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
          },
          gridComponent: (
            <ColorGrid
              hideDefaultGrid={true}
              // TODO get filterId from new chosenColor
              // filterId={chosenColor?.id}
            />
          )
        }
      }}
      cellEditorParams={{
        addDataService: addColor,
        nameKey: 'color_code',
        cacheKey: cacheKeys.colorData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.color,
        // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
      }}
      onCellValueChanged={props?.updateColorsByCrossReferencingVendorandCode}
      // TODO implement server-side filter
      // filter={"customRefFilter"}
      // floatingFilterComponent={"customRefFloatingFilter"}
      // floatingFilterComponentParams={{suppressFilterButton: true}}
      // filterParams={{
      //   cacheKey: cacheKeys.colorData,
      //   filterData: colorData,
      //   nameKey: 'color_code',
      // }}
    />,
    <AgGridColumn
      key={"roll_number"}
      headerName={"Roll #"}
      field={"roll_number"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
    />,
    <AgGridColumn
      key={"yd"}
      headerName={"Yard"}
      field={"yd"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      onCellValueChanged={(params: any) => {
        if (params.oldValue === "") {
          params.data.yd_og = params.newValue;
        }
        props?.lockInLbPerYd(params);
      }}
      cellEditor={"rollAdjustmentsEditor"}
      cellEditorParams={(rendererParams: any) => {
        return {
          openRollAdjustments: () => props?.openRollAdjustments(rendererParams?.data?.id),
        };
      }}
    />
  ];

  return {colArray};
};
