import {AgGridColumn} from "ag-grid-react";
import React from "react";
import {GridApi} from "ag-grid-community";

export const useFinishedGoodsGroup5 = (props: {
  doSkipId?: boolean,
  expandRow?: any,
  gridApi?: GridApi | null,
  hideDefaultGrid?: boolean,
}): {colArray: JSX.Element[]} => {

  const colArray = [
    ...(!props?.doSkipId ? [<AgGridColumn
      field={"id"}
      lockPosition
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={'suppress-fill-handle'}
      suppressColumnsToolPanel={true}
      hide
      key={"id"}
    />] : []),
    <AgGridColumn
      key={"customer_number"}
      headerName={"Customer #"}
      field={"customer_number"}
      width={150}
      editable={false}
      cellClass={"noneditable"}
    />,
    <AgGridColumn
      key={"customer_name"}
      headerName={"Customer Name"}
      field={"customer_name"}
      editable={false}
      cellClass={"noneditable"}
    />,
    <AgGridColumn
      key={"work_order_number"}
      headerName={"Work Order #"}
      field={"work_order_number"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
    />
  ];

  return {colArray};
};
