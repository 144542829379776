import {AgGridColumn} from "ag-grid-react";
import {utils} from "../../../utils/utils";
import {gridApiUtils} from "../../../utils/gridApiUtils";
import {cacheKeys} from "../../../constants/cacheKeys";
import {emptyObjectGenerator} from "../../../components/emptyObjects";
import React from "react";
import {GridApi} from "ag-grid-community";
import {useAddColor} from "../../rowModels/useColors";
import {useAddStyle} from "../../rowModels/useStyles";
import {useUpdateRoll} from "../../rowModels/useRolls";
import {StyleGrid} from "../../../components/grids/styleGrid";
import {useQueryClient} from "react-query";
import {ColorGrid} from "../../../components/grids/colorGrid";


export const useFinishedGoodsGroup2 = (props: {
  doSkipId?: boolean,
  expandRow?: any,
  gridApi?: GridApi | null,
  updateColorsByCrossReferencingVendorandCode: any,
  hideDefaultGrid?: boolean,
}): {colArray: JSX.Element[]} => {

  const {mutate: updateRoll} = useUpdateRoll();

  const {mutate: addColor} = useAddColor();
  const {mutate: addStyle} = useAddStyle();

  const queryClient = useQueryClient();

  const colArray = [
    ...(!props?.doSkipId ? [<AgGridColumn
      field={"id"}
      lockPosition
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={'suppress-fill-handle'}
      suppressColumnsToolPanel={true}
      hide
      key={"id"}
    />] : []),
    <AgGridColumn
      key={"style_ref"}
      headerName={"Style"}
      field={"style_ref"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = styleIdMap.get(valueA)?.style_name || '';
      //   const refValueB = styleIdMap.get(valueB)?.style_name || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      cellRendererParams={(rendererParams: any) => {
        return {
          deleteCellValue: (params: any) => {
            // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
          },
          gridComponent: (
            <StyleGrid
              hideDefaultGrid={true}
              filterId={rendererParams.data.style_ref}
            />
          )
        }
      }}
      cellEditorParams={{
        addDataService: addStyle,
        nameKey: 'style_name',
        cacheKey: cacheKeys.styleData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.style,
        // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
      }}
      onCellValueChanged={(params: any) => {
        // TODO implement update rolls in lot
        // const chosenStyle = styleData?.filter((style: IStyle) => style.id === params.data.style_ref)[0];
        // params.data.desc = chosenStyle.desc;
        // params.data.finished_width = chosenStyle.finished_width;
        // params.api.redrawRows({rowNodes: [params.node]});
        // const rollsInLot = rollData?.filter((roll: IRoll) => roll.lots_ref === params.data.id);
        // if (rollsInLot) {
        //   rollsInLot.forEach((roll: IRoll, index: number) => {
        //     // TODO get rid of timeout once real server is implemented
        //     setTimeout(() => {
        //       roll.style_ref = chosenStyle.id;
        //       // TODO bring out of foreach once batch updates are implemented
        //       updateRoll(roll, {
        //         onSuccess: () => {
        //           if (index === rollsInLot.length - 1) {
        //             queryClient.invalidateQueries(cacheKeys.rollData);
        //           }
        //         }
        //       });
        //     }, index * 200);
        //   });
        // }
      }}
      // TODO implement server-side filter
      // filter={"customRefFilter"}
      // floatingFilterComponent={"customRefFloatingFilter"}
      // floatingFilterComponentParams={{suppressFilterButton: true}}
      // filterParams={{
      //   cacheKey: cacheKeys.styleData,
      //   filterData: styleData,
      //   nameKey: 'style_name',
      // }}
    />,
    <AgGridColumn
      key={"desc"}
      headerName={"Description"}
      field={"desc"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params: any) => {
        // TODO rewrite so style is not retrieved from styleIdMap
        // const style = styleIdMap.get(params.data.style_ref);
        // if (style) {
        //   return style.desc;
        // }
        return '';
      }}
      width={300}
    />,
    <AgGridColumn
      key={"finished_width"}
      headerName={"Finished Width"}
      field={"finished_width"}
      editable={false}
      cellClass={"noneditable"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      valueGetter={(params: any) => {
        // TODO rewrite so style is not retrieved from styleIdMap
        // const style = styleIdMap.get(params.data.style_ref);
        // if (style) {
        //   return style.finished_width;
        // }
        return 0;
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"laa_color_ref"}
      headerName={"LAA Color"}
      field={"laa_color_ref"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = colorIdMap.get(valueA)?.laa_color || '';
      //   const refValueB = colorIdMap.get(valueB)?.laa_color || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      cellRendererParams={(rendererParams: any) => {
        // TODO get chosenColor from server instead of colorIdMap
        // const chosenColor = colorIdMap.get(rendererParams.data.laa_color_ref);
        return {
          deleteCellValue: (params: any) => {
            // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
          },
          gridComponent: (
            <ColorGrid
              hideDefaultGrid={true}
              // TODO get chosenColor id
              // filterId={chosenColor?.id}
            />
          )
        }
      }}
      cellEditorParams={{
        addDataService: addColor,
        nameKey: 'laa_color',
        cacheKey: cacheKeys.colorData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.color,
        // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
      }}
      onCellValueChanged={(params: any) => {
        // TODO implement update rolls in lot
        // const rollsInLot = rollData.filter((roll: IRoll) => roll.lots_ref === params.data.id);
        // rollsInLot.forEach((roll: IRoll) => {
        //   roll.laa_color_ref = params?.data?.laa_color_ref;
        //   // TODO bring out of foreach once batch updates are implemented
        //   updateRoll(roll, {
        //     onSuccess: () => {
        //       queryClient.invalidateQueries(cacheKeys.rollData);
        //     }
        //   });
        // });
      }}
      // TODO implement server-side filter
      // filter={"customRefFilter"}
      // floatingFilterComponent={"customRefFloatingFilter"}
      // floatingFilterComponentParams={{suppressFilterButton: true}}
      // filterParams={{
      //   cacheKey: cacheKeys.colorData,
      //   filterData: colorData,
      //   nameKey: 'laa_color',
      // }}
    />,
    <AgGridColumn
      key={"vendor_color_ref"}
      headerName={"Vendor Color"}
      field={"vendor_color_ref"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = colorIdMap.get(valueA)?.vendor_color || '';
      //   const refValueB = colorIdMap.get(valueB)?.vendor_color || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      cellRendererParams={(rendererParams: any) => {
        // TODO get chosenColor from server instead of colorIdMap
        // const chosenColor = colorIdMap.get(rendererParams.data.vendor_color_ref);
        return {
          deleteCellValue: (params: any) => {
            // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
          },
          gridComponent: (
            <ColorGrid
              hideDefaultGrid={true}
              // TODO get chosenColor
              // filterId={chosenColor?.id}
            />
          )
        }
      }}
      cellEditorParams={{
        addDataService: addColor,
        nameKey: 'vendor_color',
        cacheKey: cacheKeys.colorData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.color,
        // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
      }}
      onCellValueChanged={(params: any) => {
        // TODO implement update rolls in lot
        // const rollsInLot = rollData.filter((roll: IRoll) => roll.lots_ref === params.data.id);
        // rollsInLot.forEach((roll: IRoll) => {
        //   roll.vendor_color_ref = params?.data?.vendor_color_ref;
        //   // TODO bring out of foreach once batch updates are implemented
        //   updateRoll(roll, {
        //     onSuccess: () => {
        //       queryClient.invalidateQueries(cacheKeys.rollData);
        //     }
        //   });
        // });
      }}
      // TODO implement server-side filter
      // filter={"customRefFilter"}
      // floatingFilterComponent={"customRefFloatingFilter"}
      // floatingFilterComponentParams={{suppressFilterButton: true}}
      // filterParams={{
      //   cacheKey: cacheKeys.colorData,
      //   filterData: colorData,
      //   nameKey: 'vendor_color',
      // }}
    />,
    <AgGridColumn
      key={"color_code_ref"}
      headerName={"Color Code"}
      field={"color_code_ref"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = colorIdMap.get(valueA)?.color_code || '';
      //   const refValueB = colorIdMap.get(valueB)?.color_code || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      cellRendererParams={(rendererParams: any) => {
        // TODO get chosenColor from server instead of colorIdMap
        // const chosenColor = colorIdMap.get(rendererParams.data.color_code_ref);
        return {
          deleteCellValue: (params: any) => {
            // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
          },
          gridComponent: (
            <ColorGrid
              hideDefaultGrid={true}
              // TODO get chosenColor id
              // filterId={chosenColor?.id}
            />
          )
        }
      }}
      cellEditorParams={{
        addDataService: addColor,
        nameKey: 'color_code',
        cacheKey: cacheKeys.colorData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.color,
        // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
      }}
      onCellValueChanged={props?.updateColorsByCrossReferencingVendorandCode}
      // TODO implement server-side filter
      // filter={"customRefFilter"}
      // floatingFilterComponent={"customRefFloatingFilter"}
      // floatingFilterComponentParams={{suppressFilterButton: true}}
      // filterParams={{
      //   cacheKey: cacheKeys.colorData,
      //   filterData: colorData,
      //   nameKey: 'color_code',
      // }}
    />,
    <AgGridColumn
      key={"matching_rib"}
      headerName={"Matching Rib"}
      field={"matching_rib"}
      width={150}
      cellRenderer={"checkmarkRenderer"}
      cellEditor={"checkmarkEditor"}
      comparator={utils.emptyComparator}
    />,
    <AgGridColumn
      key={"shrinkage_length"}
      headerName={"Shrinkage Length"}
      field={"shrinkage_length"}
      filter={"agNumberColumnFilter"}
    />,
    <AgGridColumn
      key={"shrinkage_width"}
      headerName={"Shrinkage Width"}
      field={"shrinkage_width"}
      filter={"agNumberColumnFilter"}
    />,
    <AgGridColumn
      key={"torque"}
      field={"torque"}
      filter={"agNumberColumnFilter"}
    />
  ];

  return {colArray};
};
