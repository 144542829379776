import React, {useEffect, useState} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {cacheKeys} from "../../constants/allConsts";
import {gridApiUtils} from "../../utils/gridApiUtils";
import {AlpineContainer} from "../containers/defaultAlpineContainer";
import {contextMenuItems} from "../agContextMenu";
import {AutoCompleteReferenceEditor} from "../agFrameworkComponents/editors/autoCompleteRefEditor";
import {AutoCompleteEditor} from "../agFrameworkComponents/editors/autoCompleteEditor";
import {AutoCompleteRenderer} from "../agFrameworkComponents/renderers/autoCompleteRenderer";
import {CustomRefFilter} from "../agFrameworkComponents/filters/customRefFilter";
import {CustomRefFloatingFilter} from "../agFrameworkComponents/filters/customRefFloatingFilter";
import {emptyObjectGenerator} from "../emptyObjects";
import {ButtonBar} from "../buttonBar";
import {Button} from "@material-ui/core";
import styled from "styled-components";
import {FadeIn, MuiButtonPrimary, MuiButtonRed} from "../../styledComponentPresets/presets";
import moment from "moment";
import {useQueryClient} from "react-query";
import {AutoCompleteReferenceRenderer} from "../agFrameworkComponents/renderers/autoCompleteRefRenderer";
import {RowEditorModal} from "../modals/rowEditorModal";
import {FirstColRenderer} from "../agFrameworkComponents/renderers/firstColRenderer";
import {generatePurchaseOrderPdf} from "../pdf/purchaseOrderPdf";
import {DateEditor} from "../agFrameworkComponents/editors/dateEditor";
import {
  useAddPurchaseOrder,
  useUpdatePurchaseOrder,
  useDeletePurchaseOrder, useGetPurchaseOrdersInfinite, useGetPurchaseOrders
} from "../../hooks/rowModels/usePurchaseOrders";
import {PurchaseOrderSectionedGrid} from "./customPopupGrids/purchaseOrderSectionedGrid/purchaseOrderSectionedGrid";
import {BodyScrollEvent, GridApi} from "ag-grid-community";
import {usePurchaseOrderGroup1} from "../../hooks/gridFragments/purchaseOrderGrid/usePurchaseOrderGridGroup1";
import {usePurchaseOrderGroup2} from "../../hooks/gridFragments/purchaseOrderGrid/usePurchaseOrderGridGroup2";
import {usePurchaseOrderGroup3} from "../../hooks/gridFragments/purchaseOrderGrid/usePurchaseOrderGridGroup3";
import _ from "lodash";

/*
  used to conditionally call hook for getting purchase order by id (for instance,
  if <PurchaseOrderGrid> (non-grouped version) is passed into a modal. Will need to edit setServerDataSource
  to accept non-paged responses from server as well
  */
const GetSinglePurchaseOrderForPopup = (id?: string) => {
  return useGetPurchaseOrders({id});
};

/* used to conditionally call hook for getting purchase orders in paged format */
const GetInfinitePurchaseOrders = () => {
  return useGetPurchaseOrdersInfinite();
};

export const PurchaseOrderGrid = (props: { hideDefaultGrid?: boolean, filterId?: string | null, gridApi?: GridApi | null }): JSX.Element => {
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [parentGridApi] = useState(props?.gridApi || null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    // flag for showing date filters (only applies to dates)
    const [showClearFilters, setShowClearFilters] = useState<boolean>(false);
    // local state for serverSide data source
    const [serverSideDataSource, setServerSideDataSource] = useState(undefined);
    // state for modal visibility (for displaying expanded rows)
    const [isModalOpen, setIsModalOpen] = useState(false);
    // id for row to be displayed in modal
    const [expandedRowFilterId, setExpandedRowFilterId] = useState<string>('');
    // title for modal to display (order number)
    const [expandedRowOrderNumber, setExpandedRowOrderNumber] = useState<string>('');
    // flag: we want to load the paginated data only once (first page), and control when to load the rest of it ourselves so ag-grid won't reload the entire grid and mess up a user's scroll position
    const [isDataDisplayed, setIsDataDisplayed] = useState<boolean>(false);

    // if a filterId is passed into PurchaseOrderGrid, we'll assume it's a popup and fetch just that specified array
    const purchaseOrders = props?.filterId ? GetSinglePurchaseOrderForPopup(props.filterId) : GetInfinitePurchaseOrders();

    /*
      Expands a row (right click > expand in context menu) by putting its contents in a modal.
      A Grid Component (such as itself - PurchaseOrderGrid or PurchaseOrderSectionedGrid) is passed into a RowEditorModal with
      a filterId. A Grid will be rendered in the Modal and css is used to make the first row of the Grid display in an
      "expanded" format. It is very important the Grid that is rendered in the modal only has one row. Otherwise, there
      will be scrolling issues when the user tries to scroll through the modal.

      At the moment, the way it works is a filterId gets passed into the Grid where ag-grid performs
      client-side filtering. Because the real server returns paginated data, we can't do this anymore. It's also a waste
      of resources to return an entire page (or pages) of data just to look at 1 row. Fortunately, the server now supports
      fetching a row by id from any module. This should be used to display expanded rows. This is implemented only in
      PurchaseOrderSectionedGrid at the moment. All other Grids are still using client-side filtering.
      TODO For all Grids in all modules with filterId's passed in, fetch the row by id and display that in the modal grid instead of using client-side filtering
    */
    const expandRow = (rowId: string) => {
      // set filter id
      setExpandedRowFilterId(rowId);
      // set title of the modal for display (in this case, it's the order number of the row)
      setExpandedRowOrderNumber(gridApi?.getRowNode(rowId)?.data?.order_number);
      // open modal
      setIsModalOpen(true);
    };

    // retrieve the column data from group 1 (same data used by PurchaseOrderSectionedGrid)
    const {colArray: group1cols} = usePurchaseOrderGroup1({
      expandRow: expandRow,
      gridApi: gridApi // use gridApi from parent if currently rendered in modal
    });

    const {colArray: group2cols} = usePurchaseOrderGroup2({
      // each row has an id column so ag-grid knows which rows to associate the columns with. We don't want to return the id column in group 2 because it already exists in group 1
      doSkipId: true
    });

    const {colArray: group3cols} = usePurchaseOrderGroup3({
      // each row has an id column so ag-grid knows which rows to associate the columns with. We don't want to return the id column in group 3 because it already exists in group 1
      doSkipId: true
    });


    /* mutation requests using react query */
    const {mutate: addPurchaseOrder} = useAddPurchaseOrder();
    const {mutate: deletePurchaseOrder} = useDeletePurchaseOrder();
    const {mutate: updatePurchaseOrder} = useUpdatePurchaseOrder();

    const queryClient = useQueryClient();

    useEffect(() => {
      /*
       * Load only once on mount to populate grid. Allow FE to take care of updates optimistically to prevent
       * the entire grid from clearing up and loading or shifting to the top when server syncs
       */
      if (!isDataDisplayed && !purchaseOrders?.isLoading) {
        // TODO get rid of emptyObjectGenerator. Get empty row from server instead (the grid needs at least 1 row for user to right click to add new rows)
        //@ts-ignore
        setServerSideDataSource(gridApiUtils.setServerSidePaginatedDatasourcePOs(!purchaseOrders?.isloading, (Array.isArray(purchaseOrders?.data?.pages) && purchaseOrders?.data?.pages?.length > 0) ? purchaseOrders?.data?.pages : [emptyObjectGenerator.purchaseOrder()]));
        setIsDataDisplayed(true);
      }
    }, [purchaseOrders?.isLoading]);

    const onGridReady = (params: any) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
      // TODO the following commented filters the grid for a row by id once the grid loads. We need to delete this from ALL Grids (we want to instead retrieve the row by id directly from the server)
      // if (props?.filterId) {
      //   var filterModel = {
      //     id: {
      //       filter: props.filterId,
      //       filterType: "text",
      //       type: "contains"
      //     }
      //   };
      //   params.api.setFilterModel(filterModel);
      // }
    };

    const closeModal = () => {
      if (gridApi) {
        const expandedNode = gridApi?.getRowNode(expandedRowFilterId);
        // refresh the previously expanded node in case changes were made to it while in expanded mode
        if (expandedNode) {
          gridApi?.redrawRows({rowNodes: [expandedNode]});
        }
      }
      // close modal
      setIsModalOpen(false);
      // reset filter id
      setExpandedRowFilterId('');
      // reset expanded order number
      setExpandedRowOrderNumber('');
    };

    const onCellValueChanged = (params: any) => {
      // update server
      updatePurchaseOrder(params?.data, {
        onSuccess: () => {},
        onError: (e) => {}
      });
      gridApiUtils.updatePopupTransactionUpdates(parentGridApi, gridApi, props?.filterId);
    };

    const clearFilters = () => {
      if (!gridApi) return;
      gridApi.setFilterModel(null);
    };

    const setTodayFilter = () => {
      if (!gridApi) return;
      var filterModel = {
        date: {
          dateFrom: moment().format("YYYY-MM-DD").toString(), // start of last week
          dateTo: null,
          filterType: "date",
          type: "equals"
        }
      };
      gridApi.setFilterModel(filterModel);
    };

    const setThisWeekFilter = () => {
      if (!gridApi) return;
      var filterModel = {
        date: {
          dateFrom: moment().startOf('week').format("YYYY-MM-DD").toString(), // start of last week
          dateTo: moment().format("YYYY-MM-DD").toString(),
          filterType: "date",
          type: "inRange"
        }
      };
      gridApi.setFilterModel(filterModel);
    };

    const setLastWeekFilter = () => {
      if (!gridApi) return;
      var filterModel = {
        date: {
          dateFrom: moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD").toString(), // start of last week
          dateTo: moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD").toString(),
          filterType: "date",
          type: "inRange"
        }
      };
      gridApi.setFilterModel(filterModel);
    };

  /**
   * @description callback for when filter changes (this only affects the date fields, can be expanded to other fields too)
   * @param filterParams
   */
  const onFilterChanged = (filterParams: any) => {
      if (!gridApi) return;
      // if date filters are not active, hide clear date filter button
      if (_.isEmpty(gridApi?.getFilterModel()) && showClearFilters) setShowClearFilters(false);
      else {
        // if date filters are not active, show clear date filter button
        if (!_.isEmpty(gridApi?.getFilterModel()) && !!gridApi?.getFilterModel()?.date && !showClearFilters) setShowClearFilters(true);
      }
    };


    /**
     * @description processes cell for clipboard so the right contents get copied.
     * Used primarily for reference buttons where you want the button names to
     * be copied rather than the reference id the cell actually holds
     * */
    const processCellForClipboard = (params: any) => {
      // TODO rewrite cell processer for clipboard (example implementation in purchaseOrderGrid)
      // switch (params?.column?.colId) {
      //   case "yarn_master_code.id":
      //     return params?.node?.data?.yarn_master_code?.name;
      //   case "supplier.id":
      //     return params?.node?.data?.supplier?.name;
      //   case "maker.id:":
      //     return params?.node?.data?.maker?.name;
      //   case "knitter.id":
      //     return params?.node?.data?.knitter?.name;
      // }
      return params.value;
    };

    return (
      <AlpineContainer hideDefaultGrid={props?.hideDefaultGrid}>
        <ButtonBar
          addRowTitle="Add Yarn Order"
          cacheKey={cacheKeys.poData}
          isAllDataLoaded={!purchaseOrders?.isLoading}
          addRowEmptyObject={(onAddRowSuccess: any) => {
            // TODO get emptyObject from server instead of making it client-side
            const emptyObject = emptyObjectGenerator.purchaseOrder();
            // invalidate queries to refetch data from server
            addPurchaseOrder(emptyObject, {
              onSuccess: (data) => {
                queryClient.invalidateQueries([cacheKeys.poData]);
                setExpandedRowFilterId(data.id);
                onAddRowSuccess(data);
              }
            });
            return emptyObject;
          }}
          // pass in gridApi for buttonBar to manipulate the grid
          gridApi={gridApi}
          gridComponent={
            <PurchaseOrderSectionedGrid
              gridApi={gridApi}
              processCellForClipboard={processCellForClipboard}
              hideDefaultGrid={true}
            />
          }
        >
          <StyledRightButtonsContainer>
            {showClearFilters && (<StyledRowButtonRed
              onClick={clearFilters}
              variant={"contained"}
              color={"primary"}
              size={"medium"}
            >
              Clear Date Filters
            </StyledRowButtonRed>)}
            <StyledRowButton
              onClick={setTodayFilter}
              variant={"contained"}
              color={"primary"}
              size={"medium"}
            >
              Today
            </StyledRowButton>
            <StyledRowButton
              onClick={setThisWeekFilter}
              variant={"contained"}
              color={"primary"}
              size={"medium"}
            >
              This Week
            </StyledRowButton>
            <StyledRowButton
              onClick={setLastWeekFilter}
              variant={"contained"}
              color={"primary"}
              size={"medium"}
            >
              Last Week
            </StyledRowButton>
          </StyledRightButtonsContainer>
        </ButtonBar>
        <AgGridReact
          defaultColDef={{
            resizable: true,
            editable: true,
            floatingFilter: true,
            filter: true,
            sortable: true,
            filterParams: {
              debounceMs: 100
            },
          }}
          enableRangeSelection={!props?.filterId}
          enableFillHandle={!props?.filterId}
          fillHandleDirection={'y'}
          fillOperation={gridApiUtils.setFillOperation}
          onFillEnd={gridApiUtils.fillEnd}
          processCellForClipboard={processCellForClipboard}
          getRowNodeId={(data) => {
            return data.id;
          }}
          /* use custom function to perform infinite pagination on body
           * scroll because ag-grid's default infinite pagination model has lots of issues
           * such as not supporting transactions and redrawing the entire grid whenever row count is changed
           */
          onBodyScroll={_.debounce((event: BodyScrollEvent) => gridApiUtils.customInfinitePaginationOnBodyScroll(event, purchaseOrders, gridApi), 100)}
          // turn off column virtualization if grid is displayed in a rowEditorModal (otherwise, not all the columns will show)
          suppressColumnVirtualisation={!!props?.filterId}
          // allow single-edit if grid is displayed in a rowEditorModal
          singleClickEdit={!!props?.filterId}
          onFilterChanged={onFilterChanged}
          suppressRowClickSelection={true}
          rowSelection={'multiple'}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={50}
          animateRows={true}
          rowModelType={'serverSide'}
          serverSideDatasource={serverSideDataSource}
          onGridReady={onGridReady}
          onCellValueChanged={onCellValueChanged}
          suppressMenuHide={!!props?.hideDefaultGrid}
          //@ts-ignore
          getContextMenuItems={(params) => props?.hideDefaultGrid ? [] : contextMenuItems({
            showDownloadPurchasePdf: true,
            queryClient: queryClient,
            expandRow: expandRow,
            cacheKey: cacheKeys.poData,
            params,
            // TODO make empty object serverside
            makeEmptyObject: emptyObjectGenerator.purchaseOrder,
            addRowServerCall: addPurchaseOrder,
            deleteRowServerCall: deletePurchaseOrder,
            rowDescription: 'yarn order',
            pdfData: {
              // TODO load pdf data by fetching just the rowd by id from the server inside the pdf generator
            }
          })}
          stopEditingWhenCellsLoseFocus={true}
          frameworkComponents={{
            customRefFilter: CustomRefFilter,
            customRefFloatingFilter: CustomRefFloatingFilter,
            autoCompleteEditor: AutoCompleteEditor,
            autoCompleteRenderer: AutoCompleteRenderer,
            autoCompleteReferenceEditor: AutoCompleteReferenceEditor,
            autoCompleteReferenceRenderer: AutoCompleteReferenceRenderer,
            firstColRenderer: FirstColRenderer,
            dateEditor: DateEditor,
          }}
        >
          {group1cols}
          {group2cols}
          {group3cols}
        </AgGridReact>
        <RowEditorModal
          open={isModalOpen}
          close={closeModal}
          //@ts-ignore
          title={`Edit Purchase Order ${expandedRowOrderNumber}`}
          topButtonComponent={
            <StyledRowButton
              onClick={() => {
                if (gridApi && expandedRowFilterId) {
                  //@ts-ignore
                  const currentNode = gridApi?.getRowNode(expandedRowFilterId);
                  generatePurchaseOrderPdf({
                    rowNode: currentNode,
                    pdfData: {
                      // TODO load pdf data by fetching just the rowd by id from the server inside the pdf generator
                    }
                  });
                }
              }}
              variant={"contained"}
              color={"primary"}
              size={"medium"}
            >
              Export PDF
            </StyledRowButton>
          }
          gridComponent={
            <PurchaseOrderSectionedGrid
              filterId={expandedRowFilterId}
              gridApi={gridApi}
              processCellForClipboard={processCellForClipboard}
              hideDefaultGrid={true}
            />
          }
        />
      </AlpineContainer>
    );
  }
;

const StyledRowButton = styled(Button)`
  ${MuiButtonPrimary};
  &.MuiButtonBase-root {
    margin-left: 10px;
  }
`;

const StyledRowButtonRed = styled(Button)`
  ${MuiButtonRed};
  &.MuiButtonBase-root {
    margin-left: 10px;
    animation: ${FadeIn} 0.2s linear forwards;
  }
`;

const StyledRightButtonsContainer = styled.div`
  margin-left: auto;
`;
