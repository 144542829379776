import axios from "axios";
import {routes} from "../../constants/allConsts";
import {IMaker} from "../../types/rowModelTypes";
import {userUtils} from "../../utils/userUtils";

// TODO implement get makers paged (see purchaseOrderServices.ts for example)

export const getMakers = async (): Promise<IMaker[] | {}> => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const fetchUrl = `${routes.API_ROOT}/makers`;
    const fetchUrl = `${routes.DEMO_MAKERS}`;
    const data = await axios.get(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        pageSize: 10000,
      }
    })
    // .then(res => res?.data?.data) // mock server
      .then(res => res?.data)
      .then(res => {
        if (res.status === 'success') {
          return res?.data?.data;
        }
        return res?.data;
      })
      .catch(err => {
        console.error("Error fetching makers: ", err?.response?.data);
        throw Error(err.response);
      });
    return data;
  } catch (error: any) {
    console.log("error fetching:: ", error);
    return {};
  }
};

// TODO write function for getting makers basic list (see purchaseOrderServices.ts for example)

export const getMakerById = async (id: string) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    const fetchUrl = `${routes.DEMO_MAKERS}/by-id`;
    const data = await axios.get(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        id: id,
      }
    })
      .then(res => res?.data?.data)
      .catch(err => {
        console.error("Error fetching maker: ", err?.response?.data);
        throw Error(err.response);
      });
    return data;
  } catch (error: any) {
    console.log("error fetching:: ", error);
  }
};

export const updateMaker = async (maker: IMaker) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const apiURL = `${routes.API_ROOT}/makers`;
    const apiURL = `${routes.DEMO_MAKERS}`;
    const requestBody = maker;
    // using post because the endpoint for updating and adding are now the same
    const data = axios.post(apiURL, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing maker: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};

export const addMaker = async (maker: IMaker) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const apiURL = `${routes.API_ROOT}/makers`;
    const apiURL = `${routes.DEMO_MAKERS}`;
    const requestBody = maker;
    const data = axios.post(apiURL, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing maker: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};

export const deleteMaker = async (maker: IMaker) => {
  try {
    const accessToken = await userUtils.getAccessToken();
    // const apiURL = `${routes.API_ROOT}/makers`;
    const apiURL = `${routes.DEMO_MAKERS}`;
    const requestBody = maker;
    const data = axios.delete(apiURL, {
      data: requestBody,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
    })
      .then((response) => response?.data?.data)
      .catch((error) => {
        console.error("Error publishing maker: ", error.response.data);
        throw error.response.data;
      });
    return data;
  } catch (e) {
    console.log('Error', e);
  }
};
