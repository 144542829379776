import React from "react";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {DialogActions, DialogContent, DialogProps, DialogTitle, Slide} from "@material-ui/core";
import {TransitionProps} from "@material-ui/core/transitions";
import styled from "styled-components";
import CloseIcon from '@material-ui/icons/Close';
import {colors} from "../../constants/allConsts";

interface IProps {
  open: boolean;
  close: () => any;
  gridComponent: JSX.Element;
  title?: string;
  subtitle?: string;
  size?: string;
  topButtonComponent?: any;
  bottomButtonComponent?: any;
}

const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RollEditorModal = ({title, subtitle, open, close, size, gridComponent, topButtonComponent, bottomButtonComponent}: IProps) => {
  const [fullWidth, setFullWidth] = React.useState(true);
  // @ts-ignore
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>(size || 'lg');
  return (
    <StyledDialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={close}
      aria-labelledby="max-width-dialog-title"
      TransitionComponent={SlideTransition}
    >
      <StyledDialogActions>
        <StyledCloseButton onClick={close} color="primary">
          <CloseIcon/>
        </StyledCloseButton>
      </StyledDialogActions>
      <StyledDialogContentContainer>
        {title && <StyledDialogTitle id="max-width-dialog-title">Edit Rolls</StyledDialogTitle>}
        <StyledDialogContent id={"max-width-dialog-contents"}>
          {gridComponent}
        </StyledDialogContent>
      </StyledDialogContentContainer>
    </StyledDialog>
  );
};

export {RollEditorModal};

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    height: 100vh;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    position: sticky; // allows close button to be visible on top of the popup always
    top: 0;
    width: auto;
    margin-left: auto;
    z-index: 1;
  }
`;

const StyledDialogContentContainer = styled.div`
  position: relative;
  //height: 100%;
  height: auto;
`;

const StyledCloseButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  &.MuiDialogActions-root {
    position: sticky;
    top: 0;
  }
  .MuiSvgIcon-root {
  position: sticky;
  top: 0;
    padding: 2px;
  }
  &:hover {
    .MuiSvgIcon-root {
      background: ${colors.lightBackgroundGray};
      border-radius: 50%;
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    display: inline-block; // allows it to have auto width
    font-weight: bolder;
    padding: 0 50px;
    .MuiTypography-h6 {
      display: inline-block; // allows it to have auto width
      width: auto;
      font-size: 1.7rem;
      margin-bottom: 15px;
    }
  }
`;

const StyledDialogContent = styled(DialogContent)`
 #button-bar {
  display: none;
 }
`;