import {AgGridColumn} from "ag-grid-react";
import {utils} from "../../../utils/utils";
import {gridApiUtils} from "../../../utils/gridApiUtils";
import {cacheKeys} from "../../../constants/cacheKeys";
import {emptyObjectGenerator} from "../../../components/emptyObjects";
import React from "react";
import {GridApi} from "ag-grid-community";
import {useUpdateRoll} from "../../rowModels/useRolls";
import {useAddColor} from "../../rowModels/useColors";
import {ColorGrid} from "../../../components/grids/colorGrid";
import {lotUtils} from "../../../utils/lotUtils";

export const useRollGroup2 = (props: {
  doSkipId?: boolean,
  gridApi?: GridApi | null,
  lockInLbPerYd?: any,
  openRollAdjustments?: any,
  hideDefaultGrid?: boolean,
}): {colArray: JSX.Element[]} => {

  const {mutate: updateRoll} = useUpdateRoll();
  const {mutate: addColor} = useAddColor();

  const colArray = [
    ...(!props?.doSkipId ? [<AgGridColumn
      field={"id"}
      lockPosition
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={'suppress-fill-handle'}
      suppressColumnsToolPanel={true}
      hide
      key={"id"}
    />] : []),
    <AgGridColumn
      key={"yd_og"}
      field={"yd_og"}
      suppressColumnsToolPanel={true}
      hide
    />,
    <AgGridColumn
      key={"lb_in"}
      headerName={"Pounds In"}
      field={"lb_in"}
      filter={"agNumberColumnFilter"}
      cellClass={(params: any) => params.node.data.lb_in !== "" ? "noneditable" : ""}
      editable={(params: any) => {
        return params.data.lb_in === '';
      }}
      onCellValueChanged={(params: any) => {
        if (params.oldValue === "") {
          params.data.lb_in_og = params.newValue;
        }
      }}
    />,
    <AgGridColumn
      key={"lb_out_og"}
      field={"lb_in_og"}
      suppressColumnsToolPanel={true}
      hide
    />,
    <AgGridColumn
      key={"lb_out"}
      headerName={"Pounds Out"}
      field={"lb_out"}
      filter={"agNumberColumnFilter"}
      onCellValueChanged={(params: any) => {
        if (params.oldValue === "") {
          params.data.lb_out_og = params.newValue;
        }
        props?.lockInLbPerYd(params);
      }}
      cellEditor={"rollAdjustmentsEditor"}
      cellEditorParams={(rendererParams: any) => {
        return {
          openRollAdjustments: () => props?.openRollAdjustments(rendererParams?.data?.id),
        };
      }}
    />,
    <AgGridColumn
      key={"lb_out_og"}
      field={"lb_out_og"}
      suppressColumnsToolPanel={true}
      hide
    />,
    <AgGridColumn
      key={"working_loss"}
      headerName={"Working Loss (%)"}
      field={"working_loss"}
      editable={false}
      filter={"agNumberColumnFilter"}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // IMPORTANT: inconsistent returns when lb_out or lb_in is NaN can result in infinite updates and crash the app
        if (params.data.lb_out === '' || params.data.lb_in === '' || isNaN(params.data.lb_out) || isNaN(params.data.lb_in)) return '';
        const workingLoss = lotUtils.calculateWorkingLossPercentage(params.data.lb_out, params.data.lb_in);
        if (params.data.working_loss !== workingLoss) {
          params.data.working_loss = workingLoss;
          updateRoll(params?.data);
        }
        return workingLoss ? `${workingLoss}%` : '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"laa_color_ref"}
      headerName={"LAA Color"}
      field={"laa_color_ref"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = colorIdMap.get(valueA)?.laa_color || '';
      //   const refValueB = colorIdMap.get(valueB)?.laa_color || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      cellRendererParams={(rendererParams: any) => {
        // TODO get chosenColor from server instead of colorIdMap
        // const chosenColor = colorIdMap.get(rendererParams.data.laa_color_ref);
        return {
          deleteCellValue: (params: any) => {
            // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
          },
          gridComponent: (
            <ColorGrid
              hideDefaultGrid={true}
              // TODO get chosenColor id
              // filterId={chosenColor?.id}
            />
          )
        }
      }}
      cellEditorParams={{
        addDataService: addColor,
        nameKey: 'laa_color',
        cacheKey: cacheKeys.colorData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.color,
        // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
      }}
      // TODO implement server-side filter
      // filter={"customRefFilter"}
      // floatingFilterComponent={"customRefFloatingFilter"}
      // floatingFilterComponentParams={{suppressFilterButton: true}}
      // filterParams={{
      //   cacheKey: cacheKeys.colorData,
      //   filterData: colorData,
      //   nameKey: 'laa_color',
      // }}
    />,
    <AgGridColumn
      key={"vendor_color_ref"}
      headerName={"Vendor Color"}
      field={"vendor_color_ref"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = colorIdMap.get(valueA)?.vendor_color || '';
      //   const refValueB = colorIdMap.get(valueB)?.vendor_color || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      cellRendererParams={(rendererParams: any) => {
        // TODO get chosenColor from server instead of colorIdMap
        // const chosenColor = colorIdMap.get(rendererParams.data.vendor_color_ref);
        return {
          deleteCellValue: (params: any) => {
            // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
          },
          gridComponent: (
            <ColorGrid
              hideDefaultGrid={true}
              // TODO get chosen color id
              // filterId={chosenColor?.id}
            />
          )
        }
      }}
      cellEditorParams={{
        addDataService: addColor,
        nameKey: 'vendor_color',
        cacheKey: cacheKeys.colorData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.color,
        // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
      }}
      // TODO implement server-side filter
      // filter={"customRefFilter"}
      // floatingFilterComponent={"customRefFloatingFilter"}
      // floatingFilterComponentParams={{suppressFilterButton: true}}
      // filterParams={{
      //   cacheKey: cacheKeys.colorData,
      //   filterData: colorData,
      //   nameKey: 'vendor_color',
      // }}
    />
  ];

  return {colArray};
};
