import React, {forwardRef, useState, useImperativeHandle, useRef, useEffect, ReactHTMLElement} from 'react';
import styled from "styled-components";
import {GridApi} from "ag-grid-community";

const RollAdjustmentsEditor = forwardRef((props: { data?: any, value: string, api: GridApi, openRollAdjustments: () => void }, ref: any) => {
  // output
  const [value, setValue] = useState<string>(props?.value?.toString() === '' ? '' : props?.value);

  const inputRef = useRef(null);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value || '';
      },
      afterGuiAttached: () => {
        setValue(props?.value || '');
        if (props?.value !== '') {
          if (props?.data?.yd === '') {
            props?.api?.stopEditing();
            alert("fill in \"yards\" first before making adjustments");
          } else if (props?.data?.lb_out === '') {
            props?.api?.stopEditing();
            alert("fill in \"pounds out\" first before making adjustments");
          } else {
            props?.api?.stopEditing();
            props?.openRollAdjustments();
          }
        } else {
          //@ts-ignore
          inputRef?.current?.focus();
        }
      }
    };
  });

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const value = event.target.value;
    setValue(value.toString());
  };

  return (
    <StyledContainer>
      <input
        type={"text"}
        value={value}
        ref={inputRef}
        onChange={handleInputChange}
        onFocus={handleFocus}
      />
    </StyledContainer>
  );
});

export {RollAdjustmentsEditor};

const StyledContainer = styled.span`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  background: white;
  display: flex;
  position: absolute;
  border: inherit;
  outline: inherit;
  border-radius: inherit;
  input {
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
    border: inherit;
    outline: inherit;
    border-radius: inherit;
  }
`;

