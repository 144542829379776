import {localStorageConstants} from "../constants/allConsts";
import {IServerError, ITokens, SERVER_ERROR_CODES} from "../constants/users";
import {userApiCalls} from "../services/users/userServices";
import {ValidateFunction} from "ajv";
import cookie from 'cookie';

export const userUtils = {
  /**
   * Checks to see if there is less than one minute untill the access token is expired.
   * If it already expires or expiry date is in less than 1 minute, refetches a new token
   * and returns the access token. Otherwise, just returns the access token.
   * @returns {string} Access token string
   */
  getAccessToken: async (): Promise<string | undefined> => {
    try {
      const cookies = cookie.parse(document.cookie);
      const tokensString = cookies?.[localStorageConstants.tokens] && decodeURIComponent(cookies?.[localStorageConstants.tokens]);
      if (!tokensString) {
        return undefined;
      }
      const tokens = JSON.parse(tokensString) as ITokens;
      if (tokens.accessToken.expiresAtMs - Date.now() < 60000) {
        // Less than 1 min left for expiration, so we refetch first and then send.
        let newAccessToken = await userApiCalls.requestNewAccessToken(tokens.accessToken.token, `Bearer ${tokens.refreshToken}`);

        tokens.accessToken = newAccessToken.accessToken;
        tokens.refreshToken = newAccessToken.refreshToken;
        await userUtils.setAccessToken(tokens);
        return `Bearer ${newAccessToken.accessToken.token}`;
      } else {
        return `Bearer ${tokens.accessToken.token}`;
      }
    } catch (error) {
      console.error('Error when getting the access token', error);
      throw error;
    }
  },

  setAccessToken: async (tokens: ITokens) => {
    try {
      
      document.cookie = cookie.serialize(localStorageConstants.tokens, encodeURIComponent(JSON.stringify(tokens)), {
        expires: new Date(tokens.accessToken.expiresAt)
      });
    } catch (error) {
      throw error;
    }
  },

  removeAccessToken: async () => {
    try {
      
      document.cookie = cookie.serialize(localStorageConstants.tokens, '');
    } catch (error) {
      throw error;
    }

  },

  /**
   *
   * @param params object of config params
   * SkipSignOutRequest - Will skip the request to sign out - Intended to be set to true when signing out due to invalid access or refresh token
   * showExpiredError - Will show a pop-up saying that session expired - Intended to be set to true when signing out due to expired access token
   * makeRefreshTokenCall - Will make a new access token request - Intended to be set to true when signing out due to invalid access or refresh token.
   */
  signOut: async (params?: { skipSignOutRequest?: boolean; showExpiredError?: boolean; makeRefreshTokenCall?: boolean }) => {
    if (params?.makeRefreshTokenCall) {
      console.warn('----------- Signing out because of invalid token');
    }

    let tokens: ITokens | undefined;
    if (params?.makeRefreshTokenCall) {
      const cookies = cookie.parse(document.cookie);
      const tokensString = cookies?.[localStorageConstants.tokens] && decodeURIComponent(cookies?.[localStorageConstants.tokens]);
      if (tokensString) {
        tokens = JSON.parse(tokensString) as ITokens;
      }
    }

    // NOTE: We need this on the server side for detecting a potential malicious activity.
    // NOTE: We are deleting the interceptor before making the requestNewAccessToken to prevent loops where this call
    // will throw an invalid access token error and will be caught by the interceptor again.
    // Make sure to always add it back afterwards.
    if (params?.makeRefreshTokenCall && tokens) {
      try {
        // deleteResponseInterceptors();
        await userApiCalls.requestNewAccessToken(tokens.accessToken.token, tokens.refreshToken, true);
        // setResponseInterceptors();
      } catch (error) {
        // setResponseInterceptors();
      }
    }

    if (!params?.skipSignOutRequest) {
      try {
        await userApiCalls.signOut();
      } catch (error) { }
    }

    try {
      // const cookies = cookie.parse(document.cookie);
      console.error(`SIGNING OUT BRO`)
      userUtils.removeAccessToken();
    } catch (error) {
      console.error('Error when signing out');
    }

    if (params?.showExpiredError) {
      alert('Login Session expired. Please try logging in');
    }
  },
  isValid: (data: any, validateFunction: ValidateFunction<unknown>, skipLog?: boolean) => {
    const retVal = validateFunction(data);
    if (!retVal && !skipLog) {
      console.log('--- ERRORS ARE', validateFunction.errors);
    }
    return validateFunction(data);
  },

  createErrorObject: (message: string, errorCode: SERVER_ERROR_CODES | string): IServerError => {
    return {
      message,
      errorCode,
      status: 'error',
    };
  },
};
