import {useMutation, useQuery} from "react-query";
import {cacheKeys} from "../../constants/allConsts";
import {addDyer, getDyers, updateDyer, deleteDyer} from "../../services/rowModels/dyerServices";
import {IDyer} from "../../types/rowModelTypes";

// TODO extend useGetDyers to be able to use getDyerById once it's implemented (see usePurchaseOrders.ts for example)
export const useGetDyers = (): any => {
  const result = useQuery({
    queryKey: [cacheKeys.dyerData],
    queryFn: () => getDyers(),
  });
  return result;
};

// TODO implement useGeDyerBasic (see usePurchaseorders.ts for example)

export const useAddDyer = () => {
  const result = useMutation(async (update: IDyer) => {
    return await addDyer(update);
  });
  return result;
};

export const useUpdateDyer = () => {
  const result = useMutation(async (update: IDyer) => {
    return await updateDyer(update);
  });
  return result;
};

export const useDeleteDyer = () => {
  const result = useMutation(async (update: IDyer) => {
    return await deleteDyer(update);
  });
  return result;
};
