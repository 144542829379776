import {useMutation, useQuery} from "react-query";
import {cacheKeys} from "../../constants/allConsts";
import {addVendor, deleteVendor, getVendors, updateVendor} from "../../services/rowModels/vendorServices";
import {IVendor} from "../../types/rowModelTypes";

// TODO extend useGetVendors to be able to use getVendorById once it's implemented (see usePurchaseOrders.ts for example)
export const useGetVendors = (): any => {
  const result = useQuery({
    queryKey: [cacheKeys.vendorData],
    queryFn: () => getVendors(),
  });
  return result;
};

// TODO implement useGetVendorBasic (see usePurchaseorders.ts for example)

export const useAddVendor = () => {
  const result = useMutation(async (update: IVendor) => {
    return await addVendor(update);
  });
  return result;
};

export const useUpdateVendor = () => {
  const result = useMutation(async (update: IVendor) => {
    return await updateVendor(update);
  });
  return result;
};

export const useDeleteVendor = () => {
  const result = useMutation(async (update: IVendor) => {
    return await deleteVendor(update);
  });
  return result;
};
