import React, {forwardRef, useState, useImperativeHandle, useRef, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import styled from "styled-components";
import {useQueryClient} from "react-query";
import {utils} from "../../../utils/utils";
import {GridApi} from "ag-grid-community";

const filter = createFilterOptions<any>();

const AutoCompleteEditor = forwardRef((
  props: {
    api: GridApi,
    value: any,
    nameKey: string, // the key to the autocomplete object
    cacheKey: string,
    getDataHook: any,
    addDataServiceHook: any,
  }, ref: any) => {
  const [value, setValue] = useState<any>({[props.nameKey]: props?.value} || '');
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
  const [isNewInputEntry, setIsNewInputEntry] = useState<boolean>(false);
  const textFieldRef = useRef(null);
  const [output, setOutput] = useState<string>(props?.value || '');
  const queryClient = useQueryClient();
  const cacheKey = ''//props.cacheKey;
  const {data: dataOptions, isSuccess: isDataLoaded} = {data: {}, isSuccess: true} //props.getDataHook();
  // const {mutate: addData} = props.addDataServiceHook();

  useEffect(() => {
    // as soon as autocomplete option is clicked on (and saved), stop editing
    if (isDataChanged && value !== '') {
      props?.api?.stopEditing();
    }
  }, [value, output]);
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        // @ts-ignore
        if (output) {
          return output;
        } else if (value) {
          if (typeof value === 'string') {
            return value;
          } else if (value[props.nameKey]) {
            return value[props.nameKey];
          }
          return '';
        }
      },
      afterGuiAttached: () => {
        setValue(props?.value || '');
        if (props.value) {
          setOutput(props?.value[props.nameKey] || '');
        } else {
          setOutput('');
        }
        // allow field to be edited as soon as element UI is ready
        if (textFieldRef.current) {
          // @ts-ignore
          textFieldRef.current.focus();
        }
      }

    };
  });

  const addNewEntryTypeToServer = (dataServiceKey: string, input: string) => {
    // addData({[dataServiceKey]: input?.toUpperCase()}, {
    //   onSuccess: () => {
    //     queryClient.invalidateQueries([cacheKey]);
    //   }
    // });
  };
  return (
    isDataLoaded ? (
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setValue({[props.nameKey]: newValue?.toUpperCase()});
            setOutput(newValue?.toUpperCase());
            if (isNewInputEntry) {
              addNewEntryTypeToServer(props.nameKey, newValue);
            }
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({[props.nameKey]: newValue?.inputValue?.toUpperCase() || ''});
            setOutput(newValue?.inputValue?.toUpperCase());
          } else {
            setValue(newValue || '');
            if (newValue) {
              setOutput(newValue[props.nameKey] || '');
            } else {
              setOutput('');
            }
          }
          setIsDataChanged(true);
        }}
        onOpen={() => {
          setIsDataChanged(false);
        }}
        filterOptions={(options, params) => {
          // @ts-ignore
          const filtered = filter(options || [], params).sort((a, b) => utils.baseComparator(a[props.nameKey], b[props.nameKey]));
          // Suggest the creation of a new value
          if (params.inputValue !== '' && (filtered.filter(f => f[props.nameKey].toUpperCase() === params.inputValue.toUpperCase()).length < 1)) {
            if (!isNewInputEntry) setIsNewInputEntry(true);
            filtered.push({
              inputValue: params.inputValue?.toUpperCase(),
              [props.nameKey]: `Add "${params.inputValue.toUpperCase()}"`,
            });
          } else {
            if (isNewInputEntry) setIsNewInputEntry(true);
            setIsNewInputEntry(false);
          }
          return filtered;
        }}
        openOnFocus
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={`${cacheKey}-autocomplete-selection`}
        //@ts-ignore
        options={dataOptions}
        getOptionLabel={(option: any) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option?.toUpperCase();
          }
          // Add "xxx" option created dynamically
          if (option?.inputValue?.toUpperCase()) {
            addNewEntryTypeToServer(props.nameKey, option?.inputValue);
            return option?.inputValue?.toUpperCase() || '';
          }
          // Regular option
          return option[props.nameKey]?.toUpperCase() || '';
        }}
        //@ts-ignore
        renderOption={(option) => option[props.nameKey]}
        freeSolo
        renderInput={(params) => (
          <StyledAutoCompleteTextField
            {...params}
            variant="outlined"
            className="autocomplete-textfield"
            inputRef={input => {
              textFieldRef.current = input;
            }}
          />
        )}
      />) : null
  );
});


export {AutoCompleteEditor};

const StyledAutoCompleteTextField = styled(TextField)`
text-transform: uppercase;
input {
  padding: 0;
  text-transform: uppercase;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0;
}
.MuiOutlinedInput-notchedOutline {
  border: none;
}

`;