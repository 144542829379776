import React from "react";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {DialogActions, DialogContent, DialogProps, DialogTitle, Slide} from "@material-ui/core";
import {TransitionProps} from "@material-ui/core/transitions";
import styled from "styled-components";
import CloseIcon from '@material-ui/icons/Close';
import {colors} from "../../constants/allConsts";

interface IProps {
  open: boolean;
  close: () => any;
  gridComponent: JSX.Element;
  title?: string;
  subtitle?: string;
  size?: string;
  topButtonComponent?: any;
  bottomButtonComponent?: any;
}

const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RowEditorModal = ({title, subtitle, open, close, size, gridComponent, topButtonComponent, bottomButtonComponent}: IProps) => {
  const [fullWidth, setFullWidth] = React.useState(true);
  // @ts-ignore
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>(size || 'lg');
  return (
    <StyledDialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={close}
      aria-labelledby="max-width-dialog-title"
      TransitionComponent={SlideTransition}
    >
      <StyledDialogActions>
        <StyledCloseButton onClick={close} color="primary">
          <CloseIcon/>
        </StyledCloseButton>
      </StyledDialogActions>
      <StyledDialogContentContainer>
        {title && <StyledDialogTitle id="max-width-dialog-title">{title}</StyledDialogTitle>}
        {subtitle && <StyledSubtitle id="max-width-dialog-subtitle">{subtitle}</StyledSubtitle>}
        {topButtonComponent && <StyledTopButtonContainer className={"dialog-top-button"}>{topButtonComponent}</StyledTopButtonContainer>}
        <StyledDialogContent id={"max-width-dialog-contents"}>
          {gridComponent}
        </StyledDialogContent>
      </StyledDialogContentContainer>
      {/*<div className={"twooo"}></div>*/}
      {bottomButtonComponent && <StyledBottomButtonContainer className={"dialog-bottom-button"}>{bottomButtonComponent}</StyledBottomButtonContainer>}
    </StyledDialog>
  );
};

export {RowEditorModal};

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    height: 100vh;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    position: sticky; // allows close button to be visible on top of the popup always
    top: 0;
    width: auto;
    margin-left: auto;
    z-index: 1;
  }
`;

const StyledTopButtonContainer = styled.div`
    margin-left: auto;
    float: right;
    margin-right: 50px;
`;

const StyledBottomButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  padding: 0 50px 50px 50px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  margin-top: auto;
`;

const StyledDialogContentContainer = styled.div`
  position: relative;
  //height: 100%;
  height: auto;
`;

const StyledCloseButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  &.MuiDialogActions-root {
    position: sticky;
    top: 0;
  }
  .MuiSvgIcon-root {
  position: sticky;
  top: 0;
    padding: 2px;
  }
  &:hover {
    .MuiSvgIcon-root {
      background: ${colors.lightBackgroundGray};
      border-radius: 50%;
    }
  }
`;

const StyledSubtitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    font-weight: bolder;
    padding: 0 50px;
    .MuiTypography-h6 {
      margin-bottom: 20px;
      font-size: 1rem;
      color: rgba(255,0,0,0.8);
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    display: inline-block; // allows it to have auto width
    font-weight: bolder;
    padding: 0 50px;
    .MuiTypography-h6 {
      display: inline-block; // allows it to have auto width
      width: auto;
      font-size: 1.7rem;
      margin-bottom: 15px;
    }
  }
`;

const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding-top: 0;
    padding-left: 50px;
    padding-right: 50px;
  }
  
  #button-bar, .ag-header-cell.ag-floating-filter.ag-focus-managed {
    display: none;
  }
  
  #laa-grid-container {
    height: auto;
  }
  
  .ref-pill-button {
    &:not(:hover) {
      background-color: ${colors.white};
    }
  }
  
  .show-on-hover {
    display: none;
  }
  
  //ag rich dropdown
  .ag-rich-select {
    background: ${colors.lightBackgroundGray};
    border: 1px solid #babfc7;
    box-shadow :0 1px 4px 1px rgba(186,191,199,0.4);
    .ag-virtual-list-viewport {
      padding-top: 10px;
    }
    .ag-rich-select-value {
      display: none;
    }
    .ag-rich-select-list {
        width: 100%;
        min-width: 300px;
        height: 273px;
    }
      .ag-rich-select-virtual-list-item .ag-rich-select-row {
        padding: 10px;
        cursor: pointer;
      }
  }
  
  .ag-cell.ag-cell-not-inline-editing {
    background: ${colors.lightBackgroundGray};
    border: 1px solid #E9E9E9;
    &:not(.noneditable):hover {
      background: ${colors.lighterBackgroundGray};
    }
    &.noneditable {
      // override ag-styles so fields won't go back to being gray when not hovered on
      background: none !important;
      border: none;
      outline: none;
      cursor: default;
    }
  }

  .ag-body-viewport.ag-layout-normal.ag-row-animation {
    position: absolute !important;
    width: 100% !important;
  }
   
  box-sizing: border-box;
  margin-bottom: 35px;

  .ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
    height: 100%; // set modal parent height
  }
  // make rows overlay headers
  .ag-body-viewport.ag-layout-normal.ag-row-no-animation {
    top: 0; 
    left: 0;
    position: absolute !important;
    width: 100%;
  }
  .ag-header-container {
    max-width: 100%;
    width: 100% !important;
  }
  .ag-header-cell {
    font-weight: bold;
    font-size: 0.85rem;
    left: 0 !important;
    position: relative !important;
    width: 25% !important; // default width is 25%
    box-sizing: border-box;
    .ag-header-cell-label {
      height: 35px;
      margin-bottom: 35px;
    }
    &.span-max {
     width: 100% !important;
    }
    &.span-third {
      width: 33% !important;
    }
    &.span-half {
      width: 50% !important;
    }
    &.span-two-thirds {
      width: 66% !important;
    }
    &.span-three-fourths {
      width: 74% !important;
    }
  }

  .ag-header-row {
    height: auto !important;
    display: flex;
    flex-wrap: wrap;
    position: relative !important;
    width: 100% !important;
  }

  .ag-header {
    height: auto !important;
  }

  .ag-center-cols-container {
    max-width: 100%;
    width: 100% !important;
    height: auto !important;
  }

  .ag-row {
    height: auto !important;
    display: flex;
    flex-wrap: wrap;
    position: relative !important;
    width: 100% !important;
  }

  .ag-cell {
    left: 0 !important;
    position: relative !important;
    width: 24% !important; // default width is bit less than 25% - 1% right margin
    box-sizing: border-box;
    height: 35px;
    margin-top: 35px;
    border: 1px solid rgba(0,0,0,0.5);
    border-radius: 6px;
    margin-right: 1%;
    padding-left: 5px;
    display: flex;
    align-items: center;
    cursor: text;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    .ag-cell-editor input {
      border: none;
      outline: none;
    }
    .ag-cell-edit-wrapper {
    background: none !important;
      .ag-selection-checkbox {
        display: none !important;
      }
    }
    .ag-react-container {
      width: 100%;
      button:first-child {
          max-width: 85%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: normal;
      }
    }
    &.span-max {
      width: 99% !important;
    }
    &.span-third {
      width: 32% !important;
    }
    &.span-half {
      width: 49% !important;
    }
    &.span-two-thirds {
      width: 66% !important;
    }
    &.span-three-fourths {
      width: 74% !important;
    }
  }

  .ag-center-cols-clipper {
    height: auto !important;
  }
  
  .ag-center-cols-viewport, .ag-center-cols-clipper {
    overflow: visible !Important;
  }
  
  .ag-address-editor {
    box-shadow: 3px 1px 6px rgba(0,0,0,0.1);
    input {
       padding: 5px 10px;
     }
  }
  
  .ag-root-wrapper, .ag-body-viewport, .ag-root, .ag-center-cols-viewport {
    overflow: visible; // allows dropdowns to be displayed over instead of being contained in a scrollview
  }
  .ag-body-viewport.ag-has-focus {
    z-index: 1; /* allows current dropdown to be displayed on top of other sections */
  }
`;