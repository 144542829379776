import React from "react";
import {AgGridColumn} from "ag-grid-react";
import {GridApi} from "ag-grid-community";

import {
  useGetAdjustments,
} from "../../rowModels/useAdjustments";

export const useAdjustmentGroup2 = (props: {
  doSkipId?: boolean,
  gridApi?: GridApi | null,
  hideDefaultGrid?: boolean,
}): {colArray: JSX.Element[]} => {

  const colArray = [
    ...(!props?.doSkipId ? [<AgGridColumn
      field={"id"}
      lockPosition
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={'suppress-fill-handle'}
      suppressColumnsToolPanel={true}
      hide
      key={"id"}
    />] : []),
    <AgGridColumn
      key={"lb_before"}
      headerName={"Lbs Before"}
      field={"lb_before"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
    />,
    <AgGridColumn
      key={"lb_after"}
      headerName={"Lbs After"}
      field={"lb_after"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
    />,
    <AgGridColumn
      key={"yd_before"}
      headerName={"Yards Before"}
      field={"yd_before"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
    />,
    <AgGridColumn
      key="yd_after"
      headerName={"Yards After"}
      field={"yd_after"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
    />,
    <AgGridColumn
      key={"notes"}
      field={"notes"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      width={500}
    />,
    <AgGridColumn
      key={"entered_by"}
      // TODO use current user
      headerName={"Entered By"}
      field={"entered_by"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      flex={1}
      minWidth={300}
    />
  ];

  return {colArray};
};
