import React, {forwardRef, useState, useImperativeHandle, useRef, useEffect} from 'react';
import styled from "styled-components";
import {colors} from "../../../constants/allConsts";
import CheckIcon from '@material-ui/icons/Check';
import {GridApi} from "ag-grid-community";

const CheckmarkEditor = forwardRef((props: {value: boolean, api: GridApi}, ref: any) => {
    const [value, setValue] = useState<boolean>(props?.value && props.value.toString() === "true");
    const [previousValue] = useState<boolean>(value);

    useEffect(()=> {
        // as soon as autocomplete option is clicked on (and saved), stop editing
        if (value !== previousValue) {
            props.api.stopEditing();
        }
    }, [value]);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value || false;
            },
            afterGuiAttached: () => {
                setValue(!props?.value);
            }
        };
    });


    const handleToggle = () => {
        setValue(!value);
    };

    return (
      //@ts-ignore
      <StyledCheckboxContainer onClick={handleToggle}>{value ? <CheckIcon /> : ' '}</StyledCheckboxContainer>
    );
});

export {CheckmarkEditor};

const StyledCheckboxContainer = styled.span`
    height: 100%;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: ${colors.checkmarkGreen};
    cursor: pointer;
    background: inherit;
    box-sizing: border-box;
`;