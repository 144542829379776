import React, {useEffect, useState} from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {AlpineContainer} from "../containers/defaultAlpineContainer";
import {contextMenuItems} from "../agContextMenu";
import {gridApiUtils} from "../../utils/gridApiUtils";
import {routes, cacheKeys} from "../../constants/allConsts";
import {emptyObjectGenerator} from "../emptyObjects";
import {ButtonBar} from "../buttonBar";
import {useQueryClient} from "react-query";
import {RowEditorModal} from "../modals/rowEditorModal";
import {FirstColRenderer} from "../agFrameworkComponents/renderers/firstColRenderer";
import {AddressEditor} from "../agFrameworkComponents/editors/addressEditor";
import {BodyScrollEvent, GridApi} from "ag-grid-community";
import { IMaker, IVendor } from '../../types/rowModelTypes';
import { useGetRowModel, useGetRowModelInfinite, 
        useGetRowModelBasic, useAddRowModel, 
        useDeleteRowModel, useUpdateRowModel } from '../../hooks/rowModels/RowModelHooks';
import _, { filter } from "lodash";

const GetSingleVendorForPopup = (id?: string) => {
  return useGetRowModel<IVendor>(routes.DEMO_VENDORS , {id});
};

const GetInfiniteVendors = () => {
  return useGetRowModelInfinite<IVendor>(routes.DEMO_VENDORS);
};

export const VendorGrid = (props: { hideDefaultGrid?: boolean, filterId?: string | null, gridApi?: GridApi | null }): JSX.Element => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [parentGridApi] = useState(props?.gridApi || null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [serverSideDataSource, setServerSideDataSource] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedRowFilterId, setExpandedRowFilterId] = useState<string>('');
  const [isDataDisplayed, setIsDataDisplayed] = useState<boolean>(false);

  const vendors = props?.filterId ? GetSingleVendorForPopup(props.filterId) : GetInfiniteVendors();

  const {mutate: addVendor} = useAddRowModel<IVendor>(routes.DEMO_VENDORS);
  const {mutate: updateVendor} = useUpdateRowModel<IVendor>(routes.DEMO_VENDORS);
  const {mutate: deleteVendor} = useDeleteRowModel<IVendor>(routes.DEMO_VENDORS);

  const queryClient = useQueryClient();

  useEffect(() => {
    let data = emptyObjectGenerator.vendor();
      data = props.filterId ? vendors?.data : vendors?.data?.pages
      // TODO get rid of emptyObjectGenerator. Get empty row from server instead (the grid needs at least 1 row for user to right click to add new rows)
      //@ts-ignore
      const setDataSourceFunc = props?.filterId ? gridApiUtils.setServerSideDatasource : gridApiUtils.setServerSidePaginatedDatasource;
      const dataSource: any = setDataSourceFunc(vendors?.isSuccess, data)
      setServerSideDataSource(dataSource);
      setIsDataDisplayed(true);
  }, [vendors?.isLoading]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setExpandedRowFilterId('');
  };

  const expandRow = (rowId: string) => {
    setExpandedRowFilterId(rowId);
    setIsModalOpen(true);
  };

  const onCellValueChanged = (params: any) => {
    // update server
    updateVendor(params?.data);
    gridApiUtils.updatePopupTransactionUpdates(parentGridApi, gridApi, props?.filterId);
  };

  return (
    <AlpineContainer hideDefaultGrid={props?.hideDefaultGrid}>
      <ButtonBar
        addRowTitle="Add Vendor"
        cacheKey={cacheKeys.vendorData}
        isAllDataLoaded={!vendors?.isLoading}
        addRowEmptyObject={(onAddRowSuccess: any) => {
          let newRowData = {}
          // invalidate queries to refetch data from server
          addVendor(newRowData, {
            onSuccess: (data) => {
              console.log(`NEW DATA: ${JSON.stringify(data,null,2)}`)
              queryClient.invalidateQueries([cacheKeys.vendorData]);
              setExpandedRowFilterId(data.id);
              onAddRowSuccess(data);
            }
          });
        }}
        // pass in gridApi for buttonBar to manipulate the grid
        gridApi={gridApi}
        gridComponent={<VendorGrid gridApi={gridApi}/>}
      >
      </ButtonBar>
      <AgGridReact
        defaultColDef={{
          resizable: true,
          editable: true,
          floatingFilter: true,
          filter: true,
          sortable: true,
          filterParams: {
            debounceMs: 100
          }
        }}
        enableRangeSelection={!props?.filterId}
        enableFillHandle={!props?.filterId}
        fillHandleDirection={'y'}
        fillOperation={gridApiUtils.setFillOperation}
        onFillEnd={gridApiUtils.fillEnd}
        getRowNodeId={(data) => {
          return data.id;
        }}
        onBodyScroll={_.debounce((event: BodyScrollEvent) => gridApiUtils.customInfinitePaginationOnBodyScroll(event, vendors, gridApi), 100)}
        // turn off column virtualization if grid is displayed in a rowEditorModal (otherwise, not all the columns will show)
        suppressColumnVirtualisation={!!props?.filterId}
        // allow single-edit if grid is displayed in a rowEditorModal
        singleClickEdit={!!props?.filterId}
        suppressRowClickSelection={true}
        rowSelection={'multiple'}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={50}
        animateRows={true}
        rowModelType={'serverSide'}
        serverSideDatasource={serverSideDataSource}
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        suppressMenuHide={!!props?.hideDefaultGrid}
        //@ts-ignore
        getContextMenuItems={(params) => props?.hideDefaultGrid ? [] : contextMenuItems({
          cacheKey: cacheKeys.vendorData,
          queryClient: queryClient,
          expandRow: expandRow,
          params,
          // TODO make empty object serverside
          makeEmptyObject: emptyObjectGenerator.vendor,
          addRowServerCall: addVendor,
          deleteRowServerCall: deleteVendor,
          rowDescription: 'vendor'
        })}
        stopEditingWhenCellsLoseFocus={true}
        frameworkComponents={{
          addressEditor: AddressEditor,
          firstColRenderer: FirstColRenderer,
        }}
      >
        <AgGridColumn
          field={"id"}
          lockPosition
          filter={"agTextColumnFilter"}
          editable={false}
          cellClass={'suppress-fill-handle'}
          suppressColumnsToolPanel={true}
          hide
        />
        <AgGridColumn
          headerName={"Vendor"}
          field={"vendor_name"}
          cellRenderer={"firstColRenderer"}
          cellRendererParams={{expandRow: expandRow}}
          lockPosition
          checkboxSelection={!props?.hideDefaultGrid}
          filter={"agTextColumnFilter"}
          width={250}
        />
        <AgGridColumn
          field={"phone"}
          filter={"agTextColumnFilter"}
          width={200}
        />
        <AgGridColumn
          field={"contact"}
          filter={"agTextColumnFilter"}
          width={200}
        />
        <AgGridColumn
          field={"email"}
          filter={"agTextColumnFilter"}
          minWidth={250}
        />
        <AgGridColumn
          field={"address"}
          filter={"agTextColumnFilter"}
          minWidth={575}
          cellEditor={"addressEditor"}
          headerClass={"span-half"}
          cellClass={"ag-cell-overflow-visible-on-edit span-half"}
          flex={1}
        />
      </AgGridReact>
      <RowEditorModal
        open={isModalOpen}
        close={closeModal}
        title={"Edit Vendor"}
        gridComponent={<VendorGrid hideDefaultGrid={true} filterId={expandedRowFilterId} gridApi={gridApi}/>}
      />
    </AlpineContainer>
  );
};
