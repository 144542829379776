import {AgGridColumn} from "ag-grid-react";
import {utils} from "../../../utils/utils";
import React from "react";
import {GridApi} from "ag-grid-community";
import {
  useUpdateFinishedGood
} from "../../rowModels/useFinishedGoods";

export const useFinishedGoodsGroup4 = (props: {
  doSkipId?: boolean,
  expandRow?: any,
  gridApi?: GridApi | null,
  hideDefaultGrid?: boolean,
}): {colArray: JSX.Element[]} => {

  const {mutate: updateFinishedGoods} = useUpdateFinishedGood();
  // const rollsByLotsMap = useMemo(() => {
  //   const rolls = new Map();
  //   /* we don't want to loop and filter every single roll in lots when data changes,
  //     so we'll do it once at the top here and use this data directly */
  //   rollData?.forEach((roll: IRoll) => {
  //     if (rolls.get(roll.lots_ref)) rolls.set(roll.lots_ref, [roll, ...rolls.get(roll.lots_ref)]);
  //     else rolls.set(roll.lots_ref, [roll]);
  //   });
  //   return rolls;
  // }, [rollData]);

  const colArray = [
    ...(!props?.doSkipId ? [<AgGridColumn
      field={"id"}
      lockPosition
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={'suppress-fill-handle'}
      suppressColumnsToolPanel={true}
      hide
      key={"id"}
    />] : []),
    <AgGridColumn
      key={"total_rolls_in_stock"}
      headerName={"Total Rolls In Stock"}
      field={"total_rolls_in_stock"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params: any) => {
        // TODO rewrite
        // const totalRollsInLot = rollsByLotsMap.get(params?.data?.id)?.length;
        // if (params.data.total_rolls_in_stock !== totalRollsInLot) {
        //   params.data.total_rolls_in_stock = totalRollsInLot;
        //   updateFinishedGoods(params?.data);
        // }
        // return totalRollsInLot;
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"total_yd_in_stock"}
      headerName={"Total Yards In Stock"}
      field={"total_yd_in_stock"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const yardSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + Number((current?.yd || 0)), 0);
        // if (params.data.total_yd_in_stock !== yardSum) {
        //   params.data.total_yd_in_stock = yardSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return yardSum || '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"total_lb_out_in_stock"}
      headerName={"Total Pounds Out In Stock"}
      field={"total_lb_out_in_stock"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const lbOutSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + Number((current?.lb_out || 0)), 0);
        // if (params.data.total_lb_out_in_stock !== lbOutSum) {
        //   params.data.total_lb_out_in_stock = lbOutSum;
        //   updateFinishedGoods(params?.data);
        // }
        // return lbOutSum || '';
      }}
      comparator={utils.numberComparator}
      width={225}
    />,
    <AgGridColumn
      key={"working_loss_avg"}
      headerName={"Working Loss Average"}
      field={"working_loss_avg"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const workingLossSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + Number((current.working_loss || 0)), 0);
        // const workingLossAvg = utils.roundNumber((workingLossSum / rollsInLot.length), 3);
        // if (params.data.working_loss_avg !== workingLossAvg) {
        //   params.data.working_loss_avg = workingLossAvg;
        //   updateFinishedGoods(params?.data);
        // }
        // return `${workingLossAvg}%` || '';

      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"cost_per_yd"}
      headerName={"Cost ($)/Yard"}
      field={"cost_per_yd"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"cost_per_lb"}
      headerName={"Cost ($)/Pound"}
      field={"cost_per_lb"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"avg_linear_yield"}
      headerName={"Average Linear Yield"}
      field={"avg_linear_yield"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const linearYieldSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + Number((current?.linear_yield || 0)), 0);
        // const avgLinearYield = utils.roundNumber((linearYieldSum / rollsInLot.length), 6) || '';
        // if (params.data.avg_linear_yield !== avgLinearYield) {
        //   params.data.avg_linear_yield = avgLinearYield;
        //   updateFinishedGoods(params?.data);
        // }
        // return avgLinearYield;
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"avg_sq_yield"}
      headerName={"Average Square Yield"}
      field={"avg_sq_yield"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const sqYieldSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + Number((current?.sq_yield || 0)), 0);
        // const avgSqYield = utils.roundNumber((sqYieldSum / rollsInLot.length), 6) || '';
        // if (params.data.avg_sq_yield !== avgSqYield) {
        //   params.data.avg_sq_yield = avgSqYield;
        //   updateFinishedGoods(params?.data);
        // }
        // return avgSqYield;
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"avg_gsm"}
      headerName={"Average GSM"}
      field={"avg_gsm"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO rewrite
        // const rollsInLot = rollsByLotsMap.get(params?.data?.id);
        // if (!rollsInLot) return '';
        // const GSMSum = rollsInLot.reduce((prev: number, current: IRoll) => prev + Number((current?.gsm || 0)), 0);
        // const avgGSM = utils.roundNumber((GSMSum / rollsInLot.length), 6) || '';
        // if (params.data.avg_gsm !== avgGSM) {
        //   params.data.avg_gsm = avgGSM;
        //   updateFinishedGoods(params?.data);
        // }
        // return avgGSM;
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"actual_linear_yield"}
      headerName={"Actual Linear Yield"}
      field={"actual_linear_yield"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO get data from server data instead of styleIdMap
        // const style = styleIdMap.get(params.data.style_ref);
        // if (style) {
        //   return style?.actual_linear_yield;
        // }
        // return '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"actual_sq_yield"}
      headerName={"Actual Square Yield"}
      field={"actual_sq_yield"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO get data from server data instead of styleIdMap
        // const style = styleIdMap.get(params.data.style_ref);
        // if (style) {
        //   return style?.actual_sq_yield;
        // }
        // return '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"actual_gsm"}
      headerName={"Actual GSM"}
      field={"actual_gsm"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      editable={false}
      cellClass={"noneditable"}
      valueGetter={(params) => {
        // TODO get data from server data instead of styleIdMap
        // const style = styleIdMap.get(params.data.style_ref);
        // if (style) {
        //   return style.actual_gsm;
        // }
        // return '';
      }}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"before_wash_width"}
      headerName={"Before-Wash Width"}
      field={"before_wash_width"}
      // TODO implement server-side filter
      filter={"agNumberColumnFilter"}
      comparator={utils.numberComparator}
    />,
    <AgGridColumn
      key={"after_wash_width"}
      headerName={"After-Wash Width"}
      field={"after_wash_width"}
      filter={"agNumberColumnFilter"}
      comparator={utils.numberComparator}
    />
  ];

  return {colArray};
};
