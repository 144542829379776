import {AgGridColumn} from "ag-grid-react";
import {gridApiUtils} from "../../../utils/gridApiUtils";
import {cacheKeys} from "../../../constants/cacheKeys";
import {emptyObjectGenerator} from "../../../components/emptyObjects";
import React from "react";
import {
  IPurchaseOrder,
} from "../../../types/rowModelTypes";
import {useAddMaker, useGetMakers} from "../../rowModels/useMakers";
import {MakerGrid} from "../../../components/grids/makerGrid";
import {GridApi} from "ag-grid-community";
import {useAddVendor} from "../../rowModels/useVendors";
import {useAddDyer} from "../../rowModels/useDyers";
import {DyerGrid} from "../../../components/grids/dyerGrid";
import {VendorGrid} from "../../../components/grids/vendorGrid";
import {useAddSubLocation, useGetSubLocations} from "../../selections/useSubLocations";
import {useAddLocation} from "../../rowModels/useLocations";
import {LocationGrid} from "../../../components/grids/locationGrid";

export const useFinishedGoodsGroup3 = (props: {
  doSkipId?: boolean,
  expandRow?: any,
  updateColorsByCrossReferencingVendorandCode: any,
  gridApi?: GridApi | null,
  hideDefaultGrid?: boolean,
}): {colArray: JSX.Element[]} => {

  const {mutate: addMaker} = useAddMaker();
  const {mutate: addVendor} = useAddVendor();
  const {mutate: addDyer} = useAddDyer();
  const {mutate: addLocation} = useAddLocation();

  const colArray = [
    ...(!props?.doSkipId ? [<AgGridColumn
      field={"id"}
      lockPosition
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      editable={false}
      cellClass={'suppress-fill-handle'}
      suppressColumnsToolPanel={true}
      hide
      key={"id"}
    />] : []),
    <AgGridColumn
      key={"notes"}
      field={"notes"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      minWidth={350}
    />,
    <AgGridColumn
      key={"trims_notes"}
      headerName={"Trims Notes"}
      field={"trims_notes"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      minWidth={350}
    />,
    <AgGridColumn
      key={"qc_report"}
      headerName={"QC Report"}
      field={"qc_report"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      cellEditor={"qcEditor"}
      cellClass={"ag-cell-overflow-visible-on-edit"}
    />,
    <AgGridColumn
      key={"dyer_ref"}
      headerName={"Dye House"}
      field={"dyer_ref"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = dyerIdMap.get(valueA)?.dyer_name || '';
      //   const refValueB = dyerIdMap.get(valueB)?.dyer_name || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      cellRendererParams={(rendererParams: any) => {
        return {
          deleteCellValue: (params: any) => {
            // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
          },
          gridComponent: (
            <DyerGrid
              hideDefaultGrid={true}
              filterId={rendererParams.data.dyer_ref}
            />
          )
        }
      }}
      cellEditorParams={{
        addDataService: addDyer,
        nameKey: 'dyer_name',
        cacheKey: cacheKeys.dyerData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.dyer,
        // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
      }}
      // TODO implement server-side filter
      // filter={"customRefFilter"}
      // floatingFilterComponent={"customRefFloatingFilter"}
      // floatingFilterComponentParams={{suppressFilterButton: true}}
      // filterParams={{
      //   cacheKey: cacheKeys.dyerData,
      //   filterData: dyerData,
      //   nameKey: 'dyer_name',
      // }}
    />,
    <AgGridColumn
      key={"vendor_ref"}
      headerName={"Vendor"}
      field={"vendor_ref"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = vendorIdMap.get(valueA)?.vendor_name || '';
      //   const refValueB = vendorIdMap.get(valueB)?.vendor_name || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      cellRendererParams={(rendererParams: any) => {
        return {
          deleteCellValue: (params: any) => {
            // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
          },
          gridComponent: (
            <VendorGrid
              hideDefaultGrid={true}
              filterId={rendererParams.data.vendor_ref}
            />
          )
        }
      }}
      cellEditorParams={{
        addDataService: addVendor,
        nameKey: 'vendor_name',
        cacheKey: cacheKeys.vendorData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.vendor,
        // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
      }}
      onCellValueChanged={props.updateColorsByCrossReferencingVendorandCode}
      // TODO implement server-side filter
      // filter={"customRefFilter"}
      // floatingFilterComponent={"customRefFloatingFilter"}
      // floatingFilterComponentParams={{suppressFilterButton: true}}
      // filterParams={{
      //   cacheKey: cacheKeys.vendorData,
      //   filterData: vendorData,
      //   nameKey: 'vendor_name',
      // }}
    />,
    <AgGridColumn
      key={"maker_ref"}
      headerName={"Yarn Maker"}
      field={"maker_ref"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = makerIdMap.get(valueA)?.maker_name || '';
      //   const refValueB = makerIdMap.get(valueB)?.maker_name || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      cellRendererParams={(rendererParams: any) => {
        return {
          deleteCellValue: (params: any) => {
            // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
          },
          gridComponent: (
            <MakerGrid
              hideDefaultGrid={true}
              filterId={rendererParams.data.maker_ref}
            />
          )
        }
      }
      }
      cellEditorParams={{
        addDataService: addMaker,
        nameKey: 'maker_name',
        cacheKey: cacheKeys.makerData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.maker,
        // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
      }
      }
      // TODO implement server-side filter
      // filter={"customRefFilter"}
      // floatingFilterComponent={"customRefFloatingFilter"}
      // floatingFilterComponentParams={{suppressFilterButton: true}}
      // filterParams={{
      //   cacheKey: cacheKeys.makerData,
      //   filterData: makerData,
      //   nameKey: 'maker_name',
      // }}
    />,
    <AgGridColumn
      key={"location"}
      headerName={"Loc"}
      field={"location_ref"}
      cellEditor={"autoCompleteReferenceEditor"}
      cellRenderer={"autoCompleteReferenceRenderer"}
      // TODO rewrite comparator by using contents retrieved from paginated data
      // comparator={(valueA, valueB) => {
      //   const refValueA = locationIdMap.get(valueA)?.location_name || '';
      //   const refValueB = locationIdMap.get(valueB)?.location_name || '';
      //   return utils.baseComparator(refValueA, refValueB);
      // }}
      editable={gridApiUtils.setRefEditable}
      onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
      cellRendererParams={(rendererParams: any) => {
        return {
          deleteCellValue: (params: any) => {
            // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
          },
          gridComponent: (
            <LocationGrid
              hideDefaultGrid={true}
              filterId={rendererParams.data.location_ref}
            />
          )
        }
      }}
      cellEditorParams={{
        addDataService: addLocation,
        nameKey: 'location_name',
        cacheKey: cacheKeys.locationData,
        // TODO make empty object serverside
        emptyObject: emptyObjectGenerator.location,
        // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
      }}
      // TODO implement server-side filter
      // filter={"customRefFilter"}
      // floatingFilterComponent={"customRefFloatingFilter"}
      // floatingFilterComponentParams={{suppressFilterButton: true}}
      // filterParams={{
      //   cacheKey: cacheKeys.locationData,
      //   filterData: locationData,
      //   nameKey: 'location_name',
      // }}
    />,
    <AgGridColumn
      key={"sub_location"}
      headerName={"SubLoc"}
      field={"sub_location"}
      // TODO implement server-side filter
      filter={"agTextColumnFilter"}
      // cellEditor={"autoCompleteEditor"}
      cellRenderer={"autoCompleteRenderer"}
      // cellEditorParams={{
      //   getDataHook: useGetSubLocations,
      //   addDataServiceHook: useAddSubLocation,
      //   nameKey: 'sub_loc',
      //   cacheKey: cacheKeys.sub_locs,
      // }}
      singleClickEdit
    />
  ];

  return {colArray};
};
