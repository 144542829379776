import React, {useEffect, useMemo, useState} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {AlpineContainer} from "../containers/defaultAlpineContainer";
import {contextMenuItems} from "../agContextMenu";
import {gridApiUtils} from "../../utils/gridApiUtils";
import {cacheKeys} from "../../constants/allConsts";
import {AddressEditor} from "../agFrameworkComponents/editors/addressEditor";
import {emptyObjectGenerator} from "../emptyObjects";
import {ButtonBar} from "../buttonBar";
import {useQueryClient} from "react-query";
import {utils} from "../../utils/utils";
import {CheckmarkRenderer} from "../agFrameworkComponents/renderers/checkmarkRenderer";
import {CheckmarkEditor} from "../agFrameworkComponents/editors/checkmarkEditor";
import {CustomRefFilter} from "../agFrameworkComponents/filters/customRefFilter";
import {CustomRefFloatingFilter} from "../agFrameworkComponents/filters/customRefFloatingFilter";
import {AutoCompleteReferenceEditor} from "../agFrameworkComponents/editors/autoCompleteRefEditor";
import {AutoCompleteReferenceRenderer} from "../agFrameworkComponents/renderers/autoCompleteRefRenderer";
import {AutoCompleteEditor} from "../agFrameworkComponents/editors/autoCompleteEditor";
import {AutoCompleteRenderer} from "../agFrameworkComponents/renderers/autoCompleteRenderer";
import {RowEditorModal} from "../modals/rowEditorModal";
import {FirstColRenderer} from "../agFrameworkComponents/renderers/firstColRenderer";
import {DateEditor} from "../agFrameworkComponents/editors/dateEditor";
import {useGetQueryParams} from "../../hooks/useQueryParams";
import {useAddRoll, useDeleteRoll, useGetRolls, useUpdateRoll} from "../../hooks/rowModels/useRolls";
import {GridApi} from "ag-grid-community";
import styled from "styled-components";
import {Button} from "@material-ui/core";
import {FadeIn, MuiButtonRed} from "../../styledComponentPresets/presets";
import {RollAdjustmentsEditor} from "../agFrameworkComponents/editors/rollAdjustmentsEditor";
import {RollAdjustmentModal} from "../modals/rollAdjustmentModal";
import {useRollGroup1} from "../../hooks/gridFragments/rollGrid/useRollGridGroup1";
import {useRollGroup2} from "../../hooks/gridFragments/rollGrid/useRollGridGroup2";
import {useRollGroup3} from "../../hooks/gridFragments/rollGrid/useRollGridGroup3";
import {RollSectionedGrid} from "./customPopupGrids/rollsSectionedGrid/rollSectionedGrid";
import {rollUtils} from "../../utils/rollUtils";

export const RollGrid = (props: { lotToFilter?: string, hideDefaultGrid?: boolean, filterId?: string | null, gridApi?: GridApi | null }): JSX.Element => {
  const [gridApi, setGridApi] = useState(null);
  const [parentGridApi] = useState(props?.gridApi || null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [showClearFilters, setShowClearFilters] = useState<boolean>(false);
  const [serverSideDataSource, setServerSideDataSource] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedRowFilterId, setExpandedRowFilterId] = useState<string>('');
  const [isDataDisplayed, setIsDataDisplayed] = useState<boolean>(false);
  const [rollAdjustmentsId, setRollAdjustmentsId] = useState<string>('');
  const [isRollAdjustmentsPopupOpen, setIsRollAdjustmentsPopupOpen] = useState<boolean>(false);

  const {data, isLoading} = useGetRolls();

  const {mutate: addRoll} = useAddRoll();
  const {mutate: updateRoll} = useUpdateRoll();
  const {mutate: deleteRoll} = useDeleteRoll();

  let {lot: lotToFilter}: { lot: string } = useGetQueryParams();

  if (props?.lotToFilter) {
    lotToFilter = props.lotToFilter;
  }

  const queryClient = useQueryClient();

  const updateColorsByCrossReferencingVendorandCode = (params: any) => {
    // TODO rewrite implementation for cross referencing vendor and color without idMap
    // const colorRow = colorIdMap.get(params.data.color_code_ref);
    // let laaColor = '';
    // let vendorColor = '';
    // if (params.data.color_code_ref && params.data.vendor_ref) {
    //   if (colorRow?.vendor_ref === params.data.vendor_ref) {
    //     laaColor = colorRow?.id || '';
    //     vendorColor = colorRow?.id || '';
    //   }
    // }
    // // if empty, we don't want to replace what's already there
    // if (laaColor) params.data.laa_color_ref = laaColor;
    // // if empty, we don't want to replace what's already there
    // if (vendorColor) params.data.vendor_color_ref = vendorColor;
    // updateRoll(params.node.data, {
    //   onSuccess: () => {
    //     queryClient.invalidateQueries(cacheKeys.rollData);
    //   }
    // });
    // params.api.redrawRows({rowNodes: [params.node]});
  };
  const lockInLbPerYd = (params: any) => {
    // ONLY update if lb_per_yd isn't filled in already. We're essentially "locking in" this value once yard and pounds out are set
    if (params?.data?.lb_per_yd === '' && params?.data?.yd !== '' && params?.data?.lb_out !== '') {
      const lbPerYd = utils.roundNumber(((Number(params.data.lb_out) || 0) / (Number(params.data.yd) || 0)), 6);
      params.data.lb_per_yd = lbPerYd;
      updateRoll(params?.data);
    }
  };

  const openRollAdjustments = (id: string) => {
    setRollAdjustmentsId(id);
    setIsRollAdjustmentsPopupOpen(true);
  };

  const expandRow = (rowId: string) => {
    setExpandedRowFilterId(rowId);
    setIsModalOpen(true);
  };

  const {colArray: group1cols} = useRollGroup1({
    expandRow: expandRow,
    hideDefaultGrid: props?.hideDefaultGrid,
    lockInLbPerYd: lockInLbPerYd,
    openRollAdjustments: openRollAdjustments,
    updateColorsByCrossReferencingVendorandCode: updateColorsByCrossReferencingVendorandCode,
  });

  const {colArray: group2cols} = useRollGroup2({
    doSkipId: true,
    lockInLbPerYd: lockInLbPerYd,
    openRollAdjustments: openRollAdjustments,
  });

  const {colArray: group3cols} = useRollGroup3({
    doSkipId: true,
    updateColorsByCrossReferencingVendorandCode: updateColorsByCrossReferencingVendorandCode,
  });

  useEffect(() => {
    // Load only once on mount to populate grid. Allow FE to take care of updates optimistically
    if (!isDataDisplayed && !isLoading) {
      // TODO get rid of emptyObjectGenerator. Get empty row from server instead (the grid needs at least 1 row for user to right click to add new rows)
      //@ts-ignore
      setServerSideDataSource(gridApiUtils.setServerSideDatasource(!isLoading, (Array.isArray(data) && data?.length > 0) ? data : [emptyObjectGenerator.roll(true)]));
      setIsDataDisplayed(true);
    }
  }, [!isLoading]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    if (props?.filterId) {
      const filterModel = {
        id: {
          filter: props.filterId,
          filterType: "text",
          type: "contains"
        }
      };
      params.api.setFilterModel(filterModel);
    }
    if (lotToFilter) {
      const filterModel = {
        lot_number: {
          filter: lotToFilter,
          filterType: "text",
          type: "equals"
        }
      };
      params.api.setFilterModel(filterModel);
      setShowClearFilters(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setExpandedRowFilterId('');
  };

  const onCellValueChanged = (params: any) => {
    // update server
    updateRoll(params?.data);
    gridApiUtils.updatePopupTransactionUpdates(parentGridApi, gridApi, props?.filterId);
  };

  const onFilterChanged = (filterParams: any) => {
    if (!gridApi) return;
    // @ts-ignore
    if (_.isEmpty(gridApi?.getFilterModel()) && showClearFilters) setShowClearFilters(false);
    else {
      // @ts-ignore
      if (!_.isEmpty(gridApi?.getFilterModel()) && !!gridApi?.getFilterModel()?.lot_number && !showClearFilters) setShowClearFilters(true);
    }
  };

  const clearFilters = () => {
    if (!gridApi) return;
    // @ts-ignore
    gridApi.setFilterModel(null);
  };

  const closeRollAdjustments = () => {
    if (gridApi) {
      //@ts-ignore
      const expandedNode = gridApi?.getRowNode(rollAdjustmentsId);
      //@ts-ignore
      gridApi?.redrawRows({rowNodes: [expandedNode]});
    }
    setIsRollAdjustmentsPopupOpen(false);
    setRollAdjustmentsId('');
  };


  /**
   * @description processes cell for clipboard so the right contents get copied.
   * Used primarily for reference buttons where you want the button names to
   * be copied rather than the reference id the cell actually holds
   * */
  const processCellForClipboard = (params: any) => {
    // TODO rewrite cell processer for clipboard (example implementation in purchaseOrderGrid)
    // switch (params?.column?.colId) {
    //   case "style_ref":
    //     return styleIdMap.get(params?.value)?.style_name;
    //   case "color_code_ref":
    //     return colorIdMap.get(params?.value)?.color_code;
    //   case "laa_color_ref":
    //     return colorIdMap.get(params?.value)?.laa_color;
    //   case "vendor_color_ref":
    //     return colorIdMap.get(params?.value)?.vendor_color;
    //   case "vendor_ref":
    //     return vendorIdMap.get(params?.value)?.vendor_name;
    // }
    return params.value;
  };

  return (
    <AlpineContainer hideDefaultGrid={props?.hideDefaultGrid}>
      <ButtonBar
        addRowTitle="Add Roll"
        cacheKey={cacheKeys.rollData}
        isAllDataLoaded={!isLoading}
        addRowEmptyObject={() => {
          // we don't want users to create rows unless there's a mechanism that connects the new rows to an existing lot
        }}
        hideAddButton
        // pass in gridApi for buttonBar to manipulate the grid
        gridApi={gridApi}
        gridComponent={<RollSectionedGrid
          hideDefaultGrid={true}
          // pass in gridApi for buttonBar to manipulate the grid
          gridApi={gridApi}
          openRollAdjustments={openRollAdjustments}
          processCellForClipboard={processCellForClipboard}
          lockInLbPerYd={lockInLbPerYd}
          updateColorsByCrossReferencingVendorandCode={updateColorsByCrossReferencingVendorandCode}
        />}
      >
        <StyledRightButtonsContainer>
          {showClearFilters && (<StyledRowButtonRed
            onClick={clearFilters}
            variant={"contained"}
            color={"primary"}
            size={"medium"}
          >
            Clear Lot # Filter
          </StyledRowButtonRed>)}
        </StyledRightButtonsContainer>
      </ButtonBar>
      <AgGridReact
        defaultColDef={{
          resizable: true,
          editable: true,
          floatingFilter: true,
          filter: true,
          sortable: true,
          filterParams: {
            debounceMs: 100
          }
        }}
        enableRangeSelection={!props?.filterId}
        enableFillHandle={!props?.filterId}
        fillHandleDirection={'y'}
        fillOperation={rollUtils.setFillRollOperation}
        onFillEnd={gridApiUtils.fillEnd}
        processCellForClipboard={processCellForClipboard}
        getRowNodeId={(data) => {
          return data.id;
        }}
        // allows relevant data to be set for calculations in lots
        suppressColumnVirtualisation={true}
        // allow single-edit if grid is displayed in a rowEditorModal
        singleClickEdit={!!props?.filterId}
        onFilterChanged={onFilterChanged}
        suppressRowClickSelection={true}
        rowSelection={'multiple'}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={50}
        animateRows={true}
        rowModelType={'serverSide'}
        serverSideDatasource={serverSideDataSource}
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        suppressMenuHide={!!props?.hideDefaultGrid}
        // TODO make roll have popup asking for lot number when adding new entry
        //@ts-ignore
        getContextMenuItems={(params) => props?.hideDefaultGrid ? [] : contextMenuItems({
          cacheKey: cacheKeys.rollData,
          queryClient: queryClient,
          expandRow: expandRow,
          params,
          disableAddRows: true,
          // TODO make empty object serverside
          makeEmptyObject: emptyObjectGenerator.roll,
          addRowServerCall: addRoll,
          deleteRowServerCall: deleteRoll,
          rowDescription: 'roll',
          preventRowEdits: true,
        })}
        stopEditingWhenCellsLoseFocus={true}
        frameworkComponents={{
          customRefFilter: CustomRefFilter,
          customRefFloatingFilter: CustomRefFloatingFilter,
          autoCompleteReferenceEditor: AutoCompleteReferenceEditor,
          autoCompleteReferenceRenderer: AutoCompleteReferenceRenderer,
          autoCompleteEditor: AutoCompleteEditor,
          autoCompleteRenderer: AutoCompleteRenderer,
          addressEditor: AddressEditor,
          checkmarkRenderer: CheckmarkRenderer,
          checkmarkEditor: CheckmarkEditor,
          firstColRenderer: FirstColRenderer,
          dateEditor: DateEditor,
          rollAdjustmentsEditor: RollAdjustmentsEditor,
        }}
      >
        {group1cols}
        {group2cols}
        {group3cols}
      </AgGridReact>
      <RowEditorModal
        open={isModalOpen}
        close={closeModal}
        title={"Edit Roll"}
        gridComponent={<RollSectionedGrid
          hideDefaultGrid={true}
          filterId={expandedRowFilterId}
          // pass in gridApi for buttonBar to manipulate the grid
          gridApi={gridApi}
          openRollAdjustments={openRollAdjustments}
          processCellForClipboard={processCellForClipboard}
          lockInLbPerYd={lockInLbPerYd}
          updateColorsByCrossReferencingVendorandCode={updateColorsByCrossReferencingVendorandCode}
        />}
      />
      {isRollAdjustmentsPopupOpen &&
      <RollAdjustmentModal
        open={isRollAdjustmentsPopupOpen}
        close={closeRollAdjustments}
        rollAdjustmentsId={rollAdjustmentsId}
        gridApi={gridApi}
      />
      }
    </AlpineContainer>
  );
};

const StyledRowButtonRed = styled(Button)`
  ${MuiButtonRed};
  &.MuiButtonBase-root {
    margin-left: 10px;
    animation: ${FadeIn} 0.2s linear forwards;
  }
`;

const StyledRightButtonsContainer = styled.div`
  margin-left: auto;
`;
