import React, {useEffect, useMemo, useState} from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {AlpineContainer} from "../containers/defaultAlpineContainer";
import {contextMenuItems} from "../agContextMenu";
import {utils} from "../../utils/utils";
import {gridApiUtils} from "../../utils/gridApiUtils";
import {routes, cacheKeys} from "../../constants/allConsts";
import {CustomRefFilter} from "../agFrameworkComponents/filters/customRefFilter";
import {CustomRefFloatingFilter} from "../agFrameworkComponents/filters/customRefFloatingFilter";
import {emptyObjectGenerator} from "../emptyObjects";
import {ButtonBar} from "../buttonBar";
import {AutoCompleteReferenceEditor} from "../agFrameworkComponents/editors/autoCompleteRefEditor";
import {useQueryClient} from "react-query";
import {AutoCompleteReferenceRenderer} from "../agFrameworkComponents/renderers/autoCompleteRefRenderer";
import {useAddDescription, useGetDescriptions} from "../../hooks/selections/useDesc";
import {AutoCompleteEditor} from "../agFrameworkComponents/editors/autoCompleteEditor";
import {AutoCompleteRenderer} from "../agFrameworkComponents/renderers/autoCompleteRenderer";
import {useAddContent, useGetContents} from "../../hooks/selections/useContents";
import {RowEditorModal} from "../modals/rowEditorModal";
import {FirstColRenderer} from "../agFrameworkComponents/renderers/firstColRenderer";
import {DateEditor} from "../agFrameworkComponents/editors/dateEditor";
import {BasicSelectionEditor} from "../agFrameworkComponents/editors/basicSelectionEditor";
import {BodyScrollEvent, GridApi} from "ag-grid-community";
import { IColor, IMaker, IYarnType } from '../../types/rowModelTypes';
import { useGetRowModel, useGetRowModelInfinite, 
        useGetRowModelBasic, useAddRowModel, 
        useDeleteRowModel, useUpdateRowModel } from '../../hooks/rowModels/RowModelHooks';
import _, { filter } from "lodash";
import { RowModelServices } from '../../services/rowModels/RowModelServices';
import { ColorGrid } from './colorGrid';

const rowModelServices = {
  colors: new RowModelServices<IColor>(routes.DEMO_COLORS)
}

const GetSingleYarnTypeForPopup = (id?: string) => {
  return useGetRowModel<IYarnType>(routes.DEMO_MASTER_CODES , {id});
};

const GetInfiniteYarnType = () => {
  return useGetRowModelInfinite<IYarnType>(routes.DEMO_MASTER_CODES);
};

export const YarnTypeGrid = (props: { hideDefaultGrid?: boolean, filterId?: string | null, gridApi?: GridApi | null }): JSX.Element => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [parentGridApi] = useState(props?.gridApi || null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [serverSideDataSource, setServerSideDataSource] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedRowFilterId, setExpandedRowFilterId] = useState<string>('');
  const [isDataDisplayed, setIsDataDisplayed] = useState<boolean>(false);

  const yarnTypes = props?.filterId ? GetSingleYarnTypeForPopup(props.filterId) : GetInfiniteYarnType();

  const {mutate: addYarnType} = useAddRowModel<IYarnType>(routes.DEMO_MASTER_CODES);
  const {mutate: updateYarnType} = useUpdateRowModel<IYarnType>(routes.DEMO_MASTER_CODES);
  const {mutate: deleteYarnType} = useDeleteRowModel<IYarnType>(routes.DEMO_MASTER_CODES);

  const queryClient = useQueryClient();

  useEffect(() => {
    // Load only once on mount to populate grid. Allow FE to take care of updates optimistically
    if (!isDataDisplayed && !yarnTypes?.isLoading) {
      let data = emptyObjectGenerator.yarnType();
      data = props.filterId ? yarnTypes?.data : yarnTypes?.data?.pages
      // TODO get rid of emptyObjectGenerator. Get empty row from server instead (the grid needs at least 1 row for user to right click to add new rows)
      //@ts-ignore
      const setDataSourceFunc = props?.filterId ? gridApiUtils.setServerSideDatasource : gridApiUtils.setServerSidePaginatedDatasource;
      const dataSource: any = setDataSourceFunc(yarnTypes?.isSuccess, data)
      setServerSideDataSource(dataSource);
      setIsDataDisplayed(true);
    }
  }, [yarnTypes?.isLoading]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const closeModal = () => {
    if(gridApi) {
      const expandedNode = gridApi?.getRowNode(expandedRowFilterId);
      // refresh the previously expanded node in case changes were made to it while in expanded mode
      if (expandedNode) {
        gridApi?.redrawRows({rowNodes: [expandedNode]});
      }
    }
    setIsModalOpen(false);
    setExpandedRowFilterId('');
  };

  const expandRow = (rowId: string) => {
    setExpandedRowFilterId(rowId);
    setIsModalOpen(true);
  };

  const onCellValueChanged = (params: any) => {
    // update server
    updateYarnType(params?.data);
    gridApiUtils.updatePopupTransactionUpdates(parentGridApi, gridApi, props?.filterId);
  };

  return (
    <AlpineContainer hideDefaultGrid={props?.hideDefaultGrid}>
      <ButtonBar
        addRowTitle="Add Yarn Type"
        cacheKey={cacheKeys.yarnTypeData}
        isAllDataLoaded={!yarnTypes?.isLoading}
        addRowEmptyObject={(onAddRowSuccess: any) => {
          let newRowData = {}
          // invalidate queries to refetch data from server
          addYarnType(newRowData, {
            onSuccess: (data) => {
              console.log(`NEW DATA: ${JSON.stringify(data,null,2)}`)
              queryClient.invalidateQueries([cacheKeys.yarnTypeData]);
              setExpandedRowFilterId(data.id);
              onAddRowSuccess(data);
            }
          });
        }}
        // pass in gridApi for buttonBar to manipulate the grid
        gridApi={gridApi}
        gridComponent={<YarnTypeGrid gridApi={gridApi}/>}
      >
      </ButtonBar>
      <AgGridReact
        defaultColDef={{
          resizable: true,
          editable: true,
          floatingFilter: true,
          filter: true,
          sortable: true,
          filterParams: {
            debounceMs: 100
          }
        }}
        enableRangeSelection={!props?.filterId}
        enableFillHandle={!props?.filterId}
        fillHandleDirection={'y'}
        fillOperation={gridApiUtils.setFillOperation}
        onFillEnd={gridApiUtils.fillEnd}
        getRowNodeId={(data) => {
          return data.id;
        }}
        // turn off column virtualization if grid is displayed in a rowEditorModal (otherwise, not all the columns will show)
        suppressColumnVirtualisation={!!props?.filterId}
        // allow single-edit if grid is displayed in a rowEditorModal
        singleClickEdit={!!props?.filterId}
        suppressRowClickSelection={true}
        rowSelection={'multiple'}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={50}
        animateRows={true}
        rowModelType={'serverSide'}
        serverSideDatasource={serverSideDataSource}
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        suppressMenuHide={!!props?.hideDefaultGrid}
        //@ts-ignore
        getContextMenuItems={(params) => props?.hideDefaultGrid ? [] : contextMenuItems({
          cacheKey: cacheKeys.yarnTypeData,
          queryClient: queryClient,
          expandRow: expandRow,
          params,
          // TODO make empty object serverside
          makeEmptyObject: emptyObjectGenerator.roll,
          addRowServerCall: addYarnType,
          deleteRowServerCall: deleteYarnType,
          rowDescription: 'yarn type',
        })}
        stopEditingWhenCellsLoseFocus={true}
        frameworkComponents={{
          autoCompleteReferenceEditor: AutoCompleteReferenceEditor,
          autoCompleteReferenceRenderer: AutoCompleteReferenceRenderer,
          autoCompleteEditor: AutoCompleteEditor,
          autoCompleteRenderer: AutoCompleteRenderer,
          customRefFilter: CustomRefFilter,
          customRefFloatingFilter: CustomRefFloatingFilter,
          firstColRenderer: FirstColRenderer,
          dateEditor: DateEditor,
          basicSelectionEditor: BasicSelectionEditor,
        }}
      >
        <AgGridColumn
          field={"id"}
          lockPosition
          editable={false}
          cellClass={'suppress-fill-handle'}
          suppressColumnsToolPanel={true}
          hide
        />
        <AgGridColumn
          headerName="Yarn Master Code"
          field={"name"}
          cellRenderer={"firstColRenderer"}
          cellRendererParams={{expandRow: expandRow}}
          lockPosition
          checkboxSelection={!props?.hideDefaultGrid}
          width={300}
        />
        <AgGridColumn field={"size"}
                      width={150}
        />
        <AgGridColumn
          headerName={"Date Created"}
          field={"date_created"}
          width={200}
          cellEditor={"dateEditor"}
          cellClass={"ag-cell-overflow-visible-on-edit"}
          filterParams={{
            comparator: utils.filterDateComparatorfunction, // comparator for filters
            inRangeInclusive: true
          }}
          comparator={utils.dateComparator} // comparator for sorting
        />
        <AgGridColumn
          headerName={"Description"}
          field={"description"}
          // cellEditor={"autoCompleteEditor"}
          cellRenderer={"autoCompleteRenderer"}
          // cellEditorParams={{
          //   getDataHook: useGetDescriptions,
          //   addDataServiceHook: useAddDescription,
          //   nameKey: 'desc',
          //   cacheKey: cacheKeys.descriptions,
          // }}
        />
        <AgGridColumn
          headerName={"Content"}
          field={"content"}
          // cellEditor={"autoCompleteEditor"}
          cellRenderer={"autoCompleteRenderer"}
          // cellEditorParams={{
          //   getDataHook: useGetContents,
          //   addDataServiceHook: useAddContent,
          //   nameKey: 'content',
          //   cacheKey: cacheKeys.contents,
          // }}
        />
        <AgGridColumn
          headerName={"Color"}
          field={"color"}
          // cellEditor={"autoCompleteEditor"}
          cellRenderer={"autoCompleteRenderer"}
          cellEditorParams={{
            // TODO
            // getDataHook: useGetYarnTypeColors,
            // addDataServiceHook: useAddYarnTypeColor,
            // nameKey: 'color',
            // cacheKey: cacheKeys.yarn_type_colors,
          }}
        />
        <AgGridColumn
          headerName={"Price/LB"}
          field={"price_per_lb"}
          valueFormatter={(params: any) => {
            return utils.USDFormatter(params.value);
          }}
          comparator={utils.numberComparator}
        />
        <AgGridColumn
          headerName={"Price/KG"}
          field={"price_per_kg"}
          valueFormatter={(params: any) => {
            return utils.USDFormatter(params.value);
          }}
          comparator={utils.numberComparator}
        />
        <AgGridColumn
          headerName={"Cost"}
          field={"cost"}
          width={200}
          valueFormatter={(params: any) => {
            return utils.USDFormatter(params.value);
          }}
          comparator={utils.numberComparator}
        />
        <AgGridColumn
          field={"notes"}
          minWidth={300}
          headerClass={"span-three-fourths"}
          cellClass={"span-three-fourths"}
          flex={1}
        />
      </AgGridReact>
      <RowEditorModal
        open={isModalOpen}
        close={closeModal}
        title={"Edit Master Code"}
        gridComponent={
          <YarnTypeGrid
            hideDefaultGrid={true}
            filterId={expandedRowFilterId}
            gridApi={gridApi}
          />}
      />
    </AlpineContainer>
  );
};
