import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  useState,
} from 'react';

const CustomRefFilter = forwardRef((props: {cacheKey: string, filterData: any, nameKey: string, valueGetter: any}, ref) => {
  const [filterText, setFilterText] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current.value = filterText;
    }
    // @ts-ignore
    props.filterChangedCallback();
  }, [filterText]);

  const isFilterActive = () => {
    return (
      filterText !== null &&
      filterText !== undefined &&
      filterText !== ''
    );
  };

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      isFilterActive,

      doesFilterPass(params: any) {
        const value = props?.valueGetter(params);

        if (isFilterActive()) {
          if (!value) return false;
          // @ts-ignore
          const cacheKey = props?.cacheKey;
          const filterData = props?.filterData;
          if (cacheKey) {
            // @ts-ignore
            if (cacheKey && filterData) {
              const filtered = filterData.filter((dat: any) => dat.id === value)[0];
              return filtered[props.nameKey]?.toLowerCase().indexOf(String(filterText).toLowerCase()) >= 0;
            }
          }
          return false;
        }
      },

      getModel() {
        return isFilterActive() ? filterText : null;
      },

      setModel(model: any) {
        setFilterText(model);
      },

      myMethodForTakingValueFromFloatingFilter(value: any) {
        setFilterText(value);
      },
    };
  });

  const onInputBoxChanged = (event: any) => {
    setFilterText(event.target.value);
  };

  return (
    <div style={{padding: '4px'}}>
      <div>
        <input
          ref={inputRef}
          style={{margin: '4px 0'}}
          type="text"
          onInput={onInputBoxChanged}
          className={"ag-input-field-input"}
        />
      </div>
    </div>
  );
});

export {CustomRefFilter};