import React, {forwardRef, useImperativeHandle, useState} from "react";
import styled from "styled-components";
import openInFullBlue from "../../../assets/img/open_in_full_blue.svg";
import {CellContainer, CellIconButton} from "../../../styledComponentPresets/presets";

const FirstColRenderer = forwardRef((props: {value: any, data: any, expandRow: any, valueFormatter?: any}, ref: any) => {
  const [selected, setSelected] = useState(props?.value);
  useImperativeHandle(ref, () => {
    return {
      refresh(params: any) {
        setSelected(params?.value || false);
      }
    };
  });

  const expandRow = () => {
    if (props?.expandRow && props?.data?.id) {
      props?.expandRow(props?.data?.id);
    }
  };

  return (
    <StyledContainer>
      <StyledExpandRowButton className={"show-on-hover"} onClick={expandRow}>
        <img src={openInFullBlue}/>
      </StyledExpandRowButton>
      <span>{props?.valueFormatter ? props?.valueFormatter(props) : props?.value}</span>
    </StyledContainer>
  );
});

export {FirstColRenderer};

const StyledExpandRowButton = styled.button`
  ${CellIconButton};
`;
const StyledContainer = styled.span`
  ${CellContainer};
`;
