import React from 'react';
import {createGlobalStyle, keyframes} from 'styled-components';
import {QueryClient, QueryClientProvider} from 'react-query';
import {colors} from "./constants/allConsts";
import {ReactQueryDevtools} from "react-query/devtools";
import {AppNavigator} from './components/Navigator';

const fadeLettersIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const GlobalStyle = createGlobalStyle`  
body, html, #root {
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
button {
  cursor: pointer;
}
.redFont {
  color: ${colors.dangerRed};
}
.bold {
  font-weight: bold;
}
.semi-bold {
  font-weight: 500;
}
.uppercase {
  text-transform: uppercase;
  * {
    text-transform: uppercase;
  }
}
.ref-deleted {
  color: ${colors.dangerRed};
  font-weight: bold;
  animation: ${fadeLettersIn} 0.5s linear forwards;
}

.context-icon {
  img {
    width: 1rem;
  }
}

.capitalize {
  text-transform: capitalize;
}

/* ag overrides */
.ag-cell.ag-cell-inline-editing.ag-cell-overflow-visible-on-edit{
  overflow: visible;
}

.ag-fill-handle {
  cursor: pointer !important;
}

// highlighted cells
.ag-cell-range-selected {
  // change cursor in bordering cells in range (excludes single cell selection)
  &.ag-cell-range-left, &.ag-cell-range-right, &.ag-cell-range-top, &.ag-cell-range-bottom {
    cursor: cell;
  }
}

// highlighted cells
.ag-cell-range-selected-1:not(.ag-cell-focus) {
  //change cursor for middle cells in range (excludes single cell selection)
  cursor: cell;
}

// suppress fill handle only. Does not change background or cursor
.suppress-fill-handle .ag-fill-handle {
  display: none;
}

.ag-row {
  &:not(.ag-row-hover) {
    .show-on-hover {
      opacity: 0;
    }    
    &:not(.ag-row-selected) {
      .ag-selection-checkbox {
        opacity: 0;
      }  
    }
    &.ag-row-selected {
      // if row with noneditable cells selected, color it using default (alpine) theme color
      .ag-cell.noneditable {
        &[unselectable="on"] {
          background: #bcdffb !important;
        }
    }
  }
  &:nth-child(even) .ag-cell.ag-cell-not-inline-editing.noneditable {
    background: #E8E8E8;
    &.ag-cell-range-selected {
      // if cell is selected, use default ag-grid color (alpine theme)
      background: #bcdffb;
    }
  }

  &:nth-child(odd) .ag-cell.ag-cell-not-inline-editing.noneditable {
    background: #F6F6F6;
    &.ag-cell-range-selected {
      // if cell is selected, use default ag-grid color (alpine theme)
      background: #bcdffb;
    }
  }

  .ag-cell.ag-cell-not-inline-editing.noneditable {
    .ag-fill-handle {
      display: none;
    }
  }
}

input[type=checkbox] {
  cursor: pointer;
}
`;

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // we store data on queries now
      },
    }
  });


  return (
    <>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        {process.env.NODE_ENV === "development" && <ReactQueryDevtools initialIsOpen={false} />}
        <AppNavigator />
      </QueryClientProvider>
    </>
  );
}

export default App;
