import React from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import styled from "styled-components";

export const AlpineContainer = (props: {hideDefaultGrid?: boolean, className?: string, children: any}): JSX.Element => {

    return (
        <StyledGridContainer
            id="laa-grid-container"
            className={`${props.hideDefaultGrid ? '' : 'ag-theme-alpine'}${props.className ? ` ${props.className}` : ''}`}
            //@ts-ignore
            hidedefaultgrid={props?.hideDefaultGrid}
        >
            {props.children}
        </StyledGridContainer>
    );
};


const StyledGridContainer = styled.div<{hidedefaultgrid: boolean}>`
  //height: calc(100vh - 100px);
  width: 100%;
  height: calc(100vh - 50px - 48px - 50px - 48px); // window height - [menu] - [tab] - [button row] - [secondary tabs row]
`;