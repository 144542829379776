import React, {useEffect, useState} from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {AlpineContainer} from "../containers/defaultAlpineContainer";
import {contextMenuItems} from "../agContextMenu";
import {gridApiUtils} from "../../utils/gridApiUtils";
import {routes, cacheKeys} from "../../constants/allConsts";
import {utils} from "../../utils/utils";
import {emptyObjectGenerator} from "../emptyObjects";
import {ButtonBar} from "../buttonBar";
import {useQueryClient} from "react-query";
import {AutoCompleteReferenceEditor} from "../agFrameworkComponents/editors/autoCompleteRefEditor";
import {AutoCompleteReferenceRenderer} from "../agFrameworkComponents/renderers/autoCompleteRefRenderer";
import {DyerGrid} from "./dyerGrid";
import {CustomRefFilter} from "../agFrameworkComponents/filters/customRefFilter";
import {CustomRefFloatingFilter} from "../agFrameworkComponents/filters/customRefFloatingFilter";
import {CheckmarkRenderer} from "../agFrameworkComponents/renderers/checkmarkRenderer";
import {CheckmarkEditor} from "../agFrameworkComponents/editors/checkmarkEditor";
import {useAddDescription, useGetDescriptions} from "../../hooks/selections/useDesc";
import {AutoCompleteRenderer} from "../agFrameworkComponents/renderers/autoCompleteRenderer";
import {AutoCompleteEditor} from "../agFrameworkComponents/editors/autoCompleteEditor";
import {useAddContent, useGetContents} from "../../hooks/selections/useContents";
import {RowEditorModal} from "../modals/rowEditorModal";
import {FirstColRenderer} from "../agFrameworkComponents/renderers/firstColRenderer";
import {BasicSelectionEditor} from "../agFrameworkComponents/editors/basicSelectionEditor";
import {BodyScrollEvent, GridApi} from "ag-grid-community";
import { IDyer, IStyle } from '../../types/rowModelTypes';
import { useGetRowModel, useGetRowModelInfinite, 
        useGetRowModelBasic, useAddRowModel, 
        useDeleteRowModel, useUpdateRowModel } from '../../hooks/rowModels/RowModelHooks';
import _, { filter } from "lodash";

const GetSingleStyleForPopup = (id?: string) => {
  return useGetRowModel<IStyle>(routes.DEMO_STYLES , {id});
};

const GetInfiniteStyles = () => {
  return useGetRowModelInfinite<IStyle>(routes.DEMO_STYLES);
};

export const StyleGrid = (props: { hideDefaultGrid?: boolean, filterId?: string | null, gridApi?: GridApi | null }): JSX.Element => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [parentGridApi] = useState(props?.gridApi || null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [serverSideDataSource, setServerSideDataSource] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedRowFilterId, setExpandedRowFilterId] = useState<string>('');
  const [isDataDisplayed, setIsDataDisplayed] = useState<boolean>(false);

  const styles = props?.filterId ? GetSingleStyleForPopup(props.filterId) : GetInfiniteStyles();

  const {mutate: addStyle} = useAddRowModel<IStyle>(routes.DEMO_STYLES);
  const {mutate: updateStyle} = useUpdateRowModel<IStyle>(routes.DEMO_STYLES);
  const {mutate: deleteStyle} = useDeleteRowModel<IStyle>(routes.DEMO_STYLES);
  const {mutate: addDyer} = useAddRowModel<IDyer>(routes.DEMO_DYERS);

  const queryClient = useQueryClient();

  useEffect(() => {
    // Load only once on mount to populate grid. Allow FE to take care of updates optimistically
    if (!isDataDisplayed && !styles?.isLoading) {
      let data = emptyObjectGenerator.maker();
      data = props.filterId ? styles?.data : styles?.data?.pages
      // TODO get rid of emptyObjectGenerator. Get empty row from server instead (the grid needs at least 1 row for user to right click to add new rows)
      //@ts-ignore
      const setDataSourceFunc = props?.filterId ? gridApiUtils.setServerSideDatasource : gridApiUtils.setServerSidePaginatedDatasource;
      const dataSource: any = setDataSourceFunc(styles?.isSuccess, data)
      setServerSideDataSource(dataSource);
      setIsDataDisplayed(true);
    }
  }, [styles?.isLoading]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const closeModal = () => {
    if(gridApi) {
      const expandedNode = gridApi?.getRowNode(expandedRowFilterId);
      // refresh the previously expanded node in case changes were made to it while in expanded mode
      if (expandedNode) {
        gridApi?.redrawRows({rowNodes: [expandedNode]});
      }
    }
    setIsModalOpen(false);
    setExpandedRowFilterId('');
  };

  const expandRow = (rowId: string) => {
    setExpandedRowFilterId(rowId);
    setIsModalOpen(true);
  };

  const onCellValueChanged = (params: any) => {
    // update server
    updateStyle(params?.data);
    gridApiUtils.updatePopupTransactionUpdates(parentGridApi, gridApi, props?.filterId);
  };

  /**
   * @description processes cell for clipboard so the right contents get copied.
   * Used primarily for reference buttons where you want the button names to
   * be copied rather than the reference id the cell actually holds
   * */
  const processCellForClipboard = (params: any) => {
    // TODO rewrite cell processer for clipboard (example implementation in purchaseOrderGrid)
    // switch (params?.column?.colId) {
    //   case "dyer_ref":
    //     return dyerIdMap.get(params?.value)?.dyer_name;
    // }
    return params.value;
  };

  return (
    <AlpineContainer hideDefaultGrid={props?.hideDefaultGrid}>
      <ButtonBar
        addRowTitle="Add Style"
        cacheKey={cacheKeys.styleData}
        isAllDataLoaded={!styles?.isLoading}
        addRowEmptyObject={(onAddRowSuccess: any) => {
          let newRowData = {}
          // invalidate queries to refetch data from server
          addStyle(newRowData, {
            onSuccess: (data) => {
              console.log(`NEW DATA: ${JSON.stringify(data,null,2)}`)
              queryClient.invalidateQueries([cacheKeys.styleData]);
              setExpandedRowFilterId(data.id);
              onAddRowSuccess(data);
            }
          });
        }}
        // pass in gridApi for buttonBar to manipulate the grid
        gridApi={gridApi}
        gridComponent={<StyleGrid gridApi={gridApi}/>}
      >
      </ButtonBar>
      <AgGridReact
        defaultColDef={{
          resizable: true,
          editable: true,
          floatingFilter: true,
          filter: true,
          sortable: true,
          filterParams: {
            debounceMs: 100
          }
        }}
        enableRangeSelection={!props?.filterId}
        enableFillHandle={!props?.filterId}
        fillHandleDirection={'y'}
        fillOperation={gridApiUtils.setFillOperation}
        onFillEnd={gridApiUtils.fillEnd}
        processCellForClipboard={processCellForClipboard}
        getRowNodeId={(data) => {
          return data.id;
        }}
        onBodyScroll={_.debounce((event: BodyScrollEvent) => gridApiUtils.customInfinitePaginationOnBodyScroll(event, styles, gridApi), 100)}
        // turn off column virtualization if grid is displayed in a rowEditorModal (otherwise, not all the columns will show)
        suppressColumnVirtualisation={!!props?.filterId}
        // allow single-edit if grid is displayed in a rowEditorModal
        singleClickEdit={!!props?.filterId}
        suppressRowClickSelection={true}
        rowSelection={'multiple'}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={50}
        animateRows={true}
        rowModelType={'serverSide'}
        serverSideDatasource={serverSideDataSource}
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        suppressMenuHide={!!props?.hideDefaultGrid}
        //@ts-ignore
        getContextMenuItems={(params) => props?.hideDefaultGrid ? [] : contextMenuItems({
          cacheKey: cacheKeys.styleData,
          queryClient: queryClient,
          expandRow: expandRow,
          params,
          // TODO make empty object serverside
          makeEmptyObject: emptyObjectGenerator.style,
          addRowServerCall: addStyle,
          deleteRowServerCall: deleteStyle,
          rowDescription: 'style'
        })}
        stopEditingWhenCellsLoseFocus={true}
        frameworkComponents={{
          autoCompleteReferenceEditor: AutoCompleteReferenceEditor,
          autoCompleteReferenceRenderer: AutoCompleteReferenceRenderer,
          autoCompleteEditor: AutoCompleteEditor,
          autoCompleteRenderer: AutoCompleteRenderer,
          customRefFilter: CustomRefFilter,
          customRefFloatingFilter: CustomRefFloatingFilter,
          checkmarkRenderer: CheckmarkRenderer,
          checkmarkEditor: CheckmarkEditor,
          firstColRenderer: FirstColRenderer,
          basicSelectionEditor: BasicSelectionEditor,
        }}
      >
        <AgGridColumn
          field={"id"}
          lockPosition
          filter={"agTextColumnFilter"}
          editable={false}
          cellClass={'suppress-fill-handle'}
          suppressColumnsToolPanel={true}
          hide
        />
        <AgGridColumn
          headerName={"Style Number"}
          field={"style_number"}
          cellRenderer={"firstColRenderer"}
          cellRendererParams={{expandRow: expandRow}}
          lockPosition
          checkboxSelection={!props?.hideDefaultGrid}
          filter={"agTextColumnFilter"}
        />
        <AgGridColumn
          field={"pattern"}
          width={150}
          cellEditor={"basicSelectionEditor"}
          cellEditorParams={{
            options: ['woven', 'knit', 'lace'],
          }}
          cellClass={"ag-cell-overflow-visible-on-edit"}
        />
        <AgGridColumn
          field={"desc"}
          // cellEditor={"autoCompleteEditor"}
          cellRenderer={"autoCompleteRenderer"}
          // cellEditorParams={{
          //   getDataHook: useGetDescriptions,
          //   addDataServiceHook: useAddDescription,
          //   nameKey: 'desc',
          //   cacheKey: cacheKeys.descriptions,
          // }}
        />
        <AgGridColumn
          field={"content"}
          width={200}
          // cellEditor={"autoCompleteEditor"}
          cellRenderer={"autoCompleteRenderer"}
          // cellEditorParams={{
          //   getDataHook: useGetContents,
          //   addDataServiceHook: useAddContent,
          //   nameKey: 'content',
          //   cacheKey: cacheKeys.contents,
          // }}
        />
        <AgGridColumn
          field={"notes"}
          headerClass={"span-three-fourths"}
          cellClass={"span-three-fourths"}
          width={350}
        />
        <AgGridColumn
          headerName={"Finished Width"}
          field={"finished_width"}
          comparator={utils.numberComparator}
        />
        <AgGridColumn
          headerName={"Dye House"}
          field={"dye_house_id"}
          cellEditor={"autoCompleteReferenceEditor"}
          cellRenderer={"autoCompleteReferenceRenderer"}
          // TODO rewrite comparator by using contents retrieved from paginated data
          // comparator={(valueA, valueB) => {
          //   const refValueA = dyerIdMap.get(valueA)?.dyer_name || '';
          //   const refValueB = dyerIdMap.get(valueB)?.dyer_name || '';
          //   return utils.baseComparator(refValueA, refValueB);
          // }}
          editable={gridApiUtils.setRefEditable}
          onCellClicked={gridApiUtils.editEmptyRefCellOnClick}
          cellRendererParams={(rendererParams: any) => {
            return {
              deleteCellValue: (params: any) => {
                // TODO call deleteReferenceButton to perform deletion using real server endpoints (see usePurchaseOrderGridGroup1.tsx for example)
              },
              gridComponent: (
                <DyerGrid
                  hideDefaultGrid={true}
                  filterId={rendererParams.data.dye_house_id}
                />
              )
            }
          }}
          cellEditorParams={{
            addDataService: addDyer,
            nameKey: 'dye_house_name',
            cacheKey: cacheKeys.dyerData,
            // TODO make empty object serverside
            emptyObject: emptyObjectGenerator.dyer,
            // TODO pass in getBasicListHook implementation for autoCompleteReferenceEditor (see usePurchaseOrderGridGroup1.tsx for example)
          }}
          // TODO implement server-side filter
          // filter={"customRefFilter"}
          // floatingFilterComponent={"customRefFloatingFilter"}
          // floatingFilterComponentParams={{suppressFilterButton: true}}
          // filterParams={{
          //   cacheKey: cacheKeys.dyerData,
          //   filterData: dyerData,
          //   nameKey: 'dyer_name',
          // }}
        />
        <AgGridColumn
          headerName={"Actual Linear Yield"}
          field={"actual_linear_yield"}
          comparator={utils.numberComparator}
        />
        <AgGridColumn
          headerName={"Actual Square Yield"}
          field={"actual_square_yield"}
          comparator={utils.numberComparator}
        />
        <AgGridColumn
          headerName={"Actual GSM"}
          field={"actual_gsm"}
          comparator={utils.numberComparator}
        />
      </AgGridReact>
      <RowEditorModal
        open={isModalOpen}
        close={closeModal}
        title={"Edit Style"}
        gridComponent={<StyleGrid hideDefaultGrid={true} filterId={expandedRowFilterId} gridApi={gridApi}/>}
      />
    </AlpineContainer>
  );
};
