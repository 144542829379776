import {useInfiniteQuery, useMutation, useQuery} from "react-query";
import {cacheKeys} from "../../constants/allConsts";
import { RowModelServices } from "../../services/rowModels/RowModelServices";
import {IDataPaged} from "../../types/rowModelTypes";
import { utils } from "../../utils/utils";

export function useGetRowModel<T>(apiRoute: string, rowModelOptions?: {id?: string | null | undefined}): any {
    const rowModelServices: RowModelServices<T> = new RowModelServices<T>(apiRoute)
    const cacheKey = utils.getCacheKeyForRoute(apiRoute);
    const result = useQuery({
        queryKey: rowModelOptions?.id ? [cacheKey, rowModelOptions?.id] : [cacheKey],
        queryFn: () => rowModelOptions?.id ? rowModelServices.getDataById(rowModelOptions?.id) : rowModelServices.getData(),
      });
      return result;
};

export function useGetRowModelBasic<T>(apiRoute: string, nameKey: string) {
    const rowModelServices: RowModelServices<T> = new RowModelServices<T>(apiRoute)
    const cacheKey = utils.getCacheKeyForRoute(apiRoute);
    const result = useQuery({
      queryKey: [cacheKey, 'basic'],
      queryFn: () => rowModelServices.getDataBasic([nameKey]),
    });
    return result;
};
  
/**
* @description use infinite queries to retrieve paged data */
export function useGetRowModelInfinite<T>(apiRoute: string): any {
    const rowModelServices: RowModelServices<T> = new RowModelServices<T>(apiRoute)
    const cacheKey = utils.getCacheKeyForRoute(apiRoute);
    const getRowModelInfinite = async ({pageParam = {page: 0, offset: null}}) => {
      return await rowModelServices.getDataPaged(pageParam.page, pageParam.offset);
    };
    // options to get more data based on server response (each page has 100 data elements by default, can be changed in backend if needed)
    const infiniteQueryOptions = {
      getNextPageParam: (lastPage: IDataPaged<T>, allPages: IDataPaged<T>[]) => {
        console.log("get fetch more:: ", {lastPage, allPages});
        return lastPage?.hasNextPage ? {page: Number(lastPage.nextPage), offset: lastPage.offset} : null;
      }
    };
    //@ts-ignore
    const data = useInfiniteQuery(cacheKey, getRowModelInfinite, infiniteQueryOptions);
    console.log("retrieved data:: ", data?.data?.pages);
    return data;
};
  
export function useAddRowModel<T>(apiRoute: string){
    const rowModelServices: RowModelServices<T> = new RowModelServices<T>(apiRoute)
    const result = useMutation(async (update: T) => {
      console.log(`UPDTAE REQUEST: ${JSON.stringify(update, null, 2)}`);
      return await rowModelServices.addData(update);
    });
    return result;
};
  
export function useUpdateRowModel<T>(apiRoute: string) {
    const rowModelServices: RowModelServices<T> = new RowModelServices<T>(apiRoute)
    const result = useMutation(async (update: T) => {
      return await rowModelServices.updateData(update);
    });
    return result;
};
  
export function useDeleteRowModel<T>(apiRoute: string) {
    const rowModelServices: RowModelServices<T> = new RowModelServices<T>(apiRoute)
    const result = useMutation(async (update: T) => {
      return await rowModelServices.deleteData(update);
    });
    return result;
};

// export class RowModelHooks<T> {
//     private rowModelServices: RowModelServices<T> = new RowModelServices<T>();
//     constructor(private apiRoute: string) {
//         this.rowModelServices.apiRoute = apiRoute;
//     };

//     public useGetRowModel = (rowModelOptions?: {id?: string | null | undefined}): any => {
//         const cacheKey = utils.getCacheKeyForRoute(this.rowModelServices.apiRoute);
//         const result = useQuery({
//           queryKey: rowModelOptions?.id ? [cacheKey, rowModelOptions?.id] : [cacheKey],
//           queryFn: () => rowModelOptions?.id ? this.rowModelServices.getDataById(rowModelOptions?.id) : this.rowModelServices.getData(),
//         });
//         return result;
//       };
      
//     public useGetRowModelBasic = (nameKey: string) => {
//         const result = useQuery({
//           queryKey: [cacheKeys.poData, 'basic'],
//           queryFn: () => this.rowModelServices.getDataBasic([nameKey]),
//         });
//         return result;
//     };
      
//     /**
//     * @description use infinite queries to retrieve paged data */
//     public useGetRowModelInfinite = (): any => {
//         const getRowModelInfinite = async ({pageParam = {page: 0, offset: null}}) => {
//           return await this.rowModelServices.getDataPaged(pageParam.page, pageParam.offset);
//         };
//         // options to get more data based on server response (each page has 100 data elements by default, can be changed in backend if needed)
//         const infiniteQueryOptions = {
//           getNextPageParam: (lastPage: IDataPaged<T>, allPages: IDataPaged<T>[]) => {
//             console.log("get fetch more:: ", {lastPage, allPages});
//             return lastPage?.hasNextPage ? {page: Number(lastPage.nextPage), offset: lastPage.offset} : null;
//           }
//         };
//         //@ts-ignore
//         const data = useInfiniteQuery(cacheKeys.poData, getRowModelInfinite, infiniteQueryOptions);
//         console.log("retrieved data:: ", data?.data?.pages);
//         return data;
//     };
      
//     public useAddRowModel = () => {
//         const result = useMutation(async (update: T) => {
//           return await this.rowModelServices.addData(update);
//         });
//         return result;
//     };
      
//     public useUpdateRowModel = () => {
//         const result = useMutation(async (update: T) => {
//           return await this.rowModelServices.updateData(update);
//         });
//         return result;
//     };
      
//     public useDeleteRowModel = () => {
//         const result = useMutation(async (update: T) => {
//           return await this.rowModelServices.deleteData(update);
//         });
//         return result;
//     };
// }