import React from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import styled from "styled-components";
import {PurchaseOrderFragmentLoader} from "./purchaseOrderFragmentLoader";
import {GridApi} from "ag-grid-community";
import {usePurchaseOrderGroup1} from "../../../../hooks/gridFragments/purchaseOrderGrid/usePurchaseOrderGridGroup1";
import {usePurchaseOrderGroup2} from "../../../../hooks/gridFragments/purchaseOrderGrid/usePurchaseOrderGridGroup2";
import {usePurchaseOrderGroup3} from "../../../../hooks/gridFragments/purchaseOrderGrid/usePurchaseOrderGridGroup3";
import {useGetPurchaseOrders, useGetPurchaseOrdersInfinite} from "../../../../hooks/rowModels/usePurchaseOrders";
import {getPurchaseOrderById, getPurchaseOrders} from "../../../../services/rowModels/purchaseOrderServices";
import {useQuery} from "react-query";
import {cacheKeys} from "../../../../constants/cacheKeys";

export const PurchaseOrderSectionedGrid = React.forwardRef((props: {
  hideDefaultGrid?: boolean,
  processCellForClipboard: (params: any) => void,
  filterId?: string,
  gridApi?: GridApi | null,
}, ref): JSX.Element => {

  // get selected purchase order by id
  const {data} = useGetPurchaseOrders({id: props?.filterId});

  return (
    <>
      <StyledSectionedGroup className={"group1"} groupName={"Group 1"}>
        <PurchaseOrderFragmentLoader
          {...props}
          data={data}
          getGridFragmentHook={usePurchaseOrderGroup1}
        />
      </StyledSectionedGroup>
      <StyledSectionedGroup className={"group2"} groupName={"Group 2"}>
        <PurchaseOrderFragmentLoader
          {...props}
          data={data}
          getGridFragmentHook={usePurchaseOrderGroup2}
        />
      </StyledSectionedGroup>
      <StyledSectionedGroup className={"group3"} groupName={"Group 3"}>
        <PurchaseOrderFragmentLoader
          {...props}
          data={data}
          getGridFragmentHook={usePurchaseOrderGroup3}
        />
      </StyledSectionedGroup>
    </>
  );
});

interface ISectionedGroup {
  groupName: string;
};

const StyledSectionedGroup = styled.div<ISectionedGroup>`
  padding: 15px;
  position: relative;
  margin-top: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  &:nth-child(1) {
    margin-top: 1rem;
  }
  &:before {
    content: ${(props) => `'${props.groupName}'`};
    position: absolute;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    top: -0.8rem;
    background: white;
    padding: 0 12px;
    left: 12px;
    color: #2D2D2D;
    border-radius: 4px;
   }
`;
