import {useMutation, useQuery} from "react-query";
import {cacheKeys} from "../../constants/allConsts";
import {
  addAdjustment,
  getAdjustments,
  updateAdjustment,
  deleteAdjustment,
} from "../../services/rowModels/adjustmentServices";
import {IAdjustment} from "../../types/rowModelTypes";

// TODO extend useGetAdjustments to be able to use getAdjustmentById once it's implemented (see usePurchaseOrders.ts for example)
export const useGetAdjustments = (): any => {
  const result = useQuery({
    queryKey: [cacheKeys.adjustmentData],
    queryFn: () => getAdjustments(),
  });
  return result;
};

// TODO implement useGetAdjustmentBasic (see usePurchaseorders.ts for example)

export const useAddAdjustment = () => {
  const result = useMutation(async (update: IAdjustment) => {
    return await addAdjustment(update);
  });
  return result;
};

export const useUpdateAdjustment = () => {
  const result = useMutation(async (update: IAdjustment) => {
    return await updateAdjustment(update);
  });
  return result;
};

export const useDeleteAdjustment = () => {
  const result = useMutation(async (update: IAdjustment) => {
    return await deleteAdjustment(update);
  });
  return result;
};
