import React from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import styled from "styled-components";
import {FinishedGoodsFragmentLoader} from "./finishedGoodsFragmentLoader";
import {GridApi} from "ag-grid-community";
import {FinishedGoodsAggregateTable} from "./finishedGoodsAggregateTable";
import {useFinishedGoodsGroup1} from "../../../../hooks/gridFragments/finishedGoodsGrid/useFinishedGoodsGridGroup1";
import {useFinishedGoodsGroup5} from "../../../../hooks/gridFragments/finishedGoodsGrid/useFinishedGoodsGridGroup5";
import {useFinishedGoodsGroup4} from "../../../../hooks/gridFragments/finishedGoodsGrid/useFinishedGoodsGridGroup4";
import {useFinishedGoodsGroup3} from "../../../../hooks/gridFragments/finishedGoodsGrid/useFinishedGoodsGridGroup3";
import {useFinishedGoodsGroup2} from "../../../../hooks/gridFragments/finishedGoodsGrid/useFinishedGoodsGridGroup2";
import {useGetPurchaseOrders} from "../../../../hooks/rowModels/usePurchaseOrders";
import {useGetFinishedGoods} from "../../../../hooks/rowModels/useFinishedGoods";

export const FinishedGoodsSectionedGrid = React.forwardRef((props: {
  hideDefaultGrid?: boolean,
  updateColorsByCrossReferencingVendorandCode: any,
  processCellForClipboard: (params: any) => void,
  filterId?: string,
  gridApi?: GridApi | null
}, ref): JSX.Element => {
  // get selected purchase order
  // TODO implement and pass to finishedgoods fragment loader (see purchaseORderSectionedGrid for example)
  // const {data} = useGetFinishedGoods({id: props?.filterId});
  return (
    <>
      <StyledSectionedGroup className={"group1"} groupName={"Group 1"}>
        <FinishedGoodsFragmentLoader
          {...props}
          getGridFragmentHook={useFinishedGoodsGroup1}
        />
      </StyledSectionedGroup>
      <StyledSectionedGroup className={"group2"} groupName={"Group 2"}>
        <FinishedGoodsFragmentLoader
          {...props}
          getGridFragmentHook={useFinishedGoodsGroup2}
        />
      </StyledSectionedGroup>
      <StyledSectionedGroup className={"group3"} groupName={"Group 3"}>
        <FinishedGoodsFragmentLoader
          {...props}
          getGridFragmentHook={useFinishedGoodsGroup3}
        />
      </StyledSectionedGroup>
      <StyledSectionedGroup className={"group4"} groupName={"Group 4"}>
        <FinishedGoodsFragmentLoader
          {...props}
          getGridFragmentHook={useFinishedGoodsGroup4}
        />
      </StyledSectionedGroup>
      <StyledSectionedGroup className={"group5"} groupName={"Group 5"}>
        <FinishedGoodsFragmentLoader
          {...props}
          getGridFragmentHook={useFinishedGoodsGroup5}
        />
      </StyledSectionedGroup>
      <StyledSectionedGroup className={"group6"} groupName={"Group 6"}>
        <FinishedGoodsAggregateTable filterId={props.filterId || ''} />
      </StyledSectionedGroup>
    </>
  );
});

interface ISectionedGroup {
  groupName: string;
};

const StyledSectionedGroup = styled.div<ISectionedGroup>`
  padding: 15px;
  position: relative;
  margin-top: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  &:nth-child(1) {
    margin-top: 1rem;
  }
  &:before {
    content: ${(props) => `'${props.groupName}'`};
    position: absolute;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    top: -0.8rem;
    background: white;
    padding: 0 12px;
    left: 12px;
    color: #2D2D2D;
    border-radius: 4px;
   }
`;
