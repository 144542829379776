import React, {useCallback, useMemo} from 'react';
import {AlpineContainer} from "../../../containers/defaultAlpineContainer";
import {useGetFinishedGoods} from "../../../../hooks/rowModels/useFinishedGoods";
import {
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import styled from "styled-components";
import {IFinishedGood} from "../../../../types/rowModelTypes";
import {utils} from "../../../../utils/utils";


const FinishedGoodsTable = (props: { filterId: string }): JSX.Element => {
  const [type, setType] = React.useState<number>(0);
  // TODO rewrite: get selected finished good directly from server
  const {data, isSuccess, isFetching} = useGetFinishedGoods();
  const selectedRoll = useMemo(() => (isSuccess && !isFetching) ? data?.filter((lot: IFinishedGood) => lot.id === props.filterId)[0] : {}, [data, props.filterId, isFetching]);

  const handleTypeChange = (event: React.ChangeEvent<{ value: any }>) => {
    setType(event.target.value);
  };

  const numSelfRollsOriginalReceived = Number(selectedRoll?.self_rolls_originally_received) || 0;
  const numRibRollsOriginalReceived = Number(selectedRoll?.rib_rolls_originally_received) || 0;
  const numSelfRollsUsed = Number(selectedRoll?.self_rolls_used) || 0;
  const numRibRollsUsed = Number(selectedRoll?.rib_rolls_used) || 0;
  const numSelfRollsInStock = Number(selectedRoll?.self_rolls_in_stock) || 0;
  const numRibRollsInStock = Number(selectedRoll?.rib_rolls_in_stock) || 0;
  const yardSelfRollsOriginalReceived = Number(selectedRoll?.self_rolls_originally_received_in_yd) || 0;
  const yardRibRollsOriginalReceived = Number(selectedRoll?.rib_rolls_originally_received_in_yd) || 0;
  const yardSelfRollsUsed = Number(selectedRoll?.self_rolls_used_in_yd) || 0;
  const yardRibRollsUsed = Number(selectedRoll?.rib_rolls_used_in_yd) || 0;
  const yardSelfRollsInStock = Number(selectedRoll?.self_rolls_in_stock_in_yd) || 0;
  const yardRibRollsInStock = Number(selectedRoll?.rib_rolls_in_stock_in_yd) || 0;
  const poundsOutSelfRollsOriginalReceived = Number(selectedRoll?.self_rolls_originally_received_in_lb_out) || 0;
  const poundsOutRibRollsOriginalReceived = Number(selectedRoll?.rib_rolls_originally_received_in_lb_out) || 0;
  const poundsOutSelfRollsUsed = Number(selectedRoll?.self_rolls_used_in_lb_out) || 0;
  const poundsOutRibRollsUsed = Number(selectedRoll?.rib_rolls_used_in_lb_out) || 0;
  const poundsOutSelfRollsInStock = Number(selectedRoll?.self_rolls_in_stock_in_lb_out) || 0;
  const poundsOutRibRollsInStock = Number(selectedRoll?.rib_rolls_in_stock_in_lb_out) || 0;

  const NumOriginal = () => (
    <>
      <StyledTableCell>{numSelfRollsOriginalReceived}</StyledTableCell>
      <StyledTableCell>{numRibRollsOriginalReceived}</StyledTableCell>
      <StyledTableCell>{utils.roundNumber((numSelfRollsOriginalReceived + numRibRollsOriginalReceived), 3)}</StyledTableCell>
    </>
  );

  const YardOriginal = useCallback(() => (
    <>
      <StyledTableCell>{yardSelfRollsOriginalReceived}</StyledTableCell>
      <StyledTableCell>{yardRibRollsOriginalReceived}</StyledTableCell>
      <StyledTableCell>{utils.roundNumber((yardSelfRollsOriginalReceived + yardRibRollsOriginalReceived), 3)}</StyledTableCell>
    </>
  ), [data, selectedRoll]);

  const PoundsOutOriginal = useCallback(() => (
    <>
      <StyledTableCell>{poundsOutSelfRollsOriginalReceived}</StyledTableCell>
      <StyledTableCell>{poundsOutRibRollsOriginalReceived}</StyledTableCell>
      <StyledTableCell>{utils.roundNumber((poundsOutSelfRollsOriginalReceived + poundsOutRibRollsOriginalReceived), 3)}</StyledTableCell>
    </>
  ), [data, selectedRoll]);

  const NumUsed = useCallback(() => (
    <>
      <StyledTableCell>{numSelfRollsUsed}</StyledTableCell>
      <StyledTableCell>{numRibRollsUsed}</StyledTableCell>
      <StyledTableCell>{utils.roundNumber((numSelfRollsUsed + numRibRollsUsed), 3)}</StyledTableCell>
    </>
  ), [data, selectedRoll]);

  const YardUsed = useCallback(() => (
    <>
      <StyledTableCell>{yardSelfRollsUsed}</StyledTableCell>
      <StyledTableCell>{yardRibRollsUsed}</StyledTableCell>
      <StyledTableCell>{utils.roundNumber((yardSelfRollsUsed + yardRibRollsUsed), 3)}</StyledTableCell>
    </>
  ), [data, selectedRoll]);

  const PoundsOutUsed = useCallback(() => (
    <>
      <StyledTableCell>{poundsOutSelfRollsUsed}</StyledTableCell>
      <StyledTableCell>{poundsOutRibRollsUsed}</StyledTableCell>
      <StyledTableCell>{utils.roundNumber((poundsOutSelfRollsUsed + poundsOutRibRollsUsed), 3)}</StyledTableCell>
    </>
  ), [data, selectedRoll]);

  const NumInStock = useCallback(() => (
    <>
      <StyledTableCell>{numSelfRollsInStock}</StyledTableCell>
      <StyledTableCell>{numRibRollsInStock}</StyledTableCell>
      <StyledTableCell>{utils.roundNumber((numSelfRollsInStock + numRibRollsInStock), 3)}</StyledTableCell>
    </>
  ), [data, selectedRoll]);

  const YardInStock = useCallback(() => (
    <>
      <StyledTableCell>{yardSelfRollsInStock}</StyledTableCell>
      <StyledTableCell>{yardRibRollsInStock}</StyledTableCell>
      <StyledTableCell>{utils.roundNumber((yardSelfRollsInStock + yardRibRollsInStock), 3)}</StyledTableCell>
    </>
  ), [data, selectedRoll]);

  const PoundsOutInStock = useCallback(() => (
    <>
      <StyledTableCell>{poundsOutSelfRollsInStock}</StyledTableCell>
      <StyledTableCell>{poundsOutRibRollsInStock}</StyledTableCell>
      <StyledTableCell>{utils.roundNumber((poundsOutSelfRollsInStock + poundsOutRibRollsInStock), 3)}</StyledTableCell>
    </>
  ), [data, selectedRoll]);

  /* NOTE: Didn't use map function to automatically generate table cells because contents of the values (particularly 0's, negative numbers, etc)
  * can mess with the rendering process and render out funny outputs
  * */
  return (
    <AlpineContainer hideDefaultGrid={true}>
      <StyledTableContainer>
        <StyledTable>
          <StyledTableHeader>
            <StyledTableRow>
              <StyledTableCell>Toggle:</StyledTableCell>
              <StyledTableCell colSpan={3}>
                <FormControl>
                  <Select
                    labelId="__type-selection"
                    value={type}
                    label="__type-selection"
                    onChange={handleTypeChange}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      }
                    }}
                  >
                    <MenuItem value={0}># Rolls</MenuItem>
                    <MenuItem value={1}># Rolls in Yards</MenuItem>
                    <MenuItem value={2}># Rolls in Pounds Out</MenuItem>
                  </Select>
                </FormControl>

              </StyledTableCell>
            </StyledTableRow>
          </StyledTableHeader>
          <tbody>
          <StyledTableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Self Rolls</StyledTableCell>
            <StyledTableCell>Rib Rolls</StyledTableCell>
            <StyledTableCell>Total</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>Original:</StyledTableCell>
            {
              type === 0 ?
                <NumOriginal/> :
                type === 1 ?
                  <YardOriginal/> :
                  type === 2 ?
                    <PoundsOutOriginal/> : null
            }
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>Used:</StyledTableCell>
            {
              type === 0 ?
                <NumUsed/> :
                type === 1 ?
                  <YardUsed/> :
                  type === 2 ?
                    <PoundsOutUsed/> : null
            }
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>In Stock:</StyledTableCell>
            {
              type === 0 ?
                <NumInStock/> :
                type === 1 ?
                  <YardInStock/> :
                  type === 2 ?
                    <PoundsOutInStock/> : null
            }
          </StyledTableRow>
          </tbody>
        </StyledTable>
      </StyledTableContainer>
    </AlpineContainer>
  );
};

export const FinishedGoodsAggregateTable = React.memo(FinishedGoodsTable);

const StyledTableContainer = styled.div`
  border-radius: 6px;
  overflow: hidden;
  display: grid;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  border: none;
  overflow:hidden;
  border-radius: 3px;
`;

const StyledTableHeader = styled.thead`
  font-weight: 600;
`;

const StyledTableRow = styled.tr`
  border-bottom: 1px solid rgba(0,0,0,0.2);
`;

const StyledTableCell = styled.td`
  padding: 5px;
  .MuiSelect-select:focus {
    background: none;
  }
  .MuiInput-underline:before, .MuiInput-underline:after {
    display: none;
  }
  .MuiInput-root {
    font-weight: 500;
  }
`;
