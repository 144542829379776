import React, {useEffect, useState} from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {AlpineContainer} from "../containers/defaultAlpineContainer";
import {contextMenuItems} from "../agContextMenu";
import {gridApiUtils} from "../../utils/gridApiUtils";
import {routes, cacheKeys} from "../../constants/allConsts";
import {emptyObjectGenerator} from "../emptyObjects";
import {ButtonBar} from "../buttonBar";
import {useQueryClient} from "react-query";
import {RowEditorModal} from "../modals/rowEditorModal";
import {FirstColRenderer} from "../agFrameworkComponents/renderers/firstColRenderer";
import {AddressEditor} from "../agFrameworkComponents/editors/addressEditor";
import {BodyScrollEvent, GridApi} from "ag-grid-community";
import { ILocation } from '../../types/rowModelTypes';
import { useGetRowModel, useGetRowModelInfinite, 
        useGetRowModelBasic, useAddRowModel, 
        useDeleteRowModel, useUpdateRowModel } from '../../hooks/rowModels/RowModelHooks';
import _, { filter } from "lodash";

const GetSingleLocationForPopup = (id?: string) => {
  return useGetRowModel<ILocation>(routes.DEMO_LOCATIONS , {id});
};

const GetInfiniteLocations = () => {
  return useGetRowModelInfinite<ILocation>(routes.DEMO_LOCATIONS);
};

export const LocationGrid = (props: { hideDefaultGrid?: boolean, filterId?: string | null, gridApi?: GridApi | null }): JSX.Element => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [parentGridApi] = useState(props?.gridApi || null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [serverSideDataSource, setServerSideDataSource] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedRowFilterId, setExpandedRowFilterId] = useState<string>('');
  const [isDataDisplayed, setIsDataDisplayed] = useState<boolean>(false);

  const locations = props?.filterId ? GetSingleLocationForPopup(props.filterId) : GetInfiniteLocations();

  const {mutate: addLocation} = useAddRowModel<ILocation>(routes.DEMO_LOCATIONS);
  const {mutate: updateLocation} = useUpdateRowModel<ILocation>(routes.DEMO_LOCATIONS);
  const {mutate: deleteLocation} = useDeleteRowModel<ILocation>(routes.DEMO_LOCATIONS);

  const queryClient = useQueryClient();

  useEffect(() => {
    // Load only once on mount to populate grid. Allow FE to take care of updates optimistically
    if (!isDataDisplayed && !locations?.isLoading) {
      let data = emptyObjectGenerator.location();
      data = props.filterId ? locations?.data : locations?.data?.pages
      // TODO get rid of emptyObjectGenerator. Get empty row from server instead (the grid needs at least 1 row for user to right click to add new rows)
      //@ts-ignore
      const setDataSourceFunc = props?.filterId ? gridApiUtils.setServerSideDatasource : gridApiUtils.setServerSidePaginatedDatasource;
      const dataSource: any = setDataSourceFunc(locations?.isSuccess, data)
      setServerSideDataSource(dataSource);
      setIsDataDisplayed(true);
    }
  }, [locations?.isLoading]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const closeModal = () => {
    if(gridApi) {
      const expandedNode = gridApi?.getRowNode(expandedRowFilterId);
      // refresh the previously expanded node in case changes were made to it while in expanded mode
      if (expandedNode) {
        gridApi?.redrawRows({rowNodes: [expandedNode]});
      }
    }
    setIsModalOpen(false);
    setExpandedRowFilterId('');
  };

  const expandRow = (rowId: string) => {
    setExpandedRowFilterId(rowId);
    setIsModalOpen(true);
  };

  const onCellValueChanged = (params: any) => {
    // update server
    updateLocation(params?.data);
    gridApiUtils.updatePopupTransactionUpdates(parentGridApi, gridApi, props?.filterId);
  };

  return (
    <AlpineContainer hideDefaultGrid={props?.hideDefaultGrid}>
      <ButtonBar
        addRowTitle="Add Location"
        cacheKey={cacheKeys.locationData}
        isAllDataLoaded={!locations?.isLoading}
        addRowEmptyObject={(onAddRowSuccess: any) => {
          let newRowData = {}
          // invalidate queries to refetch data from server
          addLocation(newRowData, {
            onSuccess: (data) => {
              console.log(`NEW DATA: ${JSON.stringify(data,null,2)}`)
              queryClient.invalidateQueries([cacheKeys.locationData]);
              setExpandedRowFilterId(data.id);
              onAddRowSuccess(data);
            }
          });
        }}
        // pass in gridApi for buttonBar to manipulate the grid
        gridApi={gridApi}
        gridComponent={<LocationGrid gridApi={gridApi}/>}
      >
      </ButtonBar>
      <AgGridReact
        defaultColDef={{
          resizable: true,
          editable: true,
          floatingFilter: true,
          filter: true,
          sortable: true,
          filterParams: {
            debounceMs: 100
          }
        }}
        enableRangeSelection={!props?.filterId}
        enableFillHandle={!props?.filterId}
        fillHandleDirection={'y'}
        fillOperation={gridApiUtils.setFillOperation}
        onFillEnd={gridApiUtils.fillEnd}
        getRowNodeId={(data) => {
          return data.id;
        }}
        onBodyScroll={_.debounce((event: BodyScrollEvent) => gridApiUtils.customInfinitePaginationOnBodyScroll(event, locations, gridApi), 100)}
        // turn off column virtualization if grid is displayed in a rowEditorModal (otherwise, not all the columns will show)
        suppressColumnVirtualisation={!!props?.filterId}
        // allow single-edit if grid is displayed in a rowEditorModal
        singleClickEdit={!!props?.filterId}
        suppressRowClickSelection={true}
        rowSelection={'multiple'}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={50}
        animateRows={true}
        rowModelType={'serverSide'}
        serverSideDatasource={serverSideDataSource}
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        suppressMenuHide={!!props?.hideDefaultGrid}
        //@ts-ignore
        getContextMenuItems={(params) => props?.hideDefaultGrid ? [] : contextMenuItems({
          cacheKey: cacheKeys.locationData,
          queryClient: queryClient,
          expandRow: expandRow,
          params,
          // TODO make empty object serverside
          makeEmptyObject: emptyObjectGenerator.location,
          addRowServerCall: addLocation,
          deleteRowServerCall: deleteLocation,
          rowDescription: 'location'
        })}
        stopEditingWhenCellsLoseFocus={true}
        frameworkComponents={{
          addressEditor: AddressEditor,
          firstColRenderer: FirstColRenderer,
        }}
      >
        <AgGridColumn
          field={"id"}
          lockPosition
          editable={false}
          cellClass={'suppress-fill-handle'}
          suppressColumnsToolPanel={true}
          hide
        />

        <AgGridColumn
          field={"name"}
          cellRenderer={"firstColRenderer"}
          headerClass={"span-half"}
          cellClass={"span-half"}
          cellRendererParams={{expandRow: expandRow}}
          lockPosition
          checkboxSelection={!props?.hideDefaultGrid}
          width={400}
        />
        <AgGridColumn
          field={"address"}
          minWidth={600}
          cellEditor={"addressEditor"}
          headerClass={"span-half"}
          cellClass={"ag-cell-overflow-visible-on-edit span-half"}
          flex={1}
        />
      </AgGridReact>
      <RowEditorModal
        open={isModalOpen}
        close={closeModal}
        title={"Edit Location"}
        gridComponent={<LocationGrid hideDefaultGrid={true} filterId={expandedRowFilterId} gridApi={gridApi}/>}
      />
    </AlpineContainer>
  );
};
